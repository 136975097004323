import React, {useCallback} from 'react'
import {FText, FView, FNavLink} from 'components'
import {colors} from 'styles'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {Divider, Drawer, Hidden, IconButton, List, ListItem, CircularProgress, Collapse} from '@material-ui/core'
import {Store, People, Dashboard, Menu} from '@material-ui/icons'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useSelector, useDispatch} from 'react-redux'

const DRAWER_WIDTH = 240
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
}))

const FPointOfSaleDrawer = ({pointOfSaleId}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isUpSm = useMediaQueryUp('sm')
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const pointOfSalesData = useSelector(dispatch.pointOfSales.getPointOfSales)

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen)
  }, [mobileOpen])

  if (!pointOfSaleId) {
    return (
      <FView h={'100vh'} center>
        <CircularProgress />
      </FView>
    )
  }

  const DrawerElement = (
    <FView pt={isUpSm ? 80 : 0}>
      <FView pv={25} pl={15}>
        <FText caption medium grey700>
          Point Of Sale Name:
        </FText>
        <FText body1 medium>
          {pointOfSalesData[pointOfSaleId].name}
        </FText>
      </FView>
      <Divider />
      <FView size={10} />
      <List>
        <ListItem>
          <FNavLink to={`/point-of-sales/info/${pointOfSaleId}`}>
            <FView row alignCenter>
              <Store />
              <FView size={10} />
              <FText subtitle1>Info</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <ListItem>
          <FNavLink to={`/point-of-sales/employee/${pointOfSaleId}`}>
            <FView row alignCenter>
              <People />
              <FView size={10} />
              <FText subtitle1>Employee</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <ListItem>
          <FNavLink to={`/point-of-sales/floor-table/${pointOfSaleId}`}>
            <FView row alignCenter>
              <Dashboard />
              <FView size={10} />
              <FText subtitle1>Floor and Table</FText>
            </FView>
          </FNavLink>
        </ListItem>
      </List>
    </FView>
  )

  return (
    <nav className={classes.drawer} aria-label="restaurant menu">
      <Hidden smUp implementation="css">
        <FView fixed top={80} left={0} right={0} zIndex={theme.zIndex.drawer}>
          <FView h={1} bg={theme.palette.grey['200']} />
          <FView h={50} bg={colors.white} row alignCenter shadow10>
            <IconButton aria-label="open drawer" onClick={handleDrawerToggle}>
              <Menu />
            </IconButton>
            <FView fill>
              <FText alignCenter h6>
                {pointOfSalesData[pointOfSaleId].name}
              </FText>
            </FView>
          </FView>
        </FView>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {DrawerElement}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open>
          {DrawerElement}
        </Drawer>
      </Hidden>
    </nav>
  )
}

export default FPointOfSaleDrawer
