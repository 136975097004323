import React, {useEffect, useState} from 'react'
import {FText, FView, FNavBar, FPointOfSaleDrawer, ButtonFillView} from 'components'
import {colors} from 'styles'
import {useDispatch, useSelector} from 'react-redux'
import useReactRouter from 'use-react-router'
import {useMediaQueryUp} from 'f-web/src/hooks'
import PointOfSaleEmployeeInfo from './PointOfSaleEmployeeInfo'

const PointOfSaleEmployee = () => {
  const dispatch = useDispatch()
  const {match} = useReactRouter()
  const isUpSm = useMediaQueryUp('sm')
  const {pointOfSaleId} = match.params

  const [isCreating, setIsCreating] = useState(false)
  const employees = useSelector(() => dispatch.pointOfSales.getEmployees(pointOfSaleId))

  useEffect(() => {
    return dispatch.pointOfSales.subscribeEmployees(pointOfSaleId)
  }, [dispatch.pointOfSales, pointOfSaleId])

  useEffect(() => {
    return dispatch.pointOfSales.subscribePassCodes(pointOfSaleId)
  }, [dispatch.pointOfSales, pointOfSaleId])

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow={isUpSm} />
        </FView>
        <FView fill bg={colors.background} row>
          <FPointOfSaleDrawer pointOfSaleId={pointOfSaleId} />
          <FView fill alignCenter pt={isUpSm ? 25 : 75} pb={25}>
            {Object.values(employees).map((employeeData) => {
              if (employeeData.status !== 'active') {
                return null
              }
              return (
                <PointOfSaleEmployeeInfo
                  pointOfSaleId={pointOfSaleId}
                  employeeData={employeeData}
                  key={employeeData.employeeId}
                  onCancelEvent={() => {
                    setIsCreating(false)
                  }}
                />
              )
            })}
            {isCreating ? (
              <PointOfSaleEmployeeInfo
                isCreateNew
                pointOfSaleId={pointOfSaleId}
                onCancelEvent={() => {
                  setIsCreating(false)
                }}
              />
            ) : (
              <FView pt={15}>
                <ButtonFillView w={200} rounded onClick={() => setIsCreating(true)}>
                  <FText button primaryContrast>
                    Add New Employee
                  </FText>
                </ButtonFillView>
              </FView>
            )}
          </FView>
        </FView>
      </FView>
    </FView>
  )
}

export default PointOfSaleEmployee
