import React from 'react'
import {FView, FText, ButtonFillView} from 'components'
import {useTheme} from '@material-ui/core/styles'

const IconButtonView = ({Icon, label, disabled}) => {
  const theme = useTheme()
  return (
    <ButtonFillView ph={8} w={165} row alignCenter rounded disabled={disabled}>
      <Icon style={{color: theme.palette.primary.contrastText}} />
      <FView fill>
        <FText primaryContrast button alignCenter>
          {label}
        </FText>
      </FView>
    </ButtonFillView>
  )
}

export default IconButtonView
