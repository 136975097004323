import React from 'react'
import * as utils from 'f-utils'
import {colors} from 'styles'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import useReactRouter from 'use-react-router'
import {getOrdersCreatedAtDesc} from 'f-core/src/config/firebase'
import DateMomentUtils from '@date-io/moment'
import {Grid, CircularProgress} from '@material-ui/core'
import {Done, Block, NotificationsActive, Timer, ErrorOutline} from '@material-ui/icons'
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers'
import {FView, FNavBar, FDrawer, FText, FButton, ButtonFillView} from 'components'
import OrderDetailsDialog from './OrderDetailsDialog'
import {isEmpty} from 'lodash'

let unsubOrders = null

export default function RestaurantOrders() {
  const {match} = useReactRouter()
  const {restaurantId, locationId} = match.params
  const dispatch = useDispatch()
  const isUpSm = useMediaQueryUp('sm')
  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))

  const [selectedStartDate, setSelectedStartDate] = React.useState(moment().startOf('day'))
  const [selectedEndDate, setSelectedEndDate] = React.useState(moment().endOf('day'))
  const [isFetching, setIsFetching] = React.useState(false)
  const [orders, setOrders] = React.useState({})
  const [selectedOrderId, setSelectedOrderId] = React.useState(null)
  const isSuperAdmin = dispatch.portal.getIsSuperAdmin()

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date)
  }

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date)
  }

  React.useEffect(() => {
    return () => {
      unsubOrders && unsubOrders()
    }
  }, [])

  const handleOrdersFetch = () => {
    if (isFetching) {
      return
    }
    setIsFetching(true)
    const createdAtMin = selectedStartDate.valueOf()
    const createdAtMax = selectedEndDate.valueOf()
    const limit = 500

    unsubOrders && unsubOrders()

    unsubOrders = getOrdersCreatedAtDesc({restaurantId, createdAtMin, createdAtMax, limit}).onSnapshot((snapshot) => {
      setIsFetching(false)
      const orders = {}
      for (const doc of snapshot.docs) {
        orders[doc.id] = doc.data()
        orders[doc.id].id = doc.id
      }
      setOrders(orders)
      if (snapshot.docs.length >= limit) {
        alert(`Too many orders. Only ${limit} orders were fetched.`)
      }
    })
  }

  if (!locationData) {
    return (
      <FView h={'100vh'} center>
        <CircularProgress />
      </FView>
    )
  }

  let total = 0
  let inpersonTotal = 0
  let onlineTotal = 0
  let fPointsDiscount = 0

  let foodSubTotal = 0
  let deliveryFees = 0
  let taxAmount = 0
  let taxes = {}
  let pickupTips = 0
  let inpersonPickupTips = 0
  let courierTips = 0
  let totalCommission = 0
  let networkAccessFees = 0
  let subsidizedDeliveryFees = 0
  let foodDelayContributions = 0
  let commissionTaxes = 0
  let otherAdjustments = 0
  let inpersonCourierTips = 0
  // let stripeTransfers = 0
  let grossEarnings = 0
  let cashRevenue = 0
  let netDirectDeposit = 0
  let revenue = 0

  const orderDatas = Object.values(orders)
  // eslint-disable-next-line no-unused-vars
  for (const orderData of orderDatas) {
    const orderReportDetails = utils.getOrderReportDetails({orderData})

    total += orderReportDetails.total
    inpersonTotal += orderReportDetails.inpersonTotal
    onlineTotal += orderReportDetails.onlineTotal
    fPointsDiscount += orderReportDetails.fPointsDiscount

    foodSubTotal += orderReportDetails.foodSubTotal
    deliveryFees += orderReportDetails.deliveryFee
    taxAmount += orderReportDetails.taxAmount
    if (taxes && !orderReportDetails.taxes) {
      taxes = null
    }
    if (taxes && orderReportDetails.taxes) {
      for (const [taxId, taxData] of Object.entries(orderReportDetails.taxes)) {
        if (!taxes[taxId]) {
          taxes[taxId] = {...taxData}
        } else {
          taxes[taxId].taxAmount += taxData.taxAmount
        }
      }
    }
    pickupTips += orderReportDetails.pickupTip
    inpersonPickupTips += orderReportDetails.inpersonPickupTip
    courierTips += orderReportDetails.courierTip
    totalCommission += orderReportDetails.totalCommission
    networkAccessFees += orderReportDetails.networkAccessFee
    subsidizedDeliveryFees += orderReportDetails.subsidizedDeliveryFee
    foodDelayContributions += orderReportDetails.foodDelayContribution
    commissionTaxes += orderReportDetails.commissionTax
    otherAdjustments += orderReportDetails.otherAdjustments
    inpersonCourierTips += orderReportDetails.inpersonCourierTip
    // stripeTransfers += orderReportDetails.stripeTransfer
    grossEarnings += orderReportDetails.grossEarning
    cashRevenue += orderReportDetails.cashRevenue
    netDirectDeposit += orderReportDetails.netDirectDeposit
    revenue += orderReportDetails.revenue
  }

  foodSubTotal = utils.currencyRounding(foodSubTotal)
  deliveryFees = utils.currencyRounding(deliveryFees)
  taxAmount = utils.currencyRounding(taxAmount)
  pickupTips = utils.currencyRounding(pickupTips)
  inpersonPickupTips = utils.currencyRounding(inpersonPickupTips)
  courierTips = utils.currencyRounding(courierTips)
  totalCommission = utils.currencyRounding(totalCommission)
  networkAccessFees = utils.currencyRounding(networkAccessFees)
  subsidizedDeliveryFees = utils.currencyRounding(subsidizedDeliveryFees)
  foodDelayContributions = utils.currencyRounding(foodDelayContributions)
  commissionTaxes = utils.currencyRounding(commissionTaxes)
  otherAdjustments = utils.currencyRounding(otherAdjustments)
  inpersonCourierTips = utils.currencyRounding(inpersonCourierTips)
  // stripeTransfers = utils.currencyRounding(stripeTransfers)
  grossEarnings = utils.currencyRounding(grossEarnings)
  cashRevenue = utils.currencyRounding(cashRevenue)
  netDirectDeposit = utils.currencyRounding(netDirectDeposit)
  revenue = utils.currencyRounding(revenue)

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow={isUpSm} />
        </FView>
        <FView fill bg={colors.background} row>
          <FDrawer locationData={locationData} />
          <FView fill alignCenter pt={isUpSm ? 25 : 75} pb={25}>
            <FText h5 bold gutterBottom>
              Orders
            </FText>
            <FView w="100%" maxWidth={768} bg={colors.white} p={15}>
              <MuiPickersUtilsProvider utils={DateMomentUtils}>
                <Grid container justify="space-around">
                  <FView>
                    <KeyboardDatePicker
                      margin="none"
                      label="Start Date"
                      format="MM/DD/YYYY"
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change start date',
                      }}
                    />
                    <KeyboardTimePicker
                      margin="normal"
                      label="Start Time"
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change start time',
                      }}
                    />
                  </FView>
                  <FView>
                    <KeyboardDatePicker
                      margin="none"
                      label="End Date"
                      format="MM/DD/YYYY"
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change end date',
                      }}
                    />
                    <KeyboardTimePicker
                      margin="normal"
                      label="End Time"
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change end time',
                      }}
                    />
                  </FView>
                  <FButton onPress={handleOrdersFetch}>
                    <ButtonFillView rounded center w={120} disabled={isFetching}>
                      <FText body2 bold primaryContrast>
                        {isFetching ? 'Fetching Orders...' : 'Fetch Orders'}
                      </FText>
                    </ButtonFillView>
                  </FButton>
                </Grid>
              </MuiPickersUtilsProvider>
            </FView>
            <FView w="100%" maxWidth={768} bg={colors.white} p={25}>
              <Grid container spacing={3}>
                {isSuperAdmin && (
                  <Grid item xs={12} md={6}>
                    <FView row justifyBetween alignCenter>
                      <FText h6 bold>
                        Total:
                      </FText>
                      <FText h6 bold primary>
                        ${total.toFixed(2)}
                      </FText>
                    </FView>
                    <FView row justifyBetween alignCenter>
                      <FText body1 bold grey700>
                        Online Total:
                      </FText>
                      <FText body1 bold grey700>
                        ${onlineTotal.toFixed(2)}
                      </FText>
                    </FView>
                    <FView row justifyBetween alignCenter>
                      <FText body1 bold grey700>
                        Inperson Total:
                      </FText>
                      <FText body1 bold grey700>
                        ${inpersonTotal.toFixed(2)}
                      </FText>
                    </FView>

                    {fPointsDiscount > 0 && (
                      <>
                        <FView row justifyBetween alignCenter>
                          <FText body1 bold grey700>
                            Foodly Discount:
                          </FText>
                          <FText body1 bold grey700>
                            (${fPointsDiscount.toFixed(2)})
                          </FText>
                        </FView>
                        <FText body2 bold grey700>
                          (Foodly paid for the discount)
                        </FText>
                      </>
                    )}
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <FView row justifyBetween alignCenter>
                    <FText h6 bold>
                      Gross Earnings:
                    </FText>
                    <FText h6 bold primary>
                      ${grossEarnings.toFixed(2)}
                    </FText>
                  </FView>
                  <FView row justifyBetween alignCenter>
                    <FText body1 bold grey700>
                      Food Subtotal:
                    </FText>
                    <FText body1 bold grey700>
                      ${foodSubTotal.toFixed(2)}
                    </FText>
                  </FView>
                  {deliveryFees != null && deliveryFees !== 0 && (
                    <FView row justifyBetween alignCenter>
                      <FText body1 bold grey700>
                        Delivery Fees:
                      </FText>
                      <FText body1 bold grey700>
                        ${deliveryFees.toFixed(2)}
                      </FText>
                    </FView>
                  )}
                  {isEmpty(taxes) ? (
                    <FView row justifyBetween alignCenter>
                      <FText body1 bold grey700>
                        Taxes:
                      </FText>
                      <FText body1 bold grey700>
                        ${taxAmount.toFixed(2)}
                      </FText>
                    </FView>
                  ) : (
                    Object.entries(taxes).map(([taxId, taxData]) => (
                      <FView row justifyBetween alignCenter key={taxId}>
                        <FText body1 bold grey700>
                          {taxData.name}:
                        </FText>
                        <FText body1 bold grey700>
                          ${taxData.taxAmount.toFixed(2)}
                        </FText>
                      </FView>
                    ))
                  )}
                  <FView row justifyBetween alignCenter>
                    <FText body1 bold grey700>
                      Pickup Online Tips:
                    </FText>
                    <FText body1 bold grey700>
                      ${pickupTips.toFixed(2)}
                    </FText>
                  </FView>
                  {inpersonPickupTips != null && inpersonPickupTips !== 0 && (
                    <FView row justifyBetween alignCenter>
                      <FText body1 bold grey700>
                        Pickup Cash Tips:
                      </FText>
                      <FText body1 bold grey700>
                        ${inpersonPickupTips.toFixed(2)}
                      </FText>
                    </FView>
                  )}
                  {courierTips != null && courierTips !== 0 && (
                    <FView row justifyBetween alignCenter>
                      <FText body1 bold grey700>
                        Courier Online Tips:
                      </FText>
                      <FText body1 bold grey700>
                        ${courierTips.toFixed(2)}
                      </FText>
                    </FView>
                  )}
                  {inpersonCourierTips != null && inpersonCourierTips !== 0 && (
                    <FView row justifyBetween alignCenter>
                      <FText body1 bold grey700>
                        Courier Cash Tips:
                      </FText>
                      <FText body1 bold grey700>
                        ${inpersonCourierTips.toFixed(2)}
                      </FText>
                    </FView>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <FView row justifyBetween alignCenter>
                    <FText h6 bold>
                      Total Commission:
                    </FText>
                    <FText h6 bold primary>
                      (${totalCommission.toFixed(2)})
                    </FText>
                  </FView>
                  <FView row justifyBetween alignCenter>
                    <FText body1 bold grey700>
                      Network Access Contribution:
                    </FText>
                    <FText body1 bold grey700>
                      (${networkAccessFees.toFixed(2)})
                    </FText>
                  </FView>
                  {subsidizedDeliveryFees != null && subsidizedDeliveryFees !== 0 && (
                    <FView row justifyBetween alignCenter>
                      <FText body1 bold grey700>
                        Subsidized Delivery Fees:
                      </FText>
                      <FText body1 bold grey700>
                        (${subsidizedDeliveryFees.toFixed(2)})
                      </FText>
                    </FView>
                  )}
                  {foodDelayContributions != null && foodDelayContributions !== 0 && (
                    <FView row justifyBetween alignCenter>
                      <FText body1 bold grey700>
                        Courier Delay Contribution:
                      </FText>
                      <FText body1 bold grey700>
                        (${foodDelayContributions.toFixed(2)})
                      </FText>
                    </FView>
                  )}
                  <FView row justifyBetween alignCenter>
                    <FText body1 bold grey700>
                      GST:
                    </FText>
                    <FText body1 bold grey700>
                      (${commissionTaxes.toFixed(2)})
                    </FText>
                  </FView>
                </Grid>
                {otherAdjustments != null && otherAdjustments !== 0 && (
                  <Grid item xs={12} md={6}>
                    <FView row justifyBetween alignCenter>
                      <FText h6 bold>
                        Other Adjustments:
                      </FText>
                      <FText h6 bold primary>
                        {otherAdjustments < 0 && '('}${Math.abs(otherAdjustments).toFixed(2)}
                        {otherAdjustments < 0 && ')'}
                      </FText>
                    </FView>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <FView row justifyBetween alignCenter>
                    <FText h6 bold>
                      Revenue:
                    </FText>
                    <FText h6 bold primary>
                      ${revenue.toFixed(2)}
                    </FText>
                  </FView>
                  <FView row justifyBetween alignCenter>
                    <FText body1 bold grey700>
                      Total Cash Received:
                    </FText>
                    <FText body1 bold grey700>
                      ${cashRevenue.toFixed(2)}
                    </FText>
                  </FView>
                  <FView row justifyBetween alignCenter>
                    <FText body1 bold grey700>
                      Net Amount (Actual Deposit):
                    </FText>
                    <FText body1 bold grey700>
                      ${netDirectDeposit.toFixed(2)}
                    </FText>
                  </FView>
                  {/* <FView row justifyBetween alignCenter>
                    <FText body1 bold grey700>
                      Previously Owed Commission:
                    </FText>
                    <FText body1 bold grey700>
                      (${(netDirectDeposit - stripeTransfers).toFixed(2)})
                    </FText>
                  </FView>
                  <FView row justifyBetween alignCenter>
                    <FText body1 bold grey700>
                      Actual Direct Deposit:
                    </FText>
                    <FText body1 bold grey700>
                      ${stripeTransfers.toFixed(2)}
                    </FText>
                  </FView>
                  {stripeTransfers > netDirectDeposit && (
                    <FView row justifyBetween alignCenter>
                      <FText body1 bold grey700>
                        Commission Still Owed:
                      </FText>
                      <FText body1 bold grey700>
                        ${(stripeTransfers - netDirectDeposit).toFixed(2)}
                      </FText>
                    </FView>
                  )} */}
                </Grid>
              </Grid>
            </FView>
            {Object.values(orders).map((order) => {
              return (
                <FView key={order.id} w="100%" maxWidth={768} mt={15}>
                  <FButton onPress={() => setSelectedOrderId(order.id)}>
                    <FView bg={colors.white} p={15} h={105} row>
                      <FView fill alignStart justifyBetween>
                        <FText body1 bold>
                          #{order.orderNumber}
                        </FText>
                        <FText body2>Created: {moment(order.createdAt).format('MM/DD LT')}</FText>
                        <FText body2>
                          {Object.keys(order.orderCartItems || {}).length + Object.keys(order.rewards || {}).length}{' '}
                          Item
                        </FText>
                      </FView>
                      <FView fill alignStart>
                        <FView row>
                          {order.status === 'New' ? (
                            <NotificationsActive />
                          ) : order.status === 'Preparing' ? (
                            <Timer />
                          ) : order.status === 'Done' ? (
                            <Done />
                          ) : order.status === 'Cancelled' ? (
                            <Block />
                          ) : (
                            <ErrorOutline />
                          )}
                          <FText body1>{order.status}</FText>
                        </FView>
                      </FView>
                      <FView alignEnd justifyBetween>
                        {(order.status === 'New' || order.status === 'Preparing') && (
                          <FText body2>{utils.formatPhoneNumber(order.phoneNumber)}</FText>
                        )}
                        <FText body1 bold>
                          Total: ${order.total}
                        </FText>
                        <FText body1 bold>
                          Revenue: ${order.revenue}
                        </FText>
                      </FView>
                    </FView>
                  </FButton>
                </FView>
              )
            })}
          </FView>
        </FView>
      </FView>
      <OrderDetailsDialog
        restaurantId={restaurantId}
        locationId={locationId}
        orderId={selectedOrderId}
        orderData={orders[selectedOrderId]}
        open={!!selectedOrderId}
        handleClose={() => setSelectedOrderId(null)}
      />
    </FView>
  )
}
