import {FText, ButtonFillView, FButton} from 'components'

const PERMISSIONS = [
  'MERCHANT_PROFILE_READ',
  'ORDERS_READ',
  'ORDERS_WRITE',
  'PAYMENTS_READ',
  'PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS',
  'PAYMENTS_WRITE',
]
const URL =
  process.env.REACT_APP_SQUARE_OAUTH_URL +
  `?client_id=${process.env.REACT_APP_SQUARE_APPLICATION_ID}&scope=${PERMISSIONS.join('+')}`

const SquareAuthButton = ({isLinking, onStartLink, isSuperAdmin}) => {
  return (
    <FButton
      onClick={() => {
        window.location = URL
        onStartLink()
      }}
      disabled={isLinking || isSuperAdmin}>
      <ButtonFillView disabled={isLinking || isSuperAdmin} p={10}>
        <FText white>Link Square POS</FText>
      </ButtonFillView>
    </FButton>
  )
}

export default SquareAuthButton
