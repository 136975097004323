import React, {useState, useEffect} from 'react'
import {FNavBar, FView, FText, FButton} from 'components'
import {db} from 'f-core/src/config/firebase'
import {useTheme} from '@material-ui/core/styles'
import {moment, formatPhoneNumber} from 'f-utils'
import BlockDialog from './BlockDialog'

export default function Blocklist() {
  const theme = useTheme()
  const [isBlockDialgoOpen, setIsBlockDialogOpen] = useState(false)
  const [blockedUsers, setBlockedUsers] = useState([])

  useEffect(() => {
    return db
      .collection('Blocklist')
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnaps) => {
        const blocked = querySnaps.docs.map((doc) => Object.assign(doc.data(), {id: doc.id}))
        setBlockedUsers(blocked)
      })
  }, [])

  return (
    <>
      <FView fill>
        <FNavBar fixed={false} />
        <FView alignCenter>
          <FButton onClick={() => setIsBlockDialogOpen(true)}>
            <FView round p={16} bg={theme.palette.primary.main}>
              <FText h6 bold primaryContrast>
                Create Blocked User
              </FText>
            </FView>
          </FButton>
          <FView mv={32}>
            <FText h4> Blocked Users:</FText>
            <FView>
              {blockedUsers.map((blockedUser) => (
                <FView key={blockedUser.id}>
                  <FView h={32} />
                  <FView bw={1} bc={theme.palette.primary.main} p={8} row alignCenter justifyBetween>
                    <FView>
                      {!!blockedUser.userId && (
                        <FView row alignCenter>
                          <FText bold>userId: </FText>
                          <FView w={8} />
                          <FText> {blockedUser.userId}</FText>
                        </FView>
                      )}
                      {!!blockedUser.phoneNumber && (
                        <FView row alignCenter>
                          <FText bold>Phone Number: </FText>
                          <FView w={8} />
                          <FText> {formatPhoneNumber(blockedUser.phoneNumber)}</FText>
                        </FView>
                      )}
                      {!!blockedUser.email && (
                        <FView row alignCenter>
                          <FText bold>Email: </FText>
                          <FView w={8} />
                          <FText> {blockedUser.email}</FText>
                        </FView>
                      )}
                      {!!blockedUser.description && (
                        <FView row alignCenter>
                          <FText bold>Description: </FText>
                          <FView w={8} />
                          <FText> {blockedUser.description}</FText>
                        </FView>
                      )}
                      {!!blockedUser.createdAt && (
                        <FView row alignCenter>
                          <FText bold>Created At: </FText>
                          <FView w={8} />
                          <FText> {moment(blockedUser.createdAt.toMillis()).format('LLL')}</FText>
                        </FView>
                      )}
                    </FView>
                    <FView w={32} />
                    <FButton
                      onClick={async () => {
                        if (!window.confirm('Are you sure you want to unblock this user')) {
                          return
                        }
                        try {
                          await db.collection('Blocklist').doc(blockedUser.id).delete()
                        } catch (e) {
                          alert('Error: ' + e.message)
                        }
                      }}>
                      <FView p={8} bg={theme.palette.primary.main}>
                        <FText white> Unblock</FText>
                      </FView>
                    </FButton>
                  </FView>
                </FView>
              ))}
            </FView>
          </FView>
        </FView>
      </FView>
      <BlockDialog open={isBlockDialgoOpen} onClose={() => setIsBlockDialogOpen(false)} />
    </>
  )
}
