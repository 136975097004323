import React from 'react'
import {FText, FView} from 'components'
import {moment, formatPhoneNumber} from 'f-utils'
import {useMediaMinMD} from 'f-web/src/hooks'
import {colors} from 'styles'
import {CalendarToday} from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles'
import OrderProductsDetails from './OrderProductsDetails'

export default function OrderItemView({orderData, deliveryOrderData}) {
  const isPaid = orderData?.paymentStatus === 'paid' || orderData?.paymentStatus === 'paid-online'
  const isMinMD = useMediaMinMD()
  const classes = useStyles()
  return (
    <FView fill>
      <FView fill row={isMinMD}>
        <FView p={15} fill>
          <FText bold>{orderData.restaurantName}</FText>
          <FView alignStart>
            <a href={'tel:' + orderData.restaurantPhoneNumber}>
              <FText primary>{formatPhoneNumber(orderData.restaurantPhoneNumber)}</FText>
            </a>
          </FView>
          <FText span>
            {'Order: '}
            <FText bold inline>
              #{orderData.orderNumber}
            </FText>
          </FText>
          <FText span>
            {'Order Type: '}
            <FText bold inline>
              {orderData.orderType}
            </FText>
          </FText>
          {orderData.orderType === 'Delivery' && (
            <>
              <FView size={15} />
              <FText span>
                Delivery Address:{' '}
                <FText inline bold>
                  {orderData.deliveryAddress}
                </FText>
              </FText>
              {!!orderData.deliveryUnit && (
                <FText span>
                  Unit:{' '}
                  <FText inline bold>
                    {orderData.deliveryUnit}
                  </FText>
                </FText>
              )}
              {!!orderData.deliveryInstructions && (
                <FText span>
                  Instructions:{' '}
                  <FText inline bold>
                    {orderData.deliveryInstructions}
                  </FText>
                </FText>
              )}
              <FText span>
                Delivery Status:{' '}
                <FText inline bold>
                  {deliveryOrderData?.deliveryStatus}
                </FText>
              </FText>
              {deliveryOrderData?.riderUserId && (
                <>
                  <FText span>
                    Rider Name:{' '}
                    <FText inline bold>
                      {deliveryOrderData?.riderName}
                    </FText>
                  </FText>
                  <FText span>
                    Rider Number:{' '}
                    <a href={'tel:' + deliveryOrderData?.riderPhoneNumber}>
                      <FText inline primary>
                        {formatPhoneNumber(deliveryOrderData?.riderPhoneNumber)}
                      </FText>
                    </a>
                  </FText>
                </>
              )}
            </>
          )}
          <FView size={15} />
          <FText span>
            Created:{' '}
            <FText bold inline>
              {moment(orderData.createdAt).calendar()}
            </FText>
          </FText>
          {orderData.orderTimeType === 'Scheduled' && (
            <FView row alignCenter>
              <CalendarToday className={classes.calendar} />
              <FView w={10} />
              <FText bold color={colors.warning}>
                SCHEDULED ORDER
              </FText>
            </FView>
          )}
          {(orderData.orderTimeType === 'Scheduled' || orderData.status === 'Preparing') && (
            <FView row>
              <FText>Prepare by: </FText>
              <FView w={4} />
              <FText bold>
                {orderData.orderTimeType === 'Scheduled'
                  ? moment(orderData.scheduledOrderTimestamp).format('ddd, MMM D, LT')
                  : moment(orderData.completionTime).calendar()}
              </FText>
            </FView>
          )}
          <FText span>
            {'Status: '}
            <FText bold inline>
              {orderData.status}
            </FText>
          </FText>
          {!!orderData.cancelReason && (
            <FView w={300} maxWidth={300}>
              <FText span>
                Cancel Reason:
                <FText bold inline>
                  {' ' + orderData.cancelReason}
                </FText>
              </FText>
            </FView>
          )}
          <FView size={15} />
          {!!orderData.notes && (
            <FView w={300} maxWidth={300}>
              <FText>Special Request:</FText>
              <FText bold>{orderData.notes}</FText>
            </FView>
          )}
          <FView size={15} />
          <FText span>Customer Name: {orderData.name}</FText>
          <FText span>
            Customer Number:{' '}
            <a href={'tel:' + orderData.phoneNumber}>
              <FText inline primary>
                {formatPhoneNumber(orderData.phoneNumber)}
              </FText>
            </a>
          </FText>
          <FText span>
            Payment Status:{' '}
            {orderData.paymentMethod?.startsWith('online') && (
              <FText bold inline>
                {orderData.paymentStatus === 'free' ? 'FREE' : 'Paid Online'}
              </FText>
            )}
            {orderData.paymentMethod?.startsWith('inperson') && (
              <FText bold inline error={!isPaid}>
                Cash {isPaid ? 'Paid' : 'Unpaid'}
              </FText>
            )}
          </FText>
        </FView>
        <FView w={150} p={15} justifyEnd>
          <FText span>
            Total:{' '}
            <FText bold inline>
              ${orderData.total.toFixed(2)}
            </FText>
          </FText>
        </FView>
      </FView>
      <OrderProductsDetails orderData={orderData} />
    </FView>
  )
}

const useStyles = makeStyles({
  calendar: {
    color: colors.warning,
  },
})
