import React from 'react'
import {colors} from 'styles'
import {getCollection} from 'f-core/src/config/firebase'
import {FText, FView, FButton, ButtonFillView, ButtonOutlineView} from 'components'
// import {Grid} from '@material-ui/core'
import OrderItemView from './OrderItemView'

export default function ConfirmedOrderItemView({orderData, deliveryOrderData}) {
  const orderDocRef = getCollection('Orders', {restaurantId: orderData.restaurantId}).doc(orderData.id)
  const deliveryOrderDocRef = getCollection('DeliveryInfo', {restaurantId: orderData.restaurantId}).doc(orderData.id)
  const isFoodReady = !!orderData?.isFoodReady
  const isPaid = orderData?.paymentStatus === 'paid' || orderData?.paymentStatus === 'paid-online'
  const isDone = orderData?.status === 'Done'
  const isCancelled = orderData?.status === 'Cancelled'
  // const status = orderData?.status
  return (
    <FView fill bg={colors.white}>
      <FView row>
        <OrderItemView orderData={orderData} deliveryOrderData={deliveryOrderData} />
        <FView w={2} bg={colors.lightGrey25} />
        {!isCancelled && (
          <FView p={15} maxWidth={150} w={'20vw'} center>
            <FButton
              disabled={!isFoodReady || (orderData?.paymentMethod?.startsWith('inperson') && !isPaid)}
              onPress={() => {
                if (!isFoodReady || (orderData?.paymentMethod?.startsWith('inperson') && !isPaid)) {
                  return
                }

                if (orderData?.orderType === 'Delivery') {
                  if (isDone) {
                    deliveryOrderDocRef.update({deliveryStatus: 'HeadingToCustomer'})
                    orderDocRef.update({status: 'Preparing'})
                  } else {
                    // Marking deliveryStatus as Delivered will fire functions to set status to Done
                    deliveryOrderDocRef.update({deliveryStatus: 'Delivered'})
                  }
                } else {
                  if (isDone) {
                    orderDocRef.update({status: 'Preparing'})
                  } else {
                    orderDocRef.update({status: 'Done'})
                  }
                }
              }}>
              {isDone ? (
                <ButtonOutlineView
                  rounded
                  center
                  w={100}
                  mv={2}
                  selected
                  disabled={!isFoodReady || (orderData?.paymentMethod?.startsWith('inperson') && !isPaid)}>
                  <FText body2 bold primary>
                    Mark as {orderData?.orderType === 'Delivery' ? 'Delivered' : 'Done'}
                  </FText>
                </ButtonOutlineView>
              ) : (
                <ButtonFillView
                  rounded
                  center
                  w={100}
                  mv={2}
                  disabled={!isFoodReady || (orderData?.paymentMethod?.startsWith('inperson') && !isPaid)}>
                  <FText body2 bold primaryContrast>
                    Mark as {orderData?.orderType === 'Delivery' ? 'Delivered' : 'Done'}
                  </FText>
                </ButtonFillView>
              )}
            </FButton>
            {/* <Grid container justify="space-around">
            {['New', 'Preparing', 'Done', 'Cancelled'].map((myStatus, i) => {
              return (
                <FButton
                  key={i}
                  onPress={() => {
                    if (orderData?.orderType === 'Delivery') {
                      if (myStatus === 'Done') {
                        deliveryOrderDocRef.update({deliveryStatus: 'Delivered'})
                      } else if (myStatus === 'Cancelled') {
                        deliveryOrderDocRef.update({deliveryStatus: 'Failed'})
                      }
                    }
                    orderDocRef.update({status: myStatus})
                  }}>
                  {myStatus === status ? (
                    <ButtonOutlineView rounded center w={55} selected mv={2}>
                      <FText body2 bold primary noLineHeight>
                        {myStatus.substr(0, 4)}
                      </FText>
                    </ButtonOutlineView>
                  ) : (
                    <ButtonFillView rounded center w={55} mv={2}>
                      <FText body2 bold primaryContrast>
                        {myStatus.substr(0, 4)}
                      </FText>
                    </ButtonFillView>
                  )}
                </FButton>
              )
            })}
          </Grid> */}
            <FButton
              disabled={isDone}
              onPress={() => {
                if (isDone) {
                  return
                }

                orderDocRef.update({isFoodReady: !isFoodReady})
              }}>
              {isFoodReady ? (
                <ButtonOutlineView rounded center w={100} selected mv={2} disabled={isDone}>
                  <FText body2 bold grey700={isDone} primary={!isDone}>
                    Food is Ready
                  </FText>
                </ButtonOutlineView>
              ) : (
                <ButtonFillView rounded center w={100} mv={2} disabled={isDone}>
                  <FText body2 bold primaryContrast>
                    Food is Ready
                  </FText>
                </ButtonFillView>
              )}
            </FButton>
            {orderData?.paymentMethod?.startsWith('inperson') && (
              <FButton
                onPress={() => {
                  orderDocRef.update({paymentStatus: isPaid ? 'unpaid' : 'paid'})
                }}>
                {isPaid ? (
                  <ButtonOutlineView rounded center w={100} selected mv={2}>
                    <FText body2 bold primary>
                      Paid
                    </FText>
                  </ButtonOutlineView>
                ) : (
                  <ButtonFillView rounded center w={100} mv={2}>
                    <FText body2 bold primaryContrast>
                      Paid
                    </FText>
                  </ButtonFillView>
                )}
              </FButton>
            )}
          </FView>
        )}
      </FView>
      <FView h={2} bg={colors.lightGrey25} />
    </FView>
  )
}
