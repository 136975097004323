import React from 'react'
import {FView, FText} from 'components'
import {LocalAtm} from '@material-ui/icons'

export default function OrderItemAddedCharges({addedCharges}) {
  return addedCharges.map((charge, index) => (
    <FView row alignCenter minHeight={20} key={index} mb={4}>
      <FView size={20} center>
        <FText bold body1>
          1
        </FText>
      </FView>
      <FView size={6} />
      <LocalAtm />
      <FView size={6} />
      <FView fill>
        <FText body1>{`${charge?.name}`}</FText>
      </FView>
      <FView w={60}>
        <FText body1 alignRight>
          {`$${charge?.price?.toFixed(2)}`}
        </FText>
      </FView>
    </FView>
  ))
}
