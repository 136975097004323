import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FText, FView, FButton, ButtonFillView, FInput} from 'components'
import {Close} from '@material-ui/icons'
import {Dialog, DialogTitle, DialogContent, Divider} from '@material-ui/core'
import * as utils from 'f-utils'
import moment from 'moment'
import {colors} from 'styles'

const DelayOrderDialog = React.memo(({open, setOpenDeliveryOrderDialog, orderId, restaurantId}) => {
  const dispatch = useDispatch()
  const [delayMinutes, setDelayMinutes] = useState(0)
  const [isDelaying, setIsDelaying] = useState(false)
  const orders = useSelector(dispatch.orders.getActiveDeliveryOrders)
  const orderData = orders[orderId]
  const handleDelayOrder = () => {
    if (delayMinutes === '') {
      alert('Please enter a number to delay an order.')
      return
    }
    setIsDelaying(true)
    dispatch.orders
      .updateOrderCompletionTime({
        restaurantId,
        orderId,
        completionTime: (orderData?.pickupTime || orderData?.estimatedPickupTime) + delayMinutes * 60000,
      })
      .catch((e) => alert('Error delaying order. ' + e.message))
      .finally(() => {
        setIsDelaying(false)
        handleClose()
      })
  }
  const handleClose = () => {
    setDelayMinutes(0)
    setOpenDeliveryOrderDialog(false)
  }
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle>
        <FView row alignCenter>
          <FView fill alignCenter ml={48}>
            <FText bold h5>
              Delay Order
            </FText>
          </FView>
          <FButton onClick={handleClose}>
            <Close />
          </FButton>
        </FView>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <FView>
          <FView row center mb={8}>
            <FButton
              onClick={() =>
                setDelayMinutes(
                  delayMinutes >= 60 ? delayMinutes - 10 : delayMinutes >= 10 ? delayMinutes - 5 : delayMinutes - 1,
                )
              }
              disabled={delayMinutes < 1}>
              <ButtonFillView disabled={delayMinutes < 1} ph={16} rounded>
                <FText white>{delayMinutes >= 60 ? '-10' : delayMinutes >= 10 ? '-5' : '-1'}</FText>
              </ButtonFillView>
            </FButton>
            <FView ph={16} row alignCenter>
              <FView maxWidth={64} bc={colors.grey50} bw={1} rounded mr={10}>
                <FInput
                  style={{fontSize: 22, height: 50}}
                  value={delayMinutes}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleDelayOrder()
                    }
                  }}
                  onChange={(e) => setDelayMinutes(e.target.value.replace(/\D/g, ''))}
                />
              </FView>
              <FText h6 bold>
                Minutes
              </FText>
            </FView>
            <FButton
              onClick={() =>
                setDelayMinutes(
                  delayMinutes >= 60 ? delayMinutes + 10 : delayMinutes >= 10 ? delayMinutes + 5 : delayMinutes + 1,
                )
              }>
              <ButtonFillView ph={16} rounded>
                <FText white>{delayMinutes >= 60 ? '+10' : delayMinutes >= 10 ? '+5' : '+1'}</FText>
              </ButtonFillView>
            </FButton>
          </FView>
          <FText span>
            Prepare Food By:{' '}
            <FText inline bold>
              {utils
                .moment(orderData?.pickupTime || orderData?.estimatedPickupTime)
                .add(delayMinutes, 'minutes')
                .format('MMM D, LT')}
            </FText>
          </FText>
          <FText span>
            Deliver By:{' '}
            <FText inline bold>
              {moment(orderData?.deliveryTime || orderData?.estimatedDeliveryTime)
                .add(delayMinutes, 'minutes')
                .format('MMM D, LT')}
            </FText>
          </FText>
          <FView mv={8}>
            <FButton disabled={isDelaying} onClick={handleDelayOrder}>
              <ButtonFillView disabled={isDelaying} rounded>
                <FText bold white>
                  {isDelaying ? 'Delaying Order...' : 'Delay Order'}
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
      </DialogContent>
    </Dialog>
  )
})

export default DelayOrderDialog
