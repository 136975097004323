import React, {useRef, useEffect, useState} from 'react'
import {FView, FText, FTextField, ButtonFillView} from 'components'
import {colors} from 'styles'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {MenuItem} from '@material-ui/core'
import {useSelector, useDispatch} from 'react-redux'

const PointOfSaleEmployeeInfo = ({pointOfSaleId, employeeData, isCreateNew, onCancelEvent}) => {
  const dispatch = useDispatch()

  const isUpSm = useMediaQueryUp('sm')

  const nameRef = useRef()
  const roleRef = useRef()
  const clockInCodeRef = useRef()
  const phoneNumberRef = useRef()

  const passCodes = useSelector(dispatch.pointOfSales.getPassCodes)

  const [isDataChanged, setIsDataChanged] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (employeeData) {
      nameRef.current.value = employeeData.name
      roleRef.current.value = employeeData.role
      clockInCodeRef.current.value = employeeData.clockInCode
      phoneNumberRef.current.value = employeeData.phoneNumber
    }
  }, [pointOfSaleId, employeeData])

  const handleClockInCodeChange = (event) => {
    if (event.target?.name === 'clockInCodeTextField') {
      if (event.target?.value < 0) {
        event.target.value = 0
      }
      event.target.value = Math.round(event.target?.value, 0)
    }
    if (isCreateNew) {
      return
    }
    if (event.target?.value === employeeData.clockInCode) {
      setIsDataChanged(false)
    } else {
      setIsDataChanged(true)
    }
  }

  const handleNameChange = (event) => {
    if (isCreateNew) {
      return
    }
    if (event.target?.value === employeeData.name) {
      setIsDataChanged(false)
    } else {
      setIsDataChanged(true)
    }
  }

  const handleRoleChange = (event) => {
    if (isCreateNew) {
      return
    }
    if (event.target?.value === employeeData.role) {
      setIsDataChanged(false)
    } else {
      setIsDataChanged(true)
    }
  }

  const handlePhoneNumberChange = (event) => {
    if (isCreateNew) {
      return
    }
    if (event.target?.value === employeeData.phoneNumber) {
      setIsDataChanged(false)
    } else {
      setIsDataChanged(true)
    }
  }

  const handleUpdateEmployee = async () => {
    setIsEditing(true)
    if (nameRef.current.value === '' || nameRef.current.value === null) {
      alert('Please enter name')
      setIsEditing(false)
      return
    }
    if (clockInCodeRef.current.value === '' || nameRef.current.value === null) {
      alert('Please enter clock in code')
      setIsEditing(false)
      return
    }
    if (isCreateNew) {
      if (Object.keys(passCodes).includes(clockInCodeRef.current.value)) {
        alert('Same clock in code already exists')
        setIsEditing(false)
        return
      }
      try {
        const doc = await dispatch.pointOfSales.createNewEmployee(pointOfSaleId, {
          name: nameRef.current.value,
          clockInCode: clockInCodeRef.current.value,
          phoneNumber: phoneNumberRef.current.value,
          role: roleRef.current.value,
          status: 'active',
        })
        await dispatch.pointOfSales.createNewPassCode(pointOfSaleId, clockInCodeRef.current.value, doc.id)
      } catch (e) {
        alert(e.message)
        setIsEditing(false)
        return
      }
      onCancelEvent()
    } else {
      if (
        clockInCodeRef.current.value !== employeeData.clockInCode &&
        Object.keys(passCodes).includes(clockInCodeRef.current.value)
      ) {
        alert('Same clock in code already exists')
        setIsEditing(false)
        return
      }
      try {
        await dispatch.pointOfSales.deletePassCode(pointOfSaleId, employeeData.clockInCode)
        await dispatch.pointOfSales.createNewPassCode(
          pointOfSaleId,
          clockInCodeRef.current.value,
          employeeData.employeeId,
        )
        await dispatch.pointOfSales.updateEmployee(pointOfSaleId, employeeData.employeeId, {
          name: nameRef.current.value,
          clockInCode: clockInCodeRef.current.value,
          phoneNumber: phoneNumberRef.current.value,
          role: roleRef.current.value,
          status: 'active',
        })
        setIsDataChanged(false)
      } catch (e) {
        alert(e.message)
        setIsEditing(false)
        return
      }
    }
    setIsEditing(false)
  }

  const handleDisableEmployee = async () => {
    try {
      await dispatch.pointOfSales.disableEmployee(pointOfSaleId, employeeData.employeeId, employeeData.clockInCode)
    } catch (e) {
      alert(e.message)
      return
    }
  }

  return (
    <>
      <FView row ph={15} bg={colors.white} alignCenter>
        <FView size={25} />
        <FView>
          <FView grid gridTemplateColumns={isUpSm ? '1fr 1fr 1fr 1fr' : '1fr 1fr'} columnGap={15}>
            <FTextField
              name="nameTextField"
              required
              label="Name"
              onChange={handleNameChange}
              inputRef={nameRef}
              defaultValue={isCreateNew ? '' : employeeData.name}
            />
            <FTextField
              name="clockInCodeTextField"
              required
              label="Clock In Code"
              onChange={handleClockInCodeChange}
              type="number"
              inputProps={{min: '0', step: '1'}}
              inputRef={clockInCodeRef}
              defaultValue={isCreateNew ? '' : employeeData.clockInCode}
            />
            <FTextField
              name="roleTextField"
              select
              required
              label="Role"
              onChange={handleRoleChange}
              inputRef={roleRef}
              disabled
              defaultValue={isCreateNew ? 'employee' : employeeData.role}>
              <MenuItem key={'admin'} value={'admin'}>
                admin
              </MenuItem>
              <MenuItem key={'employee'} value={'employee'}>
                employee
              </MenuItem>
            </FTextField>
            <FTextField
              name="phoneNumberTextField"
              label="phoneNumber"
              onChange={handlePhoneNumberChange}
              inputRef={phoneNumberRef}
              defaultValue={isCreateNew ? '' : employeeData.phoneNumber}
            />
          </FView>
        </FView>
        {isCreateNew ? (
          <FView row pl={15} fill alignEnd>
            <ButtonFillView w={100} rounded onClick={handleUpdateEmployee}>
              <FText button primaryContrast>
                Add
              </FText>
            </ButtonFillView>
            <FView size={5} />
            <ButtonFillView w={100} rounded onClick={onCancelEvent}>
              <FText button primaryContrast>
                Cancel
              </FText>
            </ButtonFillView>
          </FView>
        ) : (
          <FView row pl={15} fill alignEnd>
            <ButtonFillView w={100} rounded disabled={!isDataChanged || isEditing} onClick={handleUpdateEmployee}>
              <FText button primaryContrast>
                {isEditing ? 'Editing' : 'Edit'}
              </FText>
            </ButtonFillView>
            <FView size={5} />
            <ButtonFillView w={100} rounded onClick={handleDisableEmployee}>
              <FText button primaryContrast>
                Remove
              </FText>
            </ButtonFillView>
          </FView>
        )}
      </FView>
      <FView size={8} />
    </>
  )
}

export default PointOfSaleEmployeeInfo
