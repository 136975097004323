import React, {useRef, useState} from 'react'
import {FView, FButton, FTextField, ButtonFillView, FText} from 'components'
import {Close} from '@material-ui/icons'
import {useTheme} from '@material-ui/core/styles'
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core'

export default function FloorNameModal({open, onClose, onClick}) {
  const theme = useTheme()
  const floorNameRef = useRef()
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <FView row alignCenter>
          <FTextField label="Floor Name" required inputRef={floorNameRef} defaultValue="" />
          <FView size={5} />
          <ButtonFillView
            w={100}
            rounded
            onClick={() => {
              if (floorNameRef.current?.value === null || floorNameRef.current?.value === '') {
                alert('Please enter name')
                return
              }
              onClick(floorNameRef.current.value)
              onClose()
            }}>
            <FText button primaryContrast>
              Add
            </FText>
          </ButtonFillView>
          <FView size={5} />
          <FButton onClick={onClose}>
            <Close />
          </FButton>
        </FView>
      </DialogTitle>
      <DialogContent></DialogContent>
    </Dialog>
  )
}
