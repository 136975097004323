import React, {useEffect, useState} from 'react'
import {FView, FNavBar, FPointOfSaleDrawer, FTextField, ButtonFillView, FText} from 'components'
import {colors} from 'styles'
import {useDispatch, useSelector} from 'react-redux'
import useReactRouter from 'use-react-router'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {MenuItem} from '@material-ui/core'
import PointOfSaleTableInfo from './PointOfSaleTableInfo'
import FloorNameModal from './FloorNameModal'

const PointOfSaleFloorTable = () => {
  const dispatch = useDispatch()
  const {match} = useReactRouter()
  const isUpSm = useMediaQueryUp('sm')
  const {pointOfSaleId} = match.params
  const floors = useSelector(dispatch.pointOfSales.getFloors)
  const tables = useSelector(dispatch.pointOfSales.getTables)
  const pointOfSaleData = useSelector(() => dispatch.pointOfSales.getPointOfSale(pointOfSaleId))

  const [isCreating, setIsCreating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isFloorEmpty, setIsFloorEmpty] = useState(false)
  const [isCreatingNewFloor, setIsCreatingNewFloor] = useState(false)
  const [selectedFloorId, setSelectedFloorId] = useState(null)
  const [openFloorNameModal, setOpenFloorNameModal] = useState(false)

  useEffect(() => {
    return dispatch.pointOfSales.subscribeTables(pointOfSaleId)
  }, [dispatch.pointOfSales, pointOfSaleId])

  useEffect(() => {
    return dispatch.pointOfSales.subscribeFloors(pointOfSaleId)
  }, [dispatch.pointOfSales, pointOfSaleId])

  useEffect(() => {
    if (!isDeleting && selectedFloorId !== '' && selectedFloorId !== null) {
      setIsFloorEmpty(floors[selectedFloorId].tableIds.length === 0)
    }
  }, [floors, isDeleting, selectedFloorId])

  const handleAddNewFloor = async (name) => {
    setIsCreatingNewFloor(true)
    try {
      const doc = await dispatch.pointOfSales.createNewFloor(pointOfSaleId, {
        name: name,
      })
      const floorOrderIds = pointOfSaleData.floorOrderIds
      floorOrderIds.push(doc.id)
      await dispatch.pointOfSales.updatePointOfSale(pointOfSaleId, {floorOrderIds})
    } catch (e) {
      alert(e.message)
    }
    setIsCreatingNewFloor(false)
  }

  const handleDeleteFloor = async () => {
    if (!isFloorEmpty) {
      alert('Floor is not empty and cannot be deleted')
      return
    }
    setIsDeleting(true)
    try {
      const floorOrderIds = pointOfSaleData.floorOrderIds
      const index = floorOrderIds.indexOf(selectedFloorId)
      if (index > -1) {
        floorOrderIds.splice(index, 1)
      }
      await dispatch.pointOfSales.deleteFloor(pointOfSaleId, selectedFloorId)
      await dispatch.pointOfSales.updatePointOfSale(pointOfSaleId, {floorOrderIds})
    } catch (e) {
      alert(e.message)
    }
    setSelectedFloorId('')
    setIsFloorEmpty(false)
    setIsDeleting(false)
  }

  const handleFloorChange = (event) => {
    setIsCreating(false)
    if (event.target.value) {
      setIsFloorEmpty(floors[event.target.value].tableIds.length === 0)
    }
    setSelectedFloorId(event.target.value)
  }

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow={isUpSm} />
        </FView>
        <FView fill bg={colors.background} row>
          <FPointOfSaleDrawer pointOfSaleId={pointOfSaleId} />
          <FView fill alignCenter pt={isUpSm ? 25 : 75} pb={25}>
            <FView w={'80%'} bg={colors.white} ph={15} row fill alignCenter>
              <FTextField select name="floorTextField" label="Floor" onChange={handleFloorChange}>
                {Object.values(pointOfSaleData.floorOrderIds).map((floorId) => {
                  return (
                    <MenuItem key={floorId} value={floorId}>
                      {floors[floorId]?.name}
                    </MenuItem>
                  )
                })}
              </FTextField>
              <FView size={5} />
              <ButtonFillView
                onClick={() => {
                  setOpenFloorNameModal(true)
                }}
                w={isUpSm ? 200 : 150}
                disabled={isCreatingNewFloor}
                rounded>
                <FText primaryContrast body1 bold>
                  {isCreatingNewFloor ? 'Adding' : 'Add New Floor'}
                </FText>
              </ButtonFillView>
              <FView size={5} />
              <ButtonFillView
                disabled={!selectedFloorId || selectedFloorId === '' || !isFloorEmpty || isDeleting}
                onClick={handleDeleteFloor}
                w={isUpSm ? 200 : 150}
                rounded>
                <FText primaryContrast body1 bold>
                  {isDeleting ? 'Deleting' : 'Delete'}
                </FText>
              </ButtonFillView>
            </FView>
            <FView size={25} />
            {floors[selectedFloorId]?.tableIds.map((tableId) => {
              const tableData = tables[tableId]
              const floorId = selectedFloorId
              return (
                <PointOfSaleTableInfo
                  floorId={floorId}
                  key={tableId}
                  tableData={tableData}
                  onCancelEvent={() => {
                    setIsCreating(false)
                  }}
                />
              )
            })}
            {selectedFloorId &&
              (isCreating ? (
                <PointOfSaleTableInfo
                  floorId={selectedFloorId}
                  isCreateNew
                  onCancelEvent={() => setIsCreating(false)}
                />
              ) : (
                <FView pt={15}>
                  <ButtonFillView w={200} rounded onClick={() => setIsCreating(true)}>
                    <FText button primaryContrast>
                      Add New Table
                    </FText>
                  </ButtonFillView>
                </FView>
              ))}
          </FView>
        </FView>
      </FView>
      <FloorNameModal
        open={openFloorNameModal}
        onClose={() => setOpenFloorNameModal(false)}
        onClick={handleAddNewFloor}
      />
    </FView>
  )
}

export default PointOfSaleFloorTable
