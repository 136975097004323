import React, {useRef} from 'react'
import {FView, FText, FInput, FButton, ButtonFillView} from 'components'
import {css} from 'emotion'
import {useDispatch} from 'react-redux'
import * as images from 'images'
import {colors} from 'styles'

const SignIn = () => {
  const emailInputRef = useRef()
  const passwordInputRef = useRef()
  const dispatch = useDispatch()

  const _handleSignInPress = () => {
    const email = emailInputRef.current.value
    const password = passwordInputRef.current.value
    dispatch.portal.signInWithEmailAndPassword(email, password).catch((e) => {
      alert(e.message)
    })
  }

  return (
    <FView h="100vh" bg={colors.background} center overflowX="hidden">
      <FView center mt={68}>
        <img src={images.foodlyLogo} alt="foodly logo" height="85" width="262" className={classes.foodlyLogo} />
      </FView>
      <FView mt={10} center>
        <FText h4>Foodly Restaurants Portal</FText>
      </FView>
      <FView w={395} maxWidth="100vw" p={15} bg={colors.white} mt={25}>
        <SignInItemView label="Email">
          <FInput ref={emailInputRef} type="email" autoComplete="email" placeholder="Email" autoFocus />
        </SignInItemView>
        <FView h={15} />
        <SignInItemView label="Password">
          <FInput
            onKeyPress={(e) => {
              e.key === 'Enter' && _handleSignInPress()
            }}
            ref={passwordInputRef}
            type="password"
            autoComplete="current-password"
            placeholder="Password"
          />
        </SignInItemView>
        <FView size={15} />
        <FButton alignStretch onClick={_handleSignInPress}>
          <ButtonFillView rounded>
            <FText button semiBold primaryContrast>
              Sign In
            </FText>
          </ButtonFillView>
        </FButton>
      </FView>
    </FView>
  )
}

function SignInItemView({label, children}) {
  return (
    <FView>
      <FText semiBold caption>
        {label}
      </FText>
      <FView bc={colors.lightGrey} bw={1} rounded justifyCenter>
        {children}
      </FView>
    </FView>
  )
}

const classes = {
  foodlyLogo: css({objectFit: 'contain'}),
}

export default SignIn
