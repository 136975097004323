import React from 'react'
import {useHistory} from 'react-router-dom'
import {FButtonA, ButtonFillView, FText, FView, FLinkAnimate} from 'components'
import * as images from 'images'
import {css} from 'emotion'
import {colors} from 'styles'
import {useDispatch, useSelector} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useTheme} from '@material-ui/core/styles'

const FNavBar = ({shadow, fixed = true}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()
  const email = useSelector(dispatch.portal.getUserEmail)
  const isUpSm = useMediaQueryUp('sm')

  return (
    <FView
      fixed={fixed}
      row
      width="100%"
      left={0}
      right={0}
      top={0}
      h={80}
      shadow10={shadow}
      justifyBetween
      alignCenter
      zIndex={theme.zIndex.drawer + 50}
      ph={isUpSm ? 64 : 16}
      bg={colors.white}>
      <FLinkAnimate to="/">
        <img className={classes.container} src={isUpSm ? images.foodlyLogo : images.foodlyIcon} alt="foodlyLogo" />
      </FLinkAnimate>
      <FView row alignCenter>
        <FText medium>{email}</FText>
        <FView mh={15} w={1} selfStretch bg={colors.grey25} />
        <FButtonA
          onClick={() => {
            dispatch.portal.signOut().then(() => {
              history.push('/')
            })
          }}>
          <ButtonFillView w={isUpSm ? 100 : 75} rounded>
            <FText body2 bold primaryContrast>
              Sign Out
            </FText>
          </ButtonFillView>
        </FButtonA>
      </FView>
    </FView>
  )
}

const classes = {
  container: css({
    height: 40,
  }),
}

export default FNavBar
