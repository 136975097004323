import React, {useEffect, useState} from 'react'
import {colors} from 'styles'
import {useDispatch, useSelector} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import useReactRouter from 'use-react-router'
import {CircularProgress} from '@material-ui/core'
import {FView, FNavBar, FDrawer} from 'components'
import RestaurantInfo from './RestaurantInfo'

const Restaurant = () => {
  const dispatch = useDispatch()
  const {match} = useReactRouter()
  const isUpSm = useMediaQueryUp('sm')
  const {locationId} = match.params
  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const {restaurantId} = match.params
  const isSuperAdmin = dispatch.portal.getIsSuperAdmin()
  const [privateData, setPrivateData] = useState(null)
  const [isPrivateDataLoaded, setIsPrivateDataLoaded] = useState(false)

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch.restaurants.getPrivate({restaurantId: restaurantId}).then((data) => {
        if (isSuperAdmin) {
          setPrivateData(data)
        } else {
          setPrivateData(null)
        }
        setIsPrivateDataLoaded(true)
      })
    } else {
      setPrivateData(null)
      setIsPrivateDataLoaded(true)
    }
  }, [dispatch.restaurants, isSuperAdmin, restaurantId])

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow={isUpSm} />
        </FView>
        <FView fill bg={colors.background} row>
          <FDrawer locationData={locationData} />
          <FView fill alignCenter pt={isUpSm ? 25 : 75} pb={25}>
            {locationData && isPrivateDataLoaded ? (
              <RestaurantInfo locationId={locationId} locationData={locationData} privateData={privateData} />
            ) : (
              <FView h={'100vh'} center>
                <CircularProgress />
              </FView>
            )}
          </FView>
        </FView>
      </FView>
    </FView>
  )
}

export default Restaurant
