import {getCollection, FieldValue, subscribeCollection} from 'f-core/src/config/firebase'

let unsubTranslations = null

const translationsModel = {
  state: {
    Translations: {},
  },
  reducers: {
    setTranslations(state, translations) {
      return {
        ...state,
        Translations: translations,
      }
    },
  },
  actions: ({dispatch, getState}) => ({
    getTranslations() {
      return getState().translations.Translations ?? {}
    },
    getTranslation(translationId) {
      return dispatch.translations.getTranslations()[translationId]
    },
    getTranslationInput(translationId) {
      return dispatch.translations.getTranslation(translationId)?.input
    },
    getTranslationTranslated(translationId) {
      return dispatch.translations.getTranslation(translationId)?.translated
    },
    getTranslationTarget(translationId) {
      return dispatch.translations.getTranslation(translationId)?.target
    },
    getTranslationCreatedAt(translationId) {
      return dispatch.translations.getTranslation(translationId)?.createdAt
    },
    getTranslationUpdatedAt(translationId) {
      return dispatch.translations.getTranslation(translationId)?.updatedAt
    },
    subscribeTranslations() {
      unsubTranslations && unsubTranslations()
      unsubTranslations = subscribeCollection(
        getCollection('Translations').orderBy('updatedAt', 'desc'),
        dispatch.translations.setTranslations,
      )
      return unsubTranslations
    },
    addTranslation(translationData) {
      return getCollection('Translations').add({
        input: translationData.input,
        target: translationData.target,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      })
    },
    deleteTranslation(translationId) {
      return getCollection('Translations').doc(translationId).delete()
    },
    updateTranslationInput(translationId, translationInput) {
      return getCollection('Translations').doc(translationId).update({
        input: translationInput,
        translated: {},
        updatedAt: FieldValue.serverTimestamp(),
      })
    },
    updateTranslation(translationData) {
      return getCollection('Translations').doc(translationData.id).update({
        target: translationData.target,
        translated: translationData.translated,
        updatedAt: FieldValue.serverTimestamp(),
      })
    },
  }),
}
export default translationsModel
