import React, {useState, useCallback} from 'react'
import {FText, FView, FNavLink} from 'components'
import {colors} from 'styles'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {Divider, Drawer, Hidden, IconButton, List, ListItem} from '@material-ui/core'
import {Menu, Store, Add} from '@material-ui/icons'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useSelector, useDispatch} from 'react-redux'

export default function RegionDrawer() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isUpSm = useMediaQueryUp('sm')
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)
  const regions = useSelector(dispatch.regions.getRegions)

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen)
  }, [mobileOpen])

  const DrawerElement = (
    <FView pt={isUpSm ? 80 : 0}>
      <FView pv={16} pl={16}>
        <FText h6 medium grey700>
          Regions
        </FText>
      </FView>
      <Divider />
      <FView size={8} />
      <List>
        {Object.values(regions).map((region) => (
          <ListItem key={region.id}>
            <FNavLink to={`/regions/info/${region.id}`}>
              <FView row alignCenter>
                <Store />
                <FView size={8} />
                <FText subtitle1>{region.id}</FText>
              </FView>
            </FNavLink>
          </ListItem>
        ))}
      </List>
      <FNavLink to={`/regions/addRegion`}>
        <FView row ph={16}>
          <Add />
          <FView size={8} />
          <FText>add region</FText>
        </FView>
      </FNavLink>
    </FView>
  )

  return (
    <nav className={classes.drawer} aria-label="restaurant menu">
      <Hidden smUp implementation="css">
        <FView fixed top={80} left={0} right={0} zIndex={theme.zIndex.drawer}>
          <FView h={1} bg={theme.palette.grey['200']} />
          <FView h={50} bg={colors.white} row alignCenter shadow10>
            <IconButton aria-label="open drawer" onClick={handleDrawerToggle}>
              <Menu />
            </IconButton>
            <FView fill>
              <FText alignCenter h6>
                Regions
              </FText>
            </FView>
          </FView>
        </FView>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {DrawerElement}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer classes={{paper: classes.drawerPaper}} variant="permanent" open>
          {DrawerElement}
        </Drawer>
      </Hidden>
    </nav>
  )
}

const DRAWER_WIDTH = 240
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
}))
