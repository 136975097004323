import React, {useRef, useState, useEffect} from 'react'
import {getCollection, getDocument} from 'f-core/src/config/firebase'
import {useTheme} from '@material-ui/core/styles'
import {Divider} from '@material-ui/core'
import {FNavBar, FView, FText, FTextField, ButtonFillView, FButtonA} from 'components'
import * as utils from 'f-utils'
import Addresses from './Addresses'
import Payments from './Payments'
import FPoints from './UserFPoints'
import {useLocation, useHistory} from 'react-router'
import {isEmpty} from 'lodash'
import qs from 'qs'
import {usePrevious} from 'f-web/src/hooks'

let unsubUsers

export default function Users() {
  const theme = useTheme()
  const location = useLocation()
  const history = useHistory()
  const params = qs.parse(location.search, {ignoreQueryPrefix: true})

  const emailInputRef = useRef()
  const phoneNumberInputRef = useRef()
  const idInputRef = useRef()

  // 1. Create a state that contains list of all userData, readwrite, readonly, RewardHistory as well as function to unsubscribe from that user.
  const [users, setUsers] = useState({})
  const [usersReadonly, setUsersReadonly] = useState({})
  const [usersReadwrite, setUsersReadwrite] = useState({})
  const [usersRewardHistory, setUsersRewardHistory] = useState({})

  const prevUsers = usePrevious(users)

  useEffect(() => {
    const _prevUsers = prevUsers ?? {}
    const addedUsers = []
    for (const userId of Object.keys(users)) {
      if (!_prevUsers[userId]) {
        addedUsers.push(userId)
      }
    }

    const deletedUsers = []
    for (const prevUserId of Object.keys(_prevUsers)) {
      if (!users[prevUserId]) {
        deletedUsers.push(prevUserId)
      }
    }

    // 3. Everytime list of uers gets updated, check any added/missing users
    //    a. if user is missing, unsubs from readwrite, readonly, RewardHistory and remove from their state
    if (!isEmpty(deletedUsers)) {
      for (const userId of deletedUsers) {
        setUsersReadonly((usersReadonly) => {
          if (!usersReadonly[userId]) {
            return usersReadonly
          }
          usersReadonly[userId].unsub()
          delete usersReadonly[userId]
          return {...usersReadonly}
        })
        setUsersReadwrite((usersReadwrite) => {
          if (!usersReadwrite[userId]) {
            return usersReadwrite
          }
          usersReadwrite[userId].unsub()
          delete usersReadwrite[userId]
          return {...usersReadwrite}
        })
        setUsersRewardHistory((usersRewardHistory) => {
          if (!usersRewardHistory[userId]) {
            return usersRewardHistory
          }
          usersRewardHistory[userId].unsub()
          delete usersRewardHistory[userId]
          return {...usersRewardHistory}
        })
      }
    }
    //    b. If user is added, subscribe to that user's readwrite, readonly, and RewardHistory.
    for (const userId of addedUsers) {
      const unsubReadonly = getDocument('readonly', {userId}).onSnapshot((snap) => {
        if (snap && snap.exists) {
          setUsersReadonly((usersReadonly) => {
            usersReadonly[userId] = snap.data() ?? {}
            usersReadonly[userId].unsub = unsubReadonly
            return {...usersReadonly}
          })
        }
      })
      const unsubReadwrite = getDocument('readwrite', {userId}).onSnapshot((snap) => {
        if (snap && snap.exists) {
          setUsersReadwrite((usersReadwrite) => {
            usersReadwrite[userId] = snap.data() ?? {}
            usersReadwrite[userId].unsub = unsubReadwrite
            return {...usersReadwrite}
          })
        }
      })
      const unsubRewardHistory = getCollection('RewardHistory', {userId})
        .orderBy('createdAt', 'desc')
        .limit(50)
        .onSnapshot((snap) => {
          setUsersRewardHistory((usersRewardHistory) => {
            const rewardHistoryList = []
            for (const doc of snap.docs) {
              const rewardHistory = doc.data()
              rewardHistory.id = doc.id
              rewardHistoryList.push(rewardHistory)
            }
            usersRewardHistory[userId] = {
              rewardHistoryList,
              unsub: unsubRewardHistory,
            }
            return {...usersRewardHistory}
          })
        })
    }
  }, [users, prevUsers])

  // 2. Create useEffect that does onSnapshot on users filtered by id, email, or phoneNumber from params, with id, email and phoneNumber as re-render dependencies.
  const {userId, email, phoneNumber} = params
  useEffect(() => {
    let usersCollectionRef = getCollection('Users')
    if (userId) {
      usersCollectionRef = usersCollectionRef.where('id', '==', userId)
    } else if (email) {
      usersCollectionRef = usersCollectionRef.where('email', '==', email)
    } else if (phoneNumber) {
      usersCollectionRef = usersCollectionRef.where('phoneNumber', '==', phoneNumber)
    } else {
      unsubUsers && unsubUsers()
      return
    }
    unsubUsers && unsubUsers()
    unsubUsers = usersCollectionRef.onSnapshot(async (snapshot) => {
      const usersData = {}
      for (const doc of snapshot.docs) {
        const userId = doc.id
        usersData[userId] = doc.data()
        usersData[userId].id = userId
      }
      setUsers(usersData)
    })
  }, [userId, email, phoneNumber])

  function handleUserLookup(inputType) {
    switch (inputType) {
      case 'userId':
        history.replace(`/users?userId=${idInputRef.current.value}`)
        break
      case 'email':
        history.replace(`/users?email=${emailInputRef.current.value}`)
        break
      case 'phoneNumber':
        history.replace(`/users?${inputType}=${phoneNumberInputRef.current.value}`)
        break
      default:
        history.replace(
          `/users?userId=${idInputRef.current.value}&email=${emailInputRef.current.value}&phoneNumber=${phoneNumberInputRef.current.value}`,
        )
        break
    }
  }

  return (
    <FView block bg={theme.palette.common.background} minHeight="100vh">
      <FView h={80}>
        <FNavBar />
      </FView>
      <FView bg={theme.palette.common.background} fill p={25} alignCenter>
        <FView w={600} maxWidth="100vw">
          <FText h4 bold>
            User Lookup
          </FText>
          <FView size={15} />

          <FView row w="100%">
            <FView w="100%">
              <FTextField
                margin="none"
                defaultValue={userId ?? ''}
                inputRef={idInputRef}
                label="User ID"
                onKeyPress={(e) => {
                  e.key === 'Enter' && handleUserLookup('userId')
                }}
              />
              <FView size={16} />
              <FTextField
                margin="none"
                defaultValue={email ?? ''}
                inputRef={emailInputRef}
                label="User Email"
                onKeyPress={(e) => {
                  e.key === 'Enter' && handleUserLookup('email')
                }}
              />
              <FView size={16} />
              <FTextField
                margin="none"
                defaultValue={phoneNumber ?? ''}
                inputRef={phoneNumberInputRef}
                label="User Phone Number"
                onKeyPress={(e) => {
                  e.key === 'Enter' && handleUserLookup('phoneNumber')
                }}
              />
            </FView>
            <FView w={16} />
            <FButtonA
              onPress={() => {
                handleUserLookup()
              }}>
              <ButtonFillView w={120} h={55} rounded>
                <FText primaryContrast body1 bold>
                  Find
                </FText>
              </ButtonFillView>
            </FButtonA>
          </FView>
          {Object.values(users).map((userData) => {
            return (
              <FView fill key={userData.id} shadow25 mv={25} p={15} w="100%" rounded bg={theme.palette.common.white}>
                <FView row justifyBetween alignCenter>
                  <FView>
                    <FText bold>Info</FText>
                    <FView p={10}>
                      <FText>userId: {userData.id}</FText>
                      <FText>Email: {userData.email}</FText>
                      <FText>Name: {userData.name}</FText>
                      <FText>Phone: {utils.formatPhoneNumber(userData.phoneNumber)}</FText>
                      <FText>Role: {userData.role}</FText>
                      <FText>Created: {utils.moment(userData.createdAt).calendar()}</FText>
                      <FText>Last Login: {utils.moment(userData.lastLogin).calendar()}</FText>
                    </FView>
                  </FView>
                  <FView>
                    <FButtonA
                      onPress={() => {
                        getCollection('Users').doc(userData.id).update({
                          role: 'customer',
                        })
                      }}>
                      <ButtonFillView w={120} h={55} rounded disabled={userData?.role === 'customer'}>
                        <FText primaryContrast body1 bold>
                          Customer
                        </FText>
                      </ButtonFillView>
                    </FButtonA>
                    <FView size={8} />
                    <FButtonA
                      onPress={() => {
                        getCollection('Users').doc(userData.id).update({
                          role: 'courier',
                        })
                      }}>
                      <ButtonFillView w={120} h={55} rounded disabled={userData?.role === 'courier'}>
                        <FText primaryContrast body1 bold>
                          Courier
                        </FText>
                      </ButtonFillView>
                    </FButtonA>
                    <FView size={8} />
                    <FButtonA
                      onPress={() => {
                        getCollection('Users').doc(userData.id).update({
                          role: 'courier_manager',
                        })
                      }}>
                      <ButtonFillView w={120} h={55} rounded disabled={userData?.role === 'courier_manager'}>
                        <FText primaryContrast body1 bold alignCenter>
                          Courier Manager
                        </FText>
                      </ButtonFillView>
                    </FButtonA>
                  </FView>
                </FView>
                <Divider />
                <Payments userId={userData.id} payments={userData.payments} />
                <Divider />
                <Addresses userId={userData.id} addresses={usersReadwrite[userData.id]?.addresses} />
                <Divider />
                <FPoints
                  fPoints={usersReadonly[userData.id]?.fPoints}
                  userId={userData.id}
                  rewardHistoryList={usersRewardHistory[userData.id]?.rewardHistoryList}
                />
              </FView>
            )
          })}
          <FView size={15} />
        </FView>
      </FView>
    </FView>
  )
}
