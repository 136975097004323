import React from 'react'
import {FText, FView} from 'components'

export default function LabelBox({label, children, ...props}) {
  return (
    <FView {...props}>
      <FText body1 medium>
        {label}
      </FText>
      {children}
    </FView>
  )
}
