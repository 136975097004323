import React from 'react'
import {Line} from 'react-chartjs-2'

const LineChart = ({labels, datasets, xLabel, yLabel}) => {
  return (
    <Line
      data={{
        labels,
        datasets,
      }}
      options={{
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: xLabel,
                fontStyle: 'bold',
                fontSize: '16',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: yLabel,
                fontStyle: 'bold',
                fontSize: '16',
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      }}
    />
  )
}

export default LineChart
