import React, {useRef, useState, useEffect} from 'react'
import {useTheme, makeStyles} from '@material-ui/core/styles'
import {getDocument, FieldValue} from 'f-core/src/config/firebase'
import {Divider, InputAdornment, MenuItem} from '@material-ui/core'
import {Delete} from '@material-ui/icons'
import {FNavBar, FView, FText, FTextField, ButtonFillView, FButtonA} from 'components'
import {useSelector, useDispatch} from 'react-redux'
import {clamp} from 'lodash'

export default function Promotions() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyle()

  const [promotions, setPromotions] = useState()
  useEffect(() => {
    return getDocument('public').onSnapshot((snapshot) => {
      const _promotions = snapshot?.data()?.promotionsV2
      if (_promotions) {
        setPromotions(_promotions)
      }
    })
  }, [])

  const locations = useSelector(dispatch.restaurants.getLocations)

  const imageUrlRef = useRef()
  const largeImageUrlRef = useRef()
  const targetUrlRef = useRef()
  const locationInputRef = useRef()
  const couponCodeRef = useRef()

  return (
    <FView block bg={theme.palette.common.background} minHeight="100vh">
      <FView h={80}>
        <FNavBar />
      </FView>
      <FView fill p={25} alignCenter bg={theme.palette.common.background}>
        <FView w={600} alignCenter maxWidth="100vw">
          <FText h4 bold>
            Promotions
          </FText>
          <FView size={15} />

          <FView row w="100%">
            <FView fill>
              <FTextField size="small" margin="none" inputRef={imageUrlRef} label="ImageUrl" />
              <FView size={16} />
              <FTextField size="small" margin="none" inputRef={largeImageUrlRef} label="Image Large Url" />
              <FView size={16} />
              <FTextField
                size="small"
                margin="none"
                inputRef={targetUrlRef}
                label="targetUrl"
                InputProps={{
                  startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                }}
              />
              <FView size={16} />
              <FTextField size="small" margin="none" inputRef={couponCodeRef} label="Coupon Code (optional)" />
              <FTextField size="small" inputRef={locationInputRef} required select label="Target Restaurant">
                {Object.values(locations).map((locationData) => (
                  <MenuItem key={locationData.locationId} value={locationData.locationId}>
                    {locationData.restaurantName}
                  </MenuItem>
                ))}
              </FTextField>
            </FView>
            <FView w={16} />
            <FView selfEnd mb={8}>
              <FButtonA
                onPress={() => {
                  const locationId = locationInputRef.current.value ?? null
                  const restaurantId = locationId ? locations[locationId].restaurantId : null
                  try {
                    getDocument('public').update({
                      promotionsV2: [
                        {
                          imageUrl: imageUrlRef.current.value,
                          imageUrlWide: largeImageUrlRef.current.value,
                          couponCode: couponCodeRef.current.value ? couponCodeRef.current.value : null,
                          targetUrl: targetUrlRef.current.value ? 'https://' + targetUrlRef.current.value : null,
                          restaurantId,
                          locationId,
                        },
                        ...promotions,
                      ],
                    })
                    imageUrlRef.current.value = ''
                    largeImageUrlRef.current.value = ''
                    targetUrlRef.current.value = ''
                    locationInputRef.current.value = ''
                    couponCodeRef.current.value = ''
                  } catch (e) {
                    alert(e.message)
                  }
                }}>
                <ButtonFillView w={120} h={55} rounded>
                  <FText primaryContrast body1 bold>
                    Add
                  </FText>
                </ButtonFillView>
              </FButtonA>
            </FView>
          </FView>
          <FView size={15} />
          {promotions?.map((promotion, index) => {
            return (
              <>
                <FView w={600} mv={8}>
                  <Divider />
                </FView>
                <FView>
                  <FText bold>{locations[promotion.locationId]?.restaurantName}</FText>
                </FView>
                <FView row w={'100%'} center key={index} mb={16}>
                  <FView w={60} mr={8}>
                    <FTextField
                      className={classes.categoryIndexTextField}
                      InputProps={{className: classes.indexInputStyle}}
                      inputProps={{className: classes.indexInputNativeStyle}}
                      onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                          e.target.value = index
                          e.target.blur()
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          const fromIndex = index
                          const toIndex = e.target.value
                          const toIndexClamped = clamp(toIndex, 0, promotions.length - 1)
                          if (toIndexClamped === fromIndex) {
                            return
                          }
                          const newPromotions = []
                          if (fromIndex > toIndexClamped) {
                            for (let i = 0; i < toIndexClamped; i++) {
                              if (i !== fromIndex) {
                                newPromotions.push(promotions[i])
                              }
                            }
                            newPromotions.push(promotions[fromIndex])
                            for (let i = toIndexClamped; i < promotions.length; i++) {
                              if (i !== fromIndex) {
                                newPromotions.push(promotions[i])
                              }
                            }
                          } else {
                            for (let i = 0; i < toIndexClamped + 1; i++) {
                              if (i !== fromIndex) {
                                newPromotions.push(promotions[i])
                              }
                            }
                            newPromotions.push(promotions[fromIndex])
                            for (let i = toIndexClamped + 1; i < promotions.length; i++) {
                              if (i !== fromIndex) {
                                newPromotions.push(promotions[i])
                              }
                            }
                          }
                          getDocument('public').update({
                            promotionsV2: newPromotions,
                          })
                        }
                      }}
                      defaultValue={index}
                      type="number"
                    />
                  </FView>
                  <FView alignCenter>
                    <img src={promotion.imageUrl} style={styles.promotionImg} alt="promotion" />
                    <FView h={8} />
                    <Divider />
                    <img src={promotion.imageUrlWide} style={styles.promotionImgWide} alt="promotionWide" />
                  </FView>
                  <FButtonA
                    row
                    alignCenter
                    onPress={() => {
                      getDocument('public').update({
                        promotionsV2: FieldValue.arrayRemove(promotion),
                      })
                    }}>
                    <Delete fontSize="large" color="primary" />
                    <FText primary body1 medium>
                      DELETE
                    </FText>
                  </FButtonA>
                </FView>
              </>
            )
          })}
        </FView>
      </FView>
    </FView>
  )
}

const styles = {
  promotionImg: {
    width: 400,
    height: 200,
  },
  promotionImgWide: {
    width: 400,
  },
}

const useStyle = makeStyles((theme) => ({
  // Remove number up down arrows as it blocks index number from showing
  categoryIndexTextField: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  indexInputStyle: {
    height: 40,
  },
  indexInputNativeStyle: {
    textAlign: 'center',
  },
}))
