import React, {useState, useRef, useEffect} from 'react'
import {getCollection, getPayoutsCreatedAtDesc, FieldValue, cancelVopayTransaction} from 'f-core/src/config/firebase'
import {useDispatch} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useTheme, makeStyles} from '@material-ui/core/styles'
import {FView, FText, FButton, FTextField, ButtonFillView} from 'components'
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@material-ui/core'
import {IconButton, MenuItem} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import moment from 'moment'
let unsubPayoutHistory = null

export default function PayoutDialog({accountId, balance, ownerEmail, open, handleClose}) {
  const dispatch = useDispatch()
  const [creatingPayout, setCreatingPayout] = useState(false)
  const theme = useTheme()
  const classes = useStyle()
  const isUpSm = useMediaQueryUp('sm')

  const payoutMethodInputRef = useRef(null)
  const payoutAmountInputRef = useRef(null)

  const [isCancelling, setIsCancelling] = useState(false)

  const [loading, setLoading] = useState(false)
  const [payoutHistory, setPayoutHistory] = useState([])
  useEffect(() => {
    if (open) {
      unsubPayoutHistory && unsubPayoutHistory()
      setLoading(true)
      unsubPayoutHistory = getPayoutsCreatedAtDesc({accountId}).onSnapshot((snap) => {
        setLoading(false)
        const payouts = []
        for (const doc of snap.docs) {
          const payout = doc.data()
          payout.id = doc.id
          payouts.push(payout)
        }
        setPayoutHistory(payouts)
      })
    } else {
      setPayoutHistory([])
    }
    return () => {
      unsubPayoutHistory && unsubPayoutHistory()
    }
  }, [open, accountId])

  return (
    <Dialog fullWidth fullScreen={!isUpSm} open={open} onClose={handleClose}>
      <DialogTitle>
        <FView alignCenter row justifyBetween>
          <FView fill>
            <FText medium h5>
              Payout {ownerEmail}
            </FText>
          </FView>
          <IconButton aria-label="close payout dialog" onClick={handleClose}>
            <FText black noLineHeight>
              <Close />
            </FText>
          </IconButton>
        </FView>
      </DialogTitle>
      <DialogContent>
        <FView fill>
          <FText body1>Restaurant Balance: ${balance?.toFixed(2)}</FText>
          <FView row>
            <FTextField inputRef={payoutMethodInputRef} required select label="Payout Method" defaultValue={'Manual'}>
              <MenuItem value={'Manual'}>Manual</MenuItem>
              <MenuItem value={'VoPay'}>VoPay</MenuItem>
            </FTextField>
            <FView w={16} />
            <FTextField
              inputRef={payoutAmountInputRef}
              inputProps={{
                min: 0.01,
                max: balance,
                step: 0.01,
                className: classes.inputStyle,
              }}
              defaultValue={balance}
              required
              label="Amount to Payout"
              type="number"
            />
          </FView>
          <FView size={16} />
          <Divider />
          <FView size={16} />
          <FText medium h5>
            Payout History
          </FText>
          <FView h={200} overflowY="auto">
            <FView block>
              {loading && (
                <FView alignCenter p={15}>
                  <CircularProgress />
                </FView>
              )}
              {payoutHistory.map((payout) => {
                return (
                  <FView key={payout.id} mv={5}>
                    <FView>
                      <FView row justifyBetween alignCenter mv={5}>
                        <FText body1>{moment(payout.createdAt?.toDate()).format('MM/DD LT')}</FText>
                        <FText body1>{payout.authorEmail}</FText>
                        <FText body1>${payout.amount}</FText>
                        <FText body1>{payout.delivery?.state ?? '...'}</FText>
                        {payout.delivery?.state === 'ERROR' && (
                          <FButton
                            onPress={() => {
                              getCollection('Payouts').doc(payout.id).update({
                                'delivery.state': 'RETRY',
                              })
                            }}>
                            <ButtonFillView ph={10} rounded>
                              <FText primaryContrast>RETRY</FText>
                            </ButtonFillView>
                          </FButton>
                        )}
                        {!payout.delivery?.isNotCancellable && payout.delivery?.state === 'PROCESSING' && (
                          <FButton
                            onPress={() => {
                              // api calls
                              //payout
                              setIsCancelling(true)
                              cancelVopayTransaction({transactionId: payout.delivery.transactionId})
                                .then(() => alert('Cancel Successful'))
                                .catch((e) => alert(e.message))
                                .finally(() => setIsCancelling(false))
                            }}
                            disabled={isCancelling}>
                            <ButtonFillView disabled={isCancelling} ph={10} rounded>
                              <FText primaryContrast>CANCEL</FText>
                            </ButtonFillView>
                          </FButton>
                        )}
                      </FView>
                      {payout?.delivery?.error && (
                        <FText body1 error>
                          Error: {payout.delivery.error}
                        </FText>
                      )}
                    </FView>
                    <Divider />
                  </FView>
                )
              })}
            </FView>
          </FView>
        </FView>
      </DialogContent>
      <Divider />
      <DialogActions>
        <FView fill center bg={theme.palette.common.white} pv={isUpSm ? 15 : 5} row>
          <FButton
            onPress={() => {
              if (creatingPayout) {
                return
              }
              const method = payoutMethodInputRef.current.value
              const amount = Number(payoutAmountInputRef.current.value)
              if (!method || amount > balance || amount < 0 || isNaN(amount) || !amount || !accountId) {
                alert('Missing or invalid field')
                return
              }
              const authorId = dispatch.portal.getUserId()
              const authorEmail = dispatch.portal.getUserEmail()
              if (!authorId) {
                alert('Missing authorId')
                return
              }
              setCreatingPayout(true)
              getCollection('Payouts')
                .add({
                  authorId,
                  authorEmail,
                  createdAt: FieldValue.serverTimestamp(),
                  amount,
                  method,
                  accountId,
                })
                .finally(() => {
                  setCreatingPayout(false)
                })
            }}>
            <ButtonFillView rounded w={isUpSm ? 250 : 130} disabled={creatingPayout}>
              <FText body1 bold primaryContrast>
                {creatingPayout ? 'Creating Payout...' : 'Create Payout'}
              </FText>
            </ButtonFillView>
          </FButton>
        </FView>
      </DialogActions>
    </Dialog>
  )
}

const useStyle = makeStyles((theme) => ({
  inputStyle: {
    textAlign: 'center',
  },
}))
