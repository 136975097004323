import React, {useState} from 'react'
import {FButton, FView, MenuTimeRow, ButtonFillView, FText} from 'components'
import {useDispatch, useSelector} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import MomentUtils from '@date-io/moment'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import {Cancel} from '@material-ui/icons'
import {moment} from 'f-utils'

const HoursList = ({hoursLT, restaurantId, locationId, isDeliveryHoursLT}) => {
  const dispatch = useDispatch()
  const [updating, setUpdating] = useState(false)
  const isUpSm = useMediaQueryUp('sm')
  const isEditLocked = useSelector(dispatch.portal.getIsAdmin) && isDeliveryHoursLT
  const [newHoursLT, setNewHoursLT] = useState(hoursLT)
  function handleSubmit() {
    if (updating || isEditLocked) {
      return
    }
    setUpdating(true)
    if (isDeliveryHoursLT) {
      dispatch.restaurants
        .updateDeliveryHoursLT({restaurantId, locationId, deliveryHoursLT: newHoursLT})
        .finally(() => {
          setUpdating(false)
        })
    } else {
      dispatch.restaurants.updateHoursLT({restaurantId, locationId, hoursLT: newHoursLT}).finally(() => {
        setUpdating(false)
      })
    }
  }

  return (
    <FView>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {[1, 2, 3, 4, 5, 6, 0].map((day) => {
          return (
            <FView key={day} mv={8}>
              {(newHoursLT?.[day] ?? [{openLT: null, closeLT: null}]).map((openCloseLT, index) => {
                const startDate = openCloseLT?.openLT ? moment(openCloseLT?.openLT, 'LT') : null
                const endDate = openCloseLT?.closeLT ? moment(openCloseLT?.closeLT, 'LT') : null
                return (
                  <FView row key={String(day + index)}>
                    <MenuTimeRow
                      isEditLocked={isEditLocked}
                      day={index === 0 ? moment().day(day).format('dddd') : ''}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={(startMoment) => {
                        if (startMoment == null) {
                          return
                        } else {
                          const _newHoursLTDay = newHoursLT[day] ?? [{closeLT: null}]
                          _newHoursLTDay[index].openLT = startMoment.format('LT')
                          newHoursLT[day] = _newHoursLTDay
                          setNewHoursLT({...newHoursLT})
                        }
                      }}
                      setEndDate={(endMoment) => {
                        if (endMoment == null) {
                          return
                        } else {
                          const _newHoursLTDay = newHoursLT[day] ?? [{openLT: null}]
                          _newHoursLTDay[index].closeLT = endMoment.format('LT')
                          newHoursLT[day] = _newHoursLTDay
                          setNewHoursLT({...newHoursLT})
                        }
                      }}
                    />
                    {!newHoursLT[day] ||
                    (newHoursLT[day][0]?.openLT == null &&
                      newHoursLT[day][0]?.closeLT == null &&
                      newHoursLT[day].length <= 1) ? (
                      <FView ml={15}>
                        <FText body1 grey800>
                          Closed
                        </FText>
                      </FView>
                    ) : (
                      <FButton
                        IconButton
                        onPress={() => {
                          if (isEditLocked) {
                            return
                          }
                          if (newHoursLT[day].length > 1) {
                            newHoursLT[day].splice(index, 1)
                          } else {
                            newHoursLT[day][0] = {openLT: null, closeLT: null}
                          }
                          setNewHoursLT({...newHoursLT})
                        }}>
                        <Cancel />
                      </FButton>
                    )}
                  </FView>
                )
              })}
              {!isEditLocked && newHoursLT[day] && (
                <FView alignStart ml={113}>
                  <FButton
                    onPress={() => {
                      newHoursLT[day].push({openLT: null, closeLT: null})
                      setNewHoursLT({...newHoursLT})
                    }}>
                    <FText body1 primary>
                      Add more hours
                    </FText>
                  </FButton>
                </FView>
              )}
            </FView>
          )
        })}
      </MuiPickersUtilsProvider>
      <FView fill center pv={isUpSm ? 15 : 5}>
        <FButton onClick={handleSubmit}>
          <ButtonFillView rounded w={250} disabled={updating || isEditLocked}>
            <FText body1 bold primaryContrast>
              {isDeliveryHoursLT
                ? updating
                  ? 'Saving Delivery Hours...'
                  : 'Save Delivery Hours'
                : updating
                ? 'Saving Ordering Hours...'
                : 'Save Ordering Hours'}
            </FText>
          </ButtonFillView>
        </FButton>
      </FView>
    </FView>
  )
}

export default HoursList
