import React, {useState} from 'react'
import {FView, FText, ButtonFillView, FButtonA} from 'components'
import FPointsAddDialog from './FPointsAddDialog'
import moment from 'moment'

const UserFPoints = ({userId, fPoints, rewardHistoryList}) => {
  const [openDialog, setOpenDialog] = useState(false)
  return (
    <FView>
      <FView size={16} />
      <FView row justifyBetween alignCenter>
        <FView>
          <FText bold>Foodly Points</FText>
          <FView p={10}>
            <FText bold> {fPoints} </FText>
          </FView>
        </FView>
        <FButtonA
          onClick={() => {
            setOpenDialog(true)
          }}>
          <ButtonFillView w={120} h={55} m={16} rounded>
            <FText primaryContrast body1 bold>
              Add Points
            </FText>
          </ButtonFillView>
        </FButtonA>
      </FView>
      <FText bold>Points History</FText>
      <FView maxHeight={500} overflowY={'auto'}>
        {rewardHistoryList?.map((entry) => (
          <FView key={entry.id} row justifyBetween>
            <FView p={10}>
              <FText>{entry.name}</FText>
              <FText>{moment(entry.createdAt).format('LLL')}</FText>
              <FText>Created by: {entry.createdBy}</FText>
              <FText>{entry.description}</FText>
              <FText>Points: {entry.points}</FText>
            </FView>
          </FView>
        ))}
      </FView>
      <FPointsAddDialog
        open={openDialog}
        userId={userId}
        points={fPoints}
        handleClose={() => {
          setOpenDialog(false)
        }}
      />
    </FView>
  )
}

export default UserFPoints
