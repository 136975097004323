import React, {useState, useRef} from 'react'
import {FView, FText, ButtonOutlineView, ButtonFillView, FButton, FTextField, IconButtonView} from 'components'
import {colors} from 'styles'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useDispatch} from 'react-redux'
import {Add} from '@material-ui/icons'

const CategoryAdd = ({restaurantId, locationId, menuId}) => {
  const isUpMd = useMediaQueryUp('md')
  const isUpLg = useMediaQueryUp('lg')
  const dispatch = useDispatch()
  const nameRef = useRef()
  const descRef = useRef()
  const [addingCategory, setAddingCategory] = useState(false)
  const [savingCategory, setSavingCategory] = useState(false)

  function handleClose() {
    if (savingCategory) {
      return
    }
    setAddingCategory(false)
  }

  // add new category
  function handleSave() {
    if (savingCategory) {
      return
    }
    const newCategory = {name: '', productOrder: []}

    if (!nameRef.current.value) {
      alert('Name must be filled.')
      return
    }
    if (descRef.current.value) {
      newCategory.description = descRef.current.value
    }

    newCategory.name = nameRef.current.value
    setSavingCategory(true)
    dispatch.restaurants
      .addCategory({restaurantId, locationId, category: newCategory, menuId})
      .then(() => {
        nameRef.current.value = ''
        descRef.current.value = ''
      })
      .finally(() => {
        setSavingCategory(false)
      })
  }

  return (
    <>
      {addingCategory && (
        <>
          <FView h={214} fill bg={colors.white} rounded>
            <FView m={25} row={isUpMd}>
              <FView fill>
                <FTextField
                  inputRef={nameRef}
                  required
                  autoFocus
                  label="Category Title"
                  onKeyDown={(e) => {
                    e.key === 'Escape' && handleClose()
                  }}
                  onKeyPress={(e) => {
                    e.key === 'Enter' && handleSave()
                  }}
                />
                <FTextField
                  inputRef={descRef}
                  label="Category Description"
                  onKeyDown={(e) => {
                    e.key === 'Escape' && handleClose()
                  }}
                  onKeyPress={(e) => {
                    e.key === 'Enter' && handleSave()
                  }}
                />
              </FView>
              <FView w={isUpLg ? 100 : 40} h={8} />
              <FView row alignCenter justifyEnd>
                <FButton onClick={handleClose}>
                  <ButtonOutlineView rounded w={125} selected={!savingCategory}>
                    <FText button primary grey500={savingCategory}>
                      cancel
                    </FText>
                  </ButtonOutlineView>
                </FButton>
                <FView size={16} />
                <FButton onClick={handleSave}>
                  <ButtonFillView rounded w={125} disabled={savingCategory}>
                    <FText button primaryContrast>
                      {savingCategory ? 'saving...' : 'save'}
                    </FText>
                  </ButtonFillView>
                </FButton>
              </FView>
            </FView>
          </FView>
          <FView size={25} />
        </>
      )}
      <FView alignEnd>
        <FButton disableRipple onClick={() => setAddingCategory(true)}>
          <IconButtonView Icon={Add} label="Add Category" />
        </FButton>
      </FView>
    </>
  )
}

export default CategoryAdd
