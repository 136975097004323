import {getSchedules, db} from 'f-core/src/config/firebase'

const schedulesModel = {
  state: {
    Schedules: {},
  },
  reducers: {
    setSchedules(state, schedules) {
      return {
        ...state,
        Schedules: schedules,
      }
    },
  },
  actions: ({dispatch, getState}) => ({
    getSchedules() {
      return getState().schedules.Schedules
    },
    subscribeThisWeeksSchedule({startDate, endDate}) {
      return getSchedules({startAtMin: startDate, startAtMax: endDate}).onSnapshot((snapshot) => {
        const schedules = {}
        for (const doc of snapshot.docs) {
          schedules[doc.id] = doc.data()
          schedules[doc.id].id = doc.id
        }
        dispatch.schedules.setSchedules(schedules)
      })
    },
    addSchedule({scheduleData}) {
      return db.collection('Schedules').add(scheduleData)
    },
    deleteSchedule({scheduleId}) {
      return db.collection('Schedules').doc(scheduleId).delete()
    },
  }),
}
export default schedulesModel
