import React, {useState} from 'react'
import {Edit, Delete} from '@material-ui/icons'
import {useTheme} from '@material-ui/core/styles'
import {FieldValue, getDocument} from 'f-core/src/config/firebase'
import {FView, FText, ButtonFillView, FButtonA, FButton} from 'components'
import AddressEditDialog from './AddressEditDialog'

const deliveryTypes = {
  LeaveAtLocation: 'LEAVE_AT_LOCATION',
}

const Addresses = ({userId, addresses}) => {
  const theme = useTheme()

  const [dialogMode, setDialogMode] = useState('')
  const [addressDialogId, setAddressDialogId] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  return (
    <>
      <FView>
        <FView size={16} />
        <FView pr={10} pb={16} row justifyBetween alignCenter>
          <FText bold> Addresses</FText>
          <FButtonA
            pr={10}
            onPress={() => {
              setAddressDialogId(addresses?.length + 1 || 0)
              setDialogMode('Add')
              setOpenDialog(true)
            }}>
            <ButtonFillView w={120} h={55} rounded>
              <FText primaryContrast body1 bold>
                Add Address
              </FText>
            </ButtonFillView>
          </FButtonA>
        </FView>
        {addresses?.map((address, index) => (
          <FView key={index} row justifyBetween>
            <FView p={10}>
              <FText>
                {!!address.containerDeliveryUnit && <FText inline>Unit {address.containerDeliveryUnit} - </FText>}
                {address.containerDeliveryAddress}
              </FText>
              <FText>{address.contactlessDeliveryType || 'Deliver to my Door'}</FText>

              {address.contactlessDeliveryType === deliveryTypes.LeaveAtLocation && (
                <FText>Location: {address.contactlessDeliveryLocation} </FText>
              )}
              {!!address.containerDeliveryInstructions && (
                <FText>Instructions: {address.containerDeliveryInstructions}</FText>
              )}
            </FView>

            <FView row alignCenter>
              <FButton
                onClick={() => {
                  setAddressDialogId(index)
                  setDialogMode('Edit')
                  setOpenDialog(true)
                }}>
                <FView row center w={90}>
                  <Edit color="primary" />
                  <FView size={8} />
                  <FText subtitle2 primary>
                    Edit
                  </FText>
                </FView>
              </FButton>
              <FView selfCenter bg={theme.palette.grey['300']} h={50} w={1} />
              <FButton
                onClick={() => {
                  getDocument('readwrite', {userId}).update({
                    addresses: FieldValue.arrayRemove(addresses[index]),
                  })
                }}>
                <FView row center w={90}>
                  <Delete color="primary" />
                  <FView size={8} />
                  <FText subtitle2 primary>
                    Delete
                  </FText>
                </FView>
              </FButton>
            </FView>
          </FView>
        ))}
      </FView>
      <AddressEditDialog
        open={openDialog}
        addresses={addresses}
        i={addressDialogId}
        mode={dialogMode}
        handleClose={() => {
          setOpenDialog(false)
        }}
        userId={userId}
      />
    </>
  )
}

export default Addresses
