import React from 'react'
import {FView} from 'components'
import {colors} from 'styles'
import LabelBox from './LabelBox'

export default function LabelInputBox({children, ...props}) {
  return (
    <LabelBox {...props}>
      <FView bc={colors.lightGrey} bw={1} rounded justifyCenter>
        {children}
      </FView>
    </LabelBox>
  )
}
