import React, {useEffect, useState, Fragment} from 'react'
import {getCollection} from 'f-core/src/config/firebase'
import {FView, FNavBar, FText, ButtonOutlineView, FButton, LabelInputBox, FInput} from 'components'
import {colors} from 'styles'
import {useDispatch, useSelector} from 'react-redux'
import {sum, orderBy} from 'lodash'
import {moment} from 'f-utils'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import {CircularProgress} from '@material-ui/core'

const startOfDay = moment().startOf('day')

let unsubDeliveryInfos = null

export default function RidersEarnings() {
  const dispatch = useDispatch()
  const [rangeSelection, setRangeSelection] = useState('day')
  const [filteredRiderId, setFilteredRiderId] = useState(null)
  const [dateVal, setDateVal] = useState(startOfDay)

  useEffect(() => {
    unsubDeliveryInfos && unsubDeliveryInfos()
    const startTimestamp = dateVal.valueOf()
    const endTimestamp =
      rangeSelection === 'week'
        ? moment(dateVal).add(6, 'days').endOf('day').valueOf()
        : moment(dateVal).endOf(rangeSelection).valueOf()
    unsubDeliveryInfos = dispatch.riders.subscribeDeliveryInfos({startTimestamp, endTimestamp})
    return unsubDeliveryInfos
  }, [dispatch.riders, dateVal, rangeSelection])

  const isLoadingDeliveryInfos = useSelector(dispatch.riders.getIsLoadingDeliveryInfos)

  const deliveryInfos = useSelector(dispatch.riders.getDeliveryInfos)

  const [couriers, setCouriers] = useState({})
  useEffect(() => {
    return getCollection('Users')
      .where('role', 'in', ['admin', 'courier', 'courier_manager', 'super_admin'])
      .onSnapshot((snapshot) => {
        if (snapshot) {
          const couriers = {}
          for (const doc of snapshot.docs) {
            couriers[doc.id] = doc.data()
            couriers[doc.id].id = doc.id
          }
          setCouriers(couriers)
        }
      })
  }, [])

  const ridersSum = deliveryInfos.reduce((prev, cur) => {
    if (cur.deliveryStatus === 'Delivered') {
      const {foodlySubstitution, restaurantSubstitution, restaurantStandby, deliveryFee, tipAmount} = cur.earnings
      const onlineEarning = cur.paymentMethod.startsWith('inperson-cash')
        ? foodlySubstitution + restaurantSubstitution + restaurantStandby
        : foodlySubstitution + restaurantSubstitution + restaurantStandby + deliveryFee + tipAmount
      if (prev[cur.riderUserId]) {
        prev[cur.riderUserId].earnings += sum(Object.values(cur.earnings))
        prev[cur.riderUserId].onlineEarnings += onlineEarning
        prev[cur.riderUserId].foodlySubstitution += foodlySubstitution
        prev[cur.riderUserId].restaurantSubstitution += restaurantSubstitution
        prev[cur.riderUserId].restaurantStandby += restaurantStandby
        if (!cur.paymentMethod.startsWith('inperson-cash')) {
          prev[cur.riderUserId].deliveryFee += deliveryFee
          prev[cur.riderUserId].tipAmount += tipAmount
        }
        prev[cur.riderUserId].count++
      } else {
        prev[cur.riderUserId] = {
          foodlySubstitution: cur.earnings.foodlySubstitution,
          restaurantSubstitution: cur.earnings.restaurantSubstitution,
          restaurantStandby: cur.earnings.restaurantStandby,
          earnings: sum(Object.values(cur.earnings)),
          onlineEarnings: onlineEarning,
          count: 1,
          riderEmail: cur.riderEmail,
          riderName: cur.riderName,
          riderUserId: cur.riderUserId,
          role: couriers[cur.riderUserId]?.role ?? 'Not a Courier',
        }
        if (!cur.paymentMethod.startsWith('inperson-cash')) {
          prev[cur.riderUserId].deliveryFee = deliveryFee
          prev[cur.riderUserId].tipAmount = tipAmount
        } else {
          prev[cur.riderUserId].deliveryFee = 0
          prev[cur.riderUserId].tipAmount = 0
        }
      }
    }
    return prev
  }, {})

  const ridersSumArr = orderBy(Object.values(ridersSum), 'count', 'desc')

  return (
    <FView block bg={colors.background} h={'100vh'} overflowY={'auto'}>
      <FNavBar />
      <FView h={80} />
      <FView bg={colors.white} p={15} alignStart>
        <FView row center w={330}>
          <FButton
            onClick={() => {
              if (rangeSelection === 'day') {
                setDateVal(moment(dateVal).subtract(1, 'days'))
              } else if (rangeSelection === 'week') {
                setDateVal(moment(dateVal).subtract(2, 'days').startOf('week').add(1, 'days'))
              } else {
                setDateVal(moment(dateVal).subtract(1, 'days').startOf('month'))
              }
            }}>
            <ChevronLeft color="primary" fontSize="large" />
          </FButton>
          <LabelInputBox>
            <FInput disabled type="date" value={dateVal.format('YYYY-MM-DD')} />
          </LabelInputBox>
          <FButton
            onClick={() => {
              if (rangeSelection === 'day') {
                setDateVal(moment(dateVal).add(1, 'days'))
              } else if (rangeSelection === 'week') {
                setDateVal(moment(dateVal).add(7, 'days').startOf('week').add(1, 'days'))
              } else {
                setDateVal(moment(dateVal).add(32, 'days').startOf('month'))
              }
            }}>
            <ChevronRight color="primary" fontSize="large" />
          </FButton>
        </FView>
        <FView size={15} />
        <FView row>
          <FButton onClick={() => setFilteredRiderId(null)}>
            <ButtonOutlineView rounded w={100} selected={!filteredRiderId}>
              <FText bold color={!filteredRiderId ? colors.primary : colors.grey}>
                All
              </FText>
            </ButtonOutlineView>
          </FButton>
          <FView size={15} />
          {ridersSumArr.map(({riderUserId, riderName}, index) => {
            return (
              <Fragment key={index}>
                <FButton onClick={() => setFilteredRiderId(riderUserId)}>
                  <ButtonOutlineView rounded w={100} selected={filteredRiderId === riderUserId}>
                    <FText bold color={filteredRiderId === riderUserId ? colors.primary : colors.grey}>
                      {riderName || riderUserId || 'NO USER FOUND'}
                    </FText>
                  </ButtonOutlineView>
                </FButton>
                <FView size={15} />
              </Fragment>
            )
          })}
        </FView>
        <FView size={15} />
        <FView row>
          <FButton onClick={() => setRangeSelection('day')}>
            <ButtonOutlineView rounded w={100} selected={rangeSelection === 'day'}>
              <FText bold color={rangeSelection === 'day' ? colors.primary : colors.grey}>
                Day
              </FText>
            </ButtonOutlineView>
          </FButton>
          <FView size={15} />
          <FButton
            onClick={() => {
              setDateVal(moment(dateVal).startOf('week').add(1, 'days'))
              setRangeSelection('week')
            }}>
            <ButtonOutlineView rounded w={100} selected={rangeSelection === 'week'}>
              <FText bold color={rangeSelection === 'week' ? colors.primary : colors.grey}>
                Week
              </FText>
            </ButtonOutlineView>
          </FButton>
          <FView size={15} />
          <FButton
            onClick={() => {
              setDateVal(moment(dateVal).startOf('month'))
              setRangeSelection('month')
            }}>
            <ButtonOutlineView rounded w={100} selected={rangeSelection === 'month'}>
              <FText bold color={rangeSelection === 'month' ? colors.primary : colors.grey}>
                Month
              </FText>
            </ButtonOutlineView>
          </FButton>
        </FView>
      </FView>
      {isLoadingDeliveryInfos && (
        <FView fill center>
          <CircularProgress />
        </FView>
      )}
      <FView p={15}>
        <FText bold h5>
          Rider List
        </FText>
      </FView>
      {ridersSumArr.map(
        (
          {
            riderName,
            riderUserId,
            riderEmail,
            count,
            role,
            earnings,
            onlineEarnings,
            foodlySubstitution,
            restaurantSubstitution,
            restaurantStandby,
            deliveryFee,
            tipAmount,
          },
          index,
        ) => {
          if (!!filteredRiderId && riderUserId !== filteredRiderId) {
            return null
          }
          return (
            <FView key={index}>
              <FView bg={colors.white} p={15}>
                <FText h6>
                  {riderName} {riderEmail}
                </FText>
                <FText h6>Total Earnings: ${earnings.toFixed(2)}</FText>
                <FText h6>Online Earnings: ${onlineEarnings.toFixed(2)}</FText>
                <FView ml={10}>
                  <FText body2>-Foodly Substitution: ${foodlySubstitution.toFixed(2)}</FText>
                  <FText body2>-Restaurant Substitution: ${restaurantSubstitution.toFixed(2)}</FText>
                  <FText body2>-Restaurant Standby: ${restaurantStandby.toFixed(2)}</FText>
                  <FText body2>-Online Delivery Fee: ${deliveryFee.toFixed(2)}</FText>
                  <FText body2>-Online Tip Amount: ${tipAmount.toFixed(2)}</FText>
                </FView>
                <FText h6>Delivery Count: {count}</FText>
                <FText h6>Role: {role}</FText>
              </FView>
              <FView h={2} />
            </FView>
          )
        },
      )}
      <FView p={15}>
        <FText bold h5>
          Delivery Order List
        </FText>
      </FView>
      {deliveryInfos.map((deliveryInfo) => {
        if (!!filteredRiderId && deliveryInfo.riderUserId !== filteredRiderId) {
          return null
        }
        return <DeliveryInfo key={deliveryInfo.orderId} deliveryInfo={deliveryInfo} />
      })}
    </FView>
  )
}

function DeliveryInfo({deliveryInfo}) {
  const {createdAt, restaurantName, orderNumber, earnings, paymentMethod, riderName, deliveryStatus} = deliveryInfo
  const {foodlySubstitution, restaurantSubstitution, restaurantStandby, deliveryFee, tipAmount} = earnings
  const onlineEarning = paymentMethod.startsWith('inperson-cash')
    ? foodlySubstitution + restaurantSubstitution + restaurantStandby
    : foodlySubstitution + restaurantSubstitution + restaurantStandby + deliveryFee + tipAmount

  const totalEarnings = sum(Object.values(earnings))
  return (
    <>
      <FView bg={colors.white} p={15}>
        <FView row>
          <FView>
            <FText h6>{moment(createdAt).format('MM/DD LT')}</FText>
            <FText h6>
              {restaurantName} #{orderNumber}
            </FText>
            <FText h6>{paymentMethod}</FText>
            <FText h6>Total earnings: ${totalEarnings.toFixed(2)}</FText>
          </FView>
          <FView size={30} />
          <FView>
            <FText h6>{riderName}</FText>
            <FText h6>Foodly pays: ${onlineEarning.toFixed(2)}</FText>
            <FText h6>{deliveryStatus}</FText>
          </FView>
        </FView>
      </FView>
      <FView h={2} />
    </>
  )
}
