import React, {useState} from 'react'
import {parseCurrency} from 'f-utils'
import {auth} from 'f-core/src/config/firebase'
import {FText, FView, FButton, ButtonFillView} from 'components'
import * as api from 'models/api'
import {Dialog, DialogContent, DialogTitle, DialogActions, TextField} from '@material-ui/core'

export default function RefundTipDialog({open, handleClose, orderData}) {
  const [refunding, setRefunding] = useState(false)
  const [refundTipAmount, setRefundTipAmount] = useState('0.00')
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        <FText h5>Refund Tip Amount</FText>
      </DialogTitle>
      <DialogContent>
        <FView>
          <TextField value={refundTipAmount} onChange={(e) => setRefundTipAmount(e.target.value)} />
        </FView>
      </DialogContent>
      <DialogActions>
        <FView fill>
          <FButton
            disabled={refunding}
            onPress={async () => {
              if (refunding) {
                return
              }
              const confirmed = window.confirm(
                `Are you sure you want to Refund Tip for Order #${orderData.orderNumber}?`,
              )
              if (confirmed) {
                const restaurantId = orderData.restaurantId
                const authToken = await auth.currentUser.getIdToken()
                setRefunding(true)
                try {
                  await api.refundTipAmount(process.env.REACT_APP_SERVER_URL, authToken, {
                    restaurantId,
                    orderId: orderData.id,
                    refundTipAmount: parseCurrency(refundTipAmount),
                  })
                  alert('Refund Successful')
                  setRefundTipAmount('0.00')
                  handleClose()
                } catch (e) {
                  alert(e.message)
                } finally {
                  setRefunding(false)
                }
              }
            }}>
            <ButtonFillView disabled={refunding}>
              <FText color="white">Refund Tip</FText>
            </ButtonFillView>
          </FButton>
        </FView>
      </DialogActions>
    </Dialog>
  )
}
