import React from 'react'
import moment from 'moment'
import {FView, FText} from 'components'
import {FormControlLabel, Radio} from '@material-ui/core'

import LineChart from './LineChart'

const chartRanges = {
  daily: 'dayRange',
  weekly: 'weekRange',
  monthly: 'monthRange',
}

const DataCharts = ({chartRange, selectedTimeZone, setChartRange, orderDatas, fetchedStartDate, fetchedEndDate}) => {
  const ranges = {
    dayRange: [],
    weekRange: [],
    monthRange: [],
  }
  const ordersCountRange = {
    dayRange: [],
    weekRange: [],
    monthRange: [],
  }
  const commissionRange = {
    dayRange: [],
    weekRange: [],
    monthRange: [],
  }
  const totalRange = {
    dayRange: [],
    weekRange: [],
    monthRange: [],
  }
  const revenueRange = {
    dayRange: [],
    weekRange: [],
    monthRange: [],
  }

  const setRangeLabels = ({range, _fetchedStartDate}) => {
    ranges[range].push(_fetchedStartDate)
    ordersCountRange[range].push(0)
    commissionRange[range].push(0)
    totalRange[range].push(0)
    revenueRange[range].push(0)
  }
  const setRangeData = ({range, order, i}) => {
    ordersCountRange[range][i] += 1
    commissionRange[range][i] += isNaN(order.totalCommission) ? 0 : order.totalCommission
    totalRange[range][i] += isNaN(order.total) ? 0 : order.total
    if (order.orderType === 'Delivery' && order.deliveryType === 'foodly') {
      const deliveryFee = isNaN(order.deliveryFee) ? 0 : order.deliveryFee
      const tipAmount = isNaN(order.tipAmount) ? 0 : order.tipAmount
      const totalCommission = isNaN(order.totalCommission) ? 0 : order.totalCommission
      revenueRange[range][i] += deliveryFee + tipAmount + totalCommission
    } else {
      revenueRange[range][i] += isNaN(order.totalCommission) ? 0 : order.totalCommission
    }
  }

  // sets the day labels
  let _fetchedStartDate = moment(fetchedStartDate)
  while (_fetchedStartDate?.valueOf() <= fetchedEndDate?.valueOf()) {
    setRangeLabels({range: 'dayRange', _fetchedStartDate})
    _fetchedStartDate = moment(_fetchedStartDate).add(1, 'days')
  }

  // sets the week labels
  _fetchedStartDate = moment(fetchedStartDate)
  while (_fetchedStartDate?.valueOf() <= fetchedEndDate?.valueOf()) {
    setRangeLabels({range: 'weekRange', _fetchedStartDate})
    _fetchedStartDate = moment(_fetchedStartDate).add(1, 'weeks')
  }

  // sets the month labels
  _fetchedStartDate = moment(fetchedStartDate)
  while (_fetchedStartDate?.valueOf() <= fetchedEndDate?.valueOf()) {
    setRangeLabels({range: 'monthRange', _fetchedStartDate})
    _fetchedStartDate = moment(_fetchedStartDate).add(1, 'months')
  }

  // sets the data for day, week, month
  for (const order of orderDatas) {
    for (let i = 0; i < ranges.dayRange.length; i++) {
      const isSameDayOfYear =
        moment(order.createdAt).tz(selectedTimeZone.timezone).dayOfYear() === ranges.dayRange[i].dayOfYear()
      const isSameYear = moment(order.createdAt).year() === ranges.dayRange[i].year()
      if (isSameDayOfYear && isSameYear) {
        setRangeData({range: 'dayRange', order, i})
      }
    }

    for (let i = 0; i < ranges.weekRange.length; i++) {
      const isSameWeek = moment(order.createdAt).week() === ranges.weekRange[i].week()
      const isSameYear = moment(order.createdAt).year() === ranges.weekRange[i].year()
      if (isSameWeek && isSameYear) {
        setRangeData({range: 'weekRange', order, i})
      }
    }

    for (let i = 0; i < ranges.monthRange.length; i++) {
      const isSameMonth = moment(order.createdAt).month() === ranges.monthRange[i].month()
      const isSameYear = moment(order.createdAt).year() === ranges.monthRange[i].year()
      if (isSameMonth && isSameYear) {
        setRangeData({range: 'monthRange', order, i})
      }
    }
  }
  return (
    <>
      <FView mb={8} center>
        <FText h5 bold>
          Charts
        </FText>
      </FView>
      <FView row center mb={16}>
        {['daily', 'weekly', 'monthly'].map((range) => (
          <FormControlLabel
            key={range}
            value={range}
            control={
              <Radio
                checked={chartRange === range}
                value={range}
                name={range}
                onChange={() => setChartRange(range)}
                color="primary"
              />
            }
            label={range}
            labelPlacement="end"
          />
        ))}
      </FView>
      <FView row center>
        <FView size={24} />
        <FView w="100%" maxWidth={664} fill bg="white" p={16}>
          <LineChart
            datasets={[
              {
                data: ordersCountRange[chartRanges[chartRange]],
                label: '# of Orders',
                backgroundColor: 'red',
                borderColor: 'red',
                fill: false,
                pointHitRadius: 24,
                borderWidth: 0.7,
              },
            ]}
            labels={ranges[chartRanges[chartRange]].map((date) => {
              if (chartRange === 'daily') {
                return date.format('MMM D')
              } else if (chartRange === 'weekly') {
                return date.startOf('week').format('MMM D')
              } else {
                return date.startOf('month').format('MMM')
              }
            })}
            xLabel="Period"
            yLabel="Count"
          />
        </FView>
      </FView>
      <FView size={24} />
      <FView row center>
        <FView w="100%" maxWidth={664} fill bg="white" p={16}>
          <LineChart
            datasets={[
              {
                data: totalRange[chartRanges[chartRange]].map((total) => total.toFixed(2)),
                label: 'Total Processed',
                backgroundColor: 'orange',
                borderColor: 'orange',
                fill: false,
                pointHitRadius: 24,
                borderWidth: 0.7,
              },
              {
                data: commissionRange[chartRanges[chartRange]].map((commission) => commission.toFixed(2)),
                label: 'Total Commission',
                backgroundColor: 'purple',
                borderColor: 'purple',
                fill: false,
                pointHitRadius: 24,
                borderWidth: 0.7,
              },
              {
                data: revenueRange[chartRanges[chartRange]].map((revenue) => revenue.toFixed(2)),
                label: 'Foodly Revenue',
                backgroundColor: 'green',
                borderColor: 'green',
                fill: false,
                pointHitRadius: 24,
                borderWidth: 0.7,
              },
            ]}
            labels={ranges[chartRanges[chartRange]].map((date) => {
              if (chartRange === 'daily') {
                return date.format('MMM D')
              } else if (chartRange === 'weekly') {
                return date.startOf('week').format('MMM D')
              } else {
                return date.startOf('month').format('MMM')
              }
            })}
            xLabel="Period"
            yLabel="Amount ($)"
          />
        </FView>
      </FView>
    </>
  )
}

export default DataCharts
