import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import * as pages from 'pages'
import {useDispatch, useSelector} from 'react-redux'
import {FView} from 'components'
import {CircularProgress} from '@material-ui/core'

const PrivateAdminRoute = ({component: Component, ...rest}) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(dispatch.portal.getIsUserLoggedIn)
  const isAdmin = useSelector(dispatch.portal.getIsAdmin)
  const isSuperAdmin = useSelector(dispatch.portal.getIsSuperAdmin)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn == null) {
          return (
            <FView h={'100vh'} center>
              <CircularProgress />
            </FView>
          )
        } else if (!isLoggedIn) {
          return <pages.SignIn.SignIn />
        } else if (!isAdmin && !isSuperAdmin) {
          return <pages.NotFound.NotFound />
        } else {
          return (
            <FView h={'100vh'} overflowX="hidden">
              <Component {...props} />
            </FView>
          )
        }
      }}
    />
  )
}

const PrivateSuperAdminRoute = ({component: Component, ...rest}) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(dispatch.portal.getIsUserLoggedIn)
  const isAdmin = useSelector(dispatch.portal.getIsAdmin)
  const isSuperAdmin = useSelector(dispatch.portal.getIsSuperAdmin)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn == null) {
          return (
            <FView h={'100vh'} center>
              <CircularProgress />
            </FView>
          )
        } else if (!isLoggedIn) {
          return <pages.SignIn.SignIn />
        } else if (!isSuperAdmin) {
          if (isAdmin) {
            const {restaurantId, locationId} = dispatch.portal.getUserData()
            return <Redirect to={`/restaurant/info/${restaurantId}/${locationId}`} />
          } else {
            return <pages.NotFound.NotFound />
          }
        } else {
          return (
            <FView h={'100vh'} overflowX="hidden">
              <Component {...props} />
            </FView>
          )
        }
      }}
    />
  )
}

const Routes = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch.portal.subscribeUser()
    dispatch.restaurants.subscribeLocations()
    dispatch.public.subscribePublic()
  }, [dispatch.portal, dispatch.restaurants, dispatch.public])
  return (
    <Router>
      <Switch>
        <PrivateSuperAdminRoute path="/" exact component={pages.Home.Home} />
        <PrivateSuperAdminRoute path="/blocklist" exact component={pages.Blocklist.Blocklist} />
        <PrivateSuperAdminRoute path="/schedules" exact component={pages.Schedules.Schedules} />
        <PrivateSuperAdminRoute path="/translations" exact component={pages.Translations.Translations} />
        <PrivateSuperAdminRoute path="/analytics" exact component={pages.Analytics.Analytics} />
        <PrivateSuperAdminRoute path="/balances" exact component={pages.Balances.Balances} />
        <PrivateSuperAdminRoute path="/restaurants" exact component={pages.Restaurants.Restaurants} />
        <PrivateSuperAdminRoute path="/regions" exact component={pages.Regions.Regions} />
        <PrivateSuperAdminRoute path="/regions/info/:regionId" exact component={pages.Regions.RegionInfo} />
        <PrivateSuperAdminRoute path="/regions/addRegion" exact component={pages.Regions.AddRegion} />

        <PrivateSuperAdminRoute path="/point-of-sales" exact component={pages.PointOfSales.PointOfSales} />
        <PrivateSuperAdminRoute path="/orders" exact component={pages.Orders.Orders} />
        <PrivateSuperAdminRoute path="/deliveries" exact component={pages.DeliveryOrdersMap.DeliveryOrdersMap} />
        <PrivateSuperAdminRoute path="/riders-earnings" exact component={pages.RidersEarnings.RidersEarnings} />
        <PrivateSuperAdminRoute path="/devices" exact component={pages.Devices.Devices} />
        <PrivateSuperAdminRoute path="/promotions" exact component={pages.Promotions.Promotions} />
        <PrivateSuperAdminRoute path="/tags" exact component={pages.Tags.Tags} />
        <PrivateSuperAdminRoute path="/users" exact component={pages.Users.Users} />
        <PrivateSuperAdminRoute path="/support" exact component={pages.Support.Support} />
        <PrivateSuperAdminRoute path="/restaurants-info" exact component={pages.RestaurantsInfo.RestaurantsInfo} />
        <PrivateSuperAdminRoute path="/restaurant/create" exact component={pages.Restaurant.CreateRestaurant} />
        <PrivateSuperAdminRoute path="/point-of-sales/create" exact component={pages.PointOfSale.CreatePointOfSale} />
        <PrivateAdminRoute
          path="/restaurant/info/:restaurantId/:locationId"
          exact
          component={pages.Restaurant.Restaurant}
        />
        <PrivateAdminRoute path="/restaurant/menus/:restaurantId/:locationId" exact component={pages.Menu.Menu} />
        <PrivateAdminRoute path="/restaurant/hours/:restaurantId/:locationId" exact component={pages.Hours.Hours} />
        <PrivateAdminRoute
          path="/restaurant/orders/:restaurantId/:locationId"
          exact
          component={pages.RestaurantOrders.RestaurantOrders}
        />
        <PrivateAdminRoute
          path="/restaurant/balances/:restaurantId/:locationId"
          exact
          component={pages.RestaurantBalances.RestaurantBalances}
        />
        <PrivateAdminRoute
          path="/restaurant/notifications/:restaurantId/:locationId"
          exact
          component={pages.Notifications.Notifications}
        />
        <PrivateAdminRoute
          path="/restaurant/rewards/:restaurantId/:locationId"
          exact
          component={pages.Rewards.Rewards}
        />
        <PrivateAdminRoute
          path="/restaurant/announcement/:restaurantId/:locationId"
          exact
          component={pages.Announcement.Announcement}
        />
        <PrivateAdminRoute
          path="/restaurant/reports/:restaurantId/:locationId"
          exact
          component={pages.Reports.Reports}
        />
        <PrivateAdminRoute
          path="/restaurant/taxes/:restaurantId/:locationId"
          exact
          component={pages.ProductTaxes.ProductTaxes}
        />
        <PrivateAdminRoute
          path="/restaurant/receipt/:restaurantId/:locationId"
          exact
          component={pages.Receipt.Receipt}
        />
        <PrivateAdminRoute
          path="/restaurant/banking/:restaurantId/:locationId"
          exact
          component={pages.Banking.Banking}
        />
        <PrivateAdminRoute path="/restaurant/square/:restaurantId/:locationId" exact component={pages.Square.Square} />
        <PrivateAdminRoute path="/restaurant/square/" exact component={pages.Square.Square} />
        <PrivateAdminRoute
          path="/restaurant/category/:restaurantId/:locationId/:menuId"
          exact
          component={pages.Category.Category}
        />
        <PrivateAdminRoute path="/point-of-sales/info/:pointOfSaleId" exact component={pages.PointOfSale.PointOfSale} />
        <PrivateAdminRoute
          path="/point-of-sales/employee/:pointOfSaleId"
          exact
          component={pages.PointOfSaleEmployee.PointOfSaleEmployee}
        />
        <PrivateAdminRoute
          path="/point-of-sales/floor-table/:pointOfSaleId"
          exact
          component={pages.PointOfSaleFloorTable.PointOfSaleFloorTable}
        />
      </Switch>
    </Router>
  )
}

export default Routes
