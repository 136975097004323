import React, {useCallback, useState, useEffect} from 'react'
import {colors} from 'styles'
import {filter} from 'lodash'
import {useTheme} from '@material-ui/core/styles'
import {useSelector, useDispatch} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import SearchIcon from '@material-ui/icons/Search'
import {CircularProgress} from '@material-ui/core'
import {FView, FText, FNavBar, FInput} from 'components'

export default function Devices() {
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')

  const onSearchTermChange = useCallback((e) => {
    setSearchTerm(e.target.value)
  }, [])

  const isUpSm = useMediaQueryUp('sm')
  const theme = useTheme()

  useEffect(() => {
    return dispatch.users.subscribeAdminAnalytics()
  }, [dispatch.users])

  const adminAnalytics = useSelector(dispatch.users.getAdminAnalytics)
  const isLoadingAdminAnalytics = useSelector(dispatch.users.getIsLoadingAdminAnalytics)

  const admins = Object.values(adminAnalytics)
  const filteredAdmins = !!searchTerm
    ? filter(admins, (admin) => admin?.deviceInfo?.email?.toLowerCase().includes(searchTerm.toLowerCase()))
    : admins

  return (
    <FView block minHeight="100vh" bg={theme.palette.common.background}>
      <FView h={130}>
        <FNavBar />
        <FView fixed zIndex={theme.zIndex.appBar} top={80} left={0} right={0} shadow10>
          <FView h={1} bg={theme.palette.grey[300]} />
          <FView row alignCenter ph={isUpSm ? 64 : 16} h={50} bg={colors.white}>
            <SearchIcon color="action" />
            <FInput value={searchTerm} onChange={onSearchTermChange} placeholder="Search for a Admin User" />
          </FView>
        </FView>
      </FView>
      {isLoadingAdminAnalytics && (
        <FView fill center>
          <CircularProgress />
        </FView>
      )}
      <FView fill p={25} alignCenter bg={theme.palette.common.background}>
        <FView w={600} maxWidth="100vw">
          <FText h4 bold>
            Admin Devices (Deprecated)
          </FText>
          <FView alignStart>
            <a href="https://biz.airdroid.com/#/devices" target="_blank" rel="noopener noreferrer">
              <FText h6 bold primary>
                AirDroid Link
              </FText>
            </a>
          </FView>
          <FView size={15} />
          {filteredAdmins.map((admin) => {
            return (
              <FView fill row key={admin.id} shadow25 mb={10}>
                <FView w={10} bg={admin?.state === 'online' ? theme.palette.success.main : theme.palette.error.main} />
                <FView
                  fill
                  p={15}
                  rounded
                  bg={theme.palette.common.white}
                  grid
                  gridTemplateColumns={isUpSm ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'}
                  gap={15}>
                  <FText>{admin?.deviceInfo?.email}</FText>
                  <FText>{admin?.deviceInfo?.appState}</FText>
                  <FText>
                    Battery: {(admin?.deviceInfo?.batteryLevel * 100).toFixed(0)}% ({admin?.deviceInfo?.batteryState})
                  </FText>
                  <FText>
                    v{admin?.deviceInfo?.readableVersion} rev.{admin?.deviceInfo?.codepushLabel?.substring(1)}
                  </FText>
                  <FText>{admin?.deviceInfo?.deviceName}</FText>
                </FView>
              </FView>
            )
          })}
        </FView>
      </FView>
    </FView>
  )
}
