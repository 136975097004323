import React, {useState, useRef, useEffect, useCallback} from 'react'
import {Dialog, DialogContent, DialogTitle, DialogActions, Select, MenuItem} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import {FButton, FView, FText, FInput, FTextField} from 'components'
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete'
import {colors} from 'styles'
import {CircularProgress} from '@material-ui/core'
import {css} from 'emotion'
import {useDispatch} from 'react-redux'
import {AddLocation, Backspace} from '@material-ui/icons'

const AddressEditDialog = ({open, i, addresses, mode, handleClose, userId}) => {
  const theme = useTheme()

  const [isCreate, setIsCreate] = useState(false)

  const [deliveryInstructions, setDeliveryInstructions] = useState('')
  const [deliveryUnit, setDeliveryUnit] = useState('')
  const [deliveryType, setDeliveryType] = useState('DELIVER_TO_DOOR')
  const [deliveryLocation, setDeliveryLocation] = useState('')
  const dispatch = useDispatch()
  const [address, setAddress] = useState('')
  const [isAddressSelected, setIsAddressSelected] = useState(false)
  const enterAddressInputRef = useRef(null)

  const resetToDefaultState = useCallback(() => {
    setAddress('')
    setDeliveryUnit('')
    setDeliveryType('DELIVER_TO_DOOR')
    setDeliveryLocation('')
    setDeliveryInstructions('')
  }, [])

  useEffect(() => {
    if (mode === 'Edit') {
      setDeliveryInstructions(addresses[i].containerDeliveryInstructions)
      setDeliveryUnit(addresses[i].containerDeliveryUnit)
      setDeliveryType(addresses[i].contactlessDeliveryType)
      setDeliveryLocation(addresses[i].contactlessDeliveryLocation || '')
      setAddress(addresses[i].containerDeliveryAddress)
      setIsAddressSelected(true)
    } else {
      resetToDefaultState()
    }
  }, [resetToDefaultState, addresses, i, mode])

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{mode} an Address</DialogTitle>
      <DialogContent>
        <FView>
          <FText>Address</FText>
          <FView pv={10}>
            <PlacesAutocomplete
              value={address}
              onChange={(address) => {
                setAddress(address)
                setIsAddressSelected(false)
              }}
              onSelect={(address, placeId) => {
                if (address) {
                  setAddress(address)
                  setIsAddressSelected(true)
                }
              }}
              searchOptions={{
                componentRestrictions: {country: ['ca']},
                types: ['address'],
              }}>
              {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                <>
                  <FView selfCenter row w={635} maxWidth="100%">
                    <FView fill>
                      <FView block>
                        <FView fill bc={colors.grey25} blr={4} br={4} bw={1} bg={colors.white} row>
                          <FView fill>
                            <FInput
                              ref={enterAddressInputRef}
                              autoComplete="street-address"
                              placeholder="Enter an Address"
                              style={{height: 48, background: 'transparent'}}
                              {...getInputProps()}
                              onKeyDown={(e) => {
                                e.keyCode === 13 && e.preventDefault()
                              }}
                            />
                          </FView>
                          {!!address && (
                            <FButton
                              size={48}
                              onClick={() => {
                                setAddress('')
                                setIsAddressSelected(false)
                              }}>
                              <FView size={48} center>
                                <Backspace color="primary" size={30} />
                              </FView>
                            </FButton>
                          )}
                        </FView>
                      </FView>
                      {suggestions.length > 0 && (
                        <FView relative fill>
                          <FView
                            absolute
                            zIndex={10}
                            bg={colors.white}
                            left={0}
                            right={0}
                            rounded
                            bc={colors.grey50}
                            bw={1}
                            btw={0}>
                            {loading && (
                              <FView h={'5.3rem'} justifyCenter pl={'6rem'}>
                                <CircularProgress />
                              </FView>
                            )}
                            {suggestions.map((suggestion, index) => (
                              <div key={index} className={classes.placeItem} {...getSuggestionItemProps(suggestion)}>
                                <FView size={'5.4rem'} center>
                                  <AddLocation color="primary" size={30} />
                                </FView>
                                <FText body1 grey700 bold>
                                  {suggestion.description}
                                </FText>
                              </div>
                            ))}
                          </FView>
                        </FView>
                      )}
                    </FView>
                  </FView>
                </>
              )}
            </PlacesAutocomplete>
          </FView>
          <FText> Unit/Suite</FText>
          <FTextField value={deliveryUnit} onChange={(e) => setDeliveryUnit(e.target.value)} />
          <FText> Delivery Instructions</FText>
          <Select value={deliveryType} onChange={(e) => setDeliveryType(e.target.value)}>
            <MenuItem value={'DELIVER_TO_DOOR'}>Deliver to my door</MenuItem>
            <MenuItem value={'MEET_COURIER_OUTSIDE'}>Meet courier outside</MenuItem>
            <MenuItem value={'LEAVE_AT_LOCATION'}>Leave at specified location</MenuItem>
          </Select>
          {deliveryType === 'LEAVE_AT_LOCATION' && (
            <>
              <FText> Location</FText>
              <FTextField value={deliveryLocation} onChange={(e) => setDeliveryLocation(e.target.value)} />
            </>
          )}
          <FText>Instructions</FText>
          <FTextField
            helperText="optional"
            value={deliveryInstructions}
            onChange={(e) => setDeliveryInstructions(e.target.value)}
          />
        </FView>
      </DialogContent>
      <DialogActions>
        <FView fill justifyEvenly bg={theme.palette.common.white} row>
          <FButton
            fill
            onClick={() => {
              setAddress('')
              handleClose()
            }}>
            <FView p={8} br={4} bg={theme.palette.primary.main} center>
              <FText bold white>
                Cancel
              </FText>
            </FView>
          </FButton>
          <FView w={8} />
          <FButton
            fill
            disabled={isCreate}
            onClick={() => {
              if (!isAddressSelected) {
                alert('Address is missing or invalid')
              } else {
                setIsCreate(true)
                setIsAddressSelected(false)
                deliveryInstructions === 'DELIVER_TO_DOOR' && setDeliveryInstructions(null)
                geocodeByAddress(address)
                  .then((results) => getLatLng(results[0]))
                  .then(({lat, lng}) => {
                    const new_address = {
                      contactlessDeliveryLocation: deliveryLocation,
                      contactlessDeliveryType: deliveryType === 'DELIVER_TO_DOOR' ? null : deliveryType,
                      containerDeliveryAddress: address,
                      containerDeliveryAddressLatLng: {
                        lat,
                        lng,
                      },
                      containerDeliveryInstructions: deliveryInstructions,
                      containerDeliveryUnit: deliveryUnit,
                    }
                    return dispatch.users.updateUserAddresses(i, addresses, new_address, userId)
                  })
                  .then(() => {
                    handleClose()
                    setIsCreate(false)
                  })
              }
            }}>
            <FView p={8} br={4} bg={isCreate ? theme.palette.grey[400] : theme.palette.primary.main} center>
              <FText bold white>
                {mode}
              </FText>
            </FView>
          </FButton>
        </FView>
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  placeItem: css({
    height: '5.3rem',
    padding: '1rem',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    paddingHorizontal: '1rem',
    flexDirection: 'row',
  }),
}

export default AddressEditDialog
