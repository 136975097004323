import React, {useState, useEffect, useRef} from 'react'
import {colors} from 'styles'
import {useDispatch} from 'react-redux'
import {useMediaMinMD} from 'f-web/src/hooks'
import {FText, FView, FNavBar, FTextField, FButton, ButtonFillView} from 'components'
import {CircularProgress, MenuItem} from '@material-ui/core'
import UnconfirmedOrderItemView from './UnconfirmedOrderItemView'
import ConfirmedOrderItemView from './ConfirmedOrderItemView'
import {getOrdersGroupCreatedAtDesc} from 'f-core/src/config/firebase'
import Autocomplete from '@material-ui/lab/Autocomplete'

const DEFAULT_LIMIT = 10

export default function Orders() {
  const dispatch = useDispatch()
  const isMinMD = useMediaMinMD()
  const [recentOrders, setRecentOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [statusFilter, setStatusFilter] = useState(['New', 'Preparing'])
  const [statusFilterInput, setStatusFilterInput] = useState(statusFilter)
  const [limit, setLimit] = useState(DEFAULT_LIMIT)
  const limitRef = useRef()

  useEffect(() => {
    return getOrdersGroupCreatedAtDesc({
      limit: limit,
      status: statusFilter,
    }).onSnapshot((snap) => {
      const recentOrders = []
      for (const doc of snap.docs) {
        const recentOrderData = doc.data()
        recentOrderData.id = doc.id
        recentOrders.push(recentOrderData)
      }
      setIsLoading(false)
      setRecentOrders(recentOrders)
    })
  }, [dispatch.orders, statusFilter, limit])
  // useEffect(() => {
  //   return dispatch.orders.subscribeActiveOrders()
  // }, [dispatch.orders])
  // useEffect(() => {
  //   return dispatch.orders.subscribeActiveDeliveryOrders()
  // }, [dispatch.orders])
  // const deliveryOrders = useSelector(dispatch.orders.getActiveDeliveryOrders)
  // const orders = useSelector(dispatch.orders.getOrders)
  // const newOrderIds = useSelector(dispatch.orders.getNewOrderIds)
  // const prepOrderIds = useSelector(dispatch.orders.getPrepOrderIds)
  return (
    <FView block bg={colors.background} h={'100vh'} overflowY={'auto'}>
      <FNavBar />
      <FView mv={104} mh={isMinMD && 70}>
        <FView row alignCenter>
          <FView w={80}>
            <FTextField
              inputRef={limitRef}
              required
              select
              type="number"
              size="small"
              label="Limit"
              defaultValue={DEFAULT_LIMIT}>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={75}>75</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </FTextField>
          </FView>
          <FView size={16} />
          <Autocomplete
            disableCloseOnSelect
            value={statusFilterInput}
            options={['New', 'Preparing', 'Done', 'Cancelled']}
            onChange={(e, values) => {
              setStatusFilterInput(values)
            }}
            size="small"
            disableClearable
            autoHighlight
            multiple
            getOptionLabel={(tasteTag) => tasteTag}
            renderOption={(tasteTag) => tasteTag}
            renderInput={(params) => <FTextField {...params} variant="outlined" label="Status" />}
          />
          <FView size={16} />
          <FButton
            disabled={isLoading}
            onPress={() => {
              if (limit !== limitRef.current.value || statusFilter !== statusFilterInput) {
                setIsLoading(true)
                setLimit(limitRef.current.value)
                setStatusFilter(statusFilterInput)
              }
            }}>
            <ButtonFillView rounded ph={16} h={40} mt={6} disabled={isLoading}>
              <FText primaryContrast bold>
                Apply
              </FText>
            </ButtonFillView>
          </FButton>
          {isLoading && (
            <FView ml={16} mt={8}>
              <CircularProgress size={24} />
            </FView>
          )}
        </FView>
        <FView size={15} />
        <FView maxWidth={700}>
          {recentOrders.map((orderData, i) => {
            if (orderData.status === 'New') {
              return <UnconfirmedOrderItemView key={orderData.id} orderId={orderData.id} orderData={orderData} />
            } else {
              return <ConfirmedOrderItemView key={orderData.id} orderId={orderData.id} orderData={orderData} />
            }
          })}
        </FView>
        {/* <FView size={30} />
        <FText h5 h1={isMinMD} bold>
          Preparing Orders
        </FText>
        <FView size={15} />
        <FView maxWidth={700}>
          {prepOrderIds.map((orderId, i) => {
            if (process.env.REACT_APP_ENVIRONMENT === 'dev' && i > 4) return null
            const orderData = orders[orderId]
            if (!orderData) {
              return null
            }
            return (
              <ConfirmedOrderItemView
                key={orderId}
                orderId={orderId}
                orderData={orderData}
                deliveryOrderData={deliveryOrders[orderId]}
              />
            )
          })}
        </FView> */}
      </FView>
    </FView>
  )
}
