import React, {useState, useCallback} from 'react'
import {colors} from 'styles'
import {filter} from 'lodash'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useSelector, useDispatch} from 'react-redux'
import {useTheme} from '@material-ui/core/styles'
import {IconButton} from '@material-ui/core'
import {Visibility, VisibilityOff, Search} from '@material-ui/icons'
import {FView, FText, FNavBar, ButtonFillView, FInput, FLinkAnimate} from 'components'
import RestaurantItemView from './RestaurantItemView'

const Restaurants = () => {
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const locationsData = useSelector(dispatch.restaurants.getLocations)
  const isUpSm = useMediaQueryUp('sm')
  const theme = useTheme()
  const [showHidden, setShowHidden] = useState(false)

  const onSearchTermChange = useCallback((e) => {
    setSearchTerm(e.target.value)
  }, [])

  const locationsList = Object.values(locationsData)
  const filteredLocationsList = !!searchTerm
    ? filter(locationsList, (locationData) =>
        locationData.restaurantName.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : locationsList

  return (
    <FView block>
      <FView h={130}>
        <FNavBar />
        <FView fixed zIndex={theme.zIndex.appBar} top={80} left={0} right={0} shadow10>
          <FView h={1} bg={theme.palette.grey[300]} />
          <FView row alignCenter ph={isUpSm ? 64 : 16} h={50} bg={colors.white}>
            <Search color="action" />
            <FInput value={searchTerm} onChange={onSearchTermChange} placeholder="Search for a restaurant" />
          </FView>
        </FView>
      </FView>
      <FView bg={colors.background} p={25} alignCenter>
        <FView w={600} maxWidth="100vw">
          <FView ph={isUpSm ? 0 : 16} pv={16} row justifyBetween alignCenter>
            <FText medium h4>
              Restaurants
            </FText>
            <FLinkAnimate to="/restaurant/create">
              <ButtonFillView w={165} rounded>
                <FText button primaryContrast>
                  Create New
                </FText>
              </ButtonFillView>
            </FLinkAnimate>
          </FView>
          {showHidden ? (
            <FView row alignCenter>
              <IconButton color="primary" onClick={() => setShowHidden(false)}>
                <Visibility color="primary" fontSize="large" />
              </IconButton>
              <FText body1>Showing Hidden Restaurants</FText>
            </FView>
          ) : (
            <FView row alignCenter>
              <IconButton onClick={() => setShowHidden(true)}>
                <VisibilityOff color="action" fontSize="large" />
              </IconButton>
              <FText body1>Hiding Hidden Restaurants</FText>
            </FView>
          )}
          {filteredLocationsList.map((locationData) => {
            if (!showHidden && !locationData.isVisible) {
              return null
            }
            return (
              <FLinkAnimate
                key={locationData.locationId}
                to={`/restaurant/info/${locationData.restaurantId}/${locationData.locationId}`}>
                <RestaurantItemView {...locationData} />
              </FLinkAnimate>
            )
          })}
        </FView>
      </FView>
    </FView>
  )
}

export default Restaurants
