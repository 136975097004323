import React from 'react'
import {colors} from 'styles'
import {useDispatch, useSelector} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import useReactRouter from 'use-react-router'
import {getDocument} from 'f-core/src/config/firebase'
import {Divider, CircularProgress} from '@material-ui/core'
import {FView, FNavBar, FDrawer, FText} from 'components'

export default function RestaurantBalances() {
  const {match} = useReactRouter()
  const {restaurantId, locationId} = match.params
  const dispatch = useDispatch()
  const isUpSm = useMediaQueryUp('sm')
  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))

  const [privateData, setPrivateData] = React.useState(null)

  React.useEffect(() => {
    const unsubPrivate = getDocument('private', {restaurantId}).onSnapshot((snapshot) => {
      if (snapshot) {
        const privateData = snapshot.data()
        setPrivateData(privateData)
      }
    })
    return unsubPrivate
  }, [restaurantId])

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow={isUpSm} />
        </FView>
        <FView fill bg={colors.background} row>
          <FDrawer locationData={locationData} />
          <FView fill alignCenter pt={isUpSm ? 25 : 75} pb={25}>
            <FText h5 bold gutterBottom>
              Balances
            </FText>
            {privateData ? (
              <FView w="100%" maxWidth={512} bg={colors.white} p={15}>
                <FView row justifyBetween>
                  <FText body1>Available to pay out: </FText>
                  <FText body1>${privateData?.deposit}</FText>
                </FView>
                <FView size={25} />
                <FText h6 bold>
                  On the way to your bank
                </FText>
                <FView size={10} />
                <Divider />
                <FView size={10} />
                <FText body1>Coming soon...</FText>
              </FView>
            ) : (
              <FView fill center>
                <CircularProgress />
              </FView>
            )}
          </FView>
        </FView>
      </FView>
    </FView>
  )
}
