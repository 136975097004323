import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FView, FText, FButton} from 'components'
import {Edit, Visibility, ChevronRight, Delete} from '@material-ui/icons'
import {useTheme} from '@material-ui/core/styles'
import {useMediaQueryUp} from 'f-web/src/hooks'

const MenuItem = ({setEditMenuId, setDeleteMenuId, menuId, restaurantId, locationId}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const active = useSelector(() => dispatch.restaurants.getIsMenuActive({menuId, locationId}))
  const menu = useSelector(() => dispatch.restaurants.getMenu({locationId, menuId}))
  const isUpSm = useMediaQueryUp('sm')

  return (
    <FView
      justifyBetween
      shadow10
      rounded
      row
      wrap
      h={80}
      bg={active ? theme.palette.common.white : theme.palette.grey[200]}>
      <FView fill justifyCenter>
        <FButton to={`/restaurant/category/${restaurantId}/${locationId}/${menuId}`}>
          <FView row alignCenter justifyBetween ph={25}>
            <FText h6 color={active ? theme.palette.common.black : theme.palette.action.disabled}>
              {menu.name}
            </FText>
            <ChevronRight color={active ? 'inherit' : 'disabled'} />
          </FView>
        </FButton>
      </FView>
      <FView row>
        <FView bg={theme.palette.grey[300]} w={1} />
        <FButton onClick={() => setEditMenuId(menuId)}>
          <FView row center w={90}>
            <Edit color="primary" />
            <FView size={8} />
            <FText subtitle2 primary>
              Edit
            </FText>
          </FView>
        </FButton>
        <FView bg={theme.palette.grey[300]} w={1} />
        <FButton
          onClick={() => dispatch.restaurants.setIsMenuActive({menuId, restaurantId, locationId, isActive: !active})}>
          <FView row alignCenter w={90} ph={8}>
            <Visibility color="primary" />
            <FView size={8} />
            <FText subtitle2 primary>
              {active ? 'Active' : 'Inactive'}
            </FText>
          </FView>
        </FButton>
        <FView bg={theme.palette.grey[300]} w={1} />
        <FButton onClick={() => setDeleteMenuId(menuId)}>
          <FView row alignCenter w={90} ph={8}>
            <Delete color="primary" />
            <FView size={8} />
            <FText subtitle2 primary>
              Delete
            </FText>
          </FView>
        </FButton>
      </FView>
    </FView>
  )
}

export default MenuItem
