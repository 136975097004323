import React, {useEffect, useState, useRef} from 'react'
import * as utils from 'f-utils'
import {FieldValue, getCollection, getDocument} from 'f-core/src/config/firebase'
import {colors} from 'styles'
import DateMomentUtils from '@date-io/moment'
import {useMediaQueryUp, usePrevious} from 'f-web/src/hooks'
import {useDispatch, useSelector} from 'react-redux'
import {FView, FText, FTextField, ButtonFillView, FButtonA, FTextFieldPassword, FInput} from 'components'
import {Checkbox, Dialog, Divider, FormControl, FormControlLabel} from '@material-ui/core'
import {InputAdornment, MenuItem} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {isPlainObject} from 'lodash'
import * as api from 'models/api'
import {auth} from 'f-core/src/config/firebase'
import * as geofire from 'geofire-common'

const RestaurantInfo = ({locationData, locationId, privateData, isCreateNew}) => {
  const [selectedDate, setSelectedDate] = useState(null)
  const classes = useStyle()

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }
  const [publicData, setPublicData] = useState()
  const [regionData, setRegionData] = useState([])
  useEffect(() => {
    return getDocument('public').onSnapshot((snapshot) => {
      const publicData = snapshot?.data() ?? null
      setPublicData(publicData)
    })
  }, [])

  const dispatch = useDispatch()
  const isEditLocked = useSelector(dispatch.portal.getIsAdmin)
  useEffect(() => {
    const regions = []

    if (!isEditLocked) {
      getCollection('Regions').onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          regions.push(doc.id)
        })
        setRegionData(regions)
      })
    } else {
      regions.push(locationData?.region)
      setRegionData(regions)
    }
  }, [isEditLocked, locationData.region])
  const [isSaving, setIsSaving] = useState(false)
  const [newRestaurantData, setNewRestaurantdata] = useState()
  const isLoading = useSelector(dispatch.restaurants.getIsLocationsLoading)

  const [orderStatusIndex, setOrderStatusIndex] = useState(0)

  const [selectedTags, setSelectedTags] = useState(locationData.tags ?? [])
  const isSpecialCommission = isPlainObject(privateData?.commissionFee)

  const [regionTimeZone, setRegionTimeZone] = useState(locationData.timezone)
  const currentMoment = utils.moment()
  const currentTimestamp = currentMoment.valueOf()
  const orderStatusDescription =
    locationData?.orderOpenAfter < currentTimestamp
      ? 'Ordering Open'
      : (locationData?.orderOpenAfter ?? 1e15) >= 1e15
      ? 'Ordering Closed'
      : `Closed until ${utils.moment(locationData?.orderOpenAfter).tz(regionTimeZone).calendar()} (${regionTimeZone})`

  // ------ Create New Restaurant Only Fields --------------------
  const emailRef = useRef()
  const passwordRef = useRef()
  const commissionFeeRef = useRef()
  const deliveryTypeRef = useRef()
  const restaurantDeliveryFeeSubstitutionRef = useRef()
  // =================================================

  const restaurantNameRef = useRef()
  const restaurantAddressRef = useRef()
  const locationNameRef = useRef()
  const latRef = useRef()
  const lngRef = useRef()
  const phoneNumberRef = useRef()
  const foodCategoryRef = useRef()
  const timezoneRef = useRef()
  const printingSupportRef = useRef()
  const paymentProcessorRef = useRef()
  const iconImageRef = useRef()
  const promoImageRef = useRef()
  const [deliveryAvailable, setDeliveryAvailable] = useState(false)
  const [pickupAvailable, setPickupAvailable] = useState(false)
  const [dineInAvailable, setDineInAvailable] = useState(false)
  const [isDeliveryFeeVariable, setIsDeliveryFeeVariable] = useState(false)
  const [cashPaymentEnabled, setCashPaymentEnabled] = useState(false)
  const [isRestaurantVisible, setIsRestaurantVisible] = useState(false)
  const deliveryRadiusRef = useRef()
  const deliveryFeeRef = useRef()
  const deliveryFeeMinimumRef = useRef()
  const deliveryFeeDiscountMinimumSubTotalRef = useRef()
  const deliveryFeeDiscountRef = useRef()
  const deliveryFeePerDistanceRef = useRef()
  const freeDeliveryMinSubtotalRef = useRef()
  const deliveryMinSubtotalRef = useRef()
  const deliveryTimeRef = useRef()
  const titleRef = useRef()
  const descriptionRef = useRef()
  const keywordsRef = useRef()
  const commissionFeeDineInRef = useRef()
  const commissionFeePickupRef = useRef()
  const commissionFeeDirectPickupRef = useRef()
  const commissionFeeDeliveryRef = useRef()
  const waitTimeRef = useRef()
  const regionRef = useRef()
  const isUpSm = useMediaQueryUp('sm')
  const isUpMd = useMediaQueryUp('md')

  const prevLocationId = usePrevious(locationId)
  const prevIsLoading = usePrevious(isLoading)

  function checkValidity() {
    return (
      commissionFeeRef.current.reportValidity() &&
      commissionFeeDeliveryRef.current.reportValidity() &&
      commissionFeeDineInRef.current.reportValidity() &&
      commissionFeeDirectPickupRef.current.reportValidity() &&
      commissionFeePickupRef.current.reportValidity()
    )
  }
  async function handleImageUpload(selectedFileRef, imageType, restaurantId = null) {
    const authToken = await auth.currentUser.getIdToken()
    try {
      const response = await api.uploadImage({
        serverUrl: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL,
        authToken,
        restaurantId: restaurantId ?? locationData.restaurantId,
        imageFile: selectedFileRef.current?.files[0],
        imageType,
      })
      return response.data.imageUrl
    } catch (e) {
      alert(e.message)
    }
  }

  useEffect(() => {
    // Reset to default state whenever loading is finished or locationId changes
    if ((prevIsLoading === true && isLoading === false) || prevLocationId !== locationId) {
      setDeliveryAvailable(locationData?.availableOrderTypes?.Delivery ?? true)
      setPickupAvailable(locationData?.availableOrderTypes?.Pickup ?? true)
      setDineInAvailable(locationData?.availableOrderTypes?.DineIn ?? false)
      setCashPaymentEnabled(locationData?.paymentMethods?.['inperson-cash'] ?? false)
      setIsRestaurantVisible(locationData?.isVisible ?? true)
      setIsDeliveryFeeVariable(locationData?.isDeliveryFeeVariable ?? true)
    }
  }, [prevLocationId, locationId, locationData, prevIsLoading, isLoading])
  useEffect(() => {
    if (orderStatusIndex === 8) {
      setSelectedDate(utils.moment(Date.now()).tz(regionTimeZone))
    }
  }, [orderStatusIndex, regionTimeZone])
  return (
    <FView>
      <FView bg={colors.white} p={25} maxWidth="100%">
        <FView row>
          <FText h5 medium gutterBottom>
            Restaurant Info
          </FText>
          {!!locationData.iconImageUrl && (
            <>
              <FView w={48} />
              <img src={locationData.iconImageUrl} alt="locationIcon" className={classes.iconImg} />
            </>
          )}
        </FView>
        <FView grid gridTemplateColumns={isUpSm ? '1fr 1fr' : '1fr'} columnGap={15}>
          <FTextField
            inputRef={emailRef}
            required
            disabled={!isCreateNew}
            defaultValue={isCreateNew ? null : locationData.email.split('@')[0]}
            label="Restaurant ID"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  @{isCreateNew ? 'foodly.tech' : locationData.email.split('@')[1]}
                </InputAdornment>
              ),
            }}
          />
          {isCreateNew && (
            <>
              <FView w={32} />

              <FTextFieldPassword inputRef={passwordRef} required label="Password" />
            </>
          )}
          {!isEditLocked && (
            <FView w={150}>
              <FTextField
                disabled={isSpecialCommission}
                inputRef={commissionFeeRef}
                required
                inputProps={{step: '0.001', min: 0, max: 1}}
                defaultValue={
                  isCreateNew || !privateData?.commissionFee
                    ? '0.15'
                    : isSpecialCommission
                    ? null
                    : privateData.commissionFee
                }
                label="Commission Rate"
              />
            </FView>
          )}

          <FTextField
            inputRef={restaurantNameRef}
            required
            disabled={isEditLocked}
            label="Restaurant Name"
            defaultValue={locationData.restaurantName}
          />
          <FTextField
            inputRef={restaurantAddressRef}
            required
            disabled={isEditLocked}
            label="Restaurant Address"
            defaultValue={locationData.address}
          />

          <FTextField
            inputRef={locationNameRef}
            required
            disabled={isEditLocked}
            label="Location Name"
            defaultValue={locationData.locationName}
          />
          <FormControl fullWidth>
            <FView row>
              <FTextField
                inputRef={latRef}
                required
                disabled={isEditLocked}
                type="number"
                label="lat"
                defaultValue={isCreateNew ? null : locationData.restaurantLatLng.lat}
              />
              <FView w={16} />
              <FTextField
                inputRef={lngRef}
                required
                disabled={isEditLocked}
                type="number"
                label="lng"
                defaultValue={isCreateNew ? null : locationData.restaurantLatLng.lng}
              />
            </FView>
          </FormControl>

          <FTextField
            inputRef={phoneNumberRef}
            required
            type="tel"
            disabled={isEditLocked}
            label="Phone Number"
            defaultValue={locationData.phoneNumber}
          />
          <FView row>
            <FTextField
              inputRef={foodCategoryRef}
              required
              disabled={isEditLocked}
              select
              label="Food Category"
              defaultValue={locationData.restaurantType ?? ''}>
              <MenuItem value={'Korean'}>Korean</MenuItem>
              <MenuItem value={'Japanese'}>Japanese</MenuItem>
              <MenuItem value={'Chinese'}>Chinese</MenuItem>
              <MenuItem value={'Western'}>Western</MenuItem>
              <MenuItem value={'Asian'}>Asian</MenuItem>
              <MenuItem value={'Chicken'}>Chicken</MenuItem>
              <MenuItem value={'Vegan'}>Vegan</MenuItem>
              <MenuItem value={'Dessert'}>Dessert</MenuItem>
              <MenuItem value={'Grocery'}>Grocery</MenuItem>
              <MenuItem value={'Breakfast'}>Breakfast</MenuItem>
              <MenuItem value={'Cafe'}>Cafe</MenuItem>
              <MenuItem value={'Indian'}>Indian</MenuItem>
              <MenuItem value={'Italian'}>Italian</MenuItem>
              <MenuItem value={'Pub'}>Pub</MenuItem>
              <MenuItem value={'Thai'}>Thai</MenuItem>
              <MenuItem value={'Vietnamese'}>Vietnamese</MenuItem>
              <MenuItem value={'Bakery'}>Bakery</MenuItem>
            </FTextField>
            <FView w={16} />
            {!isEditLocked && (
              <FTextField
                inputRef={paymentProcessorRef}
                required
                select
                label="Payment Processor"
                defaultValue={locationData.paymentProcessor ?? 'Moneris'}>
                <MenuItem value={'Moneris'}>Moneris</MenuItem>
                <MenuItem value={'Stripe'}>Stripe</MenuItem>
              </FTextField>
            )}
          </FView>
          <FView row>
            <FView>
              <FView pl={10} alignStart>
                {!!locationData.restaurantIconUrl && (
                  <img
                    src={locationData.restaurantIconUrl}
                    style={{objectFit: 'contain', height: 60, maxWidth: 120}}
                    alt="restaurant icon"
                  />
                )}
                <FText overline>Restaurant Icon</FText>
                <FormControlLabel control={<FInput ref={iconImageRef} type="file" accept=".jpg,.png,.jpeg" />} />
              </FView>
            </FView>
            <FView w={16} />
            <FView>
              <FView pl={10} alignStart>
                {!!locationData.promoUrl && (
                  <img
                    src={locationData.promoUrl}
                    style={{objectFit: 'contain', height: 60, maxWidth: 120}}
                    alt="restaurant promo"
                  />
                )}
                <FText overline>Restaurant Promo</FText>
                <FormControlLabel control={<FInput ref={promoImageRef} type="file" accept=".jpg,.png,.jpeg" />} />
              </FView>
            </FView>
          </FView>
          <FView>
            <FView row>
              <FTextField
                inputRef={timezoneRef}
                required
                select
                disabled={isEditLocked}
                label="Time Zone"
                defaultValue={locationData.timezone ?? 'America/Vancouver'}
                onChange={(e) => {
                  setRegionTimeZone(e.target.value)
                }}>
                <MenuItem value={'America/Vancouver'}>America/Vancouver</MenuItem>
                <MenuItem value={'America/Los_Angeles'}>America/Los_Angeles</MenuItem>
                <MenuItem value={'America/Toronto'}>America/Toronto</MenuItem>
              </FTextField>
              <FView w={16} />
              <FTextField
                inputRef={printingSupportRef}
                required
                select
                disabled={isEditLocked}
                label="Printing Support"
                defaultValue={locationData.printingEnabled ?? false}>
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </FTextField>
            </FView>
            <FTextField
              inputRef={regionRef}
              required
              select
              disabled={isEditLocked}
              label="Region"
              defaultValue={locationData.region ?? ''}>
              {regionData.map((region) => {
                return (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                )
              })}
            </FTextField>
          </FView>
          <Autocomplete
            disabled={isEditLocked}
            disableCloseOnSelect
            value={selectedTags}
            options={publicData?.tasteTags?.map((tasteTag) => tasteTag.tag) ?? []}
            onChange={(e, values) => {
              if (values.length > 5) {
                alert('Cannot add more than 5')
              } else {
                setSelectedTags(values)
              }
            }}
            limitTags={3}
            disableClearable
            autoHighlight
            multiple
            getOptionLabel={(tasteTag) => tasteTag}
            renderOption={(tasteTag) => tasteTag}
            renderInput={(params) => <FTextField {...params} variant="outlined" label="Tags" />}
          />

          <FTextField
            value={orderStatusIndex ?? ''}
            required
            select
            label="Order Status"
            onChange={(event) => {
              setOrderStatusIndex(event.target.value)
            }}>
            <MenuItem value={0}>{orderStatusDescription}</MenuItem>
            <MenuItem value={1}>Turn ON</MenuItem>
            <MenuItem value={2}>Turn OFF for 20 mins</MenuItem>
            <MenuItem value={3}>Turn OFF for 40 mins</MenuItem>
            <MenuItem value={4}>Turn OFF for 1 hour</MenuItem>
            <MenuItem value={5}>Turn OFF for 4 hours</MenuItem>
            <MenuItem value={6}>Turn OFF until tmr 3 AM</MenuItem>
            <MenuItem value={7}>Turn OFF indefinitely</MenuItem>
            <MenuItem value={8}>Turn OFF at Custom Time</MenuItem>
          </FTextField>
          {orderStatusIndex === 8 && (
            <FView row>
              <MuiPickersUtilsProvider utils={DateMomentUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  label="Reopening Date"
                  format="MM/DD/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change restaurant reopening date',
                  }}
                />
                <FView w={16} />
                <KeyboardTimePicker
                  margin="normal"
                  label="Reopening Time"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change restaurant reopening time',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FView>
          )}
          {!isCreateNew && (
            <FTextField
              inputRef={waitTimeRef}
              type="number"
              required
              label="Wait Time"
              inputProps={{min: 0}}
              defaultValue={isCreateNew || !locationData.waitTime ? 30 : locationData.waitTime}
              InputProps={{
                endAdornment: <InputAdornment position="end">mins</InputAdornment>,
              }}
            />
          )}
          <FTextField
            inputRef={titleRef}
            required
            disabled={isEditLocked}
            label="Title"
            defaultValue={locationData.title}
          />
          <FTextField
            inputRef={descriptionRef}
            required
            disabled={isEditLocked}
            label="Description"
            defaultValue={locationData.description}
          />
          <FTextField
            inputRef={keywordsRef}
            required
            disabled={isEditLocked}
            label="Keywords"
            defaultValue={locationData.keywords}
          />
        </FView>
        <FView grid gridTemplateColumns={isUpMd ? '1fr 1fr 1fr' : isUpSm ? '1fr 1fr' : '1fr'} columnGap={15}>
          <FormControlLabel
            disabled={isEditLocked}
            control={
              <Checkbox
                checked={isRestaurantVisible}
                onChange={(event) => setIsRestaurantVisible(event.target.checked)}
                color="primary"
              />
            }
            label={isRestaurantVisible ? 'Restaurant is Visible' : 'Restaurant is Hidden'}
          />
          <FormControlLabel control={<Checkbox disabled checked color="primary" />} label={'Card Payment Enabled'} />
          <FormControlLabel
            disabled={isEditLocked}
            control={
              <Checkbox
                checked={cashPaymentEnabled}
                onChange={(event) => setCashPaymentEnabled(event.target.checked)}
                color="primary"
              />
            }
            label={cashPaymentEnabled ? 'Cash Payment Enabled' : 'Cash Payment Disabled'}
          />
          <FormControlLabel
            disabled={isEditLocked}
            control={
              <Checkbox
                checked={pickupAvailable}
                onChange={(event) => setPickupAvailable(event.target.checked)}
                color="primary"
              />
            }
            label={pickupAvailable ? 'Pickup is Available' : 'Pickup is Unavailable'}
          />
          <FormControlLabel
            disabled={isEditLocked}
            control={
              <Checkbox
                checked={deliveryAvailable}
                onChange={(event) => setDeliveryAvailable(event.target.checked)}
                color="primary"
              />
            }
            label={deliveryAvailable ? 'Delivery is Available' : 'Delivery is Unavailable'}
          />
          <FormControlLabel
            disabled={isEditLocked}
            control={
              <Checkbox
                checked={dineInAvailable}
                onChange={(event) => setDineInAvailable(event.target.checked)}
                color="primary"
              />
            }
            label={dineInAvailable ? 'Dine-in is Available' : 'Dine-in is Unavailable'}
          />

          <FormControlLabel
            disabled={isEditLocked}
            control={
              <Checkbox
                checked={isDeliveryFeeVariable}
                onChange={(event) => setIsDeliveryFeeVariable(event.target.checked)}
                color="primary"
              />
            }
            label={isDeliveryFeeVariable ? 'Delivery Fee is Variable' : 'Delivery Fee is Not Variable'}
          />
        </FView>

        <FView grid gridTemplateColumns={isUpSm ? '1fr 1fr' : '1fr'} columnGap={15}>
          {isCreateNew && (
            <FTextField
              inputRef={deliveryTypeRef}
              required
              defaultValue={'foodly'}
              select
              label="Delivery Type"
              helperText="Who does the delivery?">
              <MenuItem value={'foodly'}>Foodly</MenuItem>
              <MenuItem value={'restaurant'}>Restaurant</MenuItem>
            </FTextField>
          )}
          <FTextField
            inputRef={deliveryRadiusRef}
            disabled={!deliveryAvailable || isEditLocked}
            required
            type="number"
            defaultValue={isCreateNew ? 5 : locationData.deliveryDistance}
            InputProps={{
              endAdornment: <InputAdornment position="end">km</InputAdornment>,
            }}
            label="Delivery Radius"
          />
          <FTextField
            inputRef={deliveryFeeMinimumRef}
            disabled={!deliveryAvailable || isEditLocked}
            required
            type="number"
            defaultValue={isCreateNew ? 5.99 : locationData.deliveryFeeMinimum}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            label={'Minimum Delivery Fee'}
          />
          <FTextField
            inputRef={deliveryFeeDiscountMinimumSubTotalRef}
            disabled={!deliveryAvailable || isEditLocked}
            required
            type="number"
            defaultValue={isCreateNew ? 1000 : locationData.deliveryFeeDiscountMinimumSubTotal}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            label={'Delivery Fee Discount Min. Subtotal'}
          />
          <FTextField
            inputRef={deliveryFeeDiscountRef}
            disabled={!deliveryAvailable || isEditLocked}
            required
            type="number"
            defaultValue={isCreateNew ? 0 : locationData.deliveryFeeDiscount}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            label={'Delivery Fee Discount'}
          />
          <FTextField
            inputRef={deliveryFeeRef}
            disabled={!deliveryAvailable || isEditLocked}
            required
            type="number"
            defaultValue={isCreateNew ? 4.88 : locationData.deliveryFee}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            label={isDeliveryFeeVariable ? 'Base Delivery Fee' : 'Delivery Fee'}
          />
          <FTextField
            inputRef={deliveryFeePerDistanceRef}
            disabled={!deliveryAvailable || !isDeliveryFeeVariable || isEditLocked}
            required
            type="number"
            defaultValue={isCreateNew ? 1 : locationData.deliveryFeePerDistance}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            label="Delivery Fee Per Distance"
          />
          <FTextField
            inputRef={freeDeliveryMinSubtotalRef}
            disabled={!deliveryAvailable || isEditLocked}
            required
            type="number"
            defaultValue={isCreateNew ? 1000 : locationData.deliveryFreeMinimumSubTotal}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            label="Free Delivery Min. Subtotal"
          />
          <FTextField
            inputRef={deliveryMinSubtotalRef}
            disabled={!deliveryAvailable || isEditLocked}
            required
            type="number"
            defaultValue={isCreateNew ? 20 : locationData.deliveryMinimumSubTotal}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            label="Delivery Available Min. Subtotal"
          />
          <FTextField
            inputRef={deliveryTimeRef}
            disabled={!deliveryAvailable || isEditLocked}
            required
            type="number"
            defaultValue={isCreateNew ? 15 : locationData.deliveryTime}
            InputProps={{
              endAdornment: <InputAdornment position="end">mins</InputAdornment>,
            }}
            label="Avg. Delivery Time"
          />
          {isCreateNew && (
            <FTextField
              inputRef={restaurantDeliveryFeeSubstitutionRef}
              disabled={!deliveryAvailable}
              required
              type="number"
              defaultValue={0}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              label="Delivery Fee Restaurant Substitution"
            />
          )}
          {!isEditLocked && (
            <FTextField
              inputRef={commissionFeeDeliveryRef}
              type="number"
              label="Commission Rate for Delivery"
              inputProps={{step: '0.001', min: 0, max: 1}}
              defaultValue={
                isCreateNew || !privateData?.commissionFeeDelivery ? null : privateData.commissionFeeDelivery
              }
            />
          )}
          {!isEditLocked && (
            <FTextField
              inputRef={commissionFeeDineInRef}
              type="number"
              label="Commission Rate for Dine In"
              inputProps={{step: '0.001', min: 0, max: 1}}
              defaultValue={isCreateNew || !privateData?.commissionFeeDineIn ? null : privateData.commissionFeeDineIn}
            />
          )}
          {!isEditLocked && (
            <FTextField
              inputRef={commissionFeePickupRef}
              type="number"
              label="Commission Rate for Pickup"
              inputProps={{step: '0.001', min: 0, max: 1}}
              defaultValue={isCreateNew || !privateData?.commissionFeePickup ? null : privateData.commissionFeePickup}
            />
          )}
          {!isEditLocked && (
            <FTextField
              inputRef={commissionFeeDirectPickupRef}
              type="number"
              label="Commission Rate for Direct Pickup"
              inputProps={{step: '0.001', min: 0, max: 1}}
              defaultValue={
                isCreateNew || !privateData?.commissionFeeDirectPickup ? null : privateData.commissionFeeDirectPickup
              }
            />
          )}
        </FView>
      </FView>
      <Divider />
      <FView bg={colors.white} p={25} maxWidth="100%">
        <FView center>
          {isCreateNew ? (
            <FButtonA
              onClick={async () => {
                if (isSaving) {
                  return
                }
                if (!checkValidity()) {
                  return
                }
                setIsSaving(true)
                const iconImageUrl =
                  'https://firebasestorage.googleapis.com/v0/b/foodlytech.appspot.com/o/static%2FFoodly_Logo_Small_Orange.png?alt=media&token=8a240cdd-64e0-4ecc-9e00-3723c5d3b8ea'
                const icon192ImageUrl =
                  'https://firebasestorage.googleapis.com/v0/b/foodlytech.appspot.com/o/static%2FFoodly_Logo_Small_Orange.png?alt=media&token=8a240cdd-64e0-4ecc-9e00-3723c5d3b8ea'
                const promoImageUrl =
                  'https://firebasestorage.googleapis.com/v0/b/foodlytech.appspot.com/o/static%2FheroBG.png?alt=media&token=3434a63b-03f7-4a7f-915d-1a7bb57af98c'

                const newRestaurantData = {
                  email: emailRef.current.value ? emailRef.current.value + '@foodly.tech' : null,
                  password: passwordRef.current.value,
                  commissionFee: parseFloat(commissionFeeRef.current.value),
                  deliveryType: deliveryTypeRef.current.value,
                  restaurantDeliveryFeeSubs: utils.currencyRounding(restaurantDeliveryFeeSubstitutionRef.current.value),
                  restaurantName: restaurantNameRef.current.value,
                  address: restaurantAddressRef.current.value,
                  locationName: locationNameRef.current.value,
                  restaurantLatLng: {
                    lat: Number(latRef.current.value),
                    lng: Number(lngRef.current.value),
                  },
                  phoneNumber: phoneNumberRef.current.value,
                  restaurantType: foodCategoryRef.current.value,
                  restaurantIconUrl: iconImageUrl,
                  restaurantIcon192Url: icon192ImageUrl,
                  promoUrl: promoImageUrl,
                  availableOrderTypes: {
                    Pickup: pickupAvailable,
                    Delivery: deliveryAvailable,
                    DineIn: dineInAvailable,
                  },
                  paymentMethods: {
                    'online-creditcard': true,
                    'inperson-cash': cashPaymentEnabled,
                  },
                  paymentProcessor: paymentProcessorRef.current.value,
                  printingEnabled: printingSupportRef.current.value,
                  timezone: timezoneRef.current.value,
                  isVisible: isRestaurantVisible,
                  deliveryDistance: Number(deliveryRadiusRef.current.value),
                  deliveryFee: utils.currencyRounding(deliveryFeeRef.current.value),
                  deliveryFeeMimimum: utils.currencyRounding(deliveryFeeMinimumRef.current.value),
                  deliveryFeeDiscountMinimumSubTotal: utils.currencyRounding(
                    deliveryFeeDiscountMinimumSubTotalRef.current.value,
                  ),
                  deliveryFeeDiscount: utils.currencyRounding(deliveryFeeDiscountRef.current.value),
                  isDeliveryFeeVariable,
                  deliveryFeePerDistance: utils.currencyRounding(deliveryFeePerDistanceRef.current.value),
                  deliveryFreeMinimumSubTotal: utils.currencyRounding(freeDeliveryMinSubtotalRef.current.value),
                  deliveryMinimumSubTotal: utils.currencyRounding(deliveryMinSubtotalRef.current.value),
                  deliveryTime: Math.round(deliveryTimeRef.current.value),
                  region: regionRef.current.value,
                  tags: selectedTags,
                  title: titleRef.current.value,
                  description: descriptionRef.current.value,
                  keywords: keywordsRef.current.value,
                  commissionFeeDelivery: !commissionFeeDeliveryRef.current.value
                    ? FieldValue.delete()
                    : parseFloat(commissionFeeDeliveryRef.current.value),
                  commissionFeeDineIn: !commissionFeeDineInRef.current.value
                    ? FieldValue.delete()
                    : parseFloat(commissionFeeDineInRef.current.value),
                  commissionFeeDirectPickup: !commissionFeeDirectPickupRef.current.value
                    ? FieldValue.delete()
                    : parseFloat(commissionFeeDirectPickupRef.current.value),
                  commissionFeePickup: !commissionFeePickupRef.current.value
                    ? FieldValue.delete()
                    : parseFloat(commissionFeePickupRef.current.value),
                }
                const currentMoment = utils.moment().tz(regionTimeZone)
                const currentTimestamp = currentMoment.valueOf()
                switch (orderStatusIndex) {
                  case 1:
                    newRestaurantData.orderOpenAfter = 0
                    break
                  case 2:
                    newRestaurantData.orderOpenAfter = currentTimestamp + 20 * 60000
                    break
                  case 3:
                    newRestaurantData.orderOpenAfter = currentTimestamp + 40 * 60000
                    break
                  case 4:
                    newRestaurantData.orderOpenAfter = currentTimestamp + 60 * 60000
                    break
                  case 5:
                    newRestaurantData.orderOpenAfter = currentTimestamp + 4 * 60 * 60000
                    break
                  case 6:
                    const offUntilMoment = currentMoment.tz(regionTimeZone).startOf('day').add(1, 'days').hour(3)
                    newRestaurantData.orderOpenAfter = offUntilMoment.valueOf()
                    break
                  case 7:
                    newRestaurantData.orderOpenAfter = 1e15
                    break
                  case 8:
                    if (selectedDate) {
                      newRestaurantData.orderOpenAfter = selectedDate.valueOf()
                      setSelectedDate(null)
                    }
                    break
                  default:
                    break
                }

                for (const key in newRestaurantData) {
                  if (newRestaurantData[key] == null || Number.isNaN(newRestaurantData[key])) {
                    alert('Missing ' + key)
                    setIsSaving(false)
                    return
                  }
                }
                if (
                  Number.isNaN(newRestaurantData.restaurantLatLng.lat) ||
                  Number.isNaN(newRestaurantData.restaurantLatLng.lng)
                ) {
                  alert('Missing lat or lng')
                  setIsSaving(false)
                  return
                }

                try {
                  newRestaurantData.geohash = geofire.geohashForLocation([
                    newRestaurantData.restaurantLatLng.lat,
                    newRestaurantData.restaurantLatLng.lng,
                  ])
                } catch (e) {
                  alert(e.message)
                  setIsSaving(false)
                  return
                }

                dispatch.restaurants
                  .createNewRestaurant(newRestaurantData)
                  .then(async (res) => {
                    const restaurantId = res.restaurantId
                    const locationId = res.locationId
                    const iconImageUrl =
                      iconImageRef.current?.files.length > 0 &&
                      (await handleImageUpload(iconImageRef, 'icon512', restaurantId))
                    const icon192ImageUrl =
                      iconImageRef.current?.files.length > 0 &&
                      (await handleImageUpload(iconImageRef, 'icon192', restaurantId))
                    const promoImageUrl =
                      promoImageRef.current?.files.length > 0 &&
                      (await handleImageUpload(promoImageRef, 'promo', restaurantId))
                    await dispatch.restaurants.updateLocation({
                      restaurantId,
                      locationId,
                      locationData: {
                        restaurantIconUrl: iconImageUrl,
                        restaurantIcon192Url: icon192ImageUrl,
                        promoUrl: promoImageUrl,
                      },
                    })
                    setNewRestaurantdata(res)
                  })
                  .catch((e) => {
                    alert(e.message)
                  })
                  .finally(() => {
                    if (orderStatusIndex > 0) {
                      setOrderStatusIndex(0)
                    }
                    setIsSaving(false)
                  })
              }}>
              <ButtonFillView w={isUpMd ? 250 : 150} rounded disabled={isSaving}>
                <FText primaryContrast body1 bold>
                  {isSaving ? 'Creating...' : 'Create'}
                </FText>
              </ButtonFillView>
            </FButtonA>
          ) : (
            <FButtonA
              onClick={async () => {
                if (isSaving) {
                  return
                }
                if (!isEditLocked) {
                  if (!checkValidity()) {
                    return
                  }
                }
                if (!waitTimeRef.current.reportValidity()) {
                  return
                }

                setIsSaving(true)
                const iconImageUrl =
                  iconImageRef.current?.files.length > 0
                    ? await handleImageUpload(iconImageRef, 'icon512')
                    : locationData.restaurantIconUrl ?? null
                const icon192ImageUrl =
                  iconImageRef.current?.files.length > 0
                    ? await handleImageUpload(iconImageRef, 'icon192')
                    : locationData.restaurantIcon192Url ?? null
                const promoImageUrl =
                  promoImageRef.current?.files.length > 0
                    ? await handleImageUpload(promoImageRef, 'promo')
                    : locationData.promoUrl ?? null

                const restaurantId = locationData.restaurantId
                const locationId = locationData.locationId

                const newLocationData = {
                  restaurantName: restaurantNameRef.current.value,
                  address: restaurantAddressRef.current.value,
                  locationName: locationNameRef.current.value,
                  restaurantLatLng: {
                    lat: Number(latRef.current.value),
                    lng: Number(lngRef.current.value),
                  },
                  phoneNumber: phoneNumberRef.current.value,
                  restaurantType: foodCategoryRef.current.value,
                  restaurantIconUrl: iconImageUrl,
                  restaurantIcon192Url: icon192ImageUrl,
                  promoUrl: promoImageUrl,
                  availableOrderTypes: {
                    Pickup: pickupAvailable,
                    Delivery: deliveryAvailable,
                    DineIn: dineInAvailable,
                  },
                  'paymentMethods.inperson-cash': cashPaymentEnabled,
                  paymentProcessor: !isEditLocked
                    ? paymentProcessorRef.current.value
                    : locationData.paymentProcessor
                    ? locationData.paymentProcessor
                    : 'Moneris',
                  printingEnabled: printingSupportRef.current.value,
                  timezone: timezoneRef.current.value,
                  isVisible: isRestaurantVisible,
                  deliveryDistance: Number(deliveryRadiusRef.current.value),
                  deliveryFee: utils.currencyRounding(deliveryFeeRef.current.value),
                  deliveryFeeMinimum: utils.currencyRounding(deliveryFeeMinimumRef.current.value),
                  deliveryFeeDiscountMinimumSubTotal: utils.currencyRounding(
                    deliveryFeeDiscountMinimumSubTotalRef.current.value,
                  ),
                  deliveryFeeDiscount: utils.currencyRounding(deliveryFeeDiscountRef.current.value),
                  isDeliveryFeeVariable,
                  deliveryFeePerDistance: utils.currencyRounding(deliveryFeePerDistanceRef.current.value),
                  deliveryFreeMinimumSubTotal: utils.currencyRounding(freeDeliveryMinSubtotalRef.current.value),
                  deliveryMinimumSubTotal: utils.currencyRounding(deliveryMinSubtotalRef.current.value),
                  deliveryTime: Math.round(deliveryTimeRef.current.value),
                  region: regionRef.current.value,
                  tags: selectedTags,
                  title: titleRef.current.value,
                  description: descriptionRef.current.value,
                  keywords: keywordsRef.current.value,
                  waitTime: Math.round(waitTimeRef.current.value),
                }
                const newPrivateData = !isEditLocked
                  ? {
                      commissionFeeDelivery: !commissionFeeDeliveryRef.current.value
                        ? FieldValue.delete()
                        : parseFloat(commissionFeeDeliveryRef.current.value),
                      commissionFeeDineIn: !commissionFeeDineInRef.current.value
                        ? FieldValue.delete()
                        : parseFloat(commissionFeeDineInRef.current.value),
                      commissionFeeDirectPickup: !commissionFeeDirectPickupRef.current.value
                        ? FieldValue.delete()
                        : parseFloat(commissionFeeDirectPickupRef.current.value),
                      commissionFeePickup: !commissionFeePickupRef.current.value
                        ? FieldValue.delete()
                        : parseFloat(commissionFeePickupRef.current.value),
                    }
                  : {}
                if (!isEditLocked && !isSpecialCommission) {
                  newPrivateData.commissionFee = parseFloat(commissionFeeRef.current.value)
                }

                const currentMoment = utils.moment().tz(regionTimeZone)
                const currentTimestamp = currentMoment.valueOf()
                switch (orderStatusIndex) {
                  case 1:
                    newLocationData.orderOpenAfter = 0
                    break
                  case 2:
                    newLocationData.orderOpenAfter = currentTimestamp + 20 * 60000
                    break
                  case 3:
                    newLocationData.orderOpenAfter = currentTimestamp + 40 * 60000
                    break
                  case 4:
                    newLocationData.orderOpenAfter = currentTimestamp + 60 * 60000
                    break
                  case 5:
                    newLocationData.orderOpenAfter = currentTimestamp + 4 * 60 * 60000
                    break
                  case 6:
                    const offUntilMoment = currentMoment.tz(regionTimeZone).startOf('day').add(1, 'days').hour(3)
                    newLocationData.orderOpenAfter = offUntilMoment.valueOf()
                    break
                  case 7:
                    newLocationData.orderOpenAfter = 1e15
                    break
                  case 8:
                    if (selectedDate) {
                      newLocationData.orderOpenAfter = selectedDate.valueOf()
                      setSelectedDate(null)
                    }
                    break
                  default:
                    break
                }

                try {
                  newLocationData.geohash = geofire.geohashForLocation([
                    newLocationData.restaurantLatLng.lat,
                    newLocationData.restaurantLatLng.lng,
                  ])
                } catch (e) {
                  alert(e.message)
                  setIsSaving(false)
                  return
                }
                const handleSave = !isEditLocked
                  ? Promise.all([
                      dispatch.restaurants.updateLocation({
                        restaurantId,
                        locationId,
                        locationData: newLocationData,
                      }),
                      dispatch.restaurants.updatePrivate({
                        restaurantId,
                        newPrivate: newPrivateData,
                      }),
                    ])
                  : dispatch.restaurants.updateLocation({
                      restaurantId,
                      locationId,
                      locationData: newLocationData,
                    })

                handleSave
                  .catch((e) => {
                    alert(e.message)
                  })
                  .finally(() => {
                    if (orderStatusIndex > 0) {
                      setOrderStatusIndex(0)
                    }
                    setIsSaving(false)
                  })
              }}>
              <ButtonFillView w={isUpMd ? 250 : 150} rounded disabled={isSaving}>
                <FText primaryContrast body1 bold>
                  {isSaving ? 'Saving...' : 'Save'}
                </FText>
              </ButtonFillView>
            </FButtonA>
          )}
        </FView>
      </FView>
      <Dialog
        onClose={() => {
          setNewRestaurantdata(null)
        }}
        open={!!newRestaurantData}>
        {!!newRestaurantData && (
          <FView p={36}>
            <FText gutterBottom>email: {newRestaurantData.email}</FText>
            <FText gutterBottom>password: {passwordRef.current ? passwordRef.current.value : ''}</FText>
            <FText gutterBottom>restaurantId: {newRestaurantData.restaurantId}</FText>
            <FText gutterBottom>locationId: {newRestaurantData.locationId}</FText>
            <FText gutterBottom>userId: {newRestaurantData.userId}</FText>
            <FText gutterBottom>stripeAccountId: {newRestaurantData.stripeAccountId}</FText>
          </FView>
        )}
      </Dialog>
    </FView>
  )
}
const useStyle = makeStyles((theme) => ({
  iconImg: {objectFit: 'contain', width: 90},
}))
export default RestaurantInfo
