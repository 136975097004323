import React, {useRef, useState} from 'react'
import {moment} from 'f-utils'
import {auth, getCollection, DELETE_FIELD_VALUE} from 'f-core/src/config/firebase'
import {useDispatch} from 'react-redux'
import {Visibility} from '@material-ui/icons'
import {FText, FView, FTextField, FButton, ButtonFillView, ButtonOutlineView, Timer} from 'components'
import AddChargeDialog from './AddChargeDialog'
import OrderItemView from './OrderItemView'
import * as api from 'models/api'
import RefundTipDialog from './RefundTipDialog'

export default function RecentOrder({orderData, setAddAdjustmentDialogOrderId, setAddAdjustmentDialogRestaurantId}) {
  const isOrderNew = orderData?.status === 'New'
  const isOrderDone = orderData?.status === 'Done'
  const isOrderCancelled = orderData?.status === 'Cancelled'

  return (
    <FView fill row>
      <OrderItemView orderData={orderData} />
      {!isOrderCancelled && (
        <FView row justifyStart alignStart>
          <FView alignCenter>
            {isOrderNew && <SupportAssignSection orderData={orderData} />}
            {isOrderNew && <ConfirmButton orderData={orderData} />}
            {!isOrderNew && <DoneButton orderData={orderData} />}
            {!isOrderNew && <FoodReadyButton orderData={orderData} />}
            {!isOrderNew && orderData?.paymentMethod?.startsWith('inperson') && <PaidButton orderData={orderData} />}
            <FView row>
              {!isOrderDone && <RefundTipButton orderData={orderData} />}
              <FView w={8} />
              {!isOrderDone && <CancelButton orderData={orderData} />}
            </FView>
            <FView row alignEnd>
              {!isOrderDone && <AddChargeButton orderData={orderData} />}
              <FView w={8} />
              <FButton
                onPress={() => {
                  setAddAdjustmentDialogOrderId(orderData.id)
                  setAddAdjustmentDialogRestaurantId(orderData.restaurantId)
                }}>
                <ButtonFillView rounded enter w={85} h={40} mt={8}>
                  <FText fontSize={12} bold primaryContrast>
                    Other Adjustments
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
          </FView>
        </FView>
      )}
    </FView>
  )
}

function SupportAssignSection({orderData}) {
  const dispatch = useDispatch()
  const amISupporting = orderData.supportUserId === dispatch.portal.getUserId()

  return (
    <>
      <FView maxWidth={80}>
        <FView alignCenter maxWidth={80}>
          <Timer createdAt={orderData.createdAt} />
        </FView>
        <FButton
          alignCenter
          onPress={() => {
            const userId = dispatch.portal.getUserId()
            if (!userId) {
              alert('Cannot find current userId')
              return
            }
            if (!dispatch.portal.getIsSuperAdmin()) {
              alert('Only Super Admin can be assigned as support')
              return
            }
            getCollection('Orders', {restaurantId: orderData.restaurantId})
              .doc(orderData.id)
              .update({
                supportUserId: amISupporting ? DELETE_FIELD_VALUE : userId,
                supportUserName: amISupporting ? DELETE_FIELD_VALUE : dispatch.portal.getUserName(),
              })
          }}>
          {amISupporting ? (
            <ButtonOutlineView rounded center size={40} selected>
              <FText h5 primary noLineHeight>
                <Visibility fontSize="inherit" />
              </FText>
            </ButtonOutlineView>
          ) : (
            <ButtonFillView rounded center size={40}>
              <FText h5 primaryContrast noLineHeight>
                <Visibility fontSize="inherit" />
              </FText>
            </ButtonFillView>
          )}
        </FButton>
      </FView>
      {orderData.supportUserName && (
        <FView maxWidth={80}>
          <FText body2 alignCenter>
            {orderData.supportUserName}
          </FText>
        </FView>
      )}
    </>
  )
}

function ConfirmButton({orderData}) {
  const waitTimeRef = useRef()

  return (
    <FView w={85}>
      <FTextField
        size="small"
        inputRef={waitTimeRef}
        defaultValue={
          orderData.orderTimeType === 'Scheduled' ? moment(orderData.estimatedCompletionTime).format('LT') : 30
        }
        label="Wait Time"
      />
      <FButton
        onPress={() => {
          const waitTime = waitTimeRef.current.value
          const completionTime =
            orderData.orderTimeType === 'Scheduled'
              ? orderData.estimatedCompletionTime
              : new Date().valueOf() + waitTime * 60000
          return getCollection('Orders', {restaurantId: orderData.restaurantId})
            .doc(orderData.id)
            .update({completionTime, status: 'Preparing'})
        }}>
        <ButtonFillView rounded center w={85} h={40}>
          <FText body2 bold primaryContrast>
            Confirm
          </FText>
        </ButtonFillView>
      </FButton>
    </FView>
  )
}

function FoodReadyButton({orderData}) {
  const isFoodReady = !!orderData?.isFoodReady
  const orderDocRef = getCollection('Orders', {restaurantId: orderData.restaurantId}).doc(orderData.id)

  return (
    <FButton
      onPress={() => {
        orderDocRef.update({isFoodReady: !isFoodReady})
      }}>
      {isFoodReady ? (
        <ButtonOutlineView rounded center w={85} selected mv={2}>
          <FText body2 bold primary>
            Food is Ready
          </FText>
        </ButtonOutlineView>
      ) : (
        <ButtonFillView rounded center w={85} mv={2}>
          <FText body2 bold primaryContrast>
            Food is Ready
          </FText>
        </ButtonFillView>
      )}
    </FButton>
  )
}
function PaidButton({orderData}) {
  const isPaid = orderData?.paymentStatus === 'paid' || orderData?.paymentStatus === 'paid-online'
  const orderDocRef = getCollection('Orders', {restaurantId: orderData.restaurantId}).doc(orderData.id)

  return (
    <FButton
      onPress={() => {
        orderDocRef.update({paymentStatus: isPaid ? 'unpaid' : 'paid'})
      }}>
      {isPaid ? (
        <ButtonOutlineView rounded center w={85} selected mv={2}>
          <FText body2 bold primary>
            Paid
          </FText>
        </ButtonOutlineView>
      ) : (
        <ButtonFillView rounded center w={85} mv={2}>
          <FText body2 bold primaryContrast>
            Paid
          </FText>
        </ButtonFillView>
      )}
    </FButton>
  )
}

function DoneButton({orderData}) {
  const isFoodReady = !!orderData?.isFoodReady
  const isOrderDone = orderData?.status === 'Done'
  const isPaid = orderData?.paymentStatus === 'paid' || orderData?.paymentStatus === 'paid-online'
  const orderDocRef = getCollection('Orders', {restaurantId: orderData.restaurantId}).doc(orderData.id)
  const deliveryOrderDocRef = getCollection('DeliveryInfo', {restaurantId: orderData.restaurantId}).doc(orderData.id)

  return (
    <FButton
      disabled={!isOrderDone && (!isFoodReady || (orderData?.paymentMethod?.startsWith('inperson') && !isPaid))}
      onPress={() => {
        if (!isOrderDone && (!isFoodReady || (orderData?.paymentMethod?.startsWith('inperson') && !isPaid))) {
          return
        }

        if (orderData?.orderType === 'Delivery') {
          if (isOrderDone) {
            deliveryOrderDocRef.update({deliveryStatus: 'HeadingToCustomer'})
            orderDocRef.update({status: 'Preparing'})
          } else {
            deliveryOrderDocRef.update({deliveryStatus: 'Delivered'})
            orderDocRef.update({status: 'Done'})
          }
        } else {
          if (isOrderDone) {
            orderDocRef.update({status: 'Preparing'})
          } else {
            orderDocRef.update({status: 'Done'})
          }
        }
      }}>
      {isOrderDone ? (
        <ButtonOutlineView
          rounded
          center
          w={85}
          mv={2}
          selected
          disabled={!isOrderDone && (!isFoodReady || (orderData?.paymentMethod?.startsWith('inperson') && !isPaid))}>
          <FText body2 bold primary>
            Mark as {orderData?.orderType === 'Delivery' ? 'Delivered' : 'Done'}
          </FText>
        </ButtonOutlineView>
      ) : (
        <ButtonFillView
          rounded
          center
          w={85}
          mv={2}
          disabled={!isFoodReady || (orderData?.paymentMethod?.startsWith('inperson') && !isPaid)}>
          <FText body2 bold primaryContrast>
            Mark as {orderData?.orderType === 'Delivery' ? 'Delivered' : 'Done'}
          </FText>
        </ButtonFillView>
      )}
    </FButton>
  )
}

function AddChargeButton({orderData}) {
  const [open, setOpen] = useState(false)
  return (
    <FView w={85} mt={8}>
      <FButton
        onPress={() => {
          setOpen(true)
        }}>
        <ButtonFillView rounded center w={85} h={40}>
          <FText body2 bold primaryContrast>
            Add Charge
          </FText>
        </ButtonFillView>
      </FButton>
      <AddChargeDialog open={open} handleClose={() => setOpen(false)} orderData={orderData} />
    </FView>
  )
}

function RefundTipButton({orderData}) {
  const [open, setOpen] = useState(false)
  return (
    <FView mt={8}>
      <FButton disabled={open} onClick={() => setOpen(true)}>
        <ButtonFillView rounded center w={85} h={40}>
          <FText body2 bold primaryContrast>
            Refund Tip
          </FText>
        </ButtonFillView>
      </FButton>

      <RefundTipDialog open={open} handleClose={() => setOpen(false)} orderData={orderData} />
    </FView>
  )
}

function CancelButton({orderData}) {
  const [cancelling, setCancelling] = useState(false)
  return (
    <FView w={85} mt={8}>
      <FButton
        disabled={cancelling}
        onPress={async () => {
          if (cancelling) {
            return
          }
          const confirmed = window.confirm(`Are you sure you want to Refund Order #${orderData.orderNumber}?`)
          if (confirmed) {
            const restaurantId = orderData.restaurantId
            const authToken = await auth.currentUser.getIdToken()
            setCancelling(true)
            try {
              await api.refundOrder(process.env.REACT_APP_SERVER_URL, authToken, {
                restaurantId,
                orderId: orderData.id,
                cancelReason: 'Unknown',
              })
            } catch (e) {
              alert(e.message)
            } finally {
              setCancelling(false)
            }
          }
        }}>
        <ButtonFillView rounded center w={85} h={40} disabled={cancelling}>
          <FText body2 bold primaryContrast>
            {cancelling ? 'Canceling...' : 'Cancel'}
          </FText>
        </ButtonFillView>
      </FButton>
    </FView>
  )
}
