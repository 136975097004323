import React, {useRef, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getOrdersGroupCreatedAtDesc} from 'f-core/src/config/firebase'
import {useMediaMinMD} from 'f-web/src/hooks'
import {VolumeOff, VolumeUp} from '@material-ui/icons'
import {CircularProgress, Grid, MenuItem, Checkbox} from '@material-ui/core'
import {FText, FView, FNavBar, FTextField, FButton, ButtonFillView} from 'components'
import {colors} from 'styles'

import AssignOrderDialog from './AssignOrderDialog'
import DelayOrderDialog from './DelayOrderDialog'
import DeliveryOrderView from './DeliveryOrderView'
import RecentOrder from './RecentOrder'
import AddAdjustmentDialog from './AddAdjustmentDialog'
import ChangeStateDialog from './ChangeStatusDialog'

const DEFAULT_LIMIT = 25

export default function Support() {
  const dispatch = useDispatch()
  const isMinMD = useMediaMinMD()
  const [isSoundOn, setIsSoundOn] = useState(false)

  const [loadingOrders, setLoadingOrders] = useState(false)
  const [recentOrders, setRecentOrders] = useState([])
  const [newOrders, setNewOrders] = useState([])

  const [limit, setLimit] = useState(DEFAULT_LIMIT)
  const limitRef = useRef()

  const [statusFilter, setStatusFilter] = useState({
    Preparing: true,
    Done: false,
    Cancelled: false,
  })
  const [isNewFilterChecked, setIsNewFilterChecked] = useState(true)

  const [dialogOrderId, setDialogOrderId] = useState('')
  const [dialogRestaurantId, setDialogRestaurantId] = useState('')
  const [openAssignOrderDialog, setOpenAssignOrderDialog] = useState(false)
  const [openDeliveryOrderDialog, setOpenDeliveryOrderDialog] = useState(false)
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false)

  const [addAdjustmentDialogOrderId, setAddAdjustmentDialogOrderId] = useState(null)
  const [addAdjustmentDialogRestaurantId, setAddAdjustmentDialogRestaurantId] = useState(null)

  const activeDeliveries = useSelector(dispatch.orders.getActiveDeliveryOrders)
  const assignedDeliveries = Object.values(activeDeliveries)
    .filter((order) => order.deliveryStatus !== 'Matching' && order.deliveryStatus !== 'MatchingManager')
    .sort((a, b) => (a.deliveryTime || a.estimatedDeliveryTime) - (b.deliveryTime || b.estimatedDeliveryTime))
  const unassignedDeliveries = Object.values(activeDeliveries)
    .filter((order) => order.deliveryStatus === 'Matching' || order.deliveryStatus === 'MatchingManager')
    .sort((a, b) => (a.deliveryTime || a.estimatedDeliveryTime) - (b.deliveryTime || b.estimatedDeliveryTime))

  useEffect(() => {
    return dispatch.riders.subscribeRiders()
  }, [dispatch.riders])
  useEffect(() => {
    return dispatch.orders.subscribeActiveDeliveryOrders()
  }, [dispatch.orders])
  useEffect(() => {
    setLoadingOrders(true)
    return getOrdersGroupCreatedAtDesc({
      limit: limit,
      status: Object.entries(statusFilter)
        .filter(([key, value]) => value)
        .map(([key]) => key),
    }).onSnapshot((snap) => {
      setLoadingOrders(false)
      const orders = []
      for (const doc of snap.docs) {
        const order = doc.data()
        order.id = doc.id
        orders.push(order)
      }
      setRecentOrders(orders)
    })
  }, [limit, statusFilter])
  useEffect(() => {
    if (isNewFilterChecked) {
      return getOrdersGroupCreatedAtDesc({
        limit: 100,
        status: 'New',
      }).onSnapshot((snap) => {
        const orders = []
        for (const doc of snap.docs) {
          const order = doc.data()
          order.id = doc.id
          orders.push(order)
        }
        setNewOrders(orders)
      })
    } else {
      setNewOrders([])
    }
  }, [isNewFilterChecked])

  const allOrdersData = React.useMemo(() => {
    const ordersData = {}
    for (const order of recentOrders) {
      ordersData[order.id] = order
    }
    for (const order of newOrders) {
      ordersData[order.id] = order
    }
    return ordersData
  }, [recentOrders, newOrders])

  return (
    <FView bg={colors.background} h={'100vh'} overflowY={'auto'}>
      <FNavBar />
      <FView mv={20} mh={isMinMD && 70} pt={80}>
        <FView alignStart>
          <FButton onPress={() => setIsSoundOn(!isSoundOn)}>
            <FView row alignCenter>
              {isSoundOn ? <VolumeUp /> : <VolumeOff />}
              <FText body1 bold>
                Toggle Sound
              </FText>
            </FView>
          </FButton>
        </FView>
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <FView fill center>
            <FView row center>
              <FView w={85}>
                <FTextField
                  defaultValue={DEFAULT_LIMIT}
                  inputRef={limitRef}
                  label="Limit"
                  required
                  select
                  size="small"
                  type="number">
                  {[10, 25, 50, 75, 100].map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                  <MenuItem value={200}>200 (Slow)</MenuItem>
                  <MenuItem value={500}>500 (Very Slow)</MenuItem>
                </FTextField>
              </FView>
              <FView w={16} />
              <FButton
                disabled={loadingOrders}
                onPress={() => {
                  if (limit !== limitRef.current.value) {
                    setLoadingOrders(true)
                    setLimit(limitRef.current.value)
                  }
                }}>
                <ButtonFillView rounded ph={16} h={40} mt={6} disabled={loadingOrders}>
                  <FText primaryContrast bold>
                    Apply
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
            <FView row alignCenter>
              <FView row alignCenter>
                <Checkbox
                  color="primary"
                  checked={isNewFilterChecked}
                  onChange={() => setIsNewFilterChecked(!isNewFilterChecked)}
                />
                <FText>New</FText>
              </FView>
              {Object.keys(statusFilter).map((option) => (
                <FView row alignCenter key={option}>
                  <Checkbox
                    color="primary"
                    checked={!!statusFilter[option]}
                    onChange={() => setStatusFilter({...statusFilter, [option]: !statusFilter[option]})}
                  />
                  <FText>{option}</FText>
                </FView>
              ))}
            </FView>
            <FText h6 bold alignCenter>
              Recent Orders
            </FText>
            {loadingOrders && (
              <FView alignCenter p={15}>
                <CircularProgress />
              </FView>
            )}
            {newOrders.map((orderData) => {
              return (
                <FView bg={'#fff'} rounded key={orderData.id} p={15} mb={10} w={560} maxWidth={'100vw'} selfCenter>
                  <RecentOrder
                    orderData={orderData}
                    setAddAdjustmentDialogOrderId={setAddAdjustmentDialogOrderId}
                    setAddAdjustmentDialogRestaurantId={setAddAdjustmentDialogRestaurantId}
                  />
                </FView>
              )
            })}
            {recentOrders.map((orderData) => {
              return (
                <FView bg={'#fff'} rounded key={orderData.id} p={15} mb={10} w={560} maxWidth={'100vw'} selfCenter>
                  <RecentOrder
                    orderData={orderData}
                    setAddAdjustmentDialogOrderId={setAddAdjustmentDialogOrderId}
                    setAddAdjustmentDialogRestaurantId={setAddAdjustmentDialogRestaurantId}
                  />
                </FView>
              )
            })}
          </FView>
          <FView fill>
            <FText h6 bold alignCenter>
              Active Deliveries
            </FText>
            {unassignedDeliveries.map((deliveryData) => (
              <DeliveryOrderView
                key={deliveryData.id}
                deliveryInfoData={deliveryData}
                setDialogOrderId={setDialogOrderId}
                setDialogRestaurantId={setDialogRestaurantId}
                setOpenAssignOrderDialog={setOpenAssignOrderDialog}
                setOpenDeliveryOrderDialog={setOpenDeliveryOrderDialog}
                setOpenChangeStatusDialog={setOpenChangeStatusDialog}
              />
            ))}
            {assignedDeliveries.map((deliveryData) => (
              <DeliveryOrderView
                key={deliveryData.id}
                deliveryInfoData={deliveryData}
                setDialogOrderId={setDialogOrderId}
                setDialogRestaurantId={setDialogRestaurantId}
                setOpenAssignOrderDialog={setOpenAssignOrderDialog}
                setOpenDeliveryOrderDialog={setOpenDeliveryOrderDialog}
                setOpenChangeStatusDialog={setOpenChangeStatusDialog}
                assigned
              />
            ))}
          </FView>
        </Grid>
      </FView>
      <AssignOrderDialog
        open={openAssignOrderDialog}
        orderId={dialogOrderId}
        restaurantId={dialogRestaurantId}
        setOpenAssignOrderDialog={setOpenAssignOrderDialog}
      />
      <DelayOrderDialog
        open={openDeliveryOrderDialog}
        orderId={dialogOrderId}
        restaurantId={dialogRestaurantId}
        setOpenDeliveryOrderDialog={setOpenDeliveryOrderDialog}
      />

      <AddAdjustmentDialog
        addAdjustmentDialogOrderId={addAdjustmentDialogOrderId}
        addAdjustmentDialogRestaurantId={addAdjustmentDialogRestaurantId}
        handleClose={() => {
          setAddAdjustmentDialogOrderId(null)
        }}
        orderData={allOrdersData?.[addAdjustmentDialogOrderId] ?? {}}
      />
      <ChangeStateDialog
        open={openChangeStatusDialog}
        orderId={dialogOrderId}
        restaurantId={dialogRestaurantId}
        setOpenChangeStatusDialog={setOpenChangeStatusDialog}
      />
    </FView>
  )
}
