import React, {useRef, useState} from 'react'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useDispatch} from 'react-redux'
import {Edit, DeleteSweep, Close, KeyboardArrowDown, KeyboardArrowUp, Delete} from '@material-ui/icons'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {Divider, Dialog, DialogTitle, DialogActions, DialogContent, Collapse, FormControlLabel} from '@material-ui/core'
import * as api from 'models/api'
import {auth, DELETE_FIELD_VALUE} from 'f-core/src/config/firebase'
import {FView, FText, FButton, ButtonOutlineView, FTextField, ButtonFillView, FInput} from 'components'
import ProductAdd from './ProductAdd'
import ProductList from './ProductList'

export default function CategoryItem({
  categoryName,
  categoryDescription,
  categoryImageUrl,
  categoryIndex,
  categoryId,
  locationId,
  restaurantId,
  menuId,
  searchTerm,
}) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyle()

  const isUpMd = useMediaQueryUp('md')

  //state for controlling collapse category
  const [deleting, setDeleting] = useState(false)
  const [saving, setSaving] = useState(false)

  // state for controlling edit category window
  const [editClick, setEditClick] = useState(false)

  // state for opening delete all popup
  const [deleteAll, setDeleteAll] = useState(false)

  const [_isOpen, setIsOpen] = useState(false)

  const isOpen = !!searchTerm || _isOpen

  // edit values
  const nameRef = useRef()
  const descriptionRef = useRef()
  const selectedFileRef = useRef()

  const handleOpenDeleteAll = () => {
    if (saving || deleting) {
      return
    }
    setDeleteAll(true)
  }
  function handleCancel() {
    if (!editClick || saving || deleting) {
      return
    }
    setEditClick(false)
  }

  async function handleDeleteImage() {
    if (saving || deleting) {
      return
    } else if (!categoryImageUrl) {
      alert('no image attached to category')
      return
    }
    if (!window.confirm('Are you sure you want to delete?')) {
      return
    }

    setDeleting(true)
    try {
      const authToken = await auth.currentUser.getIdToken()
      await api.deleteImageFromBucket({
        serverUrl: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL,
        authToken,
        restaurantId,
        categoryId,
        imageType: 'category',
      })
    } catch (e) {
      // Image may not be stored in firebase storage, safely ignore.
    }
    try {
      await dispatch.restaurants.updateCategory({
        restaurantId,
        locationId,
        categoryId,
        category: {
          imageUrl: DELETE_FIELD_VALUE,
        },
      })
    } catch (e) {
      alert(e.message)
    } finally {
      setDeleting(false)
    }
  }

  function handleDelete() {
    if (saving || deleting) {
      return
    }
    setDeleting(true)
    dispatch.restaurants
      .deleteCategory({restaurantId, locationId, categoryId, menuId})
      .catch((e) => {
        alert(e.message)
      })
      .finally(() => {
        setDeleting(false)
      })
  }

  async function handleImageUpload() {
    const authToken = await auth.currentUser.getIdToken()
    try {
      const response = await api.uploadImage({
        serverUrl: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL,
        authToken,
        restaurantId,
        categoryId,
        imageFile: selectedFileRef.current?.files[0],
        imageType: 'category',
      })
      return response.data.imageUrl
    } catch (e) {
      alert(e.message)
    }
  }
  async function handleEdit() {
    if (saving || deleting) {
      return
    }
    if (!nameRef.current.value) {
      alert('Category name cannot be blank')
      return
    }
    setSaving(true)
    const imageUrl = selectedFileRef.current?.files.length > 0 ? await handleImageUpload() : categoryImageUrl ?? null
    dispatch.restaurants
      .updateCategory({
        restaurantId,
        locationId,
        categoryId,
        category: {
          name: nameRef.current.value,
          description: descriptionRef.current.value,
          imageUrl: imageUrl ? imageUrl : DELETE_FIELD_VALUE,
        },
      })
      .then(() => {
        setEditClick(false)
      })
      .finally(() => {
        setSaving(false)
      })
  }

  function updateCategoryIndex({fromIndex, toIndex}) {
    if (saving || deleting) {
      return
    }
    return dispatch.restaurants.updateCategoryOrder({
      menuId,
      restaurantId,
      locationId,
      fromIndex,
      toIndex,
    })
  }

  return (
    <FView shadow10>
      <FView bg={theme.palette.common.white} row={!editClick || isUpMd} wrap>
        <FView fill row pl={12}>
          {!editClick && (
            <FView row alignCenter>
              <FView w={50} m={8}>
                <FTextField
                  // force re-render whenever categoryIndex changes
                  key={categoryIndex}
                  className={classes.categoryIndexTextField}
                  InputProps={{className: classes.indexInputStyle}}
                  inputProps={{className: classes.indexInputNativeStyle}}
                  onKeyDown={(e) => {
                    if (e.key === 'Escape') {
                      e.target.value = categoryIndex
                      e.target.blur()
                    }
                  }}
                  onKeyPress={(e) => {
                    e.key === 'Enter' && updateCategoryIndex({fromIndex: categoryIndex, toIndex: e.target.value})
                  }}
                  defaultValue={categoryIndex}
                  type="number"
                />
              </FView>
              <FView w={1} selfStretch bg={theme.palette.grey['300']} mv={8} />
            </FView>
          )}
          <FView row alignCenter fill>
            {!editClick ? (
              <FView fill>
                <FButton hoverHighlight onPress={() => setIsOpen(!isOpen)}>
                  <FView row fill alignCenter>
                    <FView fill alignStart h={80}>
                      <FView fill overflow="auto" ph={10} justifyCenter>
                        <FText body1 bold alignLeft>
                          {categoryName}
                        </FText>
                        <FText alignLeft body2 grey700>
                          {categoryDescription}
                        </FText>
                      </FView>
                    </FView>
                    {!!categoryImageUrl && (
                      <FView mh={15}>
                        <img src={categoryImageUrl} alt="category" className={classes.categoryImg} />
                      </FView>
                    )}
                    {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    <FView size={8} />
                  </FView>
                </FButton>
              </FView>
            ) : (
              <FView fill alignStart>
                <FTextField
                  inputRef={nameRef}
                  autoFocus
                  required
                  defaultValue={categoryName}
                  label="Category Name"
                  onKeyDown={(e) => {
                    e.key === 'Escape' && handleCancel()
                  }}
                  onKeyPress={(e) => {
                    e.key === 'Enter' && handleEdit()
                  }}
                />
                <FTextField
                  inputRef={descriptionRef}
                  defaultValue={categoryDescription}
                  label="Category Description"
                  onKeyDown={(e) => {
                    e.key === 'Escape' && handleCancel()
                  }}
                  onKeyPress={(e) => {
                    e.key === 'Enter' && handleEdit()
                  }}
                />
                {!!categoryImageUrl && (
                  <FView>
                    <img src={categoryImageUrl} alt="category" className={classes.categoryImg} />
                    <FButton onClick={() => handleDeleteImage()} disabled={deleting || saving}>
                      <FView row alignCenter>
                        <Delete color={deleting || saving ? 'disabled' : 'primary'} />
                        <FView size={8} />
                        <FText button primary grey300={deleting || saving}>
                          {deleting ? 'Deleting...' : 'Delete Image'}
                        </FText>
                      </FView>
                    </FButton>
                  </FView>
                )}
                <FormControlLabel control={<FInput ref={selectedFileRef} type="file" accept=".jpg,.png,.jpeg" />} />
              </FView>
            )}
          </FView>
        </FView>
        {editClick ? (
          <FView row alignCenter p={15} justifyEnd>
            <FButton hoverHighlight onClick={handleCancel} disabled={saving || deleting}>
              <ButtonOutlineView rounded selected w={125} disabled={saving}>
                <FText button primary grey500={saving || deleting}>
                  Cancel
                </FText>
              </ButtonOutlineView>
            </FButton>
            <FView size={15} />
            <FButton hoverHighlight onClick={handleEdit} disabled={saving || deleting}>
              <ButtonFillView rounded w={125} disabled={saving}>
                <FText button primaryContrast>
                  {saving ? 'Saving...' : 'Save'}
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        ) : (
          <FView row>
            <FView w={1} bg={theme.palette.grey['300']} mv={8} />
            <FButton
              hoverHighlight
              disabled={deleting || saving}
              onClick={() => {
                setEditClick(true)
              }}>
              <FView row alignCenter ph={10}>
                <Edit color="primary" />
                {isUpMd && (
                  <>
                    <FView size={8} />
                    <FText body1 primary>
                      Edit
                    </FText>
                  </>
                )}
              </FView>
            </FButton>
            <FView w={1} bg={theme.palette.grey['300']} mv={8} />
            <FButton hoverHighlight onClick={handleOpenDeleteAll} disabled={deleting || saving}>
              <FView row alignCenter ph={10}>
                <DeleteSweep color={deleting || saving ? 'disabled' : 'primary'} />
                {isUpMd && (
                  <>
                    <FView size={8} />
                    <FText body1 primary grey300={deleting || saving}>
                      {deleting ? 'Deleting...' : 'Delete All'}
                    </FText>
                  </>
                )}
              </FView>
            </FButton>
            {/* Pop up for confirming delete all */}
            <Dialog
              fullWidth
              onClose={() => {
                setDeleteAll(false)
              }}
              open={deleteAll}>
              <DialogTitle>
                <FView row alignCenter>
                  <FView fill>
                    <FText bold h5 alignCenter>
                      Delete all category items
                    </FText>
                  </FView>
                  <FButton
                    onClick={() => {
                      setDeleteAll(false)
                    }}>
                    <Close />
                  </FButton>
                </FView>
              </DialogTitle>
              <DialogContent>
                <FView mv={15}>
                  <FText body1 grey800>
                    Please confirm if you would like to delete all menu items in this category
                  </FText>
                </FView>
              </DialogContent>
              <Divider />
              <DialogActions>
                <FView fill center row>
                  <FButton
                    onClick={() => {
                      setDeleteAll(false)
                    }}>
                    <ButtonFillView w={150} rounded>
                      <FText bold primaryContrast>
                        Cancel
                      </FText>
                    </ButtonFillView>
                  </FButton>
                  <FView size={10} />
                  <FButton
                    onClick={() => {
                      handleDelete()
                      setDeleteAll(false)
                    }}>
                    <ButtonFillView w={150} rounded>
                      <FText bold primaryContrast>
                        Confirm
                      </FText>
                    </ButtonFillView>
                  </FButton>
                </FView>
              </DialogActions>
            </Dialog>
          </FView>
        )}
      </FView>
      <FView h={1} bg={theme.palette.grey['300']} />
      <Collapse in={isOpen}>
        <FView bg={theme.palette.common.white}>
          {isOpen && (
            <ProductList
              categoryId={categoryId}
              locationId={locationId}
              restaurantId={restaurantId}
              searchTerm={searchTerm}
            />
          )}
          <ProductAdd categoryId={categoryId} />
        </FView>
        <FView h={1} bg={theme.palette.grey['200']} />
      </Collapse>
    </FView>
  )
}

const useStyle = makeStyles((theme) => ({
  // Remove number up down arrows as it blocks index number from showing
  categoryIndexTextField: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  indexInputStyle: {
    height: 40,
  },
  indexInputNativeStyle: {
    textAlign: 'center',
  },
  categoryImg: {objectFit: 'contain', width: 90},
}))
