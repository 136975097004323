import React from 'react'
import {FView, FText, FButton, FLink} from 'components'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {Grid, Dialog, DialogTitle, DialogContent, Divider} from '@material-ui/core'
import {Restaurant, Done, Block, NotificationsActive, Timer, Close} from '@material-ui/icons'
import moment from 'moment'
import * as utils from 'f-utils'
import OrderItemAddedCharges from './OrderItemAddedCharges'
import RewardItemView from './RewardItemView'
import OrderItemView from './OrderItemView'
import {useDispatch} from 'react-redux'

export default function OrderDetailsDialog({orderData, open, handleClose}) {
  const isUpSm = useMediaQueryUp('sm')
  const dispatch = useDispatch()
  const isSuperAdmin = dispatch.portal.getIsSuperAdmin()

  if (!orderData) {
    return null
  }
  const {
    foodSubTotal,
    deliveryFee,
    taxAmount,
    pickupTip,
    inpersonPickupTip,
    courierTip,
    totalCommission,
    networkAccessFee,
    subsidizedDeliveryFee,
    foodDelayContribution,
    commissionTax,
    otherAdjustments,
    inpersonCourierTip,
    stripeTransfer,
    grossEarning,
    cashRevenue,
    netDirectDeposit,
    revenue,
  } = utils.getOrderReportDetails({orderData})

  return (
    <Dialog maxWidth="md" fullScreen={!isUpSm} open={open} onClose={handleClose}>
      <DialogTitle>
        <FView alignCenter row justifyBetween>
          <FView fill>
            <FText h5 bold alignCenter>
              Order #{orderData.orderNumber}
            </FText>
          </FView>
          <FButton onPress={handleClose}>
            <Close />
          </FButton>
        </FView>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FView fill>
          <FText body1 alignCenter gutterBottom>
            Created: {moment(orderData.createdAt).calendar()}
          </FText>
          <FText body1 bold alignCenter>
            Prepare by: {moment(orderData.completionTime || orderData.estimatedCompletionTime).calendar()}
          </FText>
          <FView size={25} />
          <Grid container justify="space-around">
            <FView fill>
              <FView>
                {Object.keys(orderData.orderCartItems || {}).map((cartItemId) => {
                  const {
                    name,
                    count,
                    price,
                    refundCount = 0,
                    selectedModifierGroupsWithDetails,
                    notes,
                  } = orderData.orderCartItems[cartItemId]
                  return (
                    <FView key={cartItemId} fill>
                      {count - refundCount > 0 && (
                        <OrderItemView
                          key={cartItemId}
                          count={count - refundCount}
                          name={name}
                          price={price}
                          selectedModifierGroupsWithDetails={selectedModifierGroupsWithDetails}
                          notes={notes}
                        />
                      )}
                      {refundCount > 0 && (
                        <OrderItemView
                          isRefund
                          count={refundCount}
                          name={name}
                          price={price}
                          selectedModifierGroupsWithDetails={selectedModifierGroupsWithDetails}
                          notes={notes}
                        />
                      )}
                    </FView>
                  )
                })}
              </FView>
              {orderData.addedCharges && <OrderItemAddedCharges addedCharges={orderData.addedCharges} />}

              {Object.keys(orderData.rewards || {}).map((id) => {
                return <RewardItemView key={id} productId={id} {...orderData.rewards[id]} />
              })}
              {orderData.orderType !== 'DineIn' && (
                <FView row justifyBetween mt={4}>
                  <FView row alignCenter>
                    <Restaurant fontSize="small" />
                    <FView size={8} />
                    <FText body1>Utensils</FText>
                  </FView>
                  <FText body1>{orderData.includeUtensils ? 'Include' : 'Do NOT Include'}</FText>
                </FView>
              )}
              <FView size={10} />
              <Divider />
              <FView size={15} />
              <FView row justifyBetween>
                <FText bold body1 grey800 gutterBottom>
                  Subtotal:
                </FText>
                <FText bold body1 grey800>
                  ${orderData.subTotal.toFixed(2)}
                </FText>
              </FView>
              {orderData.orderType === 'Delivery' && (
                <FView row justifyBetween>
                  <FText bold body1 grey800 gutterBottom>
                    Delivery Fee:
                  </FText>
                  <FText bold body1 grey800>
                    {orderData.deliveryFee > 0 ? `$${orderData.deliveryFee.toFixed(2)}` : 'FREE'}
                  </FText>
                </FView>
              )}
              <FView row justifyBetween>
                <FText bold body1 grey800 gutterBottom>
                  GST (5%):
                </FText>
                <FText bold body1 grey800>
                  ${orderData.taxAmount.toFixed(2)}
                </FText>
              </FView>
              {orderData.tipAmount > 0 && (
                <FView row justifyBetween>
                  <FText bold body1 grey800 gutterBottom>
                    {orderData.orderType === 'Delivery' ? 'Courier' : 'Pickup'} Online Tip:
                  </FText>
                  <FText bold body1 grey800>
                    ${orderData.tipAmount.toFixed(2)}
                  </FText>
                </FView>
              )}
              {orderData.inpersonTipAmount > 0 && (
                <FView row justifyBetween>
                  <FText bold body1 grey800 gutterBottom>
                    Stated Cash Tip:
                  </FText>
                  <FText bold body1 grey800>
                    ${orderData.inpersonTipAmount.toFixed(2)}
                  </FText>
                </FView>
              )}
              <FView size={10} />
              <Divider />
              <FView size={10} />

              <FView row justifyBetween>
                <FText bold h6 black>
                  Total:
                </FText>
                <FText bold primary h6>
                  ${(orderData.total + (orderData.inpersonTipAmount || 0)).toFixed(2)}
                </FText>
              </FView>
              <FView row justifyBetween>
                <FText body1>• Food Total:</FText>
                <FText body1>${(orderData.subTotal + orderData.subTotalTax).toFixed(2)}</FText>
              </FView>
            </FView>
            <FView size={isUpSm ? 50 : 25} />
            <FView fill>
              <FView row alignCenter>
                <FText body1 bold>
                  Status:
                </FText>
                <FView w={4} />
                <FText primary noLineHeight>
                  {orderData.status === 'New' ? (
                    <NotificationsActive />
                  ) : orderData.status === 'Preparing' ? (
                    <Timer />
                  ) : orderData.status === 'Done' ? (
                    <Done />
                  ) : orderData.status === 'Cancelled' ? (
                    <Block />
                  ) : (
                    <FText h4 bold>
                      ?
                    </FText>
                  )}
                </FText>
                <FView w={4} />
                <FText body1 bold primary>
                  {orderData.status}
                </FText>
              </FView>
              <FView size={10} />
              <FText body1 bold>
                Source:
              </FText>
              <FView w={4} />
              <FText body1>{orderData.sourceClient}</FText>
              <FView size={10} />
              <FText body1 bold>
                Payment Method:
              </FText>
              <FText body1>{orderData.paymentMethod}</FText>
              <FView size={10} />
              <FText body1 bold>
                Order Type:
              </FText>
              <FText body1>{orderData.orderType}</FText>
              {orderData.orderType === 'Delivery' && (
                <>
                  <FText body1>{orderData.deliveryAddress}</FText>
                  {!!orderData.deliveryUnit && <FText body1>Unit {orderData.deliveryUnit}</FText>}
                  {!!orderData.deliveryInstructions && <FText body1>{orderData.deliveryInstructions}</FText>}
                </>
              )}
              <FView size={10} />
              <FText body1 bold>
                Customer Details:
              </FText>
              <FText body1>{orderData.name}</FText>
              <FText body1>{utils.formatPhoneNumber(orderData.phoneNumber)}</FText>
              <FText body1>{orderData.email}</FText>
              {!!orderData.notes && (
                <>
                  <FView size={10} />
                  <FText body1 bold>
                    Special Requests:
                  </FText>
                  <FText body1>{orderData.notes}</FText>
                </>
              )}
              {isSuperAdmin && (
                <FLink href={`/users?userId=${orderData.userId}`}>
                  <FText bold info>
                    view customer profile
                  </FText>
                </FLink>
              )}
              <FView size={10} />
              <Divider />
              <FView size={10} />
              <FView row justifyBetween>
                <FText bold body1 black>
                  Gross Earning:
                </FText>
                <FText bold primary body1>
                  ${grossEarning.toFixed(2)}
                </FText>
              </FView>
              <FView row justifyBetween>
                <FText body1 grey800 bold gutterBottom>
                  • Food Subtotal:
                </FText>
                <FText body1 grey800 bold>
                  ${foodSubTotal.toFixed(2)}
                </FText>
              </FView>
              <FView row justifyBetween>
                <FText body1 grey800 bold gutterBottom>
                  • Delivery Fee:
                </FText>
                <FText body1 grey800 bold>
                  ${deliveryFee.toFixed(2)}
                </FText>
              </FView>
              <FView row justifyBetween>
                <FText body1 grey800 bold gutterBottom>
                  • Taxes:
                </FText>
                <FText body1 grey800 bold>
                  ${taxAmount.toFixed(2)}
                </FText>
              </FView>
              {pickupTip > 0 && (
                <FView row justifyBetween>
                  <FText body1 grey800 bold gutterBottom>
                    • Pickup Online Tip:
                  </FText>
                  <FText body1 grey800 bold>
                    ${pickupTip.toFixed(2)}
                  </FText>
                </FView>
              )}
              {inpersonPickupTip > 0 && (
                <FView row justifyBetween>
                  <FText body1 grey800 bold gutterBottom>
                    • Pickup Cash Tip:
                  </FText>
                  <FText body1 grey800 bold>
                    ${inpersonPickupTip.toFixed(2)}
                  </FText>
                </FView>
              )}
              {courierTip > 0 && (
                <FView row justifyBetween>
                  <FText body1 grey800 bold gutterBottom>
                    Courier Online Tip:
                  </FText>
                  <FText body1 grey800 bold>
                    ${courierTip.toFixed(2)}
                  </FText>
                </FView>
              )}
              {inpersonCourierTip > 0 && (
                <FView row justifyBetween>
                  <FText body1 grey800 bold gutterBottom>
                    • Courier Cash Tip:
                  </FText>
                  <FText body1 grey800 bold>
                    ${inpersonCourierTip.toFixed(2)}
                  </FText>
                </FView>
              )}

              <FView size={10} />
              <Divider />
              <FView size={10} />
              <FView row justifyBetween>
                <FText bold body1 black>
                  Total Commission:
                </FText>
                <FText bold primary body1>
                  (${totalCommission.toFixed(2)})
                </FText>
              </FView>
              <FView row justifyBetween>
                <FText body1 grey800 bold gutterBottom>
                  • Network Access Contribution:
                </FText>
                <FText body1 grey800 bold>
                  ${networkAccessFee.toFixed(2)}
                </FText>
              </FView>
              {subsidizedDeliveryFee > 0 && (
                <FView row justifyBetween>
                  <FText body1 grey800 bold gutterBottom>
                    • Subsidized Delivery Fee:
                  </FText>
                  <FText body1 grey800 bold>
                    ${subsidizedDeliveryFee.toFixed(2)}
                  </FText>
                </FView>
              )}
              {foodDelayContribution > 0 && (
                <FView row justifyBetween>
                  <FText body1 grey800 bold gutterBottom>
                    • Courier Delay Contribution:
                  </FText>
                  <FText body1 grey800 bold>
                    ${foodDelayContribution.toFixed(2)}
                  </FText>
                </FView>
              )}
              <FView row justifyBetween>
                <FText body1 grey800 bold gutterBottom>
                  • GST:
                </FText>
                <FText body1 grey800 bold>
                  ${commissionTax.toFixed(2)}
                </FText>
              </FView>
              {otherAdjustments !== 0 && (
                <>
                  <FView size={10} />
                  <Divider />
                  <FView size={10} />
                  <FView row justifyBetween>
                    <FText bold body1 black>
                      Other Adjustments:
                    </FText>
                    <FText bold primary body1>
                      {otherAdjustments < 0 && '('}${Math.abs(otherAdjustments).toFixed(2)}
                      {otherAdjustments < 0 && ')'}
                    </FText>
                  </FView>
                </>
              )}

              <FView size={10} />
              <Divider />
              <FView size={10} />
              <FView row justifyBetween>
                <FText bold body1 black>
                  Revenue:
                </FText>
                <FText bold primary body1>
                  ${revenue.toFixed(2)}
                </FText>
              </FView>
              <FView row justifyBetween>
                <FText body1 grey800 bold gutterBottom>
                  • Total Cash Received:
                </FText>
                <FText body1 grey800 bold>
                  ${cashRevenue.toFixed(2)}
                </FText>
              </FView>
              <FView row justifyBetween>
                <FText body1 grey800 bold gutterBottom>
                  • Net Amount:
                </FText>
                <FText body1 grey800 bold>
                  {netDirectDeposit < 0 && '('}${Math.abs(netDirectDeposit).toFixed(2)}
                  {netDirectDeposit < 0 && ')'}
                </FText>
              </FView>
              {orderData.paymentProcessor === 'Stripe' && (
                <>
                  {stripeTransfer < netDirectDeposit && (
                    <FView row justifyBetween>
                      <FText body1 grey800 bold gutterBottom>
                        • Previously Owed Commission:
                      </FText>
                      <FText body1 grey800 bold>
                        ${(netDirectDeposit - stripeTransfer).toFixed(2)}
                      </FText>
                    </FView>
                  )}
                  <FView row justifyBetween>
                    <FText body1 grey800 bold gutterBottom>
                      • Actual Direct Deposit:
                    </FText>
                    <FText body1 grey800 bold>
                      ${stripeTransfer.toFixed(2)}
                    </FText>
                  </FView>
                  {stripeTransfer > netDirectDeposit && (
                    <FView row justifyBetween>
                      <FText body1 grey800 bold gutterBottom>
                        • Commission Still Owed:
                      </FText>
                      <FText body1 grey800 bold>
                        ${(stripeTransfer - netDirectDeposit).toFixed(2)}
                      </FText>
                    </FView>
                  )}
                </>
              )}
            </FView>
          </Grid>
        </FView>
      </DialogContent>
    </Dialog>
  )
}
