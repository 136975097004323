import {db, auth, DELETE_FIELD_VALUE, FieldValue, getCollection, getDocument} from 'f-core/src/config/firebase'
import {get, clamp} from 'lodash'
import * as api from './api'
import {v1 as uuidv1} from 'uuid'

const DEFAULT_STATE = {
  // Restaurant data
  locationsLoading: true,
  Locations: {},
  Categories: {},
  Products: {},
  ModifierGroups: {},
  printers: {},
  LocationsPrivate: {},
  rewards: {},
}

const restaurantsModel = {
  state: DEFAULT_STATE,
  reducers: {
    setLocationPrivate: (state, {locationId, data}) => {
      return {...state, LocationsPrivate: {...state.LocationsPrivate, [locationId]: data}}
    },
    setLocationsLoading: (state, locationsLoading) => {
      return {...state, locationsLoading}
    },
    setLocations: (state, Locations) => {
      return {
        ...state,
        Locations: {
          ...state.Locations,
          ...Object.entries(Locations).reduce((prev, [locationId, locationData]) => {
            prev[locationId] = locationData
            return prev
          }, {}),
        },
      }
    },
    setCategories: (state, {Categories, locationId}) => {
      return {
        ...state,
        Categories: {...state.Categories, [locationId]: Categories},
      }
    },
    setProducts: (state, {Products, locationId}) => {
      return {
        ...state,
        Products: {...state.Products, [locationId]: Products},
      }
    },
    setModifierGroups: (state, {ModifierGroups, locationId}) => ({
      ...state,
      ModifierGroups: {...state.ModifierGroups, [locationId]: ModifierGroups},
    }),
    setPrinters: (state, {locationId, printers}) => ({
      ...state,
      printers: {...state.printers, [locationId]: printers},
    }),
    setRewards: (state, {locationId, rewards}) => {
      return {
        ...state,
        rewards: {...state.rewards, [locationId]: rewards},
      }
    },
  },
  actions: ({dispatch, getState}) => ({
    getLocationPrivate({locationId}) {
      return getState().restaurants.LocationsPrivate[locationId] ?? {}
    },
    getAccountId({locationId}) {
      return dispatch.restaurants.getLocationPrivate({locationId}).accountId
    },
    getSquareTokenId({locationId}) {
      return dispatch.restaurants.getLocationPrivate({locationId}).squareTokenId
    },
    getSquareEnabled({locationId}) {
      return dispatch.restaurants.getLocationPrivate({locationId}).squareEnabled
    },
    getSquareLocationId({locationId}) {
      return dispatch.restaurants.getLocationPrivate({locationId}).squareLocationId
    },
    getIsLocationsLoading() {
      return getState().restaurants.locationsLoading
    },
    getLocations() {
      return getState().restaurants.Locations
    },
    getLocation({locationId}) {
      return getState().restaurants.Locations[locationId]
    },
    getMenus({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData == null || !locationData.menus ? {} : locationData.menus
    },
    getMenu({locationId, menuId}) {
      return dispatch.restaurants.getMenus({locationId})[menuId] || {}
    },
    getCategoriesAll({locationId}) {
      return getState().restaurants.Categories[locationId] || {}
    },
    getProductsAll({locationId}) {
      return getState().restaurants.Products[locationId] || {}
    },
    getModifierGroups({locationId}) {
      return getState().restaurants.ModifierGroups[locationId] || {}
    },
    getModifierGroup({locationId, modifierGroupId}) {
      const modifierGroups = dispatch.restaurants.getModifierGroups({locationId})
      return modifierGroups[modifierGroupId]
    },
    getRewards({locationId}) {
      return getState().restaurants.rewards?.[locationId] ?? {}
    },
    createModifierGroup({restaurantId, locationId, modifier}) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('ModifierGroups')
        .add(modifier)
    },
    updateModifierGroup({restaurantId, locationId, modifierGroupId, modifier}) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('ModifierGroups')
        .doc(modifierGroupId)
        .update(modifier)
    },
    deleteModifierGroup({restaurantId, locationId, productId, modifierGroupId}) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('ModifierGroups')
        .doc(modifierGroupId)
        .delete()
        .then(() => {
          return db
            .collection('Restaurants')
            .doc(restaurantId)
            .collection('Locations')
            .doc(locationId)
            .collection('Products')
            .doc(productId)
            .update({
              modifierGroups: FieldValue.arrayRemove(modifierGroupId),
            })
        })
    },
    subscribePrinters(restaurantId, locationId) {
      const docRef = getDocument('locationPrivate', {restaurantId, locationId})
      return docRef.onSnapshot((snapshot) => {
        dispatch.restaurants.setPrinters({locationId, printers: snapshot.data()?.printers ?? {}})
      })
    },

    subscribeLocations() {
      return db.collectionGroup('Locations').onSnapshot((snapshot) => {
        if (snapshot) {
          const Locations = {}
          for (const doc of snapshot.docs) {
            const Location = doc.data()
            Location.restaurantId = doc.ref.parent.parent.id
            Location.locationId = doc.id
            Locations[doc.id] = Location
          }
          dispatch.restaurants.setLocations(Locations)
          dispatch.restaurants.setLocationsLoading(false)
        }
      })
    },
    subscribeCategories(restaurantId, locationId) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('Categories')
        .onSnapshot((snapshot) => {
          if (snapshot) {
            const Categories = {}
            for (const doc of snapshot.docs) {
              Categories[doc.id] = doc.data()
              Categories[doc.id].categoryId = doc.id
            }
            dispatch.restaurants.setCategories({Categories, locationId})
          }
        })
    },
    subscribeLocationPrivate({restaurantId, locationId}) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('LocationPrivate')
        .doc('locationPrivate')
        .onSnapshot((snapshot) => {
          if (snapshot) {
            dispatch.restaurants.setLocationPrivate({locationId, data: snapshot.data()})
          }
        })
    },
    subscribeProducts(restaurantId, locationId) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('Products')
        .onSnapshot((snapshot) => {
          if (snapshot) {
            const Products = {}
            for (const doc of snapshot.docs) {
              Products[doc.id] = doc.data()
              Products[doc.id].productId = doc.id
            }
            dispatch.restaurants.setProducts({Products, locationId})
          }
        })
    },
    subscribeModifierGroups(restaurantId, locationId) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('ModifierGroups')
        .onSnapshot((snapshot) => {
          if (snapshot) {
            const ModifierGroups = {}
            for (const doc of snapshot.docs) {
              ModifierGroups[doc.id] = doc.data()
              ModifierGroups[doc.id].id = doc.id
            }
            dispatch.restaurants.setModifierGroups({ModifierGroups, locationId})
          }
        })
    },
    updateProductOrder({restaurantId, locationId, categoryId, fromIndex, toIndex}) {
      const productOrder = dispatch.restaurants.getProductOrder({locationId, categoryId})
      const toIndexClamped = clamp(toIndex, 0, productOrder.length - 1)
      if (toIndexClamped === fromIndex) {
        return
      }

      const newProductOrder = []
      if (fromIndex > toIndexClamped) {
        for (let i = 0; i < toIndexClamped; i++) {
          if (i !== fromIndex) {
            newProductOrder.push(productOrder[i])
          }
        }
        newProductOrder.push(productOrder[fromIndex])
        for (let i = toIndexClamped; i < productOrder.length; i++) {
          if (i !== fromIndex) {
            newProductOrder.push(productOrder[i])
          }
        }
      } else {
        for (let i = 0; i < toIndexClamped + 1; i++) {
          if (i !== fromIndex) {
            newProductOrder.push(productOrder[i])
          }
        }
        newProductOrder.push(productOrder[fromIndex])
        for (let i = toIndexClamped + 1; i < productOrder.length; i++) {
          if (i !== fromIndex) {
            newProductOrder.push(productOrder[i])
          }
        }
      }
      const category = dispatch.restaurants.getCategory({locationId, categoryId})
      category.productOrder = newProductOrder
      return dispatch.restaurants.updateCategory({restaurantId, locationId, categoryId, category})
    },
    updateCategoryOrder({menuId, restaurantId, locationId, fromIndex, toIndex}) {
      const categoryOrder = dispatch.restaurants.getMenuCategoryOrder({locationId, menuId})
      const toIndexClamped = clamp(toIndex, 0, categoryOrder.length - 1)
      if (toIndexClamped === fromIndex) {
        return
      }
      const newCategoryOrder = []
      if (fromIndex > toIndexClamped) {
        for (let i = 0; i < toIndexClamped; i++) {
          if (i !== fromIndex) {
            newCategoryOrder.push(categoryOrder[i])
          }
        }
        newCategoryOrder.push(categoryOrder[fromIndex])
        for (let i = toIndexClamped; i < categoryOrder.length; i++) {
          if (i !== fromIndex) {
            newCategoryOrder.push(categoryOrder[i])
          }
        }
      } else {
        for (let i = 0; i < toIndexClamped + 1; i++) {
          if (i !== fromIndex) {
            newCategoryOrder.push(categoryOrder[i])
          }
        }
        newCategoryOrder.push(categoryOrder[fromIndex])
        for (let i = toIndexClamped + 1; i < categoryOrder.length; i++) {
          if (i !== fromIndex) {
            newCategoryOrder.push(categoryOrder[i])
          }
        }
      }
      const menu = dispatch.restaurants.getMenu({locationId, menuId})
      menu.categoryOrder = newCategoryOrder
      return dispatch.restaurants.updateMenu({menuId, restaurantId, locationId, menu})
    },
    async deleteMenu({restaurantId, locationId, menuId}) {
      const menuData = await dispatch.restaurants.getMenu({locationId, menuId})
      for (const categoryId of menuData.categoryOrder) {
        await dispatch.restaurants.deleteCategory({restaurantId, locationId, categoryId, menuId})
      }
      return getCollection('Locations', {restaurantId})
        .doc(locationId)
        .update({['menus.' + menuId]: DELETE_FIELD_VALUE})
    },
    async updatePrinters({restaurantId, locationId, printers}) {
      try {
        return await getDocument('locationPrivate', {restaurantId, locationId}).update({printers})
      } catch (_) {
        return await getDocument('locationPrivate', {restaurantId, locationId}).set({printers}, {merge: true})
      }
    },
    updateMenu({restaurantId, locationId, menuId, menu}) {
      return getCollection('Locations', {restaurantId})
        .doc(locationId)
        .update({['menus.' + menuId]: menu})
    },
    updateHours({restaurantId, locationId, hours}) {
      return getCollection('Locations', {restaurantId}).doc(locationId).update({hours})
    },
    updateHoursLT({restaurantId, locationId, hoursLT}) {
      return getCollection('Locations', {restaurantId}).doc(locationId).update({hoursLT})
    },
    deleteDeliveryHoursLT({restaurantId, locationId}) {
      return getCollection('Locations', {restaurantId}).doc(locationId).update({
        deliveryHoursLT: DELETE_FIELD_VALUE,
      })
    },
    updateDeliveryHoursLT({restaurantId, locationId, deliveryHoursLT}) {
      return getCollection('Locations', {restaurantId}).doc(locationId).update({
        deliveryHoursLT,
      })
    },
    addAnnouncement({restaurantId, locationId, announcement}) {
      return getCollection('Locations', {restaurantId}).doc(locationId).update({
        announcement,
      })
    },
    deleteAnnouncement({restaurantId, locationId}) {
      return getCollection('Locations', {restaurantId}).doc(locationId).update({
        announcement: DELETE_FIELD_VALUE,
      })
    },
    getCategory({locationId, categoryId}) {
      return dispatch.restaurants.getCategoriesAll({locationId})[categoryId]
    },
    updateCategory({restaurantId, locationId, categoryId, category}) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('Categories')
        .doc(categoryId)
        .update(category)
    },
    updateProduct({restaurantId, locationId, productId, product}) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('Products')
        .doc(productId)
        .update(product)
    },
    async deleteProduct({restaurantId, locationId, productId, categoryId}) {
      const productData = dispatch.restaurants.getProduct({locationId, productId})
      if (productData.imageUrl) {
        try {
          const authToken = await auth.currentUser.getIdToken()
          await api.deleteImageFromBucket({
            serverUrl: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL,
            authToken,
            restaurantId,
            productId,
            imageType: 'product',
          })
        } catch (e) {
          // Image might not be stored on firebase storage, so it is safe to ignore.
        }
      }
      await db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('Products')
        .doc(productId)
        .delete()
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('Categories')
        .doc(categoryId)
        .update({productOrder: FieldValue.arrayRemove(productId)})
    },
    async deleteCategory({restaurantId, locationId, categoryId, menuId}) {
      // 1. Delete Category Image
      // 2. Delete products within the category
      // 3. Delete Category
      // 4. Remove rewards with deleted products
      // 5. Update menu without the categoryId in the categoryOrder

      const categoryData = dispatch.restaurants.getCategory({locationId, categoryId})
      if (categoryData.imageUrl) {
        try {
          const authToken = await auth.currentUser.getIdToken()
          await api.deleteImageFromBucket({
            serverUrl: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL,
            authToken,
            restaurantId,
            categoryId,
            imageType: 'category',
          })
        } catch (e) {
          // Image might not be stored on firebase storage, so it is safe to ignore.
        }
      }

      const deleteCategoryRef = db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('Categories')
        .doc(categoryId)

      const locationsRef = db.collection('Restaurants').doc(restaurantId).collection('Locations').doc(locationId)

      const locationSnapshot = await locationsRef.get()
      const locationData = locationSnapshot.data()
      const rewardsData = locationData.rewards
      return await db.runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(deleteCategoryRef).then((categorySnapshot) => {
          if (!categorySnapshot.exists) {
            return
          }

          const productOrder = categorySnapshot.data().productOrder

          for (const productId of productOrder) {
            if (rewardsData && rewardsData[productId]) {
              delete rewardsData[productId]
            }
            transaction.delete(
              db
                .collection('Restaurants')
                .doc(restaurantId)
                .collection('Locations')
                .doc(locationId)
                .collection('Products')
                .doc(productId),
            )
          }
          if (rewardsData) {
            transaction.update(locationsRef, {
              rewards: rewardsData,
            })
          }

          transaction.delete(deleteCategoryRef)
          return transaction.update(locationsRef, {
            ['menus.' + menuId + '.categoryOrder']: FieldValue.arrayRemove(categoryId),
          })
        })
      })
    },
    getIsMenuActive({menuId, locationId}) {
      const locationData = getState().restaurants.Locations[locationId]
      if (!locationData) {
        return false
      }

      const menu = locationData.menus[menuId]
      if (!menu) {
        return false
      }

      return menu.active
    },
    setIsMenuActive({menuId, restaurantId, locationId, isActive}) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .update({['menus.' + menuId + '.active']: isActive})
    },
    updateLocation({restaurantId, locationId, locationData}) {
      return db.collection('Restaurants').doc(restaurantId).collection('Locations').doc(locationId).update(locationData)
    },

    updateSquareEnabled({restaurantId, locationId, squareEnabled}) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('LocationPrivate')
        .doc('locationPrivate')
        .set({squareEnabled}, {merge: true})
    },

    updateSquareLocationId({restaurantId, locationId, squareLocationId}) {
      return db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('LocationPrivate')
        .doc('locationPrivate')
        .set({squareLocationId}, {merge: true})
    },

    async getPrivate({restaurantId}) {
      const privateDoc = await db.collection('Restaurants').doc(restaurantId).collection('Private').doc('private').get()
      return privateDoc.data() ?? {}
    },
    updatePrivate({restaurantId, newPrivate}) {
      return db.collection('Restaurants').doc(restaurantId).collection('Private').doc('private').update(newPrivate)
    },

    async createNewRestaurant(newRestaurantData) {
      const authToken = await auth.currentUser.getIdToken()
      return await api.createNewRestaurant({authToken, restaurantData: newRestaurantData})
    },
    deleteOldReward({restaurantId, locationId, docId}) {
      //TODO: remove after rewards migratation
      return getCollection('Locations', {restaurantId})
        .doc(locationId)
        .update({[`rewards.${docId}`]: DELETE_FIELD_VALUE})
    },
    deleteReward({restaurantId, locationId, docId}) {
      return getCollection('Locations', {restaurantId}).doc(locationId).collection('Rewards').doc(docId).delete()
    },
    addReward({restaurantId, locationId, rewardData}) {
      if (!rewardData.rewardId) {
        rewardData.rewardId = uuidv1()
      }
      return getCollection('Locations', {restaurantId})
        .doc(locationId)
        .collection('Rewards')
        .doc(!!rewardData.couponCode ? rewardData.couponCode : undefined)
        .set({...rewardData})
    },
    updateReward({restaurantId, locationId, rewardData, docId}) {
      if (!rewardData.rewardId) {
        rewardData.rewardId = uuidv1()
      }
      const rewards = dispatch.restaurants.getRewards({locationId})
      if (rewards[docId]) {
        if (rewardData.couponCode !== docId && rewards[docId].isPublic !== rewardData.isPublic) {
          const problemFields = ['qtyLimit', 'qty', 'qtyLimitPerUser']
          for (const field of problemFields) {
            if (typeof rewardData[field] === 'object') {
              delete rewardData[field]
            }
          }
          dispatch.restaurants.deleteReward({restaurantId, locationId, docId})

          return dispatch.restaurants.addReward({restaurantId, locationId, rewardData})
        }
      }
      return getCollection('Locations', {restaurantId})
        .doc(locationId)
        .collection('Rewards')
        .doc(docId)
        .update(rewardData)
    },
    updateOldReward({restaurantId, locationId, rewardData, rewardId}) {
      //TODO: remove after rewards migratation
      return getCollection('Locations', {restaurantId})
        .doc(locationId)
        .update({[`rewards.${rewardId}`]: rewardData})
    },

    addCategory({restaurantId, locationId, category, menuId}) {
      return getCollection('Categories', {restaurantId, locationId})
        .add(category)
        .then((docRef) => {
          const menus = dispatch.restaurants.getMenus({locationId})
          menus[menuId].categoryOrder.push(docRef.id)
          const newCategoryArray = menus[menuId].categoryOrder
          return db
            .collection('Restaurants')
            .doc(restaurantId)
            .collection('Locations')
            .doc(locationId)
            .update({['menus.' + menuId + '.categoryOrder']: newCategoryArray})
        })
    },
    addProduct({restaurantId, locationId, product, categoryId}) {
      return getCollection('Products', {restaurantId, locationId})
        .add(product)
        .then((ref) => {
          const categories = dispatch.restaurants.getCategoriesAll({locationId})
          categories[categoryId].productOrder.push(ref.id)
          const newProductArray = categories[categoryId].productOrder
          return getCollection('Categories', {restaurantId, locationId})
            .doc(categoryId)
            .update({productOrder: newProductArray})
        })
    },
    getProductOrder({locationId, categoryId}) {
      const category = dispatch.restaurants.getCategory({locationId, categoryId})
      return get(category, 'productOrder', [])
    },
    getMenuCategoryOrder({locationId, menuId}) {
      return dispatch.restaurants.getMenu({locationId, menuId}).categoryOrder || []
    },
    getMenuCategoriesArray({locationId, menuId}) {
      const menus = dispatch.restaurants.getMenus({locationId})
      if (menus && menus[menuId] && menus[menuId].categoryOrder) {
        const locationCategories = dispatch.restaurants.getCategoriesAll({locationId})

        const menuCategories = []
        for (const categoryId of menus[menuId].categoryOrder) {
          if (locationCategories[categoryId]) {
            menuCategories.push(locationCategories[categoryId])
          }
        }
        return menuCategories
      }
      return []
    },
    getMenuCategories({locationId, menuId}) {
      const menus = dispatch.restaurants.getMenus({locationId})
      if (menus && menus[menuId] && menus[menuId].categoryOrder) {
        const locationCategories = dispatch.restaurants.getCategoriesAll({locationId})

        const menuCategories = {}
        for (const categoryId of menus[menuId].categoryOrder) {
          if (locationCategories[categoryId]) {
            menuCategories[categoryId] = locationCategories[categoryId]
          }
        }
        return menuCategories
      }
      return {}
    },
    getCategoryProductsArray({locationId, categoryId}) {
      const locationCategories = dispatch.restaurants.getCategoriesAll({locationId})
      const selectedCategory = locationCategories[categoryId]
      if (locationCategories && selectedCategory && selectedCategory.productOrder) {
        const categoryProducts = []
        const locationProducts = dispatch.restaurants.getProductsAll({locationId})
        for (const productId of selectedCategory.productOrder) {
          if (locationProducts[productId]) {
            categoryProducts.push(locationProducts[productId])
          }
        }
        return categoryProducts
      }
      return []
    },
    getProducts({locationId, categoryId}) {
      const locationCategories = dispatch.restaurants.getCategoriesAll({locationId})
      const selectedCategory = locationCategories[categoryId]
      if (locationCategories && selectedCategory && selectedCategory.productOrder) {
        const categoryProducts = {}
        const locationProducts = dispatch.restaurants.getProductsAll({locationId})
        for (const productId of selectedCategory.productOrder) {
          if (locationProducts[productId]) {
            categoryProducts[productId] = locationProducts[productId]
          }
        }
        return categoryProducts
      }
      return {}
    },
    getProduct({locationId, productId}) {
      const locationProducts = dispatch.restaurants.getProductsAll({locationId})
      return locationProducts[productId]
    },
    getIsLocationProductsLoaded({locationId}) {
      const locationProducts = getState().restaurants.Products[locationId]
      const locationCategories = getState().restaurants.Categories[locationId]
      const locationModifierGroups = getState().restaurants.ModifierGroups[locationId]
      return locationProducts && locationCategories && locationModifierGroups
    },
    getPrinters({locationId}) {
      return getState().restaurants.printers[locationId]
    },
    addPrinter({printer, locationId, restaurantId}) {
      const key = printer.id
      return dispatch.restaurants.updatePrinters({
        locationId,
        restaurantId,
        printers: {
          ...dispatch.restaurants.getPrinters({locationId}),
          [key]: printer,
        },
      })
    },
    subscribeRewards({restaurantId, locationId}) {
      return getCollection('Locations', {restaurantId})
        .doc(locationId)
        .collection('Rewards')
        .onSnapshot((rewardsSnapshot) => {
          const rewards = {}
          rewardsSnapshot.forEach((doc) => {
            // rewards[locationId] = rewards[locationId] ?? {}
            rewards[doc.id] = doc.data()
          })
          dispatch.restaurants.setRewards({locationId, rewards})
        })
    },
  }),
}

export default restaurantsModel
