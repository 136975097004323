import {db} from 'f-core/src/config/firebase'
import * as api from 'f-core/src/api'
import {firebaseAuth} from 'f-core/src/config/firebase'

const portalModel = {
  state: {
    isLoggedIn: null,
    isUserLoaded: false,
    User: {},
  },
  reducers: {
    setIsLoggedIn: (state, isLoggedIn) => {
      return {...state, isLoggedIn}
    },
    setUser: (state, User) => {
      return {...state, isUserLoaded: true, User}
    },
  },
  actions: ({dispatch, getState}) => ({
    getIsAdmin() {
      return getState().portal.User.role === 'admin'
    },
    getUserData() {
      return getState().portal.User
    },
    getLocationId() {
      return dispatch.portal.getUserData()?.locationId
    },
    getIsSuperAdmin() {
      return getState().portal.User.role === 'super_admin'
    },
    getUserId() {
      return firebaseAuth.currentUser?.uid ?? null
    },
    getUserName() {
      return getState().portal.User.name ?? ''
    },
    getIsUserLoggedIn() {
      return getState().portal.isLoggedIn
    },
    getIsUserLoaded() {
      return getState().portal.isUserLoaded
    },
    getUserEmail() {
      return firebaseAuth.currentUser && firebaseAuth.currentUser.email
    },
    signOut() {
      return api.auth.signOut()
    },
    signInWithEmailAndPassword(email, password) {
      return api.auth.signInWithEmailAndPassword(email, password)
    },
    subscribeUser() {
      return firebaseAuth.onAuthStateChanged((user) => {
        if (user) {
          dispatch.portal.setIsLoggedIn(true)
          db.collection('Users')
            .doc(user.uid)
            .onSnapshot((snapshot) => {
              if (snapshot) {
                const userData = snapshot.data()
                if (userData) {
                  userData.id = snapshot.id
                  dispatch.portal.setUser(userData)
                } else {
                  dispatch.portal.setUser({})
                }
              }
            })
        } else {
          dispatch.portal.setIsLoggedIn(false)
        }
      })
    },
  }),
}

export default portalModel
