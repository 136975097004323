import React from 'react'
import {FormControlLabel, Checkbox} from '@material-ui/core'
import {FView, FText, FTextField} from 'components'
import Autocomplete from '@material-ui/lab/Autocomplete'

const Filters = ({
  allRegionFilter,
  defaultRegionFitler,
  isUpSm,
  locationsList,
  orderTypeFilter,
  orderTypes,
  regionFilter,
  regions,
  selectedLocations,
  setAllRegionFilter,
  setOrderTypeFilter,
  setRegionFilter,
  setSelectedLocations,
  setSourceClientFilter,
  sourceClientFilter,
  sourceClients,
}) => {
  return (
    <>
      <FText bold h6>
        Filters
      </FText>
      <FView size={16} />
      <FText bold body2>
        Restaurants
      </FText>
      <Autocomplete
        value={selectedLocations}
        options={locationsList}
        onChange={(e, values) => setSelectedLocations(values)}
        limitTags={3}
        disableClearable
        autoHighlight
        multiple
        getOptionLabel={(location) => location.restaurantName}
        renderOption={(location) => location.restaurantName}
        renderInput={(params) => <FTextField {...params} variant="outlined" label="Selected Restaurants" />}
      />
      <FView size={16} />
      <FText body2 bold>
        Region
      </FText>
      <FView grid gridTemplateColumns={isUpSm ? '1fr 1fr 1fr 1fr' : '1fr 1fr'} columnGap={8}>
        <FormControlLabel
          control={
            <Checkbox
              checked={allRegionFilter}
              onChange={() => {
                setRegionFilter(defaultRegionFitler)
                setAllRegionFilter(!allRegionFilter)
              }}
              color="primary"
            />
          }
          label="all"
        />
        {Object.keys(regions).map((regionId) => (
          <FormControlLabel
            key={regionId}
            disabled={allRegionFilter}
            control={
              <Checkbox
                checked={regionFilter?.[regionId] ?? false}
                onChange={() => setRegionFilter({...regionFilter, [regionId]: !regionFilter?.[regionId]})}
                color="primary"
              />
            }
            label={regionId}
          />
        ))}
      </FView>
      <FView size={16} />
      <FText bold body2>
        Order Type
      </FText>
      <FView grid gridTemplateColumns={isUpSm ? '1fr 1fr 1fr 1fr' : '1fr 1fr'} columnGap={15}>
        <FormControlLabel
          control={
            <Checkbox
              checked={orderTypeFilter === 'all'}
              onChange={(event) => setOrderTypeFilter('all')}
              color="primary"
            />
          }
          label={'all'}
        />
        {Object.keys(orderTypes).map((orderType) => (
          <FormControlLabel
            key={orderType}
            control={
              <Checkbox
                checked={orderTypeFilter === orderType}
                onChange={(event) => setOrderTypeFilter(orderType)}
                color="primary"
              />
            }
            label={orderType}
          />
        ))}
      </FView>
      <FView size={16} />
      <FText bold body2>
        Device Type (Source Client)
      </FText>
      <FView grid gridTemplateColumns={isUpSm ? '1fr 1fr 1fr 1fr' : '1fr 1fr'} columnGap={15}>
        <FormControlLabel
          control={
            <Checkbox
              checked={sourceClientFilter === 'all'}
              onChange={(event) => setSourceClientFilter('all')}
              color="primary"
            />
          }
          label={'all'}
        />
        {Object.keys(sourceClients).map((sourceClient) => (
          <FormControlLabel
            key={sourceClient}
            control={
              <Checkbox
                checked={sourceClientFilter === sourceClient}
                onChange={(event) => setSourceClientFilter(sourceClient)}
                color="primary"
              />
            }
            label={sourceClient}
          />
        ))}
      </FView>
    </>
  )
}

export default Filters
