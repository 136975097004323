import React, {useEffect, useState} from 'react'
import useReactRouter from 'use-react-router'
import {useDispatch, useSelector} from 'react-redux'
import {FView, FText, FNavBar, FDrawer, ButtonFillView, FButton, FTextField} from 'components'
import {colors} from 'styles'

const Banking = () => {
  const dispatch = useDispatch()
  const {match} = useReactRouter()
  const {restaurantId, locationId} = match.params
  const accountId = useSelector(() => dispatch.restaurants.getAccountId({locationId}))
  const account = useSelector(dispatch.accounts.getAccount)
  const isSuperAdmin = useSelector(dispatch.portal.getIsSuperAdmin)

  const [isSaving, setIsSaving] = useState(false)
  const [accountNumber, setAccountNumber] = useState('')
  const [address1, setAddress1] = useState('')
  const [branchTransitNumber, setBranchTransitNumber] = useState('')
  const [city, setCity] = useState('')
  const [companyName, setCompanyName] = useState('')
  // TODO: support first name and last names to use together with company name
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState('')
  const [financialInstitutionNumber, setFinancialInstitutionNumber] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [province, setProvince] = useState('')

  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))

  useEffect(() => {
    const bankAccount = account.bankAccount
    setAccountNumber(bankAccount.accountNumber)
    setAddress1(bankAccount.address1)
    setBranchTransitNumber(bankAccount.branchTransitNumber)
    setCity(bankAccount.city)
    setCompanyName(bankAccount.companyName)
    setCountry(bankAccount.country)
    setFinancialInstitutionNumber(bankAccount.financialInstitutionNumber)
    setPostalCode(bankAccount.postalCode)
    setProvince(bankAccount.province)
  }, [account.bankAccount])

  useEffect(() => {
    return dispatch.restaurants.subscribeLocationPrivate({restaurantId, locationId})
  }, [dispatch.restaurants, locationId, restaurantId])

  useEffect(() => {
    if (accountId) {
      return dispatch.accounts.subscribeAccount(accountId)
    }
  }, [accountId, dispatch.accounts])
  return (
    <>
      <FView fill justifyCenter bg={colors.white} m={50}>
        <FView h={80}>
          <FNavBar shadow />
        </FView>
        <FView h={10} />
        <FView row>
          <FDrawer locationData={locationData} />
          <FView fill alignCenter>
            <FView justifyCenter w={500} maxWidth={'100%'}>
              {isSuperAdmin && (
                <FText alignCenter h4>
                  Balance : ${account.balance}
                </FText>
              )}
              {!!account.locked && (
                <FText alignCenter h5>
                  ${account.locked} on the way to your bank
                </FText>
              )}
              <FView h={32} />
              <FText>Banking Information: </FText>
              <FTextField
                label="Institution Number"
                value={financialInstitutionNumber}
                disabled={!isSuperAdmin}
                onChange={(e) => setFinancialInstitutionNumber(e.target.value)}
              />
              <FTextField
                label="Transit Number"
                value={branchTransitNumber}
                disabled={!isSuperAdmin}
                onChange={(e) => setBranchTransitNumber(e.target.value)}
              />
              <FTextField
                label="Account number"
                value={accountNumber}
                disabled={!isSuperAdmin}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
              <FTextField
                label="Company Name"
                value={companyName}
                disabled={!isSuperAdmin}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <FTextField
                label="Address"
                value={address1}
                disabled={!isSuperAdmin}
                onChange={(e) => setAddress1(e.target.value)}
              />
              <FTextField
                label="Postal Code"
                value={postalCode}
                disabled={!isSuperAdmin}
                onChange={(e) => setPostalCode(e.target.value)}
              />
              <FTextField
                label="City"
                value={city}
                disabled={!isSuperAdmin}
                onChange={(e) => setCity(e.target.value)}
              />
              <FTextField
                label="Province"
                value={province}
                disabled={!isSuperAdmin}
                onChange={(e) => setProvince(e.target.value)}
              />
              <FTextField
                label="Country"
                value={country}
                disabled={!isSuperAdmin}
                onChange={(e) => setCountry(e.target.value)}
              />

              <FButton
                onClick={() => {
                  setIsSaving(true)
                  const bankAccount = {
                    accountNumber,
                    address1,
                    branchTransitNumber,
                    city,
                    companyName,
                    firstName,
                    lastName,
                    country,
                    financialInstitutionNumber,
                    postalCode,
                    province,
                  }
                  dispatch.accounts.updateAccount({accountId, account: {...account, bankAccount}}).finally(() => {
                    setIsSaving(false)
                  })
                }}
                disabled={isSaving || !isSuperAdmin}>
                <ButtonFillView disabled={isSaving || !isSuperAdmin}>
                  <FText bold white>
                    {isSaving ? 'Saving...' : 'Save'}
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
          </FView>
        </FView>
      </FView>
    </>
  )
}

export default Banking
