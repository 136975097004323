import React, {useState} from 'react'
import {Dialog, DialogContent, DialogTitle, DialogActions} from '@material-ui/core'
import {useDispatch} from 'react-redux'
import {FText, FView, FButton, FTextField} from 'components'
import {colors} from 'styles'

const AddPrinterModal = ({open, handleClose, locationId, restaurantId}) => {
  const [printer, setPrinter] = useState({name: '', ipAddress: '', bdAddress: '', emulation: 'manual', paperWidth: ''})

  const dispatch = useDispatch()

  return (
    <Dialog maxWidth={null} open={open} onClose={handleClose}>
      <FView fill alignCenter>
        <DialogTitle>
          <FText h5>Add Printer Manually</FText>
        </DialogTitle>
        <DialogContent>
          <FView h={20} />
          <FView row alignCenter flexWrap="wrap">
            <FView w={150}>
              <FText>Select Printer Type: </FText>
            </FView>
            <FView row>
              <FButton onPress={() => setPrinter({...printer, type: 'bluetooth'})}>
                <FView br={5} h={50} center bg={printer.type === 'bluetooth' ? colors.primary : 'grey'}>
                  <FText color="white">Bluetooth</FText>
                </FView>
              </FButton>
              <FView w={10} />
              <FButton
                onPress={() => {
                  setPrinter({...printer, type: 'network'})
                }}>
                <FView br={5} h={50} center bg={printer.type === 'network' ? colors.primary : 'grey'}>
                  <FText color="white">Network / LAN</FText>
                </FView>
              </FButton>
            </FView>
          </FView>
          <FView row alignCenter flexWrap="wrap" mt={15}>
            <FView w={150}>
              <FText>Printer Name:</FText>
            </FView>
            <FView fill bw={2} br={5} minWidth={150}>
              <FTextField
                margin="none"
                value={printer.name}
                onChange={(e) => setPrinter({...printer, name: e.target.value})}
              />
            </FView>
          </FView>
          <FView row alignCenter flexWrap="wrap" mt={15}>
            <FView w={150}>
              {!!printer.type && <FText>{printer.type === 'network' ? 'IP Address' : 'BD Address'}:</FText>}
            </FView>
            <FView fill bw={2} br={5} minWidth={150}>
              {!!printer.type &&
                (printer.type === 'network' ? (
                  <FTextField
                    margin="none"
                    value={printer.ipAddress}
                    onChange={(e) => {
                      const text = e.target.value
                      // if the input isn't a digit
                      if (text.match(/[^.0-9]+/g)) {
                        return
                      }
                      if (text.length > 15) {
                        return
                      }

                      setPrinter({...printer, ipAddress: text})
                    }}
                    placeholder={'xxx.xxx.xxx.xxx'}
                  />
                ) : (
                  <FTextField
                    margin="none"
                    value={printer.bdAddress}
                    onChange={(e) => {
                      let text = e.target.value
                      if (text.length > 17) {
                        return
                      }
                      if (printer.bdAddress.length < text.length && text.length < 17) {
                        if (text.length % 3 === 2) {
                          text += ':'
                        }
                      }

                      setPrinter({...printer, bdAddress: text})
                    }}
                    placeholder={'XX:XX:XX:XX:XX:XX'}
                  />
                ))}
            </FView>
          </FView>
          <FView row alignCenter flexWrap="wrap" mt={15}>
            <FView w={150}>
              <FText>Paper Width (mm):</FText>
            </FView>
            <FView fill bw={2} br={5} minWidth={150}>
              <FTextField
                margin="none"
                value={printer.paperWidth}
                onChange={(e) => setPrinter({...printer, paperWidth: e.target.value})}
              />
            </FView>
          </FView>
          <FView fill />
        </DialogContent>
        <DialogActions>
          <FView row fill center flexWrap="wrap">
            <FView mh={10} w={200}>
              <FButton onPress={handleClose}>
                <FView br={5} pv={10} bg={colors.primary}>
                  <FText white>Cancel</FText>
                </FView>
              </FButton>
            </FView>
            <FView mh={10} w={200}>
              <FButton
                onPress={() => {
                  if (!printer.type || !printer.name || !(printer.ipAddress || printer.bdAddress)) {
                    alert('Missing Fields, please fill in all fields')
                    return
                  }
                  //ip address must be in right format: 1-3 digits followed by . 3 times and then 1-3 digits
                  if (printer.type === 'network' && !printer.ipAddress.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)) {
                    alert('Invalid IP address')
                    return
                  }
                  //bt address must be in right format: a letter a-f followed by : 5 times and then a letter a-f
                  if (
                    printer.type === 'bluetooth' &&
                    !printer.bdAddress.match(/^(?:[A-F0-9]{1,3}:){5}[A-F0-9]{1,3}$/i)
                  ) {
                    alert('Invalid Bluetooth address')
                    return
                  }
                  if (!Number(printer.paperWidth) || Number(printer.paperWidth) < 0) {
                    alert('Invalid Paper Width')
                    return
                  }
                  if (printer.bdAddress) {
                    printer.bdAddress = printer.bdAddress.toUpperCase()
                  }
                  printer.id = printer.ipAddress || printer.bdAddress

                  dispatch.restaurants
                    .addPrinter({
                      locationId,
                      restaurantId,
                      printer: {...printer, paperWidth: Number(printer.paperWidth)},
                    })
                    .then(() => handleClose())
                }}>
                <FView br={5} pv={10} bg={colors.primary}>
                  <FText color="white">Add </FText>
                </FView>
              </FButton>
            </FView>
          </FView>
        </DialogActions>
      </FView>
    </Dialog>
  )
}

export default AddPrinterModal
