import React, {useRef, useState} from 'react'
import {FView, FText, FButton, IconButtonView, ButtonFillView, FTextField, ButtonOutlineView} from 'components'
import {useDispatch} from 'react-redux'
import useReactRouter from 'use-react-router'
import {parseCurrency} from 'f-utils'
import {Collapse} from '@material-ui/core'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {Add} from '@material-ui/icons'

const ProductAdd = ({categoryId}) => {
  const {match} = useReactRouter()
  const {locationId, restaurantId} = match.params
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyle()
  const [saving, setSaving] = useState(false)
  const [itemAddClicked, setItemAddClicked] = React.useState(false)

  function handleClose() {
    setItemAddClicked(false)
  }

  function handleOpen() {
    setItemAddClicked(true)
  }

  const nameRef = useRef()
  const priceRef = useRef()
  const descriptionRef = useRef()
  const isUpMd = useMediaQueryUp('md')

  function handleSave() {
    if (saving) {
      return
    }
    const newProduct = {
      activeAfter: new Date().valueOf(),
      categoryId: categoryId,
      // Old version of foodly mobile crashes when description doesn't exist (04-27-2020)
      description: '',
      name: '',
      price: 0,
      taxIds: ['cad_gst'],
    }

    const price = parseCurrency(priceRef.current.value)
    if (price < 0) {
      alert('Price cannot be negative')
      return
    }
    if (!nameRef.current.value) {
      alert('Name is required.')
      return
    }
    if (!priceRef.current.value) {
      alert('Price is required.')
      return
    }
    if (descriptionRef.current.value) {
      newProduct.description = descriptionRef.current.value
    }
    newProduct.name = nameRef.current.value
    newProduct.price = price

    setSaving(true)
    dispatch.restaurants
      .addProduct({restaurantId, locationId, product: newProduct, categoryId})
      .then(() => {
        nameRef.current.value = ''
        priceRef.current.value = ''
        descriptionRef.current.value = ''
        nameRef.current.focus()
      })
      .finally(() => {
        setSaving(false)
      })
  }

  return (
    <>
      <Collapse in={itemAddClicked}>
        <FView p={8} pl={24} bg={theme.palette.common.white} row={isUpMd}>
          <FView fill>
            <FView row>
              <FTextField
                inputRef={nameRef}
                autoFocus
                required
                label="Product Name"
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    handleClose && handleClose()
                  }
                }}
                onKeyPress={(e) => {
                  e.key === 'Enter' && handleSave()
                }}
              />
              <FView ml={16} w={90}>
                <FTextField
                  inputRef={priceRef}
                  required
                  label="Price"
                  onKeyDown={(e) => {
                    if (e.key === 'Escape') {
                      handleClose && handleClose()
                    }
                  }}
                  className={classes.numberTextField}
                  type="number"
                  onKeyPress={(e) => {
                    e.key === 'Enter' && handleSave()
                  }}
                />
              </FView>
            </FView>
            <FTextField
              inputRef={descriptionRef}
              label="Product Description"
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  handleClose && handleClose()
                }
              }}
              onKeyPress={(e) => {
                e.key === 'Enter' && handleSave()
              }}
            />
            {/* <FView mt={15}>
            <LabelInputBox label="Description:" w={600}>
              <FInput
                ref={descriptionRef}
                type="text"
                placeholder="Item Description"
                onKeyPress={(e) => {
                  e.key === 'Enter' && handleSave()
                }}
              />
            </LabelInputBox>
          </FView> */}
            {/* <FView row mt={15}>
            <FView>
              <FText h5 bold>
                Image:
              </FText>
              <FButton>
                <ButtonFillView w={165} mt={5} center row rounded>
                  <AddIcon style={iconStyles.addIcon} />
                  <FText color={colors.white} h5 bold>
                    Add Image
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
            <LabelInputBox label="Availability:" w={420} ml={15}>
              <select ref={availabilityRef} className={classes.select}>
                <option value="inStock">In Stock</option>
                <option value="outStock">Out of Stock</option>
              </select>
            </LabelInputBox>
          </FView> */}
            {/* <FView mt={15}>
            <FText h5 bold>
              Food Tags:
            </FText>
            <FView mt={15} row alignCenter>
              <FView row>
                <input type="checkbox" className={classes.checkbox} />
                <FText h5 bold>
                  Vegan
                </FText>
              </FView>
              <FView ml={25} row>
                <input type="checkbox" className={classes.checkbox} />
                <FText h5 bold>
                  Vegetarian
                </FText>
              </FView>
              <FView ml={25} row>
                <input type="checkbox" className={classes.checkbox} />
                <FText h5 bold>
                  Gluten Free
                </FText>
              </FView>
              <FView ml={25} row>
                <input type="checkbox" className={classes.checkbox} />
                <FText h5 bold>
                  Dairy Free
                </FText>
              </FView>
            </FView>
          </FView>
          <FView mt={15} row alignCenter>
            <input type="checkbox" className={classes.checkbox} />
            <FText h5 bold>
              Nut Free
            </FText>
          </FView>
          <FView mt={15} row>
            <FView>
              <KeyboardArrowUpIcon style={iconStyles.arrowIcon} />
              <KeyboardArrowDownIcon style={iconStyles.arrowIcon} />
            </FView>
            <LabelInputBox w={55} h={50} ml={5}>
              <FInput defaultValue="0" type="number" />
            </LabelInputBox>
            <FView ml={15}>
              <select className={classes.selectModifier} placeholder="Select a Modifier">
                <option value="1">Modifier 1</option>
                <option value="2">Modifier 2</option>
              </select>
            </FView>
            <FButton>
              <FView rounded w={125} h={50} bg={colors.primary} center ml={15}>
                <FText h5 bold color={colors.white}>
                  Add
                </FText>
              </FView>
            </FButton>
          </FView>
          <FView row mt={15} alignCenter>
            <AddIcon style={iconStyles.primary} />
            <FView w={5} />
            <FText color={colors.primary} h5>
              Create New Modifier Group
            </FText>
          </FView> */}
          </FView>
          <FView size={15} />
          <FView row alignCenter justifyEnd>
            <FButton onClick={handleClose} disabled={saving}>
              <ButtonOutlineView rounded selected w={125} disabled={saving}>
                <FText button primary grey500={saving}>
                  cancel
                </FText>
              </ButtonOutlineView>
            </FButton>
            <FView size={15} />
            <FButton onClick={handleSave} disabled={saving}>
              <ButtonFillView rounded w={125} disabled={saving}>
                <FText button primaryContrast>
                  {saving ? 'saving...' : 'save'}
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
          {/* <FView row alignCenter>
          <FButton onClick={() => handleClose()}>
            <FView rounded w={125} h={50} bc={colors.primary} bw={1} bg={colors.white} center mr={15}>
              <FText button primary>
                Cancel
              </FText>
            </FView>
          </FButton>
          <FView size={16} />
          <FButton onClick={() => handleSave()}>
            <FView rounded w={125} h={50} bg={colors.primary} center>
              <FText button primaryContrast>
                Save
              </FText>
            </FView>
          </FButton>
        </FView> */}
        </FView>
      </Collapse>
      <Collapse in={!itemAddClicked}>
        <FView alignEnd p={15}>
          <FButton onClick={handleOpen}>
            <IconButtonView Icon={Add} label="Add Menu Item" />
          </FButton>
        </FView>
      </Collapse>
    </>
  )
}

const useStyle = makeStyles((theme) => ({
  // Remove number up down arrows as it blocks index number from showing
  numberTextField: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
}))

export default ProductAdd
