import React, {useState, useEffect} from 'react'
import {FButton, FView, MenuTimeRow, ButtonFillView, FText, FNavBar} from 'components'
import {useDispatch, useSelector} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import MomentUtils from '@date-io/moment'
import {Divider, Checkbox, CircularProgress} from '@material-ui/core'
import {moment} from 'f-utils'
import RegionTimePicker from './RegionTimePicker'
import RegionDrawer from './RegionDrawer'
import {colors} from 'styles'

export default function AddRegion() {
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)
  const isUpSm = useMediaQueryUp('sm')
  const [hoursLT, setHoursLT] = useState({
    0: [{openLT: null, closeLT: null}],
    1: [{openLT: null, closeLT: null}],
    2: [{openLT: null, closeLT: null}],
    3: [{openLT: null, closeLT: null}],
    4: [{openLT: null, closeLT: null}],
    5: [{openLT: null, closeLT: null}],
    6: [{openLT: null, closeLT: null}],
  })

  const [polygonLatLng, setPolygonLatLng] = useState([])
  const locations = useSelector(dispatch.restaurants.getLocations)

  const unAssignedLocations = Object.values(locations).filter((location) => {
    return !location.region
  })
  const defaultChecked = {}
  for (const location of unAssignedLocations) {
    defaultChecked[location.locationId] = true
  }
  const [locationsChecked, setLocationsChecked] = useState({})
  const locationSize = Object.keys(unAssignedLocations).length
  const checkedSize = Object.keys(locationsChecked).length
  const isAllChecked = locationSize === Object.keys(locationsChecked).length
  return (
    <FView fill bg={colors.background}>
      <FView h={80}>
        <FNavBar shadow={isUpSm} />
      </FView>
      <FView fill bg={colors.background} row>
        <RegionDrawer />
        <FView pt={isUpSm ? 16 : 64} w={256}>
          <FView fill mr={16}>
            <FView ph={8}>
              <FText h6>Restaurants</FText>
            </FView>
            <FView row alignCenter>
              <Checkbox
                color="primary"
                disableRipple
                checked={checkedSize > 0}
                indeterminate={checkedSize > 0 && checkedSize < locationSize}
                onChange={(e, checked) => {
                  if (checked) {
                    setLocationsChecked(defaultChecked)
                  } else {
                    setLocationsChecked({})
                  }
                }}
              />
              <FText>All</FText>
            </FView>
            {unAssignedLocations.map((location) => (
              <FView row alignCenter key={location.locationId}>
                <Checkbox
                  color="primary"
                  disableRipple
                  checked={locationsChecked[location.locationId] ?? false}
                  onChange={(e, checked) => {
                    const newLocationsChecked = {...locationsChecked}
                    if (checked) {
                      newLocationsChecked[location.locationId] = true
                    } else {
                      delete newLocationsChecked[location.locationId]
                    }
                    setLocationsChecked(newLocationsChecked)
                  }}
                />
                <FView maxWidth={200}>
                  <FText>{location.restaurantName}</FText>
                </FView>
              </FView>
            ))}
          </FView>
        </FView>
        <Divider orientation="vertical" />
        <FView fill alignCenter pt={isUpSm ? 16 : 64}>
          <FText h6>Delivery Hours</FText>
          <RegionTimePicker newHoursLT={hoursLT} setNewHoursLT={setHoursLT} />

          <FText h6> Long, Lat</FText>
          {polygonLatLng.map((point) => {
            return <FView key={point}></FView>
          })}
          <FView fill center pv={isUpSm ? 16 : 8}>
            <FButton
              disabled={submitting}
              onClick={() => {
                setSubmitting(true)
                const updatePromises = []
                if (isAllChecked) {
                  updatePromises.push(dispatch.regions.updateRegionHours({deliveryHoursLT: hoursLT}))
                }
                for (const key in locationsChecked) {
                  if (locationsChecked[key]) {
                    const {restaurantId, locationId} = locations[key]
                    updatePromises.push(
                      dispatch.restaurants.updateDeliveryHoursLT({restaurantId, locationId, deliveryHoursLT: hoursLT}),
                    )
                  }
                }
                Promise.all(updatePromises)
                  .catch((e) => alert('Error adding new region. ' + e.message))
                  .finally(() => setSubmitting(false))
              }}>
              <ButtonFillView rounded w={250} disabled={submitting}>
                <FText body1 bold primaryContrast>
                  {submitting ? 'adding new region' : 'Add New Region'}
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
      </FView>
    </FView>
  )
}
