import React from 'react'
import {FText, FView} from 'components'
import {Redeem} from '@material-ui/icons'

export default function RewardItemView({count, name, productId, totalDiscountSubtotal, SubtractButtonElement}) {
  return (
    <FView row alignCenter minHeight={20} mb={4}>
      <FView size={20} center>
        <FText bold body1>
          {count}
        </FText>
      </FView>
      <FView size={6} />
      <Redeem fontSize="small" />
      <FView size={6} />
      <FView fill>
        <FText body1>{name}</FText>
      </FView>
      <FView w={60}>
        <FText body1 alignRight>
          {productId || !totalDiscountSubtotal ? 'FREE' : `-$${totalDiscountSubtotal.toFixed(2)}`}
        </FText>
      </FView>
      {SubtractButtonElement}
    </FView>
  )
}
