import React, {useRef, useState, useEffect} from 'react'
import {FView, FText, FTextField, ButtonFillView} from 'components'
import {colors} from 'styles'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {MenuItem} from '@material-ui/core'
import {useSelector, useDispatch} from 'react-redux'
import useReactRouter from 'use-react-router'

const PointOfSaleTableInfo = ({floorId, tableData, isCreateNew, onCancelEvent}) => {
  const dispatch = useDispatch()
  const isUpSm = useMediaQueryUp('sm')
  const {match} = useReactRouter()
  const {pointOfSaleId} = match.params

  const nameRef = useRef()
  const statusRef = useRef()
  const xPosRef = useRef()
  const yPosRef = useRef()

  const floors = useSelector(dispatch.pointOfSales.getFloors)

  const [isDataChanged, setIsDataChanged] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (tableData) {
      nameRef.current.value = tableData.name
      statusRef.current.value = tableData.status
      xPosRef.current.value = tableData.xPos
      yPosRef.current.value = tableData.yPos
    }
  }, [floorId, tableData])

  const handleTextFieldChange = (event) => {
    if (isCreateNew) {
      return
    }
    switch (event.target?.name) {
      case 'nameTextField':
        setIsDataChanged(event.target.value !== tableData.name)
        break
      case 'statusTextField':
        setIsDataChanged(event.target.value !== tableData.status)
        break
      case 'xPosTextField':
        setIsDataChanged(event.target.value !== tableData.xPos)
        break
      case 'yPosTextField':
        setIsDataChanged(event.target.value !== tableData.yPos)
        break
      default:
        break
    }
  }

  const handleUpdateTable = async () => {
    if (nameRef.current.value === null || nameRef.current.value === '') {
      alert('Please enter name')
      return
    }
    if (xPosRef.current.value === null || xPosRef.current.value === '') {
      alert('Please enter x position')
      return
    }
    if (yPosRef.current.value === null || yPosRef.current.value === '') {
      alert('Please enter y position')
      return
    }
    const tableRefData = {
      name: nameRef.current.value,
      status: statusRef.current.value,
      xPos: xPosRef.current.value,
      yPos: yPosRef.current.value,
    }
    setIsUpdating(true)
    try {
      if (isCreateNew) {
        const doc = await dispatch.pointOfSales.createNewTable(pointOfSaleId, tableRefData)
        const tableIds = floors[floorId].tableIds
        tableIds.push(doc.id)
        await dispatch.pointOfSales.updateFloor(pointOfSaleId, floorId, {tableIds})
      } else {
        await dispatch.pointOfSales.updateTable(pointOfSaleId, tableData.tableId, tableRefData)
      }
      setIsDataChanged(false)
    } catch (e) {
      alert(e.message)
    }
    setIsUpdating(false)
    onCancelEvent()
  }

  const handleDeleteTable = async () => {
    try {
      await dispatch.pointOfSales.deleteTable(pointOfSaleId, tableData.tableId)
      const tableIds = floors[floorId].tableIds
      const index = tableIds.indexOf(tableData.tableId)
      if (index > -1) {
        tableIds.splice(index, 1)
      }
      await dispatch.pointOfSales.updateFloor(pointOfSaleId, floorId, {tableIds})
    } catch (e) {
      alert(e.message)
    }
  }

  return (
    <>
      <FView W={'80%'} row bg={colors.white} fill alignCenter>
        <FView grid gridTemplateColumns={isUpSm ? '1fr 1fr 1fr 1fr' : '1fr'} columnGap={15}>
          <FTextField
            name="nameTextField"
            required
            inputRef={nameRef}
            label="Table Name"
            onChange={handleTextFieldChange}
            defaultValue={isCreateNew ? '' : tableData?.name}
          />
          <FTextField
            name="statusTextField"
            required
            select
            inputRef={statusRef}
            label="status"
            onChange={handleTextFieldChange}
            defaultValue={isCreateNew ? 'open' : tableData?.status}>
            <MenuItem key={'open'} value={'open'}>
              open
            </MenuItem>
            <MenuItem key={'closed'} value={'closed'}>
              closed
            </MenuItem>
            <MenuItem key={'reserved'} value={'reserved'}>
              reserved
            </MenuItem>
          </FTextField>
          <FTextField
            name="xPosTextField"
            required
            inputRef={xPosRef}
            type="number"
            inputProps={{min: '0', step: '1'}}
            label="X Position"
            onChange={handleTextFieldChange}
            defaultValue={isCreateNew ? '' : tableData?.xPos}
          />
          <FTextField
            name="yPosTextField"
            required
            inputRef={yPosRef}
            type="number"
            inputProps={{min: '0', step: '1'}}
            label="Y Position"
            onChange={handleTextFieldChange}
            defaultValue={isCreateNew ? '' : tableData?.yPos}
          />
        </FView>
        {isCreateNew ? (
          <FView row pl={15} fill alignEnd>
            <ButtonFillView w={100} rounded disabled={isUpdating} onClick={handleUpdateTable}>
              <FText button primaryContrast>
                {isUpdating ? 'Adding' : 'Add'}
              </FText>
            </ButtonFillView>
            <FView size={5} />
            <ButtonFillView w={100} rounded onClick={onCancelEvent}>
              <FText button primaryContrast>
                Cancel
              </FText>
            </ButtonFillView>
          </FView>
        ) : (
          <FView row pl={15} fill alignEnd>
            <ButtonFillView w={100} rounded disabled={isUpdating || !isDataChanged} onClick={handleUpdateTable}>
              <FText button primaryContrast>
                {isUpdating ? 'Editing' : 'Edit'}
              </FText>
            </ButtonFillView>
            <FView size={5} />
            <ButtonFillView w={100} rounded onClick={handleDeleteTable}>
              <FText button primaryContrast>
                Remove
              </FText>
            </ButtonFillView>
          </FView>
        )}
      </FView>
    </>
  )
}

export default PointOfSaleTableInfo
