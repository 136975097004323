import React, {useEffect, useState} from 'react'
import useReactRouter from 'use-react-router'
import {useDispatch, useSelector} from 'react-redux'
import {colors} from 'styles'
import {getSquareTokens, revokeSquareTokens} from 'f-core/src/config/firebase'
import {Switch, Dialog, DialogTitle, DialogActions} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import {FView, FText, FNavBar, FDrawer, ButtonFillView, FButton} from 'components'
import SquareAuthButton from './SquareAuthButton'
import SquareLocations from './SquareLocations'

export default function Square() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const {match, history, location} = useReactRouter()
  let {restaurantId, locationId} = match.params
  if (!restaurantId || !locationId) {
    restaurantId = dispatch.portal.getUserData().restaurantId
    locationId = dispatch.portal.getUserData().locationId
  }
  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const squareTokenId = useSelector(() => dispatch.restaurants.getSquareTokenId({locationId}))
  //&& false prevents uncontrolled -> controlled component warning
  const squareEnabled = useSelector(() => dispatch.restaurants.getSquareEnabled({locationId})) ?? false
  const isSuperAdmin = useSelector(dispatch.portal.getIsSuperAdmin)
  const [isLinking, setIsLinking] = useState(false)
  const [isUnlinking, setIsUnlinking] = useState(false)
  const [unlinkDialogue, setUnlinkDialog] = useState(false)

  const authorizationCode = new URLSearchParams(location.search).get('code')

  useEffect(() => {
    if (authorizationCode && !squareTokenId) {
      getSquareTokens({restaurantId, locationId, authorizationCode})
        .then(() => history.replace('./'))
        .catch((e) => alert(e.message))
        .finally(() => {
          setIsLinking(false)
        })
    }
  }, [authorizationCode, history, locationId, restaurantId, squareTokenId])

  useEffect(() => {
    return dispatch.restaurants.subscribeLocationPrivate({locationId, restaurantId})
  }, [dispatch.restaurants, locationId, restaurantId])

  return (
    <FView fill justifyCenter bg={colors.white} m={50}>
      <Dialog
        open={unlinkDialogue}
        onClose={() => {
          setIsUnlinking(false)
          setUnlinkDialog(false)
        }}>
        <DialogActions>
          <FView>
            <DialogTitle>Are you sure you wish to unlink your Square POS?</DialogTitle>
            <FView row center>
              <FButton
                onClick={() => {
                  setIsUnlinking(false)
                  setUnlinkDialog(false)
                }}>
                <ButtonFillView bg={theme.palette.grey[500]} pv={10} ph={20}>
                  <FText bold white>
                    No
                  </FText>
                </ButtonFillView>
              </FButton>
              <FView w={16} />
              <FButton
                onClick={() => {
                  setUnlinkDialog(false)
                  revokeSquareTokens({restaurantId, locationId})
                    .catch((e) => alert(e.message))
                    .finally(() => setIsUnlinking(false))
                }}>
                <ButtonFillView bg={theme.palette.error.main} pv={10} ph={20}>
                  <FText bold white>
                    Yes
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
          </FView>
        </DialogActions>
      </Dialog>
      <FView h={80}>
        <FNavBar shadow />
      </FView>
      <FView h={10} />
      <FView row center>
        <FDrawer locationData={locationData} />
        {squareTokenId ? (
          <FView>
            <FText bold>Your Square Account is Linked </FText>
            <FView h={10} />

            <SquareLocations locationId={locationId} restaurantId={restaurantId} />
            <FView h={10} />

            <FView row alignCenter>
              <FText bold>Push Orders To Square</FText>
              <Switch
                checked={squareEnabled}
                onChange={(e) =>
                  dispatch.restaurants.updateSquareEnabled({
                    restaurantId,
                    locationId,
                    squareEnabled: e.target.checked,
                  })
                }
                color="primary"
              />
            </FView>
            <FView h={10} />
            <FButton
              onClick={() => {
                setIsUnlinking(true)
                setUnlinkDialog(true)
              }}
              disabled={isUnlinking}>
              <ButtonFillView bg={isUnlinking ? theme.palette.grey[500] : theme.palette.error.main}>
                <FText white>Unlink</FText>
              </ButtonFillView>
            </FButton>
          </FView>
        ) : (
          <SquareAuthButton isSuperAdmin={isSuperAdmin} isLinking={isLinking} onStartLink={() => setIsLinking(true)} />
        )}
      </FView>
    </FView>
  )
}
