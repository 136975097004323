import React, {useRef} from 'react'
import moment from 'moment'
import {useDispatch} from 'react-redux'
import {colors} from 'styles'
import {getCollection, DELETE_FIELD_VALUE} from 'f-core/src/config/firebase'
import {Visibility} from '@material-ui/icons'
import {FText, FView, FTextField, FButton, ButtonFillView, ButtonOutlineView, Timer} from 'components'
import OrderItemView from './OrderItemView'

export default function UnconfirmedOrderItemView({orderData, orderId, deliveryOrderData}) {
  const dispatch = useDispatch()
  const waitTimeRef = useRef()
  const amISupporting = orderData.supportUserId === dispatch.portal.getUserId()

  return (
    <FView fill bg={colors.white}>
      <FView row>
        <OrderItemView orderData={orderData} deliveryOrderData={deliveryOrderData} />
        <FView w={2} bg={colors.lightGrey25} />
        <FView ph={15} maxWidth={150} w={'20vw'} center>
          <FView h={60} center>
            <Timer createdAt={orderData.createdAt} />
          </FView>
          <FView h={100} center>
            <FButton
              onPress={() => {
                const userId = dispatch.portal.getUserId()
                if (!userId) {
                  alert('Cannot find current userId')
                  return
                }
                if (!dispatch.portal.getIsSuperAdmin()) {
                  alert('Only Super Admin can be assigned as support')
                  return
                }
                getCollection('Orders', {restaurantId: orderData.restaurantId})
                  .doc(orderId)
                  .update({
                    supportUserId: amISupporting ? DELETE_FIELD_VALUE : userId,
                    supportUserName: amISupporting ? DELETE_FIELD_VALUE : dispatch.portal.getUserName(),
                  })
              }}>
              {amISupporting ? (
                <ButtonOutlineView rounded center w={50} selected>
                  <FText h4 primary noLineHeight>
                    <Visibility fontSize="inherit" />
                  </FText>
                </ButtonOutlineView>
              ) : (
                <ButtonFillView rounded center w={50}>
                  <FText h4 primaryContrast noLineHeight>
                    <Visibility fontSize="inherit" />
                  </FText>
                </ButtonFillView>
              )}
            </FButton>
            {orderData.supportUserName && (
              <FText body2 bold alignCenter>
                {orderData.supportUserName}
              </FText>
            )}
          </FView>
          <FView h={100} center>
            <FView w={85}>
              <FTextField
                size="small"
                inputRef={waitTimeRef}
                defaultValue={
                  orderData.orderTimeType === 'Scheduled' ? moment(orderData.estimatedCompletionTime).format('LT') : 30
                }
                label="Wait Time"
              />
            </FView>
            <FButton
              onPress={() => {
                const waitTime = waitTimeRef.current.value
                const completionTime =
                  orderData.orderTimeType === 'Scheduled'
                    ? orderData.estimatedCompletionTime
                    : new Date().valueOf() + waitTime * 60000
                return getCollection('Orders', {restaurantId: orderData.restaurantId})
                  .doc(orderId)
                  .update({completionTime, status: 'Preparing'})
              }}>
              <ButtonFillView rounded center w={90}>
                <FText body2 bold primaryContrast>
                  Confirm
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
      </FView>
      <FView h={2} bg={colors.lightGrey25} />
    </FView>
  )
}
