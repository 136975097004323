import {db, getCollection} from 'f-core/src/config/firebase'

const regionsModel = {
  state: {
    Regions: {},
  },
  reducers: {
    setRegions(state, regions) {
      return {
        ...state,
        Regions: regions,
      }
    },
  },
  actions: ({dispatch, getState}) => ({
    getRegions() {
      return getState().regions.Regions
    },
    subscribeRegions() {
      return db.collection('Regions').onSnapshot((snapshot) => {
        const regions = {}
        for (const doc of snapshot.docs) {
          regions[doc.id] = doc.data()
          regions[doc.id].id = doc.id
        }
        dispatch.regions.setRegions(regions)
      })
    },
    updateRegionHours({regionId, deliveryHoursLT}) {
      return getCollection('Regions').doc(regionId).update({deliveryHoursLT})
    },
  }),
}
export default regionsModel
