import {getDocument} from 'f-core/src/config/firebase'

const portalModel = {
  state: {
    promotions: [],
    taxes: {},
  },
  reducers: {
    setPublicData: (state, publicData) => {
      return {...state, ...publicData}
    },
  },
  actions: ({dispatch, getState}) => ({
    getPublicTaxes() {
      return getState().public.taxes
    },
    getTaxData(taxId) {
      return getState().public.taxes?.[taxId] ?? null
    },
    getSupportNumber() {
      return getState().public.supportNumber || '7787262345'
    },
    getPublicPromotionsV2() {
      return getState().public.promotionsV2 ?? []
    },
    getPublicPromotions() {
      return getState().public.promotions ?? []
    },
    getPublicProductTags() {
      return getState().public.productTags ?? {}
    },
    getPublicTagGroups() {
      return getState().public.tasteTags ?? []
    },
    subscribePublic() {
      return getDocument('public').onSnapshot((snapshot) => {
        dispatch.public.setPublicData(snapshot.data())
      })
    },
  }),
}
export default portalModel
