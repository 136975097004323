import React, {useRef} from 'react'
import {FText, FButton, FView} from 'components'
import {db, FieldValue} from 'f-core/src/config/firebase'
import {Dialog, DialogActions, DialogTitle, DialogContent, TextField} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'

export default function BlockDialog({open, onClose}) {
  const theme = useTheme()
  const userIdRef = useRef()
  const phoneNumberRef = useRef()
  const emailRef = useRef()
  const descriptionRef = useRef()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FText h5>Block User</FText>
      </DialogTitle>
      <DialogContent>
        <FView>
          <TextField label="userId" inputRef={userIdRef} />
          <TextField label="Phone Number" inputRef={phoneNumberRef} minLength={10} maxLength={10} />
          <TextField label="Email" inputRef={emailRef} />
          <TextField label="Description" inputRef={descriptionRef} required multiline />
        </FView>
      </DialogContent>
      <DialogActions>
        <FButton fill onClick={onClose}>
          <FView>
            <FText bold>Cancel</FText>
          </FView>
        </FButton>
        <FButton
          fill
          onClick={async () => {
            if (!descriptionRef.current.reportValidity()) {
              return
            }

            const userId = userIdRef.current.value
            const phoneNumber = phoneNumberRef.current.value
            const email = emailRef.current.value

            if (phoneNumber && phoneNumber.length !== 10) {
              return alert('Phone number must be 10 digits')
            }
            if (!userId && !phoneNumber && !email) {
              return alert('You must complete at least one of the inputs')
            }

            try {
              await db
                .collection('Blocklist')
                .doc()
                .set({
                  description: descriptionRef.current.value,
                  createdAt: FieldValue.serverTimestamp(),
                  ...(userId ? {userId} : {}),
                  ...(phoneNumber ? {phoneNumber} : {}),
                  ...(email ? {email} : {}),
                })
              alert('Success')
              onClose()
            } catch (e) {
              alert('Error: ' + e.message)
            }
          }}>
          <FView p={8} bg={theme.palette.primary.main}>
            <FText bold white>
              Block
            </FText>
          </FView>
        </FButton>
      </DialogActions>
    </Dialog>
  )
}
