import React from 'react'
import {FView, FNavBar, FPointOfSaleDrawer} from 'components'
import {colors} from 'styles'
import useReactRouter from 'use-react-router'
import {useMediaQueryUp} from 'f-web/src/hooks'
import PointOfSaleInfo from './PointOfSaleInfo'

const PointOfSale = () => {
  const {match} = useReactRouter()
  const isUpSm = useMediaQueryUp('sm')
  const {pointOfSaleId} = match.params

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow={isUpSm} />
        </FView>
        <FView fill bg={colors.background} row>
          <FPointOfSaleDrawer pointOfSaleId={pointOfSaleId} />
          <FView fill alignCenter pt={isUpSm ? 25 : 75} pb={25}>
            <PointOfSaleInfo pointOfSaleId={pointOfSaleId} />
          </FView>
        </FView>
      </FView>
    </FView>
  )
}

export default PointOfSale
