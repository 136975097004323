import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useTheme} from '@material-ui/core/styles'
import {InputAdornment, Checkbox, Dialog, DialogContent, DialogTitle, Divider, MenuItem, Radio} from '@material-ui/core'
import {FView, FText, FButton, FTextField} from 'components'
import {Close} from '@material-ui/icons'
import {Autocomplete} from '@material-ui/lab'
import * as utils from 'f-utils'
import {FieldValue} from 'f-core/src/config/firebase'

const REDEEM_TYPES = ['Discount', 'DiscountPercent', 'Product', 'ProductDiscount', 'ProductDiscountPercent']
const ORDER_TYPES = ['All', 'Pickup', 'Delivery', 'DineIn']

const CreateRewardDialog = ({
  allProducts,
  editData,
  handleClose,
  isEdit,
  locationId,
  open,
  restaurantId,
  rewardsList = [],
  setIsEdit,
  isOldReward, //TODO: remove after rewards migratation
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  // const [activeUntil, setActiveUntil] = useState(null)
  const [couponCode, setCouponCode] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [rewardName, setRewardName] = useState('')
  const [requiredPoints, setRequiredPoints] = useState(0)
  const [redeemType, setRedeemType] = useState('')
  const [discountAmount, setDiscountAmount] = useState(0)
  const [discountPercent, setDiscountPercent] = useState(0)
  const [qtyLimit, setQtyLimit] = useState(1)
  const [rewardItem, setRewardItem] = useState({name: null})
  const [rewardProducts, setRewardProducts] = useState([])

  const [rewardItemInput, setRewardItemInput] = useState('')
  const [minSubTotal, setMinSubTotal] = useState(0)
  const [imageUrl, setImageUrl] = useState('')
  const [orderType, setOrderType] = useState('All')
  const [qty, setQty] = useState(0)
  const [checkboxes, setCheckboxes] = useState({
    'Applied By Default': false,
    'First Order': false,
    'Requires Login': false,
    Visible: false,
    qtyUnlimited: false,
    qtyLimitUnlimited: false,
    qtyLimitPerUserUnlimited: false,
  })

  // const [groupId, setGroupId] = useState('')
  const [qtyLimitPerUser, setQtyLimitPerUser] = useState(0)

  const docId = editData?.docId
  useEffect(() => {
    if (isEdit) {
      if (editData.redeemType === 'Discount') {
        setDiscountAmount(editData.subtotalDiscountAmount || (editData.value ?? 0))
      } else if (editData.redeemType === 'DiscountPercent') {
        setDiscountPercent((editData.subtotalDiscountPercent || (editData.value ?? 0)) * 100)
      } else if (editData.redeemType === 'Product') {
        for (const productId of Object.keys(editData.productIds)) {
          setRewardItem(allProducts[productId])
          setRewardItemInput(allProducts[productId].name)
        }
      } else if (editData.redeemType === 'ProductDiscount' || editData.redeemType === 'ProductDiscountPercent') {
        const editDataProduct = []
        for (const productId of Object.keys(editData.productIds)) {
          editDataProduct.push(allProducts[productId])
        }
        setRewardProducts(editDataProduct)
      }
      if (editData.discountType === 'flat') {
        setDiscountAmount(editData.discountValue)
      } else if (editData.discountType === 'percent') {
        setDiscountPercent((editData.discountValue || 0) * 100)
      }

      setQtyLimit(editData.qtyLimit ?? 1)
      setRewardName(editData.rewardName)
      setRequiredPoints(editData.requiredPoints ?? 0)
      setRedeemType(editData.redeemType)
      setMinSubTotal(editData.minimumSubTotal)
      setImageUrl(editData.imageUrl)
      setOrderType(editData.orderType ?? 'All')
      setCheckboxes(editData.checkboxes)
      setCouponCode(editData.couponCode ?? '')
      setQty(editData.qty ?? 0)
      setQtyLimitPerUser(editData.qtyLimitPerUser ?? 0)
      // setGroupId(editData.groupId)
    } else {
      editData.couponCode = ''
    }
  }, [
    allProducts,
    editData,
    editData.checkboxes,
    editData.imageUrl,
    editData.qtyLimit,
    editData.minimumSubTotal,
    editData.orderType,
    editData.redeemType,
    editData.requiredPoints,
    editData.rewardName,
    editData.subtotalDiscountAmount,
    editData.subtotalDiscountPercent,
    editData.value,
    isEdit,
  ])
  const resetState = () => {
    setRewardName('')
    setRequiredPoints(0)
    setRedeemType('')
    setDiscountAmount(0)
    setQtyLimit(1)
    setDiscountPercent(0)
    setRewardItem({name: null})
    setRewardItemInput('')
    setMinSubTotal(0)
    setImageUrl('')
    setOrderType('All')
    setCheckboxes({
      'Applied By Default': false,
      'First Order': false,
      'Requires Login': false,

      Visible: false,
      qtyUnlimited: false,
      qtyLimitUnlimited: false,
      qtyLimitPerUserUnlimited: false,
    })
    setQty(0)
    setQtyLimitPerUser(0)
    setCouponCode('')
    // setGroupId('')
  }
  const validateReward = () => {
    if (rewardName === '') {
      alert('Please enter a reward name')
      return false
    }
    if (requiredPoints === '') {
      alert('Please enter required points')
      return false
    }
    if (redeemType === '') {
      alert('Please select a redeem type')
      return false
    }
    if (minSubTotal === '') {
      alert('Please enter a minimun sub total')
      return false
    }
    if (orderType === '') {
      alert('Please select an order type')
      return false
    }
    if (!Number.isInteger(parseInt(minSubTotal))) {
      alert('Please only enter numbers and up to one decimal in min. sub total')
      return false
    }
    if (redeemType === 'Discount' && discountAmount === '') {
      alert('Please enter a discount')
      return false
    }
    if (redeemType === 'Discount' && !Number.isInteger(parseInt(discountAmount))) {
      alert('Please enter only numbers and up to one decimal in discount amount')
      return false
    }
    if (redeemType === 'DiscountPercent' && discountPercent === '') {
      alert('Please enter a discount percent')
      return false
    }
    if (redeemType === 'DiscountPercent' && discountPercent > 100) {
      alert('Please enter a percent less than or equal to 100')
      return false
    }
    if (!checkboxes['Applied By Default'] && !checkboxes['Visible'] && !couponCode) {
      alert('Reward cannot be not visible and not applied by default without a coupon code')
      return false
    }
    if ((checkboxes['Applied By Default'] || checkboxes['Visible']) && couponCode) {
      alert('Reward cannot have a coupon code if they are Visible or Applied by Default')
      return false
    }
    if (rewardsList.includes(couponCode) && editData.couponCode !== couponCode) {
      alert('Coupon code already in use')
      return false
    }

    if (couponCode.search('/') !== -1) {
      alert('Coupon code cannot contain "/"')
      return false
    }
    if (qty < 0 && !checkboxes['qtyUnlimited']) {
      alert('Qty cannot be negative or zero')
      return false
    }
    if (redeemType === 'ProductDiscount' || redeemType === 'ProductDiscountPercent') {
      if (qtyLimit < 0 && !checkboxes['qtyLimitUnlimited']) {
        alert('Max per order cannot be negative or zero')
        return false
      }
    }
    if (qtyLimitPerUser < 0) {
      alert('Limit per User cannot be negative')
      return false
    }
    if (redeemType === 'ProductDiscount' && rewardProducts.length === 0) {
      alert('product id cannot be blank')
      return false
    }
    return true
  }
  const handleCreate = () => {
    // 1. check for empty or invalid data
    if (!validateReward()) {
      return
    }
    setIsSubmitting(true)
    // 2. convert data
    let type = ''
    if (checkboxes['First Order']) {
      type = 'FreeFirstOrderOrPoints'
    } else if (parseInt(requiredPoints) > 0) {
      type = 'Points'
    } else {
      type = 'Free'
    }
    type += '/' + redeemType
    type += !orderType || orderType === 'All' ? '' : `/${orderType}`
    const selectedOrderType = !orderType || orderType === 'All' ? null : orderType
    const imageURL =
      imageUrl === ''
        ? 'https://firebasestorage.googleapis.com/v0/b/foodlytech-dev.appspot.com/o/restaurants%2Fdemo%2F0elUkAWtumQPLLLrEAF6.jpg?alt=media&token=2df35b84-d560-4f89-bb2b-94f3f697c1df'
        : imageUrl

    // 3. call addReward with rewardData
    const rewardData = {
      appliedDefault: checkboxes['Applied By Default'],
      freeOnFirstOrder: checkboxes['First Order'],
      imageUrl: imageURL,
      minimumSubTotal: utils.currencyRounding(minSubTotal),
      points: Math.floor(requiredPoints),
      requiredFPoints: 0,
      requiredOrderType: selectedOrderType,
      requiredPoints: Math.floor(requiredPoints),
      requiresLogin: checkboxes['Requires Login'],
      rewardName,
      type,
      version: 3,
      isPublic: checkboxes['Applied By Default'] || checkboxes['Visible'],
      visible: checkboxes['Visible'],
      // activeUntil: activeUntil ? Timestamp.fromMillis(activeUntil.valueOf()) : null,
      qtyLimitPerUser: parseInt(qtyLimitPerUser),
      qtyLimit: parseInt(qtyLimit),
      qty: parseInt(qty),
      rewardId: editData.rewardId,
      couponCode,
      productIds: {},
    }

    if (checkboxes['qtyLimitUnlimited']) {
      if (isEdit) {
        rewardData.qtyLimit = FieldValue.delete()
      } else {
        delete rewardData.qtyLimit
      }
    }

    if (checkboxes['qtyLimitPerUserUnlimited']) {
      if (isEdit) {
        rewardData.qtyLimitPerUser = FieldValue.delete()
      } else {
        delete rewardData.qtyLimitPerUser
      }
    }
    if (checkboxes['qtyUnlimited']) {
      if (isEdit) {
        rewardData.qty = FieldValue.delete()
      } else {
        delete rewardData.qty
      }
    }

    // if (!!groupId) {
    //   rewardData.groupId = groupId
    // } else {
    //   if (isEdit) {
    //     delete rewardData.groupId
    //   }
    // }

    if (redeemType === 'Product') {
      rewardData.discountType = 'free'
      rewardData.discountValue = null
    } else if (redeemType === 'Discount' || redeemType === 'ProductDiscount') {
      rewardData.discountType = 'flat'
      rewardData.discountValue = parseFloat(discountAmount)
    } else if (redeemType === 'DiscountPercent' || redeemType === 'ProductDiscountPercent') {
      rewardData.discountType = 'percent'
      rewardData.discountValue = parseInt(discountPercent) / 100
    }

    if (redeemType === 'Product') {
      rewardData.subtotalDiscountAmount = 0
    } else if (redeemType === 'Discount') {
      rewardData.value = parseFloat(discountAmount)
      rewardData.subtotalDiscountAmount = parseFloat(discountAmount)
    } else if (redeemType === 'DiscountPercent') {
      rewardData.value = parseInt(discountPercent) / 100
      rewardData.subtotalDiscountPercent = parseInt(discountPercent) / 100
    } else if (redeemType === 'ProductDiscount' || redeemType === 'ProductDiscountPercent') {
      rewardData.productIds = {}
      for (const item of Object.values(rewardProducts)) {
        rewardData.productIds[item.productId] = true
      }
    }

    if (isEdit) {
      dispatch.restaurants
        .updateReward({
          restaurantId,
          locationId,
          rewardData,
          docId,
        })
        .then(() => {
          resetState()
          handleClose()
        })
        .catch((e) => alert(e.message))
        .finally(() => {
          setIsSubmitting(false)
        })
    } else {
      dispatch.restaurants
        .addReward({locationId, restaurantId, rewardData, docId: couponCode})
        .then(() => {
          resetState()
          handleClose()
        })
        .catch((e) => alert(e.message))
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={() => {
        setRewardItem({name: null})
        handleClose()
        resetState()
        setIsEdit(false)
      }}>
      <DialogTitle>
        <FView row alignCenter>
          <FView fill ml={48}>
            <FText bold h5 alignCenter>
              {isEdit ? 'Update' : 'Create'} Reward
            </FText>
          </FView>
          <FButton
            onClick={() => {
              setRewardItem({name: null})
              resetState()
              handleClose()
            }}>
            <Close />
          </FButton>
        </FView>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <LabelInput label="Reward Name" value={rewardName} setValue={setRewardName} />
        <FView row alignCenter>
          <FView w={130}>
            <FText body1>Required Points</FText>
          </FView>
          <FView fill maxWidth={200} mr={10}>
            <FTextField
              margin="dense"
              value={requiredPoints}
              type="number"
              onChange={(e) => setRequiredPoints(e.target.value.replace(/\D/g, ''))}
            />
          </FView>
          <FView w={120}>
            <FText body1>Redeem Type</FText>
          </FView>
          <FView fill>
            <FTextField
              margin="dense"
              value={redeemType}
              onChange={(e) => setRedeemType(e.target.value)}
              required
              select>
              {REDEEM_TYPES.map((redeemType) => (
                <MenuItem key={redeemType} value={redeemType}>
                  {redeemType}
                </MenuItem>
              ))}
            </FTextField>
          </FView>
        </FView>
        {redeemType === 'Discount' ? (
          <LabelInput
            maxWidth={200}
            isSubmitting={isSubmitting}
            label="DC Amount"
            value={discountAmount}
            setValue={setDiscountAmount}
            type="number"
            startAdornment="$"
          />
        ) : redeemType === 'DiscountPercent' ? (
          <FView row alignCenter>
            <FView w={120}>
              <FText body1>Discount %</FText>
            </FView>
            <FView ml={10} maxWidth={200}>
              <FTextField
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                maxWidth={200}
                margin="dense"
                type="number"
                disabled={isSubmitting}
                value={discountPercent}
                onChange={(e) => setDiscountPercent(e.target.value.replace(/\D/g, ''))}
              />
            </FView>
          </FView>
        ) : redeemType === 'Product' ? (
          <FView></FView>
        ) : (
          (redeemType === 'ProductDiscount' || redeemType === 'ProductDiscountPercent') && (
            <FView mb={6}>
              <FView row mb={-16}>
                <FView fill>
                  <FText body2 medium>
                    Target Products:
                  </FText>
                </FView>
                <FView w={120} mr={8}>
                  {redeemType === 'ProductDiscount' ? (
                    <FText body2 medium>
                      Discount Amount:
                    </FText>
                  ) : (
                    <FText body2 medium>
                      Discount %:
                    </FText>
                  )}
                </FView>
                <FView w={110}>
                  <FText body2 medium>
                    Max Per Order:
                  </FText>
                </FView>
                <FView>
                  <FText>No limit</FText>
                </FView>
              </FView>
              <React.Fragment>
                <FView row>
                  <FView fill>
                    <Autocomplete
                      margin={'dense'}
                      options={Object.values(allProducts)}
                      value={rewardProducts ?? {}}
                      onChange={(event, value) => {
                        setRewardProducts(value)
                      }}
                      getOptionLabel={(option) => {
                        return option.name ?? ''
                      }}
                      limitTags={2}
                      size="small"
                      multiple
                      disableClearable
                      autoHighlight
                      renderInput={(params) => <FTextField {...params} required />}
                    />
                  </FView>
                  <FView size={8} />
                  <FView w={100} mt={8}>
                    <FTextField
                      margin={'dense'}
                      value={redeemType === 'ProductDiscount' ? discountAmount : discountPercent}
                      onChange={(e) => {
                        redeemType === 'ProductDiscount'
                          ? setDiscountAmount(utils.currencyRounding(e.target.value))
                          : setDiscountPercent(e.target.value.replace(/\D/g, ''))
                      }}
                      type="number"
                    />
                  </FView>
                  <FView size={8} />
                  <FView w={100} mt={8}>
                    <FTextField
                      margin={'dense'}
                      disabled={checkboxes['qtyLimitUnlimited']}
                      value={qtyLimit}
                      onChange={(e) => {
                        setQtyLimit(e.target.value)
                      }}
                      type="number"
                    />
                  </FView>
                  <FView w={50} center mt={8}>
                    <Checkbox
                      checked={checkboxes['qtyLimitUnlimited']}
                      disableRipple
                      color="primary"
                      onChange={() =>
                        setCheckboxes({...checkboxes, qtyLimitUnlimited: !checkboxes['qtyLimitUnlimited']})
                      }
                    />
                  </FView>
                </FView>
              </React.Fragment>
            </FView>
          )
        )}
        <FView row alignCenter>
          <FView w={130}>
            <FText body1>Min Subtotal</FText>
          </FView>
          <FView maxWidth={200} mr={10}>
            <FTextField
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              margin="dense"
              value={minSubTotal}
              onChange={(e) => setMinSubTotal(e.target.value)}
            />
          </FView>
          <FView w={120}>
            <FText body1>Image URL</FText>
          </FView>
          <FView fill>
            <FTextField margin="dense" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
          </FView>
        </FView>
        <FView row alignCenter>
          <FView row alignCenter>
            <FView w={130}>
              <FText body1>Coupon Code</FText>
            </FView>
            <FView fill>
              <FTextField margin="dense" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
            </FView>
          </FView>
          <FView w={120} ml={10}>
            <FText>Stock Quantity</FText>
          </FView>
          <FView fill>
            <FTextField
              margin="dense"
              value={qty}
              disabled={checkboxes['qtyUnlimited']}
              onChange={(e) => {
                setQty(e.target.value)
              }}
              type="number"
              size="small"
            />
          </FView>
          <FView ml={10}>
            <FText>Unlimited Qty</FText>
          </FView>
          <Checkbox
            checked={checkboxes['qtyUnlimited']}
            disableRipple
            color="primary"
            onChange={() => setCheckboxes({...checkboxes, qtyUnlimited: !checkboxes['qtyUnlimited']})}
          />
        </FView>
        <FView row alignCenter>
          <FView w={120}>
            <FText>Limit Per User</FText>
          </FView>
          <FView maxWidth={200} ml={10}>
            <FTextField
              margin="dense"
              value={qtyLimitPerUser}
              disabled={checkboxes['qtyLimitPerUserUnlimited']}
              onChange={(e) => {
                setQtyLimitPerUser(e.target.value)
              }}
              type="number"
              size="small"
            />
          </FView>

          <FView ml={10}>
            <FText>Unlimited Qty Per User</FText>
          </FView>
          <Checkbox
            checked={checkboxes['qtyLimitPerUserUnlimited']}
            disableRipple
            color="primary"
            onChange={() =>
              setCheckboxes({...checkboxes, qtyLimitPerUserUnlimited: !checkboxes['qtyLimitPerUserUnlimited']})
            }
          />
          {/* <FView row alignCenter w={350}>
            <FView w={120}>
              <FText>Active Until</FText>
            </FView>
            <FView row alignCenter>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker format="lll" value={activeUntil} onChange={(date) => setActiveUntil(date)} />
              </MuiPickersUtilsProvider>
              <FButton onClick={() => setActiveUntil(null)}>
                <FView alignCenter>
                  <FText grey700 noLineHeight>
                    <Cancel />
                  </FText>
                </FView>
              </FButton>
            </FView>
          </FView> */}
          {/* <FView w={130}>
            <FText>Stock Quantity</FText>
          </FView>
          <FView fill maxWidth={196}>
            <FTextField
              value={qty}
              disabled={checkboxes['qtyUnlimited']}
              onChange={(e) => {
                setQty(e.target.value)
              }}
              type="number"
              size="small"
            />
          </FView>
          <FView ml={10}>
            <FText>Unlimited Qty</FText>
          </FView>
           */}
          {/* <FView row alignCenter>
            <FView pt={6}>
              <FText>Reward Group</FText>
            </FView>
            <FView pl={4} h={55} w={250} center>
              <FTextField
                value={groupId}
                onChange={(e) => {
                  setGroupId(e.target.value)
                }}
                size="small"
              />
            </FView>
          </FView> */}
        </FView>
        <FView row alignCenter justiyBetween mt={15}>
          <FView fill>
            <FText h6>Options</FText>
            <FView pl={20}>
              <OptionButton
                checkboxes={checkboxes}
                checkbox={checkboxes.appliedDefault}
                setCheckboxes={setCheckboxes}
                label="Applied By Default"
              />
              <OptionButton
                checkboxes={checkboxes}
                checkbox={checkboxes.firstOrder}
                setCheckboxes={setCheckboxes}
                label="First Order"
              />
              <OptionButton
                checkboxes={checkboxes}
                checkbox={checkboxes.requiresLogin}
                setCheckboxes={setCheckboxes}
                label="Requires Login"
              />
              <OptionButton
                checkboxes={checkboxes}
                checkbox={checkboxes.Visible}
                setCheckboxes={setCheckboxes}
                label="Visible"
              />
            </FView>
          </FView>
          <FView fill mt={20}>
            <FText body1>Order Types</FText>
            <FView pl={20}>
              {ORDER_TYPES.map((type) => (
                <FView row alignCenter key={type}>
                  <FView w={80}>
                    <FText body2>{type}</FText>
                  </FView>
                  <Radio
                    color="primary"
                    checked={orderType === type}
                    value={type}
                    disableRipple
                    onChange={(e) => setOrderType(e.target.value)}
                  />
                </FView>
              ))}
            </FView>
          </FView>
        </FView>
        <FView h={10} />
        <Divider />
        <FView mt={10} pb={16}>
          <FButton onClick={handleCreate} disabled={isSubmitting}>
            <FView fill bg={isSubmitting ? theme.palette.grey[400] : theme.palette.primary.main} center br={4} pv={10}>
              <FText white body1>
                {isEdit ? (isSubmitting ? 'Updating...' : 'Update') : isSubmitting ? 'Creating...' : 'Create'}
              </FText>
            </FView>
          </FButton>
        </FView>
      </DialogContent>
    </Dialog>
  )
}

const OptionButton = ({checkboxes, setCheckboxes, label}) => {
  return (
    <FView row alignCenter>
      <FView w={150}>
        <FText body2>{label}</FText>
      </FView>
      <FView>
        <Checkbox
          checked={checkboxes[label]}
          disableRipple
          color="primary"
          onChange={() => setCheckboxes({...checkboxes, [label]: !checkboxes[label]})}
        />
      </FView>
    </FView>
  )
}

const LabelInput = ({label, value, setValue, type, maxWidth, startAdornment, endAdornment, isSubmitting}) => {
  return (
    <FView row alignCenter>
      <FView w={120}>
        <FText body1>{label}</FText>
      </FView>
      <FView fill ml={10} maxWidth={maxWidth}>
        <FTextField
          InputProps={{
            startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
            endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
          }}
          margin="dense"
          disabled={isSubmitting}
          value={value}
          type={type}
          onChange={(e) => setValue(e.target.value)}
        />
      </FView>
    </FView>
  )
}

export default CreateRewardDialog
