import React from 'react'
import DateMomentUtils from '@date-io/moment'
import {Grid} from '@material-ui/core'
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers'
import {FView, FText, FButton, ButtonFillView} from 'components'

const FetchOrders = ({
  handleEndDateChange,
  handleOrdersFetch,
  handleStartDateChange,
  isFetching,
  selectedEndDate,
  selectedStartDate,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateMomentUtils}>
      <Grid container justify="space-around">
        <FView>
          <KeyboardDatePicker
            margin="none"
            label="Start Date"
            format="MM/DD/YYYY"
            value={selectedStartDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change start date',
            }}
          />
          <KeyboardTimePicker
            margin="normal"
            label="Start Time"
            value={selectedStartDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change start time',
            }}
          />
        </FView>
        <FView>
          <KeyboardDatePicker
            margin="none"
            label="End Date"
            format="MM/DD/YYYY"
            value={selectedEndDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change end date',
            }}
          />
          <KeyboardTimePicker
            margin="normal"
            label="End Time"
            value={selectedEndDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change end time',
            }}
          />
        </FView>
        <FButton onPress={handleOrdersFetch}>
          <ButtonFillView rounded center w={120} disabled={isFetching}>
            <FText body2 bold primaryContrast>
              {isFetching ? 'Fetching Orders...' : 'Fetch Orders'}
            </FText>
          </ButtonFillView>
        </FButton>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default FetchOrders
