import React from 'react'
import {FText, FView} from 'components'
import {KeyboardTimePicker} from '@material-ui/pickers'

export default function MenuTimeRow({day, startDate, setStartDate, endDate, setEndDate, isEditLocked}) {
  return (
    <FView row fill>
      <FView justifyCenter w={113}>
        <FText body1 bold>
          {day}
          {day ? ':' : ''}
        </FText>
      </FView>
      <FView row fill alignCenter>
        <FView w={150}>
          <KeyboardTimePicker
            autoOk
            orientation="vertical"
            value={startDate}
            onChange={setStartDate}
            minutesStep={5}
            clearable
            disabled={isEditLocked}
          />
        </FView>
        <FView size={15} />
        <FText body1>to</FText>
        <FView size={15} />
        <FView w={150}>
          <KeyboardTimePicker
            autoOk
            orientation="vertical"
            value={endDate}
            onChange={setEndDate}
            minutesStep={5}
            clearable
            disabled={isEditLocked}
          />
        </FView>
      </FView>
    </FView>
  )
}
