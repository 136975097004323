import React, {useCallback} from 'react'
import {FText, FView, FNavLink, FButton} from 'components'
import {colors} from 'styles'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {Divider, Drawer, Hidden, IconButton, List, ListItem, CircularProgress, Collapse} from '@material-ui/core'
import {Menu, Store, Restaurant, Schedule, Notifications, Receipt, Redeem} from '@material-ui/icons'
import {ArrowBack, Announcement, Equalizer, PrintOutlined, MonetizationOn, Dashboard} from '@material-ui/icons'
import {AttachMoney} from '@material-ui/icons'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useSelector, useDispatch} from 'react-redux'
import {useRouteMatch, useHistory} from 'react-router-dom'

const DRAWER_WIDTH = 240
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
}))

const FDrawer = ({locationData}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const isUpSm = useMediaQueryUp('sm')
  const theme = useTheme()
  const match = useRouteMatch([
    '/restaurant/menus/:restaurantId/:locationId',
    '/restaurant/category/:restaurantId/:locationId/:menuId',
  ])

  const menus = useSelector(() => dispatch.restaurants.getMenus({locationId: locationData && locationData.locationId}))
  const menuIds = Object.keys(menus)
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen)
  }, [mobileOpen])

  if (!locationData) {
    return (
      <FView h={'100vh'} center>
        <CircularProgress />
      </FView>
    )
  }

  const DrawerElement = (
    <FView pt={isUpSm ? 80 : 0}>
      <FView alignStart>
        <FButton onPress={history.goBack}>
          <FView row alignCenter p={15}>
            <ArrowBack />
            <FView size={8} />
            <FText>{'Back'}</FText>
          </FView>
        </FButton>
      </FView>
      <FView pv={25} pl={15}>
        <FText caption grey700>
          Restaurant Name:
        </FText>
        <FText body1>{locationData.restaurantName}</FText>
      </FView>
      <Divider />
      <FView size={10} />
      <List>
        <ListItem>
          <FNavLink to={`/restaurant/info/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Store />
              <FView size={10} />
              <FText subtitle1>Info</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <ListItem>
          <FNavLink to={`/restaurant/menus/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Restaurant />
              <FView size={10} />
              <FText subtitle1>Menus</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <Collapse in={!!match}>
          {menuIds.map((menuId) => {
            return (
              <ListItem key={menuId}>
                <FNavLink to={`/restaurant/category/${locationData.restaurantId}/${locationData.locationId}/${menuId}`}>
                  <FView row alignCenter>
                    <FView w={35} />
                    <FText subtitle2>• {menus[menuId].name}</FText>
                  </FView>
                </FNavLink>
              </ListItem>
            )
          })}
        </Collapse>
        <ListItem>
          <FNavLink to={`/restaurant/taxes/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <AttachMoney />
              <FView size={10} />
              <FText subtitle1>Taxes</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <ListItem>
          <FNavLink to={`/restaurant/receipt/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <PrintOutlined />
              <FView size={10} />
              <FText subtitle1>Printers</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <ListItem>
          <FNavLink to={`/restaurant/hours/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Schedule />
              <FView size={10} />
              <FText subtitle1>Hours</FText>
            </FView>
          </FNavLink>
        </ListItem>
        {/* <ListItem>
          <FNavLink to={`/restaurant/balances/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <ImportExport />
              <FView size={10} />
              <FText subtitle1>Balances</FText>
            </FView>
          </FNavLink>
        </ListItem> */}
        <ListItem>
          <FNavLink to={`/restaurant/orders/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Receipt />
              <FView size={10} />
              <FText subtitle1>Orders</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <ListItem>
          <FNavLink to={`/restaurant/notifications/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Notifications />
              <FView size={10} />
              <FText subtitle1>Notifications</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <ListItem>
          <FNavLink to={`/restaurant/rewards/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Redeem />
              <FView size={10} />
              <FText subtitle1>Rewards</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <ListItem>
          <FNavLink to={`/restaurant/announcement/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Announcement />
              <FView size={10} />
              <FText subtitle1>Announcement</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <ListItem>
          <FNavLink to={`/restaurant/reports/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Equalizer />
              <FView size={10} />
              <FText subtitle1>Reports</FText>
            </FView>
          </FNavLink>
        </ListItem>

        {/* /* <ListItem>
          <FNavLink to={`/menu/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Restaurant />
              <FView size={10} />
              <FText subtitle1>Business Hours</FText>
            </FView>
          </FNavLink>
        </ListItem>*/}

        <ListItem>
          <FNavLink to={`/restaurant/banking/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <MonetizationOn />
              <FView size={10} />
              <FText subtitle1>Banking</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <ListItem>
          <FNavLink to={`/restaurant/square/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Dashboard />
              <FView size={10} />
              <FText subtitle1>Square</FText>
            </FView>
          </FNavLink>
        </ListItem>

        {/* /*
        <ListItem>
          <FNavLink to={`/menu/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Restaurant />
              <FView size={10} />
              <FText subtitle1>Payments</FText>
            </FView>
          </FNavLink>
        </ListItem>
        <ListItem>
          <FNavLink to={`/menu/${locationData.restaurantId}/${locationData.locationId}`}>
            <FView row alignCenter>
              <Restaurant />
              <FView size={10} />
              <FText subtitle1>Analytics</FText>
            </FView>
          </FNavLink>
        </ListItem> */}
      </List>
    </FView>
  )

  return (
    <nav className={classes.drawer} aria-label="restaurant menu">
      <Hidden smUp implementation="css">
        <FView fixed left={0} w="100%" zIndex={theme.zIndex.drawer}>
          <FView h={1} bg={theme.palette.grey['200']} />
          <FView h={50} bg={colors.white} row alignCenter shadow10>
            <IconButton aria-label="open drawer" onClick={handleDrawerToggle}>
              <Menu />
            </IconButton>
            <FView fill>
              <FText alignCenter h6>
                {locationData.restaurantName}
              </FText>
            </FView>
          </FView>
        </FView>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {DrawerElement}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open>
          {DrawerElement}
        </Drawer>
      </Hidden>
    </nav>
  )
}

export default FDrawer
