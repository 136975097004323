import React, {useState, useRef} from 'react'
import {auth} from 'f-core/src/config/firebase'
import {FView, FText, FButton, FTextField, ButtonFillView} from 'components'
import {Dialog, DialogTitle, DialogActions, DialogContent, Divider, InputAdornment} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {useTheme} from '@material-ui/core/styles'
import {useMediaQueryUp} from 'f-web/src/hooks'
import * as api from 'models/api'

export default function AddChargeDialog({orderData, open, handleClose}) {
  const [adding, setAdding] = useState(false)
  const theme = useTheme()
  const isUpSm = useMediaQueryUp('sm')

  const nameRef = useRef()
  const priceRef = useRef()

  async function handleSubmit() {
    const name = nameRef.current.value
    const price = priceRef.current.value
    const order = orderData
    setAdding(true)
    try {
      if (!name || !price) {
        throw new Error('Please enter a name and price')
      }
      // TODO: Support bility to change taxIds (currently defaults to ['cad_gst'])
      const addedCharge = {
        order,
        price: Number(price),
        name,
      }
      const authToken = await auth.currentUser.getIdToken()
      await api.addChargeToOrder(process.env.REACT_APP_SERVER_URL, authToken, addedCharge)
      handleClose()
    } catch (e) {
      alert(e.message)
    } finally {
      setAdding(false)
    }
  }

  return (
    <Dialog fullWidth fullScreen={!isUpSm} open={open} onClose={handleClose}>
      <DialogTitle>
        <FView alignCenter row justifyBetween>
          <FView fill>
            <FText medium h5 alignCenter>
              Add Charge to #{orderData.orderNumber}
            </FText>
          </FView>
          <FButton onClick={handleClose}>
            <Close />
          </FButton>
        </FView>
      </DialogTitle>
      <DialogContent>
        <FView fill alignCenter>
          <FView w={250}>
            <FTextField inputRef={nameRef} autoFocus required label="Add Charge Name" />
            <FTextField
              inputRef={priceRef}
              required
              type="number"
              defaultValue={1}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              label="Price"
            />
          </FView>
        </FView>
      </DialogContent>
      <Divider />
      <DialogActions>
        <FView fill center bg={theme.palette.common.white} pv={isUpSm ? 15 : 5} row>
          <FButton onClick={handleSubmit}>
            <ButtonFillView rounded w={isUpSm ? 250 : 130} disabled={adding}>
              <FText button primaryContrast>
                {adding ? 'charging...' : 'add Charge'}
              </FText>
            </ButtonFillView>
          </FButton>
        </FView>
      </DialogActions>
    </Dialog>
  )
}
