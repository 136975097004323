import React, {useRef, useState, useEffect} from 'react'
import {getDocument} from 'f-core/src/config/firebase'
import {useDispatch, useSelector} from 'react-redux'
import useReactRouter from 'use-react-router'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {colors} from 'styles'
import {CircularProgress, MenuItem} from '@material-ui/core'
import {FTextField, FView, FText, FNavBar, FDrawer, ButtonFillView, FButtonA} from 'components'
import NotificationCard from './NotificationCard'

export default function Notifications() {
  const {match} = useReactRouter()
  const {restaurantId, locationId} = match.params
  const dispatch = useDispatch()
  const isUpSm = useMediaQueryUp('sm')
  const [adminReadwriteData, setAdminReadwriteData] = useState(null)
  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const privateReadwriteDoc = getDocument('admin-readwrite', {restaurantId})
  const triggerEventTypeInputRef = useRef()

  useEffect(() => {
    return privateReadwriteDoc.onSnapshot((snapshot) => {
      if (snapshot) {
        setAdminReadwriteData(snapshot.data() ?? {})
      }
    })
  }, [privateReadwriteDoc])

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow={isUpSm} />
        </FView>
        <FView fill bg={colors.background} row>
          <FDrawer locationData={locationData} />
          <FView fill alignCenter pt={isUpSm ? 25 : 75} pb={25}>
            <FView bg={colors.white} p={25} w={600} maxWidth="100%">
              <FText h5 medium gutterBottom>
                Notifications
              </FText>
              {!adminReadwriteData ? (
                <FView pv={25} mh={'auto'} w={isUpSm ? 900 : null} center>
                  <CircularProgress />
                </FView>
              ) : (
                <>
                  <FView row alignCenter>
                    <FTextField
                      margin="none"
                      inputRef={triggerEventTypeInputRef}
                      select
                      label="Select Trigger Event Type"
                      defaultValue="">
                      <MenuItem value={'ConfirmMiss1'}>Order Not Confirmed for 1 minute</MenuItem>
                      <MenuItem value={'ConfirmMiss2'}>Order Not Confirmed for 2 minutes</MenuItem>
                      <MenuItem value={'ConfirmMiss3'}>Order Not Confirmed for 3 minutes</MenuItem>
                      <MenuItem value={'NewOrder'}>New Order Details</MenuItem>
                    </FTextField>
                    <FView w={16} />
                    <FButtonA
                      onPress={() => {
                        const triggerType = triggerEventTypeInputRef.current.value
                        if (!triggerType) {
                          alert('Missing Trigger Type')
                          return
                        }
                        privateReadwriteDoc.set(
                          {
                            notifications: {
                              [new Date().valueOf()]: {
                                trigger: triggerEventTypeInputRef.current.value,
                                methods: {},
                                phoneNumber: '',
                                email: '',
                              },
                            },
                          },
                          {merge: true},
                        )
                      }}>
                      <ButtonFillView h={55} w={150} rounded>
                        <FText primaryContrast body1 bold>
                          Add
                        </FText>
                      </ButtonFillView>
                    </FButtonA>
                  </FView>
                  {Object.entries(adminReadwriteData?.notifications ?? {}).map(([notificationId, notification]) => {
                    return (
                      <NotificationCard
                        key={notificationId}
                        notificationId={notificationId}
                        notificationData={notification}
                      />
                    )
                  })}
                </>
              )}
            </FView>
          </FView>
        </FView>
      </FView>
    </FView>
  )
}
