import React from 'react'
import {FView, FText} from 'components'
import {colors} from 'styles'

const PointOfSaleItemView = ({name, restaurantId, locationId, status}) => {
  return (
    <>
      <FView row ph={15} bg={colors.white} h={115} maxHeight="100vw" alignCenter>
        <FView size={25} />
        <FView>
          <FText h6>{name}</FText>
          <FText subtitle1>
            {restaurantId}/{locationId}
          </FText>
          {status !== 'activated' && (
            <FText body1 bold error>
              Disabled
            </FText>
          )}
        </FView>
      </FView>
      <FView size={8} />
    </>
  )
}

export default PointOfSaleItemView
