import React from 'react'
import {FView, FText, FTextField} from 'components'
import Autocomplete from '@material-ui/lab/Autocomplete'

const TimeZone = ({timeZoneList, setSelectedTimeZone, defaultValue}) => {
  return (
    <>
      <FText bold body3>
        Pick A Date
      </FText>
      <FView size={16} />
      <Autocomplete
        options={timeZoneList}
        onChange={(e, values) => setSelectedTimeZone(values)}
        limitTags={1}
        disableClearable
        autoHighlight
        multiple={false}
        defaultValue={defaultValue}
        getOptionSelected={(option, value) => option.region === value.region}
        getOptionLabel={(time) => time.region}
        renderOption={(time) => time.region}
        renderInput={(params) => <FTextField {...params} variant="outlined" label="Selected TimeZone" />}
      />
      <FView size={16} />
    </>
  )
}

export default TimeZone
