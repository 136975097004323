import React, {useRef} from 'react'
import {Dialog, DialogContent, DialogTitle, IconButton, DialogActions} from '@material-ui/core'
import {FButton, FView, FText, FTextField, ButtonFillView} from 'components'
import {Close, Delete} from '@material-ui/icons'
import {useDispatch} from 'react-redux'
import * as utils from 'f-utils'

const AddAdjustmentDialog = ({orderData, addAdjustmentDialogOrderId, handleClose}) => {
  const dispatch = useDispatch()
  const nameRef = useRef()
  const amountRef = useRef()
  function checkValidity() {
    return nameRef.current.reportValidity() && amountRef.current.reportValidity()
  }
  const orderDataAdjustments = orderData?.otherAdjustments
  return (
    <Dialog
      fullWidth
      open={!!addAdjustmentDialogOrderId}
      onClose={() => {
        handleClose()
      }}>
      <DialogTitle>
        <FView row justifyBetween>
          <FView>
            Add adjustment to order
            <FView row>
              <FText> Order Number: </FText>
              <FView w={4} />
              <FText bold>#{orderData.orderNumber}</FText>
            </FView>
          </FView>

          <IconButton
            aria-label="close transfer dialog"
            onClick={() => {
              handleClose()
            }}>
            <FText black noLineHeight>
              <Close />
            </FText>
          </IconButton>
        </FView>
      </DialogTitle>

      <DialogContent>
        <FView>
          <FView row justifyBetween alignEnd>
            <FView fill>
              <FTextField inputRef={nameRef} required label="Name" />
            </FView>
            <FView w={28} />
            <FView w={150}>
              <FTextField inputRef={amountRef} type="number" inputProps={{step: 0.01}} required label="Amount" />
            </FView>
          </FView>

          <FView h={50}>
            <FButton
              onClick={async () => {
                if (!checkValidity()) {
                  return
                }

                const otherAdjustments = orderData.otherAdjustments ?? {}
                otherAdjustments[nameRef.current.value] = utils.currencyRounding(amountRef.current.value)

                try {
                  await dispatch.orders.setOrderAdjustments({
                    restaurantId: orderData.restaurantId,
                    orderId: orderData.id,
                    otherAdjustments,
                  })
                } catch (e) {
                  alert(e.message)
                }
              }}>
              <ButtonFillView rounded ph={16}>
                <FText primaryContrast body1 bold>
                  Add Adjustment
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
        <FView mt={24}>
          <FText h6>Existing adjustments</FText>
          {!!orderDataAdjustments &&
            Object.keys(orderData.otherAdjustments).map((key) => {
              return (
                <FView key={key} row alignCenter justifyBetween>
                  <FView row>
                    <FView w={200}>
                      <FText bold>{key}</FText>
                    </FView>
                    <FText>amount:</FText>
                    <FView w={8} />
                    <FText>${utils.currencyRounding(orderData.otherAdjustments[key]).toFixed(2)}</FText>
                  </FView>
                  <FView row alignEnd justifyEnd>
                    <IconButton
                      aria-label="Delete"
                      onClick={async () => {
                        delete orderDataAdjustments[key]
                        await dispatch.orders.setOrderAdjustments({
                          restaurantId: orderData.restaurantId,
                          orderId: orderData.id,
                          otherAdjustments: orderDataAdjustments,
                        })
                      }}>
                      <Delete />
                    </IconButton>
                  </FView>
                </FView>
              )
            })}
        </FView>
      </DialogContent>

      <DialogActions></DialogActions>
    </Dialog>
  )
}
export default AddAdjustmentDialog
