import React, {useState, useEffect, useCallback} from 'react'
import {FView, FText, FNavBar, FDrawer, FButton, FInput} from 'components'
import {colors} from 'styles'
import {useDispatch, useSelector} from 'react-redux'
import useReactRouter from 'use-react-router'
import {CircularProgress} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import {useMediaQueryUp} from 'f-web/src/hooks'
import CategoryAdd from './CategoryAdd'
import CategoryItem from './CategoryItem'

const Category = () => {
  const dispatch = useDispatch()
  const {match} = useReactRouter()
  const {locationId, restaurantId, menuId} = match.params
  const isUpSm = useMediaQueryUp('sm')
  const isUpMd = useMediaQueryUp('md')
  const isUpLg = useMediaQueryUp('lg')

  // Subscribe to categories
  useEffect(() => {
    return dispatch.restaurants.subscribeCategories(restaurantId, locationId)
  }, [dispatch.restaurants, restaurantId, locationId])
  // Subscribe to products
  useEffect(() => {
    return dispatch.restaurants.subscribeProducts(restaurantId, locationId)
  }, [dispatch.restaurants, restaurantId, locationId])
  useEffect(() => {
    return dispatch.restaurants.subscribeModifierGroups(restaurantId, locationId)
  }, [dispatch.restaurants, restaurantId, locationId])

  const [searchTerm, setSearchTerm] = useState('')
  const onSearchTermChange = useCallback((e) => {
    setSearchTerm(e.target.value)
  }, [])

  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const menu = useSelector(() => dispatch.restaurants.getMenus({locationId}))
  const menuCategoriesArray = useSelector(() => dispatch.restaurants.getMenuCategoriesArray({menuId, locationId}))
  const isLocationProductsLoading = useSelector(() => dispatch.restaurants.getIsLocationProductsLoaded({locationId}))
  const isLoading = !locationData || !isLocationProductsLoading

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow={isUpSm} />
        </FView>
        <FView fill row bg={colors.background}>
          <FDrawer locationData={locationData} />
          <FView fill>
            <FInput value={searchTerm} onChange={onSearchTermChange} placeholder="Search for a menu item" />
            {isLoading ? (
              <FView h={'100vh'} mh={'auto'} w={isUpLg ? 900 : null} center>
                <CircularProgress />
              </FView>
            ) : (
              <FView
                pt={isUpSm ? 40 : 80}
                pb={40}
                ph={isUpMd ? 80 : 16}
                mh={'auto'}
                w={isUpLg ? 900 : null}
                maxWidth="100vw">
                <FView alignStart>
                  <FButton to={`/restaurant/menus/${locationData.restaurantId}/${locationData.locationId}`}>
                    <FView row alignCenter>
                      <ArrowBack color="primary" />
                      <FView size={5} />
                      <FText primary body2>
                        Back To Menu
                      </FText>
                    </FView>
                  </FButton>
                  <FView size={8} />
                  <FText h5 medium gutterBottom>
                    {menu[menuId].name}
                  </FText>
                </FView>
                {menuCategoriesArray.map((categoryData, index) => {
                  return (
                    <React.Fragment key={categoryData.categoryId}>
                      <CategoryItem
                        categoryIndex={index}
                        categoryName={categoryData.name}
                        categoryDescription={categoryData.description}
                        categoryImageUrl={categoryData.imageUrl}
                        categoryId={categoryData.categoryId}
                        locationId={locationId}
                        restaurantId={restaurantId}
                        menuId={menuId}
                        searchTerm={searchTerm?.length > 1 ? searchTerm : null}
                      />
                      <FView size={16} />
                    </React.Fragment>
                  )
                })}
                <FView size={25} />
                <CategoryAdd locationId={locationId} restaurantId={restaurantId} menuId={menuId} />
              </FView>
            )}
          </FView>
        </FView>
      </FView>
    </FView>
  )
}

export default Category
