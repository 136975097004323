import React from 'react'
import {FText, FView, FButton} from 'components'
import {useSelector, useDispatch} from 'react-redux'
import {Edit, Check, Clear} from '@material-ui/icons'
import {useTheme} from '@material-ui/core/styles'
import {moment} from 'f-utils'
import {sortBy} from 'lodash'

export default function ModifierItem({locationId, modifierGroupId, handleEditModifier, handleClearModifier}) {
  const dispatch = useDispatch()
  const modifierGropuData = useSelector(() => dispatch.restaurants.getModifierGroup({locationId, modifierGroupId}))
  const theme = useTheme()

  if (!modifierGropuData) {
    return null
  }

  return (
    <>
      <FView row justifyBetween>
        <FView row alignCenter>
          <FText body1 medium>
            {modifierGropuData.title}
          </FText>
          <FView size={8} />
          <FText body2 grey500>
            {modifierGropuData.isRequired ? '(Required)' : ''}
          </FText>
          <FView size={8} />
          <FText body2 grey500>
            Select up to {modifierGropuData.maxSelection}
          </FText>
        </FView>
        <FView row>
          <FButton onClick={handleEditModifier}>
            <Edit color="primary" />
          </FButton>
          <FButton onClick={handleClearModifier}>
            <Clear color="primary" />
          </FButton>
        </FView>
      </FView>
      {sortBy(Object.entries(modifierGropuData.modifierItems), ([itemId]) => itemId).map(([k, mItem]) => {
        const itemTime = mItem?.activeAfter?.toMillis()
        const soldOutDescription =
          !itemTime || itemTime < moment().valueOf()
            ? ''
            : itemTime >= 1e14
            ? 'Out of Stock'
            : 'Out of Stock until ' + moment(itemTime).calendar()
        return (
          <FView key={k} row alignCenter maxWidth={400}>
            <FView size={30} center>
              {!!mItem.defaultValue && <Check color="action" />}
            </FView>
            <FView w={1} selfStretch bg={theme.palette.grey['300']} mr={8} />
            <FView>
              <FText body2>{mItem.name}</FText>
              {!!soldOutDescription && (
                <FText fontSize={12} error bold>
                  {soldOutDescription}
                </FText>
              )}
            </FView>
            {mItem.price !== 0 && (
              <FView fill justifyCenter>
                <FText alignRight body2>
                  ${mItem.price.toFixed(2)}
                </FText>
              </FView>
            )}
          </FView>
        )
      })}
    </>
  )
}
