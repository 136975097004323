import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useReactRouter from 'use-react-router'
import {Restaurant} from '@material-ui/icons'
import {CircularProgress} from '@material-ui/core'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {FView, FText, FNavBar, FDrawer, FButton, ButtonFillView} from 'components'
import {colors} from 'styles'

import MenuItem from './MenuItem'
import MenuEditDialog from './MenuEditDialog'
import MenuDeleteDialog from './MenuDeleteDialog'

const Menu = () => {
  const dispatch = useDispatch()
  const {match} = useReactRouter()
  const locationId = match.params.locationId
  const restaurantId = match.params.restaurantId
  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const isUpSm = useMediaQueryUp('sm')
  const isUpLg = useMediaQueryUp('lg')

  const [deleteMenuId, setDeleteMenuId] = useState('')
  const [editMenuId, setEditMenuId] = useState('')

  const menu = useSelector(() => dispatch.restaurants.getMenus({locationId}))

  useEffect(() => {
    return dispatch.restaurants.subscribeCategories(restaurantId, locationId)
  }, [dispatch.restaurants, restaurantId, locationId])

  const menuIds = Object.keys(menu)
  if (!locationData) {
    return (
      <FView h="100vh" center>
        <CircularProgress />
      </FView>
    )
  }

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow={isUpSm} />
        </FView>
        <FView row fill>
          <FDrawer locationData={locationData} />
          <FView mv={75} mh={24} fill>
            <FView row justifyBetween alignCenter>
              <FText h4 medium>
                Menus
              </FText>
              <FButton onClick={() => setEditMenuId(menuIds?.length === 0 ? 'default' : new Date().valueOf())}>
                <ButtonFillView w={165} rounded>
                  <FText button primaryContrast>
                    Create New
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
            <FView size={25} />
            {menuIds?.length === 0 ? (
              <FView center fill>
                <Restaurant style={iconStyles.noMenu} />
                <FText h6 grey400>
                  There are no menus available at the moment
                </FText>
              </FView>
            ) : (
              menuIds.map((menuId) => {
                return (
                  <React.Fragment key={menuId}>
                    <MenuItem
                      locationId={locationId}
                      menuId={menuId}
                      restaurantId={restaurantId}
                      setDeleteMenuId={setDeleteMenuId}
                      setEditMenuId={setEditMenuId}
                    />
                    <FView h={16} />
                  </React.Fragment>
                )
              })
            )}
          </FView>
        </FView>
        <MenuEditDialog
          locationId={locationId}
          menuId={editMenuId}
          open={!!editMenuId}
          restaurantId={restaurantId}
          setEditMenuId={setEditMenuId}
        />
        <MenuDeleteDialog
          locationId={locationId}
          menuId={deleteMenuId}
          open={!!deleteMenuId}
          restaurantId={restaurantId}
          setDeleteMenuId={setDeleteMenuId}
        />
      </FView>
    </FView>
  )
}

const iconStyles = {
  noMenu: {
    width: 175,
    height: 175,
    color: colors.lightGrey25,
  },
}

export default Menu
