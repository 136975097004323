import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useReactRouter from 'use-react-router'
import {colors} from 'styles'
import {Grid} from '@material-ui/core'
import {FView, FText, FNavBar, FDrawer, ButtonFillView, FButton} from 'components'
import RewardItem from './RewardItem'
import CreateRewardDialog from './CreateRewardDialog'

export default function Rewards() {
  const {match} = useReactRouter()
  const {restaurantId, locationId} = match.params
  const dispatch = useDispatch()
  const [editData, setEditData] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [isCreateRewardOpen, setIsCreateRewardOpen] = useState(false)

  const isEditLocked = useSelector(dispatch.portal.getIsAdmin)
  const allProducts = useSelector(() => dispatch.restaurants.getProductsAll({locationId}))
  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const rewards = useSelector(() => dispatch.restaurants.getRewards({locationId}))

  const oldRewards = locationData.rewards ?? {} //TODO: remove after rewards migratation
  const [isOldReward, setIsOldReward] = useState(false) //TODO: remove after rewards migratation

  useEffect(() => {
    const unsubProducts = dispatch.restaurants.subscribeProducts(restaurantId, locationId)
    const unsubRewards = dispatch.restaurants.subscribeRewards({restaurantId, locationId})
    return () => {
      unsubProducts()
      unsubRewards()
    }
  }, [dispatch.restaurants, restaurantId, locationId])

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow />
        </FView>
        <FView fill bg={colors.background} row>
          <FDrawer locationData={locationData} />
          <FView fill p={20}>
            <FView row justifyBetween alignCenter mb={20}>
              <FText h4>Rewards</FText>
              <FButton
                onClick={() => {
                  setIsCreateRewardOpen(true)
                  setIsEdit(false)
                }}
                disabled={isEditLocked}>
                <ButtonFillView w={145} rounded disabled={isEditLocked}>
                  <FText button primaryContrast>
                    Create New
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
            <Grid container spacing={1} direction="column">
              {Object.entries(rewards).map(([docId, reward]) => {
                return (
                  <Grid item xs key={docId}>
                    <RewardItem
                      allProducts={allProducts}
                      editData={editData}
                      isEdit={isEdit}
                      isEditLocked={isEditLocked}
                      locationId={locationId}
                      restaurantId={restaurantId}
                      rewardData={reward}
                      docId={docId}
                      setEditData={setEditData}
                      setIsCreateRewardOpen={setIsCreateRewardOpen}
                      setIsEdit={setIsEdit}
                      isOldReward={false} //TODO: remove after rewards migratation
                      setIsOldReward={setIsOldReward}
                    />
                  </Grid>
                )
              })}
            </Grid>
            {
              //TODO: remove after rewards migratation
            }
            <FText>Version 2 Rewards</FText>
            <Grid container spacing={1} direction="column">
              {Object.entries(oldRewards).map(([key, reward]) => {
                return (
                  <Grid item xs key={key}>
                    <RewardItem
                      allProducts={allProducts}
                      editData={editData}
                      isEdit={isEdit}
                      isEditLocked={isEditLocked}
                      locationId={locationId}
                      restaurantId={restaurantId}
                      rewardData={reward}
                      docId={key}
                      setIsCreateRewardOpen={setIsCreateRewardOpen}
                      isOldReward={true}
                      setIsOldReward={setIsOldReward}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </FView>
        </FView>
      </FView>
      <CreateRewardDialog
        allProducts={allProducts}
        editData={editData}
        handleClose={() => setIsCreateRewardOpen(false)}
        isEdit={isEdit}
        locationId={locationId}
        open={isCreateRewardOpen}
        restaurantId={restaurantId}
        setIsEdit={setIsEdit}
        rewardsList={Object.keys(rewards)}
        isOldReward={isOldReward} //TODO: remove after rewards migratation
      />
    </FView>
  )
}
