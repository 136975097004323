import React from 'react'
import {FView, FText} from 'components'
import {colors} from 'styles'
import * as utils from 'f-utils'

export default function OrderItemView({
  isRefund = false,
  count,
  name,
  price,
  selectedModifierGroupsWithDetails,
  notes,
}) {
  return (
    <FView relative fill row alignCenter mb={4}>
      {isRefund && <FView absolute top={'50%'} left={'1%'} h={1} w={'98%'} zIndex={999} bg="black" />}
      <FView selfStart rounded bg={count > 1 ? colors.primary : colors.grey} size={25} center mr={15}>
        <FText white primaryContrast={count > 1} body2 bold alignCenter lh={22}>
          x{count}
        </FText>
      </FView>
      <FView fill>
        <FView row>
          <FView fill>
            <FView row alignCenter>
              <FView fill>
                <FView row alignCenter>
                  {isRefund && (
                    <>
                      <FText body2 color="red" bold>
                        Refunded
                      </FText>
                      <FView w={8} />
                    </>
                  )}
                  <FText body2 bold>
                    {` ${name}`}
                  </FText>
                </FView>
              </FView>
              <FView row alignCenter>
                <FText body2 bold>{`$${utils
                  .getCartItemTotal({price, count, selectedModifierGroupsWithDetails})
                  ?.toFixed(2)}`}</FText>
              </FView>
            </FView>

            <FView>
              {!!selectedModifierGroupsWithDetails &&
                selectedModifierGroupsWithDetails.map((modifierGroupDetails) =>
                  Object.entries(modifierGroupDetails.selectedModifierItemsObj).map(([itemId, isSelected]) => {
                    if (!modifierGroupDetails.modifierItems || !modifierGroupDetails.modifierItems[itemId]) {
                      return null
                    }
                    const {name: optionName, price: optionPrice, defaultValue} = modifierGroupDetails.modifierItems[
                      itemId
                    ]
                    if (defaultValue === isSelected) {
                      return null
                    }
                    let isNegative = optionPrice < 0
                    if (!isSelected) {
                      isNegative = !isNegative
                    }
                    const modifierItemPriceStr =
                      optionPrice === 0 ? '' : `${isNegative ? '-' : ''}$${Math.abs(optionPrice * count).toFixed(2)}`
                    return (
                      <FView fill key={itemId} row>
                        <FView fill pl={6}>
                          <FText body2>{`• ${!isSelected ? 'No ' : ''}${optionName}`}</FText>
                        </FView>

                        <FView>
                          <FText body2>{modifierItemPriceStr}</FText>
                        </FView>
                      </FView>
                    )
                  }),
                )}
            </FView>
            {!!notes && (
              <FView>
                <FText grey800 body2 bold>
                  {'Notes: ' + notes}
                </FText>
              </FView>
            )}
          </FView>
        </FView>
      </FView>
    </FView>
  )
}
