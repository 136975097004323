import React, {useRef, useState} from 'react'
import {getDocument, DELETE_FIELD_VALUE} from 'f-core/src/config/firebase'
import {useTheme} from '@material-ui/core/styles'
import useReactRouter from 'use-react-router'
import {Checkbox, FormControlLabel} from '@material-ui/core'
import {FTextField, FView, FText, ButtonFillView, FButtonA, FButton} from 'components'
import {Delete} from '@material-ui/icons'

export default function NotificationCard({notificationData, notificationId}) {
  const {match} = useReactRouter()
  const {restaurantId} = match.params
  const privateReadwriteDoc = getDocument('admin-readwrite', {restaurantId})
  const phoneNumberRef = useRef()
  const emailRef = useRef()
  const theme = useTheme()
  const [deleting, setDeleting] = useState(false)
  const [saving, setSaving] = useState(false)

  function handleNotificationUpdate(checked, trigger, id) {
    return privateReadwriteDoc.update({
      [`notifications.${id}.methods.${trigger}`]: checked,
    })
  }

  return (
    <FView bc={theme.palette.grey['300']} bw={1} rounded p={8} mt={15}>
      <FView row fill alignCenter justifyBetween>
        <FText body1 bold gutterBottom>
          {notificationData?.trigger}
        </FText>
        <FButton
          onPress={async () => {
            if (deleting) {
              return
            }
            setDeleting(true)
            privateReadwriteDoc
              .update({
                [`notifications.${notificationId}`]: DELETE_FIELD_VALUE,
              })
              .finally(() => {
                setDeleting(false)
              })
          }}>
          <FView row alignCenter ph={10}>
            <Delete color={deleting ? 'disabled' : 'primary'} />
            <FView size={8} />
            <FText button primary grey300={deleting}>
              Delete
            </FText>
          </FView>
        </FButton>
      </FView>
      <FView row>
        <FormControlLabel
          control={
            <Checkbox
              disabled={notificationData?.trigger === 'NewOrder'}
              checked={notificationData?.methods['PhoneCall'] ?? false}
              color="primary"
            />
          }
          onChange={(e) => {
            handleNotificationUpdate(e.target.checked, 'PhoneCall', notificationId)
          }}
          label={'Phone Call'}
        />
        <FormControlLabel
          control={<Checkbox checked={notificationData?.methods['SMS'] ?? false} color="primary" />}
          onChange={(e) => {
            handleNotificationUpdate(e.target.checked, 'SMS', notificationId)
          }}
          label={'SMS'}
        />
        <FormControlLabel
          control={<Checkbox checked={notificationData?.methods['Email'] ?? false} color="primary" />}
          onChange={(e) => {
            handleNotificationUpdate(e.target.checked, 'Email', notificationId)
          }}
          label={'Email'}
        />
      </FView>
      <FView row>
        <FTextField inputRef={phoneNumberRef} defaultValue={notificationData?.phoneNumber ?? ''} label="Phone Number" />
        <FView w={16} />
        <FTextField inputRef={emailRef} defaultValue={notificationData?.email ?? ''} label="Email" />
      </FView>
      <FButtonA
        onPress={() => {
          if (saving) {
            return
          }
          setSaving(true)
          privateReadwriteDoc
            .update({
              [`notifications.${notificationId}.phoneNumber`]: phoneNumberRef.current.value,
              [`notifications.${notificationId}.email`]: emailRef.current.value,
            })
            .catch((e) => {
              alert(e.message)
            })
            .finally(() => {
              setSaving(false)
            })
        }}>
        <ButtonFillView h={55} w={250} rounded disabled={saving}>
          <FText primaryContrast body1 bold>
            Save Phone & Email
          </FText>
        </ButtonFillView>
      </FButtonA>
    </FView>
  )
}
