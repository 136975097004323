"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  calculateDeliveryFee: true,
  parseCurrency: true,
  removeNonNumericString: true,
  formatPhoneNumber: true,
  getIsRewardValid: true,
  getIsRewardRedeemable: true,
  parseRewardInfo: true,
  parseFReward: true,
  moment: true,
  isOrderOpenDetailsEqual: true,
  getOrderStatusDetailsTimestamp: true,
  getOrderStatusDetails: true,
  getOrderOpenPeriods: true,
  getIsOrderOpenHoursLT: true,
  getIsOpenForGivenTime: true,
  getIsProductActive: true,
  isMobileBrowser: true,
  currencyRounding: true,
  calculatePointsEarned: true,
  latlngDistance: true,
  getCartItemTotalWithDefaultModifiers: true,
  getCartItemTotal: true,
  getSelectedModifierGroupsWithDetails: true,
  getRasterizedText: true,
  getSelectedModifierNamesOnly: true,
  getOrderReportDetails: true,
  getPromosWithDetails: true,
  getBeforeTaxDiscount: true,
  getAfterTaxDiscount: true,
  getAddedChargeTaxes: true,
  getCartTaxes: true,
  getCartTax: true,
  getOrderCartItems: true,
  getSubtotalBeforeDiscount: true,
  getOrderCartItemsWithTaxes: true,
  getProductIdToDiscount: true
};
exports.calculateDeliveryFee = calculateDeliveryFee;
exports.getOrderOpenPeriods = getOrderOpenPeriods;
exports.currencyRounding = currencyRounding;
exports.calculatePointsEarned = calculatePointsEarned;
exports.latlngDistance = latlngDistance;
exports.getCartItemTotalWithDefaultModifiers = getCartItemTotalWithDefaultModifiers;
exports.getCartItemTotal = getCartItemTotal;
exports.getSelectedModifierGroupsWithDetails = getSelectedModifierGroupsWithDetails;
exports.getRasterizedText = getRasterizedText;
exports.getAddedChargeTaxes = getAddedChargeTaxes;
exports.getOrderCartItems = getOrderCartItems;
exports.getSubtotalBeforeDiscount = getSubtotalBeforeDiscount;
exports.getOrderCartItemsWithTaxes = getOrderCartItemsWithTaxes;
exports.getProductIdToDiscount = getProductIdToDiscount;
exports.getCartTax = exports.getCartTaxes = exports.getAfterTaxDiscount = exports.getBeforeTaxDiscount = exports.getPromosWithDetails = exports.getOrderReportDetails = exports.getSelectedModifierNamesOnly = exports.isMobileBrowser = exports.getIsProductActive = exports.getIsOpenForGivenTime = exports.getIsOrderOpenHoursLT = exports.getOrderStatusDetails = exports.getOrderStatusDetailsTimestamp = exports.isOrderOpenDetailsEqual = exports.moment = exports.parseFReward = exports.parseRewardInfo = exports.getIsRewardRedeemable = exports.getIsRewardValid = exports.formatPhoneNumber = exports.removeNonNumericString = exports.parseCurrency = void 0;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _lodash = require("lodash");

var _container = require("./container");

Object.keys(_container).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _container[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _container[key];
    }
  });
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function calculateDeliveryFee(_ref) {
  var customerCoords = _ref.customerCoords,
      deliveryFee = _ref.deliveryFee,
      deliveryFeeDiscount = _ref.deliveryFeeDiscount,
      deliveryFeeDiscountMinimumSubTotal = _ref.deliveryFeeDiscountMinimumSubTotal,
      _ref$deliveryFeeMinim = _ref.deliveryFeeMinimum,
      deliveryFeeMinimum = _ref$deliveryFeeMinim === void 0 ? 0 : _ref$deliveryFeeMinim,
      deliveryFeePerDistance = _ref.deliveryFeePerDistance,
      deliveryFreeMinimumSubTotal = _ref.deliveryFreeMinimumSubTotal,
      isDeliveryFeeVariable = _ref.isDeliveryFeeVariable,
      orderType = _ref.orderType,
      restaurantCoords = _ref.restaurantCoords,
      subTotalBeforeDiscount = _ref.subTotalBeforeDiscount;
  deliveryFeeDiscount = deliveryFeeDiscount || 0;

  if (orderType === 'Delivery') {
    var totalDeliveryFee = 0;

    if (isDeliveryFeeVariable) {
      var lat1 = customerCoords.lat,
          lng1 = customerCoords.lng;
      var lat2 = restaurantCoords.lat,
          lng2 = restaurantCoords.lng;
      var distance = latlngDistance(lat1, lng1, lat2, lng2);
      totalDeliveryFee = currencyRounding(deliveryFee + deliveryFeePerDistance * distance);

      if (totalDeliveryFee < deliveryFeeMinimum) {
        totalDeliveryFee = deliveryFeeMinimum;
      }
    } else {
      totalDeliveryFee = deliveryFee;
    }

    if (deliveryFreeMinimumSubTotal && subTotalBeforeDiscount >= deliveryFreeMinimumSubTotal) {
      totalDeliveryFee = 0;
    } else if (deliveryFeeDiscountMinimumSubTotal && subTotalBeforeDiscount >= deliveryFeeDiscountMinimumSubTotal) {
      totalDeliveryFee = currencyRounding(totalDeliveryFee - deliveryFeeDiscount);
    }

    totalDeliveryFee = Math.max(totalDeliveryFee, 0);
    return totalDeliveryFee;
  }

  return 0;
}

var parseCurrency = function parseCurrency(amount) {
  var amountNumber;

  if (amount == null) {
    return 0;
  }

  if (typeof amount === 'string') {
    amountNumber = Number(amount);
  } else {
    amountNumber = amount;
  }

  if (isNaN(amountNumber)) {
    //
    return 0;
  }

  return Math.round(amountNumber * Math.pow(10, 2)) / Math.pow(10, 2);
};

exports.parseCurrency = parseCurrency;

var removeNonNumericString = function removeNonNumericString(str) {
  if (!str) {
    return '';
  }

  return str.replace(/\D+/g, '');
};

exports.removeNonNumericString = removeNonNumericString;

var formatPhoneNumber = function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return '';
  }

  var phoneNumberStr = phoneNumber + '';
  return removeNonNumericString(phoneNumberStr).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3');
}; // Checks if given reward is currently valid


exports.formatPhoneNumber = formatPhoneNumber;

var getIsRewardValid = function getIsRewardValid(_ref2) {
  var _ref2$isLoggedIn = _ref2.isLoggedIn,
      isLoggedIn = _ref2$isLoggedIn === void 0 ? false : _ref2$isLoggedIn,
      rewardDetails = _ref2.rewardDetails,
      _ref2$isFirstOrder = _ref2.isFirstOrder,
      isFirstOrder = _ref2$isFirstOrder === void 0 ? false : _ref2$isFirstOrder,
      productsDetails = _ref2.productsDetails,
      cartSubTotal = _ref2.cartSubTotal,
      _ref2$redeemedRewardC = _ref2.redeemedRewardCount,
      redeemedRewardCount = _ref2$redeemedRewardC === void 0 ? 0 : _ref2$redeemedRewardC,
      _ref2$userPointsWithP = _ref2.userPointsWithPromoApplied,
      userPointsWithPromoApplied = _ref2$userPointsWithP === void 0 ? 0 : _ref2$userPointsWithP,
      orderType = _ref2.orderType,
      productIdToCount = _ref2.productIdToCount,
      _ref2$alreadyRedeemed = _ref2.alreadyRedeemedCount,
      alreadyRedeemedCount = _ref2$alreadyRedeemed === void 0 ? 0 : _ref2$alreadyRedeemed;
  var ERROR = {
    INVALID_REWARD: {
      valid: false,
      message: 'Reward is invalid'
    },
    NOT_LOGGED_IN: {
      valid: false,
      message: 'Invalid Reward: Please log in before redeeming rewards'
    },
    NOT_ENOUGH_POINTS: {
      valid: false,
      message: 'Invalid Reward: Not Enough Points'
    },
    FREE_REWARD_EMPTY_CART: {
      valid: false,
      message: 'Invalid Reward: Free Reward cannot be redeemed with empty cart'
    },
    DISCOUNT_EMPTY_CART: {
      valid: false,
      message: 'Invalid Reward: Discount cannot be redeemed with empty cart'
    },
    INVALID_PRODUCT: {
      valid: false,
      message: 'Invalid Reward: Product is invalid'
    },
    INVALID_ORDER_TYPE: {
      valid: false,
      message: 'Invalid Reward: Order Type is invalid'
    },
    PRODUCT_SOLDOUT: {
      valid: false,
      message: 'Invalid Reward: Product is temporarily Out Of Stock'
    },
    MAXIMUM_COUNT: {
      valid: false,
      message: 'Invalid Reward: You cannot redeem any more of this reward'
    },
    INVALID_FIELD: {
      valid: false,
      message: 'Invalid Reward: This Reward cannot be redeemed'
    },
    INVALID_COUNT: {
      valid: false,
      message: 'Invalid Reward: You must redeem 1 or more'
    },
    INVALID_SUBTOTAL: {
      valid: false,
      message: 'Invalid Reward: Subtotal cannot be negative'
    },
    PRODUCT_NOT_IN_CART: {
      valid: false,
      message: 'Discounted product not found in cart'
    },
    NOT_MET_MIN_SUBTOTAL: {
      valid: false,
      message: 'Invalid Reward: Subtotal is too low'
    },
    QTY_LESS_THAN_ONE: {
      valid: false,
      message: ' Reward not currently available'
    }
  };

  if (!rewardDetails) {
    return ERROR.INVALID_REWARD;
  }

  if (rewardDetails.requiresLogin && !isLoggedIn) {
    return ERROR.NOT_LOGGED_IN;
  }

  if (redeemedRewardCount > rewardDetails.maximumCount) {
    return ERROR.MAXIMUM_COUNT;
  }

  if (redeemedRewardCount + alreadyRedeemedCount > rewardDetails.qtyLimitPerUser) {
    return {
      valid: false,
      message: 'You have used up all your uses for this reward'
    };
  }

  if (rewardDetails.qty <= 0) {
    return ERROR.QTY_LESS_THAN_ONE;
  }

  if (redeemedRewardCount <= 0) {
    return ERROR.INVALID_COUNT;
  }

  if (cartSubTotal < rewardDetails.minimumSubTotal) {
    return ERROR.NOT_MET_MIN_SUBTOTAL;
  }

  if (cartSubTotal < 0) {
    return ERROR.INVALID_SUBTOTAL;
  }

  if (!isFirstOrder && rewardDetails.freeOnFirstOrder) {
    return {
      valid: false,
      message: 'This reward can only be used on first order'
    };
  }

  if (isFirstOrder && rewardDetails.requiredPoints === 0 && cartSubTotal <= 0) {
    return ERROR.FREE_REWARD_EMPTY_CART;
  }

  if (rewardDetails.discountSubtotal > 0 && cartSubTotal <= 0) {
    return ERROR.DISCOUNT_EMPTY_CART;
  }

  if (rewardDetails.productId && !productsDetails[rewardDetails.productId]) {
    return ERROR.INVALID_PRODUCT;
  }

  if (rewardDetails.productId && !getIsProductActive(productsDetails[rewardDetails.productId])) {
    return ERROR.PRODUCT_SOLDOUT;
  }

  if ((rewardDetails.version === 2 || rewardDetails.version === 1) && rewardDetails.redeemType === 'ProductDiscount' && !productIdToCount[rewardDetails.productId]) {
    return ERROR.PRODUCT_NOT_IN_CART;
  }

  if (rewardDetails.version === 3 && !(0, _lodash.isEmpty)(rewardDetails.productIds) && rewardDetails.discountType !== 'free' && Object.keys(rewardDetails.productIds).every(function (productId) {
    return !productIdToCount[productId];
  })) {
    return {
      valid: false,
      message: 'None of the discounted products found in cart'
    };
  }

  if (userPointsWithPromoApplied < 0) {
    return ERROR.NOT_ENOUGH_POINTS;
  }

  if (rewardDetails.requiredOrderType && rewardDetails.requiredOrderType !== orderType) {
    return ERROR.INVALID_ORDER_TYPE;
  }

  return {
    valid: true
  };
}; // Checks if given reward is redeemable.


exports.getIsRewardValid = getIsRewardValid;

var getIsRewardRedeemable = function getIsRewardRedeemable(_ref3) {
  var _ref3$isLoggedIn = _ref3.isLoggedIn,
      isLoggedIn = _ref3$isLoggedIn === void 0 ? false : _ref3$isLoggedIn,
      rewardDetails = _ref3.rewardDetails,
      _ref3$isFirstOrder = _ref3.isFirstOrder,
      isFirstOrder = _ref3$isFirstOrder === void 0 ? false : _ref3$isFirstOrder,
      productsDetails = _ref3.productsDetails,
      cartSubTotal = _ref3.cartSubTotal,
      _ref3$redeemedRewardC = _ref3.redeemedRewardCount,
      redeemedRewardCount = _ref3$redeemedRewardC === void 0 ? 0 : _ref3$redeemedRewardC,
      _ref3$userPointsWithP = _ref3.userPointsWithPromoApplied,
      userPointsWithPromoApplied = _ref3$userPointsWithP === void 0 ? 0 : _ref3$userPointsWithP,
      orderType = _ref3.orderType;

  if (!rewardDetails) {
    return {
      valid: false,
      message: 'Unsupported Reward'
    };
  }

  return getIsRewardValid({
    isLoggedIn: isLoggedIn,
    rewardDetails: rewardDetails,
    isFirstOrder: isFirstOrder,
    productsDetails: productsDetails,
    cartSubTotal: cartSubTotal,
    redeemedRewardCount: redeemedRewardCount + 1,
    userPointsWithPromoApplied: userPointsWithPromoApplied - rewardDetails.requiredPoints,
    orderType: orderType
  });
}; // Parses reward info


exports.getIsRewardRedeemable = getIsRewardRedeemable;

var parseRewardInfo = function parseRewardInfo(props) {
  var rewardInfo = props.rewardInfo,
      _props$isFirstOrder = props.isFirstOrder,
      isFirstOrder = _props$isFirstOrder === void 0 ? false : _props$isFirstOrder,
      _props$products = props.products,
      products = _props$products === void 0 ? {} : _props$products,
      cartSubTotal = props.cartSubTotal,
      cartItems = props.cartItems,
      _props$cartItemIdToPr = props.cartItemIdToPrice,
      cartItemIdToPrice = _props$cartItemIdToPr === void 0 ? {} : _props$cartItemIdToPr;
  var type = rewardInfo.type,
      value = rewardInfo.value,
      points = rewardInfo.points,
      imageUrl = rewardInfo.imageUrl,
      _rewardInfo$minimumSu = rewardInfo.minimumSubTotal,
      minimumSubTotal = _rewardInfo$minimumSu === void 0 ? 0 : _rewardInfo$minimumSu,
      _rewardInfo$appliedDe = rewardInfo.appliedDefault,
      appliedDefault = _rewardInfo$appliedDe === void 0 ? false : _rewardInfo$appliedDe,
      _rewardInfo$requiresL = rewardInfo.requiresLogin,
      requiresLogin = _rewardInfo$requiresL === void 0 ? true : _rewardInfo$requiresL,
      rewardName = rewardInfo.rewardName,
      _rewardInfo$requiredP = rewardInfo.requiredPoints,
      requiredPoints = _rewardInfo$requiredP === void 0 ? 0 : _rewardInfo$requiredP,
      _rewardInfo$freeOnFir = rewardInfo.freeOnFirstOrder,
      freeOnFirstOrder = _rewardInfo$freeOnFir === void 0 ? false : _rewardInfo$freeOnFir,
      _rewardInfo$requiredF = rewardInfo.requiredFPoints,
      requiredFPoints = _rewardInfo$requiredF === void 0 ? 0 : _rewardInfo$requiredF,
      _rewardInfo$maximumCo = rewardInfo.maximumCount,
      maximumCount = _rewardInfo$maximumCo === void 0 ? 100 : _rewardInfo$maximumCo,
      _rewardInfo$subtotalD = rewardInfo.subtotalDiscountAmount,
      subtotalDiscountAmount = _rewardInfo$subtotalD === void 0 ? 0 : _rewardInfo$subtotalD,
      _rewardInfo$subtotalD2 = rewardInfo.subtotalDiscountPercent,
      subtotalDiscountPercent = _rewardInfo$subtotalD2 === void 0 ? 0 : _rewardInfo$subtotalD2,
      _rewardInfo$totalDisc = rewardInfo.totalDiscountAmount,
      totalDiscountAmount = _rewardInfo$totalDisc === void 0 ? 0 : _rewardInfo$totalDisc,
      _rewardInfo$totalDisc2 = rewardInfo.totalDiscountPercent,
      totalDiscountPercent = _rewardInfo$totalDisc2 === void 0 ? 0 : _rewardInfo$totalDisc2,
      _rewardInfo$productId = rewardInfo.productId,
      productId = _rewardInfo$productId === void 0 ? null : _rewardInfo$productId,
      _rewardInfo$requiredO = rewardInfo.requiredOrderType,
      requiredOrderType = _rewardInfo$requiredO === void 0 ? null : _rewardInfo$requiredO,
      _rewardInfo$version = rewardInfo.version,
      version = _rewardInfo$version === void 0 ? 1 : _rewardInfo$version,
      discountType = rewardInfo.discountType,
      discountValue = rewardInfo.discountValue,
      productIds = rewardInfo.productIds,
      qtyLimit = rewardInfo.qtyLimit,
      qtyLimitPerUser = rewardInfo.qtyLimitPerUser,
      qty = rewardInfo.qty,
      rewardId = rewardInfo.rewardId;
  var count = version === 2 || version === 3 ? 1 : props.count;

  var _type$split = type.split('/'),
      _type$split2 = _slicedToArray(_type$split, 3),
      rewardWith = _type$split2[0],
      rewardFor = _type$split2[1],
      _type$split2$ = _type$split2[2],
      rewardCondition = _type$split2$ === void 0 ? 'Free' : _type$split2$;

  var rewardDetails = {
    // Descriptions
    discountType: discountType || 'undef',
    discountValue: discountValue || 0,
    productIds: productIds || {},
    qtyLimit: qtyLimit == null ? 999999999 : qtyLimit,
    qty: qty == null ? 99999999999999 : qty,
    qtyLimitPerUser: qtyLimitPerUser == null ? 99999999999999 : qtyLimitPerUser,
    rewardId: rewardId == null ? 'no reward id because it is not version 3' : rewardId,
    rewardName: rewardName,
    imageUrl: imageUrl,
    maximumCount: maximumCount,
    appliedDefault: appliedDefault,
    requiresLogin: requiresLogin,
    freeOnFirstOrder: freeOnFirstOrder,
    version: version,
    requiredPoints: requiredPoints,
    totalRequiredPoints: requiredPoints * count,
    requiredFPoints: requiredFPoints,
    subtotalDiscountAmount: subtotalDiscountAmount,
    // Applies discount before applying tax
    subtotalDiscountPercent: subtotalDiscountPercent,
    totalDiscountAmount: totalDiscountAmount,
    totalDiscountPercent: totalDiscountPercent,
    productId: productId,
    redeemType: rewardFor,
    // == Deprecated ==
    name: null,
    // Deprecated, use rewardName instead
    discountSubtotal: null,
    price: null,
    rewardValue: null,
    totalRewardValue: null,
    totalDiscountSubtotal: null,
    minimumSubTotal: minimumSubTotal,
    requiredOrderType: requiredOrderType,
    count: count
  };

  if (version === 2 || version === 3) {
    if (freeOnFirstOrder && isFirstOrder) {
      rewardDetails.requiredPoints = 0;
      rewardDetails.totalRequiredPoints = 0;
      rewardDetails.requiredFPoints = 0;
      rewardDetails.totalRequiredFPoints = 0;
    } else {
      rewardDetails.totalRequiredPoints = requiredPoints * count;
    }

    rewardDetails.name = rewardName;
    var totalDiscount = 0;

    if (version === 2) {
      totalDiscount += subtotalDiscountPercent * cartSubTotal * count;
      totalDiscount += subtotalDiscountAmount * count;
    }

    if (version === 3) {
      if (discountType === 'flat' || 'percent') {
        if ((0, _lodash.isEmpty)(productIds)) {
          if (discountType === 'flat') {
            totalDiscount = discountValue;
          }

          if (discountType === 'percent') {
            totalDiscount = discountValue * cartSubTotal;
          }
        } else {
          // sort cartItemIds by price with modifiers
          // for every promo, map cartItemId to the number of times the promo applies to it store this in applyTo
          // according to applyLimit's restrictions
          rewardDetails.count = 0;
          rewardDetails.applyTo = {};
          rewardDetails.cartItemIdToDiscount = {}; // cartItem -> (discount, count)

          var sorted = Object.entries(cartItems).sort(function (a, b) {
            var priceA = cartItemIdToPrice[a[0]] || 0;
            var priceB = cartItemIdToPrice[b[0]] || 0;
            var idealDiscA = discountType === 'flat' ? discountValue : discountValue * priceA;
            var idealDiscB = discountType === 'flat' ? discountValue : discountValue * priceB;
            var actualDiscA = Math.min(idealDiscA, priceA);
            var actualDiscB = Math.min(idealDiscB, priceB);
            var diff = actualDiscB - actualDiscA;
            return diff ? diff : a[0] > b[0] ? 1 : -1;
          });
          sorted.forEach(function (_ref4) {
            var _ref5 = _slicedToArray(_ref4, 2),
                cartItemId = _ref5[0],
                cartItem = _ref5[1];

            if (productIds[cartItem.productId]) {
              var numAvailableToApply = Math.max(Math.min(rewardDetails.qtyLimit, rewardDetails.qty, rewardDetails.qtyLimitPerUser) - rewardDetails.count, 0);
              var numToApply = Math.min(cartItem.count, numAvailableToApply);

              if (numToApply <= 0) {
                return;
              }

              rewardDetails.count += numToApply;
              rewardDetails.applyTo[cartItemId] = numToApply;
              var price = cartItemIdToPrice[cartItemId];
              var discountAmount = 0;

              if (discountType === 'flat') {
                discountAmount = discountValue * numToApply;
              }

              if (discountType === 'percent') {
                discountAmount = discountValue * price * numToApply;
              }

              discountAmount = Math.min(discountAmount, price * numToApply);
              rewardDetails.cartItemIdToDiscount[cartItemId] = discountAmount;
              totalDiscount += discountAmount;
            }
          });
        }
      }

      if (discountType === 'free') {//
      }
    }

    rewardDetails.totalDiscountSubtotal = currencyRounding(Math.min(totalDiscount, cartSubTotal));
    return rewardDetails;
  } // Type Follows With/For/Condition pattern
  // What are you redeeming WITH, what are you redeeming FOR, and in what CONDITION can you redeem it.
  // For instance, Free/Product/Birthday will indicate free product on birthday


  switch (rewardWith) {
    case 'Free':
      rewardDetails.maximumCount = 1;
      rewardDetails.requiredPoints = 0;
      rewardDetails.totalRequiredPoints = 0;
      break;

    case 'Points':
      rewardDetails.requiredPoints = points;
      rewardDetails.totalRequiredPoints = points * count;
      break;

    case 'FreeFirstOrderOrPoints':
      if (isFirstOrder) {
        rewardDetails.requiredPoints = 0;
        rewardDetails.totalRequiredPoints = 0;
        rewardDetails.maximumCount = 1;
      } else {
        rewardDetails.requiredPoints = points;
        rewardDetails.totalRequiredPoints = points * count;
      }

      break;

    default:
      return null;
  }

  switch (rewardFor) {
    case 'Product':
      var productDetails = products[value];

      if (productDetails) {
        rewardDetails.productId = productDetails.id;
        rewardDetails.name = rewardName || productDetails.name;
        rewardDetails.price = productDetails.price;
        rewardDetails.rewardValue = productDetails.price;
        rewardDetails.totalRewardValue = currencyRounding(productDetails.price * count);
      }

      break;

    case 'ProductDiscount':
      break;

    case 'DiscountPercent':
      if (minimumSubTotal == null || cartSubTotal == null) {
        return null;
      }

      rewardDetails.name = rewardName || "".concat((subtotalDiscountPercent || value) * 100, "% OFF");
      var discountPercent = parseFloat(subtotalDiscountPercent || value) || 0;
      rewardDetails.maximumCount = 1;
      rewardDetails.requiredPoints = 0;
      rewardDetails.totalRequiredPoints = 0;
      rewardDetails.subtotalDiscountPercent = discountPercent;
      rewardDetails.discountSubtotal = currencyRounding(discountPercent * cartSubTotal);
      rewardDetails.totalDiscountSubtotal = rewardDetails.discountSubtotal;
      rewardDetails.rewardValue = rewardDetails.discountSubtotal;
      rewardDetails.totalRewardValue = rewardDetails.discountSubtotal;
      break;

    case 'Discount':
      rewardDetails.name = rewardName || "$".concat(subtotalDiscountAmount || value, " OFF");
      var discount = currencyRounding(parseFloat(subtotalDiscountAmount || value) || 0);
      rewardDetails.subtotalDiscountAmount = discount;
      rewardDetails.discountSubtotal = discount;
      rewardDetails.totalDiscountSubtotal = currencyRounding(discount * count);
      rewardDetails.rewardValue = discount;
      rewardDetails.totalRewardValue = rewardDetails.totalDiscountSubtotal;
      break;

    default:
      return null;
  }

  switch (rewardCondition) {
    case 'Pickup':
    case 'Delivery':
    case 'DineIn':
      rewardDetails.requiredOrderType = rewardCondition;
      break;

    case 'MinSubTotal':
      if (minimumSubTotal == null || cartSubTotal == null) {
        return null;
      }

      break;

    case 'Free':
      break;

    default:
      return null;
  }

  if (!rewardDetails.rewardName) {
    rewardDetails.rewardName = rewardDetails.name;
  }

  return rewardDetails;
};

exports.parseRewardInfo = parseRewardInfo;

var parseFReward = function parseFReward(_ref6) {
  var fRewardId = _ref6.fRewardId,
      subtotal = _ref6.subtotal;

  switch (fRewardId) {
    case 'fPoints2500Discount':
      return {
        totalDiscountAmount: Math.min(2.5, subtotal),
        fPointsCost: 2500
      };

    case 'fPoints5000Discount':
      return {
        totalDiscountAmount: Math.min(5, subtotal),
        fPointsCost: 5000
      };

    case 'fPoints10000Discount':
      return {
        totalDiscountAmount: Math.min(10, subtotal),
        fPointsCost: 10000
      };

    case 'fPoints15000Discount':
      return {
        totalDiscountAmount: Math.min(15, subtotal),
        fPointsCost: 15000
      };

    default:
      return {
        totalDiscountAmount: 0,
        fPointsCost: 0
      };
  }
};

exports.parseFReward = parseFReward;

var moment = function moment() {
  for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
    params[_key] = arguments[_key];
  }

  return _momentTimezone.default.tz.apply(_momentTimezone.default, params.concat(['America/Vancouver']));
};

exports.moment = moment;

var isOrderOpenDetailsEqual = function isOrderOpenDetailsEqual(left, right) {
  if (!left || !right) {
    return false;
  }

  return left.isOpen === right.isOpen && (left.openMoment == null && right.openMoment == null || left.openMoment.isSame(right.openMoment));
};

exports.isOrderOpenDetailsEqual = isOrderOpenDetailsEqual;

var getOrderStatusDetailsTimestampResolver = function getOrderStatusDetailsTimestampResolver(_ref7) {
  var hoursLT = _ref7.hoursLT,
      targetTimestamp = _ref7.targetTimestamp,
      orderOpenAfter = _ref7.orderOpenAfter,
      timezone = _ref7.timezone;
  var myTargetTimestamp = (0, _momentTimezone.default)(targetTimestamp).startOf('minute').valueOf();
  return myTargetTimestamp + (0, _lodash.flatten)(Object.values(hoursLT)).map(function (e) {
    return (e.openLT || 'null') + (e.closeLT || 'null');
  }).join('') + timezone + orderOpenAfter;
};

var _getOrderStatusDetailsTimestamp = function _getOrderStatusDetailsTimestamp(_ref8) {
  var hoursLT = _ref8.hoursLT,
      targetTimestamp = _ref8.targetTimestamp,
      orderOpenAfter = _ref8.orderOpenAfter,
      timezone = _ref8.timezone;

  var targetTimeMoment = _momentTimezone.default.tz(targetTimestamp, timezone);

  var orderOpenAfterMoment = _momentTimezone.default.tz(orderOpenAfter, timezone);

  return getOrderStatusDetails({
    hoursLT: hoursLT,
    targetTimeMoment: targetTimeMoment,
    orderOpenAfterMoment: orderOpenAfterMoment,
    timezone: timezone
  });
};

var getOrderStatusDetailsTimestamp = (0, _lodash.memoize)(_getOrderStatusDetailsTimestamp, getOrderStatusDetailsTimestampResolver); // Given targetTimeMoment, find current open status, and next open, next close time.

exports.getOrderStatusDetailsTimestamp = getOrderStatusDetailsTimestamp;

var getOrderStatusDetails = function getOrderStatusDetails(_ref9) {
  var hoursLT = _ref9.hoursLT,
      targetTimeMoment = _ref9.targetTimeMoment,
      orderOpenAfterMoment = _ref9.orderOpenAfterMoment,
      _ref9$timezone = _ref9.timezone,
      timezone = _ref9$timezone === void 0 ? 'America/Vancouver' : _ref9$timezone;
  var myTargetTimeMoment = targetTimeMoment || (0, _momentTimezone.default)().tz(timezone);

  var myOrderOpenAfterMoment = orderOpenAfterMoment || _momentTimezone.default.tz(0, timezone);

  if (!hoursLT) {
    throw new Error('Missing hoursLT');
  }

  if (!myTargetTimeMoment) {
    throw new Error('Missing targetTimeMoment');
  }

  if (!myOrderOpenAfterMoment) {
    throw new Error('Missing orderOpenAfterMoment');
  } // 1. Check if targetTime is before the orderOpenAfterMoment


  if (myTargetTimeMoment.isBefore(myOrderOpenAfterMoment)) {
    // 2. Ordering is closed, find next opening hour
    var isOpenWithDetails = getIsOpenWithDetails({
      hoursLT: hoursLT,
      targetTimeMoment: myOrderOpenAfterMoment,
      timezone: timezone
    });

    if (isOpenWithDetails.isOpen) {
      // 3. Order is closed and reopens at myOrderOpenAfterMoment
      return {
        isOpen: false,
        openMoment: myOrderOpenAfterMoment
      };
    } else {
      // 4. Order is closed and reopens at isOpenWithDetails.openMoment
      return {
        isOpen: false,
        openMoment: isOpenWithDetails.openMoment
      };
    }
  } // 5. Find current and next hour block at targetTimeMoment


  return getIsOpenWithDetails({
    hoursLT: hoursLT,
    targetTimeMoment: targetTimeMoment,
    timezone: timezone
  });
}; // Return arrays of open close timestamps periods up to array that includes targetTimestamp or upcoming (range: -1 to max +7 days)
// For includes example [[111, 222], [333, 555]] for targetTimestamp of 444
// For upcoming example [[111, 222], [444, 555]] for targetTimestamp of 333


exports.getOrderStatusDetails = getOrderStatusDetails;

function getOrderOpenPeriods(_ref10) {
  var hoursLT = _ref10.hoursLT,
      _ref10$targetTimestam = _ref10.targetTimestamp,
      targetTimestamp = _ref10$targetTimestam === void 0 ? new Date().valueOf() : _ref10$targetTimestam,
      _ref10$timezone = _ref10.timezone,
      timezone = _ref10$timezone === void 0 ? 'America/Vancouver' : _ref10$timezone,
      _ref10$fullRange = _ref10.fullRange,
      fullRange = _ref10$fullRange === void 0 ? false : _ref10$fullRange;

  if (!hoursLT) {
    throw new Error('Missing hoursLT');
  }

  if (targetTimestamp == null) {
    throw new Error('Missing targetTimestamp');
  }

  var availablePeriods = []; // 1. Get week and weekday of targetTimestamp in given timezone

  var targetMoment = (0, _momentTimezone.default)(targetTimestamp).tz(timezone);
  var targetWeekday = targetMoment.weekday();
  var targetWeek = targetMoment.week();
  var targetWeekYear = targetMoment.weekYear(); // 3. Loop over hoursLT from targetWeekday - 1 + targetWeekday + 7

  for (var weekDayDeltaIndex = -1; weekDayDeltaIndex < 8; weekDayDeltaIndex++) {
    var myWeekYear = targetWeekYear;
    var myWeek = targetWeek; // moment day start from Sunday and ends on Saturday

    var myWeekday = targetWeekday + weekDayDeltaIndex; // when myWeekday is negative, we need to go back in a week and fix the myWeekday value

    if (myWeekday <= -1) {
      myWeekday += 7;
      myWeek -= 1;
    } else if (myWeekday >= 7) {
      myWeekday -= 7;
      myWeek += 1;
    } // if myWeek is negative, subtrack 1 year and reset the myWeek value


    if (myWeek < 1) {
      myWeekYear -= 1;
      myWeek = moment().year(myWeekYear).weeksInYear();
    } else if (myWeek > targetMoment.weeksInYear()) {
      myWeek = 1;
      myWeekYear += 1;
    } // 4. Loop over dayHoursLT


    var myDayHoursLT = hoursLT[myWeekday];

    if (myDayHoursLT && myDayHoursLT.length > 0) {
      var _iterator = _createForOfIteratorHelper(myDayHoursLT),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var myDayHourLT = _step.value;
          var openLT = myDayHourLT.openLT,
              closeLT = myDayHourLT.closeLT;

          if (openLT && closeLT) {
            // 5. parsing the time with 'YYYY w e LT' format
            // which translates to `weekyear() week() weekday() format('LT')`
            var momentParseFormat = 'YYYY w e LT';
            var momentYMD = myWeekYear + ' ' + myWeek + ' ' + myWeekday + ' ';

            var openMoment = _momentTimezone.default.tz(momentYMD + openLT, momentParseFormat, timezone);

            var closeMoment = _momentTimezone.default.tz(momentYMD + closeLT, momentParseFormat, timezone); // 6. Ensure closeMoment is after openMoment


            if (closeMoment.isSameOrBefore(openMoment)) {
              closeMoment.add(1, 'days');
            }

            var openTimestamp = openMoment.valueOf();
            var closeTimestamp = closeMoment.valueOf(); // 7. Add Open Period, assuming dayHoursLT is sorted and does not overlap each other

            availablePeriods.push([openTimestamp, closeTimestamp]); // 8. If fullRange is false, end as soon as end block is reached for optimization (default)

            if (!fullRange && closeTimestamp > targetTimestamp) {
              return availablePeriods;
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  }

  return availablePeriods;
}

function getIsOpenWithDetails(_ref11) {
  var hoursLT = _ref11.hoursLT,
      targetTimeMoment = _ref11.targetTimeMoment,
      _ref11$timezone = _ref11.timezone,
      timezone = _ref11$timezone === void 0 ? 'America/Vancouver' : _ref11$timezone;
  var myTargetTimeMoment = targetTimeMoment || (0, _momentTimezone.default)().tz(timezone);

  if (!hoursLT) {
    throw new Error('Missing hoursLT');
  }

  if (!myTargetTimeMoment) {
    throw new Error('Missing targetTimeMoment');
  }

  var targetTimestamp = myTargetTimeMoment.valueOf();
  var orderOpenPeriods = getOrderOpenPeriods({
    hoursLT: hoursLT,
    targetTimestamp: targetTimestamp,
    timezone: timezone
  }); // 1. Loop through order open periods

  var _iterator2 = _createForOfIteratorHelper(orderOpenPeriods),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var orderOpenPeriod = _step2.value;

      var _orderOpenPeriod = _slicedToArray(orderOpenPeriod, 2),
          openTimestamp = _orderOpenPeriod[0],
          closeTimestamp = _orderOpenPeriod[1]; // 2. Order is not open but we know when it will open again (within 6 days)


      if (targetTimestamp < openTimestamp) {
        return {
          isOpen: false,
          openMoment: (0, _momentTimezone.default)(openTimestamp).tz(timezone)
        };
      } // 3. Order is open, also return when it will close again (may not be accurate for 24 hrs)


      if (targetTimestamp >= openTimestamp && targetTimestamp < closeTimestamp) {
        return {
          isOpen: true,
          closeMoment: (0, _momentTimezone.default)(closeTimestamp).tz(timezone)
        };
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  return {
    isOpen: false,
    openMoment: null
  };
}

var getIsOrderOpenHoursLT = function getIsOrderOpenHoursLT(_ref12) {
  var hoursLT = _ref12.hoursLT,
      targetTimeMoment = _ref12.targetTimeMoment,
      _ref12$timezone = _ref12.timezone,
      timezone = _ref12$timezone === void 0 ? 'America/Vancouver' : _ref12$timezone;
  var myTargetTimeMoment = targetTimeMoment || (0, _momentTimezone.default)().tz(timezone);

  if (!hoursLT || !myTargetTimeMoment) {
    return false;
  }

  var targetTimestamp = myTargetTimeMoment.valueOf();
  var orderOpenPeriods = getOrderOpenPeriods({
    hoursLT: hoursLT,
    targetTimestamp: targetTimestamp,
    timezone: timezone
  });

  var _iterator3 = _createForOfIteratorHelper(orderOpenPeriods),
      _step3;

  try {
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      var orderOpenPeriod = _step3.value;

      var _orderOpenPeriod2 = _slicedToArray(orderOpenPeriod, 2),
          openTimestamp = _orderOpenPeriod2[0],
          closeTimestamp = _orderOpenPeriod2[1];

      if (targetTimestamp >= openTimestamp && targetTimestamp < closeTimestamp) {
        return true;
      }
    }
  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }

  return false;
}; // Deprecated in favour of getIsOrderOpenHoursLT


exports.getIsOrderOpenHoursLT = getIsOrderOpenHoursLT;

var getIsOpenForGivenTime = function getIsOpenForGivenTime(_ref13) {
  var hours = _ref13.hours,
      targetDate = _ref13.targetDate,
      time = _ref13.time,
      waitTime = _ref13.waitTime;

  // Still being used in menu hours
  //
  if (!hours || !targetDate || !time) {
    return false;
  }

  var weekday = targetDate.weekday(); // get time from hours

  var open = (0, _lodash.get)(hours[weekday], 'open');
  var close = (0, _lodash.get)(hours[weekday], 'close');

  if (!open || !close) {
    return false;
  }

  var openMoment = _momentTimezone.default.tz(open, 'America/Vancouver');

  var closeMoment = _momentTimezone.default.tz(close, 'America/Vancouver');

  var openTime = _momentTimezone.default.tz(targetDate, 'America/Vancouver').hours(openMoment.hours()).minutes(openMoment.minutes()).seconds(0);

  var closeTime = _momentTimezone.default.tz(targetDate, 'America/Vancouver').hours(closeMoment.hours()).minutes(closeMoment.minutes()).seconds(0);

  var orderCloseMoment = _momentTimezone.default.tz(closeTime, 'America/Vancouver').subtract(waitTime, 'minutes');

  if (openTime.isSame(closeTime)) {
    return false;
  }

  var todayOrderClose;

  if (openTime.isBefore(closeTime)) {
    todayOrderClose = orderCloseMoment;
  } else {
    todayOrderClose = _momentTimezone.default.tz(orderCloseMoment, 'America/Vancouver').add(1, 'days');
  }

  return time.isBetween(openTime, todayOrderClose, '[]');
};

exports.getIsOpenForGivenTime = getIsOpenForGivenTime;

var getIsProductActive = function getIsProductActive(productDetails) {
  if (!productDetails) {
    // Product doens't exist
    return false;
  }

  var qty = productDetails.qty;

  if (typeof qty === 'number' && qty < 1) {
    return false;
  } // Since Dec 16 2018, active property is deprecated in favor of activeAfter


  var activeAfter = (0, _lodash.get)(productDetails, 'activeAfter');

  if (activeAfter) {
    return new Date().valueOf() > activeAfter;
  }

  var active = (0, _lodash.get)(productDetails, 'active'); // If active property doesn't exist, this means product is active

  if (active === undefined || active === true) {
    return true;
  }

  if (active === false) {
    return false;
  } // As a fallback


  return true;
};

exports.getIsProductActive = getIsProductActive;

var isMobileBrowser = function isMobileBrowser() {
  var check = false;

  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);

  return check;
};

exports.isMobileBrowser = isMobileBrowser;

function currencyRounding(amount) {
  return Math.round(amount * Math.pow(10, 2)) / Math.pow(10, 2);
}

function calculatePointsEarned(subTotal, pointsInterval, pointsPerInterval) {
  return Math.max(Math.floor(Math.floor(subTotal / pointsInterval) * pointsPerInterval), 0);
} // Return approx distance between two points in km


function latlngDistance(lat1, lon1, lat2, lon2) {
  var p = 0.017453292519943295; // Math.PI / 180

  var c = Math.cos;
  var a = 0.5 - c((lat2 - lat1) * p) / 2 + c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p)) / 2;
  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

function getCartItemTotalWithDefaultModifiers(_ref14) {
  var price = _ref14.price,
      count = _ref14.count,
      selectedModifierGroupsWithDetails = _ref14.selectedModifierGroupsWithDetails;

  if (!selectedModifierGroupsWithDetails) {
    return Math.max(price * count, 0);
  }

  var modifierTotalPrice = selectedModifierGroupsWithDetails.reduce(function (prev, modifierGroupDetails) {
    if (!modifierGroupDetails || !modifierGroupDetails.selectedModifierItemsObj) {
      return prev;
    }

    return prev + Object.entries(modifierGroupDetails.selectedModifierItemsObj).reduce(function (prev, _ref15) {
      var _ref16 = _slicedToArray(_ref15, 1),
          modifierItemId = _ref16[0];

      if (modifierGroupDetails.modifierItems[modifierItemId].defaultValue) {
        var modifier_price = modifierGroupDetails.modifierItems[modifierItemId].price;
        prev += modifier_price;
      }

      return prev;
    }, 0);
  }, 0);
  return Math.max((price + modifierTotalPrice) * count, 0);
}

function getCartItemTotal(_ref17) {
  var price = _ref17.price,
      count = _ref17.count,
      selectedModifierGroupsWithDetails = _ref17.selectedModifierGroupsWithDetails;

  if (!selectedModifierGroupsWithDetails) {
    return Math.max(price * count, 0);
  }

  var modifierTotalPrice = selectedModifierGroupsWithDetails.reduce(function (prev, modifierGroupDetails) {
    if (!modifierGroupDetails || !modifierGroupDetails.selectedModifierItemsObj) {
      return prev;
    }

    return prev + Object.entries(modifierGroupDetails.selectedModifierItemsObj).reduce(function (prev, _ref18) {
      var _ref19 = _slicedToArray(_ref18, 2),
          modifierItemId = _ref19[0],
          isSelected = _ref19[1];

      if (!modifierGroupDetails.modifierItems[modifierItemId]) {
        return prev;
      }

      return prev + (isSelected ? modifierGroupDetails.modifierItems[modifierItemId].price : 0);
    }, 0);
  }, 0);
  return Math.max((price + modifierTotalPrice) * count, 0);
}

function getSelectedModifierGroupsWithDetails(_ref20) {
  var selectedModifiers = _ref20.selectedModifiers,
      modifierGroups = _ref20.modifierGroups;
  return Object.entries(selectedModifiers).reduce(function (prev, _ref21) {
    var _ref22 = _slicedToArray(_ref21, 2),
        modifierGroupId = _ref22[0],
        selectedModifierItemsObj = _ref22[1];

    if (modifierGroups[modifierGroupId]) {
      prev.push(_objectSpread(_objectSpread({}, modifierGroups[modifierGroupId]), {}, {
        selectedModifierItemsObj: selectedModifierItemsObj
      }));
    }

    return prev;
  }, []);
}

function getStringMemorySize(_string) {
  var codePoint,
      accum = 0;

  for (var stringIndex = 0, endOfString = _string.length; stringIndex < endOfString; stringIndex++) {
    codePoint = _string.charCodeAt(stringIndex);

    if (codePoint < 0x100) {
      accum += 1;
      continue;
    }

    if (codePoint < 0x10000) {
      accum += 1.5;
      continue;
    }

    if (codePoint < 0x1000000) {
      accum += 3;
    } else {
      accum += 4;
    }
  }

  return accum * 2;
}

function getStringLength(s) {
  return Math.ceil(getStringMemorySize(s) / 2);
}

function getRasterizedText(_ref23) {
  var addedCharges = _ref23.addedCharges,
      orderTimeType = _ref23.orderTimeType,
      scheduledOrderTimestamp = _ref23.scheduledOrderTimestamp,
      completionTime = _ref23.completionTime,
      createdAt = _ref23.createdAt,
      locationAddress = _ref23.locationAddress,
      locationPhoneNumber = _ref23.locationPhoneNumber,
      name = _ref23.name,
      email = _ref23.email,
      deliveryFee = _ref23.deliveryFee,
      deliveryAddress = _ref23.deliveryAddress,
      deliveryUnit = _ref23.deliveryUnit,
      deliveryInstructions = _ref23.deliveryInstructions,
      notes = _ref23.notes,
      orderCartItems = _ref23.orderCartItems,
      orderNumber = _ref23.orderNumber,
      orderType = _ref23.orderType,
      paymentMethod = _ref23.paymentMethod,
      paymentStatus = _ref23.paymentStatus,
      phoneNumber = _ref23.phoneNumber,
      restaurantName = _ref23.restaurantName,
      rewards = _ref23.rewards,
      subTotal = _ref23.subTotal,
      taxAmount = _ref23.taxAmount,
      taxAmounts = _ref23.taxAmounts,
      tipAmount = _ref23.tipAmount,
      total = _ref23.total,
      timezone = _ref23.timezone;

  var myMoment = function myMoment() {
    for (var _len2 = arguments.length, params = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      params[_key2] = arguments[_key2];
    }

    return _momentTimezone.default.tz.apply(_momentTimezone.default, params.concat([timezone || 'America/Vancouver']));
  };

  var prepareByTimestamp = orderTimeType === 'Scheduled' ? scheduledOrderTimestamp : completionTime;

  function getTaxString() {
    if (taxAmounts) {
      if ((0, _lodash.isEmpty)(taxAmounts)) {
        return "                  Tax".concat(textEnd('$0.00', 21));
      }

      var taxString = '';

      for (var _i2 = 0, _Object$values = Object.values(taxAmounts); _i2 < _Object$values.length; _i2++) {
        var tax = _Object$values[_i2];
        taxString += "                  ".concat(tax.name).concat(textEnd('$' + tax.taxAmount.toFixed(2), 18 + tax.name.length), "\n");
      }

      return taxString;
    } else {
      return "                  Tax".concat(textEnd('$' + taxAmount.toFixed(2), 21));
    }
  }

  return "".concat(textCenter(restaurantName), "\n").concat(textCenter(locationAddress), "\n").concat(textCenter(locationPhoneNumber), "\n--------------------------------------\n").concat(textCenter(orderType + ' Order: #' + orderNumber), "\n").concat(textCenter('Payment Method: ' + paymentMethod), "\n").concat(textCenter('Payment Status: ' + paymentStatus), "\n").concat(textCenter('Created At: ' + myMoment(createdAt).format('MM/DD/YYYY HH:mm')), "\n").concat(textCenter('Order Time Type: ' + orderTimeType), "\n").concat(textCenter('Prepare By: ' + (prepareByTimestamp ? myMoment(prepareByTimestamp).format('MM/DD/YYYY HH:mm') : 'Not Confirmed Yet...')), "\n--------------------------------------\n").concat(Object.values(orderCartItems).reduce(function (prev, cur) {
    var productStr = '';
    var countAndProductName = "".concat(cur.count, " ").concat(cur.name).substring(0, 32);
    productStr += countAndProductName + textEnd('$' + getCartItemTotal({
      price: cur.price,
      count: cur.count,
      selectedModifierGroupsWithDetails: cur.selectedModifierGroupsWithDetails
    }).toFixed(2), getStringLength(countAndProductName)) + '\n';
    var selectedModifierNames = getSelectedModifierNames(cur.selectedModifierGroupsWithDetails, cur.count);

    var _iterator4 = _createForOfIteratorHelper(selectedModifierNames),
        _step4;

    try {
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        var modifierName = _step4.value;
        productStr += modifierName + '\n';
      }
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }

    return prev + productStr;
  }, '')).concat(getAddedChargesNames(addedCharges).reduce(function (prev, addedChargeNamePrice) {
    return prev + addedChargeNamePrice + '\n';
  }, '')).concat(Object.values(rewards).reduce(function (prev, cur) {
    var countAndRewardName = "".concat(cur.count, " (R)").concat(cur.name).substring(0, 32);
    return prev + countAndRewardName + textEnd(cur.totalDiscountSubtotal ? '-$' + cur.totalDiscountSubtotal.toFixed(2) : 'FREE', getStringLength(countAndRewardName)) + '\n';
  }, ''), "                              --------\n                  Subtotal").concat(textEnd('$' + subTotal.toFixed(2), 26), "\n                  Delivery Fee").concat(textEnd('$' + deliveryFee.toFixed(2), 30), "\n").concat(getTaxString(), "\n                  Tip").concat(textEnd('$' + tipAmount.toFixed(2), 21), "\n                              --------\n                  Total").concat(textEnd('$' + total.toFixed(2), 23), "\n\n").concat(textCenter(notes), "\n").concat(textCenter("".concat(name, ", ").concat(formatPhoneNumber(phoneNumber))), "\n").concat(textCenter(email || ''), "\n").concat(textCenter(deliveryAddress || ''), "\n").concat(deliveryUnit ? textCenter('Unit: ' + deliveryUnit) : '', "\n").concat(textCenter(deliveryInstructions || ''), "\n\n").concat(textCenter('Thank you'), "\n").concat(textCenter('Powered By Foodly.ca'), "\n");
}

function textCenter(text) {
  var startIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var textLength = getStringLength(text); // Assume UTF8

  if (textLength > 38 - startIndex) {
    return text; // get overflow text
    // const firstText = text.substring(0, text.length - startIndex - (text.length % 38))
    // const overflow = text.substr(-(text.length % 38) - startIndex)
    //
    //
    //
    // return firstText + ' '.repeat((38 - startIndex - overflow.length) / 2) + overflow
  }

  return ' '.repeat((38 - startIndex - textLength) / 2) + text;
}

function textEnd(text) {
  var startIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var textLength = getStringLength(text); // cannot exceed 38

  if (textLength >= 38 - startIndex) {
    return text;
  }

  return ' '.repeat(38 - textLength - startIndex) + text;
}

var getAddedChargesNames = function getAddedChargesNames(addedCharges) {
  if (!addedCharges) {
    return [];
  }

  return addedCharges.map(function (charge) {
    if (!charge) {
      return '';
    }

    var addedChargeName = charge.name || 'No Name Charge';
    var addedChargePriceStr = (charge.price || 0).toFixed(2);
    var addedChargeNamePrice = addedChargeName + textEnd(addedChargePriceStr, getStringLength(addedChargeName));
    return addedChargeNamePrice;
  });
};

var getSelectedModifierNamesOnly = function getSelectedModifierNamesOnly(selectedModifierGroupsWithDetails) {
  var names = [];
  selectedModifierGroupsWithDetails.forEach(function (modifierGroupDetails) {
    Object.entries(modifierGroupDetails.selectedModifierItemsObj).forEach(function (_ref24) {
      var _ref25 = _slicedToArray(_ref24, 2),
          itemId = _ref25[0],
          isSelected = _ref25[1];

      if (!modifierGroupDetails.modifierItems || !modifierGroupDetails.modifierItems[itemId]) {
        return;
      }

      var _modifierGroupDetails = modifierGroupDetails.modifierItems[itemId],
          optionName = _modifierGroupDetails.name,
          defaultValue = _modifierGroupDetails.defaultValue;

      if (defaultValue === isSelected) {
        return;
      }

      var modifierName = " ".concat(!isSelected ? 'No ' : '').concat(optionName);
      names.push(modifierName);
    });
  });
  return names;
};

exports.getSelectedModifierNamesOnly = getSelectedModifierNamesOnly;

var getSelectedModifierNames = function getSelectedModifierNames(selectedModifierGroupsWithDetails, count) {
  return selectedModifierGroupsWithDetails.reduce(function (prev, modifierGroupDetails) {
    Object.entries(modifierGroupDetails.selectedModifierItemsObj).forEach(function (_ref26) {
      var _ref27 = _slicedToArray(_ref26, 2),
          itemId = _ref27[0],
          isSelected = _ref27[1];

      if (!modifierGroupDetails.modifierItems || !modifierGroupDetails.modifierItems[itemId]) {
        return prev;
      }

      var _modifierGroupDetails2 = modifierGroupDetails.modifierItems[itemId],
          optionName = _modifierGroupDetails2.name,
          optionPrice = _modifierGroupDetails2.price,
          defaultValue = _modifierGroupDetails2.defaultValue;

      if (defaultValue === isSelected) {
        return prev;
      }

      var isNegative = optionPrice < 0;

      if (!isSelected) {
        isNegative = !isNegative;
      }

      var modifierName = " \u2022 ".concat(!isSelected ? 'No ' : '').concat(optionName);
      var modifierItemPriceStr = optionPrice === 0 ? '' : "".concat(isNegative ? '-' : '', "$").concat(Math.abs(optionPrice * count).toFixed(2));
      var modifierNamePrice = modifierName + textEnd(modifierItemPriceStr, getStringLength(modifierName));
      prev.push(modifierNamePrice);
    });
    return prev;
  }, []);
};

var getOrderReportDetails = function getOrderReportDetails(_ref28) {
  var orderData = _ref28.orderData;
  var total = 0;
  var inpersonTotal = 0;
  var onlineTotal = 0;
  var fPointsDiscount = 0;
  var foodSubTotal = 0;
  var deliveryFee = 0;
  var taxAmount = 0;
  var taxes = {};
  var pickupTip = 0;
  var inpersonPickupTip = 0;
  var courierTip = 0;
  var totalCommission = 0;
  var networkAccessFee = 0;
  var subsidizedDeliveryFee = 0;
  var foodDelayContribution = 0;
  var commissionTax = 0;
  var otherAdjustments = 0;
  var cashSubTotal = 0;
  var cashDeliveryFee = 0;
  var cashTax = 0;
  var onlineSubTotal = 0;
  var onlineDeliveryFee = 0;
  var onlineTax = 0;
  var onlinePickupTip = 0;
  var onlineCourierTip = 0;
  var inpersonCourierTip = 0;
  var stripeTransfer = 0;
  var foodlyEarnedOnlineTip = 0;
  var foodlyEarnedOnlineDeliveryFee = 0;
  var foodlyEarnedOnlineDeliveryFeeTax = 0;
  var otherAdjustmentsAmount = (0, _lodash.sum)(Object.values(orderData.otherAdjustments || {}));
  stripeTransfer += otherAdjustmentsAmount;
  otherAdjustments += otherAdjustmentsAmount; // Do not include any cancelled order in the data

  if (orderData.status !== 'Cancelled') {
    total += orderData.total;
    foodSubTotal += orderData.subTotal;
    fPointsDiscount += orderData.fPointsDiscount || 0;
    totalCommission += (orderData.commissionAmount || 0) + (orderData.restaurantDeliveryFeeSubs || 0) + (orderData.foodDelayContribution || 0) + (orderData.commissionTax || 0);
    networkAccessFee += orderData.commissionAmount || 0;
    foodDelayContribution += orderData.foodDelayContribution || 0;
    commissionTax += orderData.commissionTax || 0;
    stripeTransfer += orderData.connectedAccountDestinationAmount;

    if (orderData.paymentMethod && orderData.paymentMethod.startsWith('inperson')) {
      inpersonTotal += orderData.total;
      cashSubTotal += orderData.subTotal;

      if (orderData.orderType === 'Delivery' && orderData.deliveryType === 'restaurant') {
        cashDeliveryFee += orderData.deliveryFee || 0;
        inpersonCourierTip += orderData.inpersonTipAmount || 0;
      }

      if (orderData.orderType === 'Delivery' && orderData.deliveryType === 'foodly') {
        // deliveryFeeTax collected by foodly courier
        cashTax += orderData.subTotalTax;
      } else {
        // deliveryFeeTax collected by restasurant
        cashTax += orderData.taxAmount;
        inpersonPickupTip += orderData.inpersonTipAmount || 0;
      }
    } else {
      onlineTotal += orderData.total;
      onlineSubTotal += orderData.subTotal;

      if (orderData.orderType === 'Pickup' || orderData.orderType === 'DineIn') {
        onlineTax += orderData.taxAmount;
        onlinePickupTip += orderData.tipAmount || 0;
      }

      if (orderData.orderType === 'Delivery' && orderData.deliveryType === 'restaurant') {
        onlineDeliveryFee += orderData.deliveryFee || 0;
        onlineTax += orderData.taxAmount;
        onlineCourierTip += orderData.tipAmount || 0;
      }

      if (orderData.orderType === 'Delivery' && orderData.deliveryType === 'foodly') {
        onlineTax += orderData.subTotalTax;
      }
    }

    if (orderData.orderType === 'Delivery') {
      if (orderData.deliveryType === 'restaurant') {
        // Restaurant does delivery
        deliveryFee += orderData.deliveryFee || 0;
        taxAmount += orderData.taxAmount;

        if (orderData.taxes) {
          taxes = _objectSpread({}, orderData.taxes);
        } else {
          taxes = null;
        }

        courierTip += orderData.tipAmount || 0;
      } else {
        // Foodly does delivery
        foodlyEarnedOnlineTip += orderData.tipAmount || 0;
        foodlyEarnedOnlineDeliveryFee += orderData.deliveryFee || 0;
        foodlyEarnedOnlineDeliveryFeeTax += orderData.deliveryFeeTax || 0;
        taxAmount += orderData.subTotalTax;

        if (orderData.subtotalTaxes) {
          taxes = _objectSpread({}, orderData.subtotalTaxes);
        } else {
          taxes = null;
        }

        subsidizedDeliveryFee += orderData.restaurantDeliveryFeeSubs || 0;
      }
    } else {
      // Pickup order
      taxAmount += orderData.taxAmount;

      if (orderData.taxes) {
        taxes = _objectSpread({}, orderData.taxes);
      } else {
        taxes = null;
      }

      pickupTip += orderData.tipAmount || 0;
    }
  }

  var foodTotal = orderData.subTotal + orderData.subTotalTax;
  var grossEarning = foodSubTotal + deliveryFee + taxAmount + pickupTip + courierTip + inpersonPickupTip + inpersonCourierTip;
  var cashRevenue = cashSubTotal + cashDeliveryFee + cashTax + inpersonPickupTip + inpersonCourierTip;
  var netDirectDeposit = otherAdjustments + onlineSubTotal + onlineDeliveryFee + onlineTax + onlinePickupTip + onlineCourierTip - totalCommission;
  var revenue = cashRevenue + netDirectDeposit;
  return {
    total: currencyRounding(total),
    inpersonTotal: currencyRounding(inpersonTotal),
    onlineTotal: currencyRounding(onlineTotal),
    fPointsDiscount: currencyRounding(fPointsDiscount),
    foodSubTotal: currencyRounding(foodSubTotal),
    foodTotal: currencyRounding(foodTotal),
    deliveryFee: currencyRounding(deliveryFee),
    taxAmount: currencyRounding(taxAmount),
    taxes: taxes,
    pickupTip: currencyRounding(pickupTip),
    inpersonPickupTip: currencyRounding(inpersonPickupTip),
    courierTip: currencyRounding(courierTip),
    totalCommission: currencyRounding(totalCommission),
    networkAccessFee: currencyRounding(networkAccessFee),
    subsidizedDeliveryFee: currencyRounding(subsidizedDeliveryFee),
    foodDelayContribution: currencyRounding(foodDelayContribution),
    commissionTax: currencyRounding(commissionTax),
    otherAdjustments: currencyRounding(otherAdjustments),
    cashSubTotal: currencyRounding(cashSubTotal),
    cashDeliveryFee: currencyRounding(cashDeliveryFee),
    cashTax: currencyRounding(cashTax),
    onlineSubTotal: currencyRounding(onlineSubTotal),
    onlineDeliveryFee: currencyRounding(onlineDeliveryFee),
    onlineTax: currencyRounding(onlineTax),
    onlinePickupTip: currencyRounding(onlinePickupTip),
    onlineCourierTip: currencyRounding(onlineCourierTip),
    inpersonCourierTip: currencyRounding(inpersonCourierTip),
    stripeTransfer: currencyRounding(stripeTransfer),
    grossEarning: currencyRounding(grossEarning),
    cashRevenue: currencyRounding(cashRevenue),
    netDirectDeposit: currencyRounding(netDirectDeposit),
    revenue: currencyRounding(revenue),
    foodlyEarnedOnlineTip: currencyRounding(foodlyEarnedOnlineTip),
    foodlyEarnedOnlineDeliveryFee: currencyRounding(foodlyEarnedOnlineDeliveryFee),
    foodlyEarnedOnlineDeliveryFeeTax: currencyRounding(foodlyEarnedOnlineDeliveryFeeTax)
  };
};

exports.getOrderReportDetails = getOrderReportDetails;

var getPromosWithDetails = function getPromosWithDetails(_ref29) {
  var rewards = _ref29.rewards,
      products = _ref29.products,
      orderType = _ref29.orderType,
      subtotalBeforeDiscount = _ref29.subtotalBeforeDiscount,
      isLoggedIn = _ref29.isLoggedIn,
      isFirstOrder = _ref29.isFirstOrder,
      _ref29$cartItems = _ref29.cartItems,
      cartItems = _ref29$cartItems === void 0 ? {} : _ref29$cartItems,
      _ref29$modifierGroups = _ref29.modifierGroups,
      modifierGroups = _ref29$modifierGroups === void 0 ? {} : _ref29$modifierGroups,
      rewardIdToTimesUsed = _ref29.rewardIdToTimesUsed;
  var promoWithDetails = {};
  var productIdToCount = {};
  Object.values(cartItems).forEach(function (item) {
    productIdToCount[item.productId] = (productIdToCount[item.productId] || 0) + item.count;
  });
  var cartItemIdToPrice = {};
  Object.entries(cartItems).forEach(function (_ref30) {
    var _ref31 = _slicedToArray(_ref30, 2),
        cartItemId = _ref31[0],
        cartItem = _ref31[1];

    cartItemIdToPrice[cartItemId] = getCartItemTotal({
      count: 1,
      price: products[cartItem.productId] ? products[cartItem.productId].price : 0,
      selectedModifierGroupsWithDetails: getSelectedModifierGroupsWithDetails({
        selectedModifiers: cartItem.selectedModifiers || {},
        modifierGroups: modifierGroups
      })
    });
  });
  Object.entries(rewards).forEach(function (_ref32) {
    var _ref33 = _slicedToArray(_ref32, 2),
        rewardId = _ref33[0],
        rewardInfo = _ref33[1];

    var rewardDetails;
    var _ref34 = {},
        valid = _ref34.valid,
        message = _ref34.message;
    var alreadyRedeemedCount = rewardIdToTimesUsed[rewardInfo.rewardId || ''];
    rewardDetails = parseRewardInfo({
      rewardInfo: rewardInfo,
      isFirstOrder: isFirstOrder,
      cartSubTotal: subtotalBeforeDiscount,
      products: products,
      cartItems: cartItems,
      cartItemIdToPrice: cartItemIdToPrice
    });
    var resp = rewardDetails ? getIsRewardValid({
      isLoggedIn: isLoggedIn,
      alreadyRedeemedCount: alreadyRedeemedCount,
      rewardDetails: rewardDetails,
      isFirstOrder: isFirstOrder,
      productsDetails: products,
      cartSubTotal: subtotalBeforeDiscount,
      redeemedRewardCount: rewardDetails.count,
      userPointsWithPromoApplied: -(rewardDetails.totalRequiredPoints || 0),
      productIdToCount: productIdToCount,
      orderType: orderType
    }) : {
      valid: false
    };
    valid = resp.valid;
    message = resp.message;
    promoWithDetails[rewardId] = _objectSpread(_objectSpread({}, rewardDetails), {}, {
      id: rewardId,
      valid: valid,
      message: message
    });

    if (!valid) {
      promoWithDetails[rewardId].count = 0;
    }
  });
  return promoWithDetails;
};

exports.getPromosWithDetails = getPromosWithDetails;

var getBeforeTaxDiscount = function getBeforeTaxDiscount(_ref35) {
  var promosWithDetails = _ref35.promosWithDetails,
      subtotalBeforeDiscount = _ref35.subtotalBeforeDiscount;
  var totalDiscount = 0;

  for (var _i3 = 0, _Object$values2 = Object.values(promosWithDetails); _i3 < _Object$values2.length; _i3++) {
    var promoData = _Object$values2[_i3];

    if (!promoData) {
      return;
    }

    if (promoData.version === 1 || promoData.version === 2) {
      var subtotalDiscountAmount = (promoData.subtotalDiscountAmount || 0) * (promoData.count || 1);
      var subtotalDiscountPercent = (promoData.subtotalDiscountPercent || 0) * (promoData.count || 1);
      totalDiscount += currencyRounding(subtotalDiscountPercent * subtotalBeforeDiscount) + currencyRounding(subtotalDiscountAmount);
    }

    if (promoData.version === 3) {
      totalDiscount += promoData.totalDiscountSubtotal;
    }
  }

  return currencyRounding(totalDiscount);
};

exports.getBeforeTaxDiscount = getBeforeTaxDiscount;

var getAfterTaxDiscount = function getAfterTaxDiscount(_ref36) {
  var subtotalBeforeDiscount = _ref36.subtotalBeforeDiscount,
      fRewardId = _ref36.fRewardId;

  var _parseFReward = parseFReward({
    fRewardId: fRewardId,
    subtotal: subtotalBeforeDiscount
  }),
      totalDiscountAmount = _parseFReward.totalDiscountAmount;

  return currencyRounding(totalDiscountAmount);
};

exports.getAfterTaxDiscount = getAfterTaxDiscount;

function getAddedChargeTaxes(_ref37) {
  var amount = _ref37.amount,
      publicTaxes = _ref37.publicTaxes,
      taxIds = _ref37.taxIds;

  var getTaxData = function getTaxData(taxId) {
    return publicTaxes && taxId && publicTaxes[taxId] || null;
  };

  var taxes = {};

  var _iterator5 = _createForOfIteratorHelper(taxIds),
      _step5;

  try {
    for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
      var taxId = _step5.value;

      var _taxData = getTaxData(taxId);

      if (_taxData) {
        if (!taxes[taxId]) {
          taxes[taxId] = _objectSpread(_objectSpread({}, _taxData), {}, {
            subtotal: 0,
            taxAmount: 0
          });
        }

        taxes[taxId].subtotal += amount;
      }
    }
  } catch (err) {
    _iterator5.e(err);
  } finally {
    _iterator5.f();
  }

  for (var _i4 = 0, _Object$values3 = Object.values(taxes); _i4 < _Object$values3.length; _i4++) {
    var taxData = _Object$values3[_i4];
    taxData.subtotal = currencyRounding(taxData.subtotal);
    taxData.taxAmount = currencyRounding(taxData.subtotal * taxData.rate);
  }

  return taxes;
}

var getCartTaxes = function getCartTaxes(_ref38) {
  var products = _ref38.products,
      cartItems = _ref38.cartItems,
      beforeTaxDiscountAmount = _ref38.beforeTaxDiscountAmount,
      orderType = _ref38.orderType,
      deliveryFee = _ref38.deliveryFee,
      deliveryFeeTaxType = _ref38.deliveryFeeTaxType,
      publicTaxes = _ref38.publicTaxes,
      _ref38$modifierGroups = _ref38.modifierGroups,
      modifierGroups = _ref38$modifierGroups === void 0 ? {} : _ref38$modifierGroups;

  var getTaxData = function getTaxData(taxId) {
    return publicTaxes && taxId && publicTaxes[taxId] || null;
  };

  var productsWithDetails = Object.entries(cartItems).reduce(function (prev, _ref39) {
    var _ref40 = _slicedToArray(_ref39, 2),
        cartItemId = _ref40[0],
        cartItem = _ref40[1];

    prev[cartItemId] = _objectSpread(_objectSpread({}, cartItems[cartItemId]), products[cartItem.productId]);
    return prev;
  }, {});
  var taxes = {};
  var subtotalBeforeDeliveryFee = 0;

  for (var _i5 = 0, _Object$values4 = Object.values(productsWithDetails); _i5 < _Object$values4.length; _i5++) {
    var cartItemWithProductDetails = _Object$values4[_i5];
    var count = cartItemWithProductDetails.count,
        _cartItemWithProductD = cartItemWithProductDetails.selectedModifiers,
        selectedModifiers = _cartItemWithProductD === void 0 ? {} : _cartItemWithProductD,
        price = cartItemWithProductDetails.price,
        _cartItemWithProductD2 = cartItemWithProductDetails.taxIds,
        taxIds = _cartItemWithProductD2 === void 0 ? ['cad_gst'] : _cartItemWithProductD2;
    var selectedModifierGroupsWithDetails = getSelectedModifierGroupsWithDetails({
      selectedModifiers: selectedModifiers,
      modifierGroups: modifierGroups
    });
    var cartItemPrice = getCartItemTotal({
      selectedModifierGroupsWithDetails: selectedModifierGroupsWithDetails,
      count: count,
      price: price
    });

    var _iterator6 = _createForOfIteratorHelper(taxIds),
        _step6;

    try {
      for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
        var taxId = _step6.value;
        var taxData = getTaxData(taxId);

        if (!taxes[taxId]) {
          if (taxData) {
            taxes[taxId] = _objectSpread(_objectSpread({}, taxData), {}, {
              subtotal: 0,
              taxAmount: 0
            });
          }
        }

        taxes[taxId].subtotal += cartItemPrice;
      }
    } catch (err) {
      _iterator6.e(err);
    } finally {
      _iterator6.f();
    }

    subtotalBeforeDeliveryFee += cartItemPrice;
  }

  if (beforeTaxDiscountAmount > 0) {
    for (var _i6 = 0, _Object$values5 = Object.values(taxes); _i6 < _Object$values5.length; _i6++) {
      var _taxData2 = _Object$values5[_i6];

      if (_taxData2.subtotal >= beforeTaxDiscountAmount) {
        _taxData2.subtotal -= beforeTaxDiscountAmount;
        subtotalBeforeDeliveryFee -= beforeTaxDiscountAmount;
      } else {
        _taxData2.subtotal = 0;
      }
    }
  }

  if (orderType === 'Delivery') {
    if (deliveryFee > 0) {
      // deliveryFeeTaxType can be either 'prorated', taxId, or array of taxIds
      // Check if deliveryFee should be taxed equally distributed based on currently applied taxes or a specific tax.
      if (subtotalBeforeDeliveryFee && (deliveryFeeTaxType === 'prorated' || !deliveryFeeTaxType)) {
        for (var _i7 = 0, _Object$values6 = Object.values(taxes); _i7 < _Object$values6.length; _i7++) {
          var _taxData3 = _Object$values6[_i7];
          var ratio = _taxData3.subtotal / subtotalBeforeDeliveryFee;
          _taxData3.subtotal += ratio * deliveryFee;
        }
      } else {
        if (typeof deliveryFeeTaxType === 'string') {
          var _taxData4 = getTaxData(deliveryFeeTaxType);

          if (_taxData4) {
            if (!taxes[deliveryFeeTaxType]) {
              taxes[deliveryFeeTaxType] = _objectSpread(_objectSpread({}, _taxData4), {}, {
                subtotal: 0,
                taxAmount: 0
              });
            }

            taxes[deliveryFeeTaxType].subtotal += deliveryFee;
          }
        } else {
          var _iterator7 = _createForOfIteratorHelper(deliveryFeeTaxType),
              _step7;

          try {
            for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
              var _taxId = _step7.value;

              var _taxData5 = getTaxData(_taxId);

              if (_taxData5) {
                if (!taxes[_taxId]) {
                  taxes[_taxId] = _objectSpread(_objectSpread({}, _taxData5), {}, {
                    subtotal: 0,
                    taxAmount: 0
                  });
                }

                taxes[_taxId].subtotal += deliveryFee;
              }
            }
          } catch (err) {
            _iterator7.e(err);
          } finally {
            _iterator7.f();
          }
        }
      }
    }
  }

  for (var _i8 = 0, _Object$values7 = Object.values(taxes); _i8 < _Object$values7.length; _i8++) {
    var _taxData6 = _Object$values7[_i8];
    _taxData6.subtotal = currencyRounding(_taxData6.subtotal);
    _taxData6.taxAmount = currencyRounding(_taxData6.subtotal * _taxData6.rate);
  }

  return taxes;
};

exports.getCartTaxes = getCartTaxes;

var getCartTax = function getCartTax(_ref41) {
  var taxes = _ref41.taxes;
  var totalTax = 0;

  for (var _i9 = 0, _Object$values8 = Object.values(taxes); _i9 < _Object$values8.length; _i9++) {
    var tax = _Object$values8[_i9];
    totalTax += tax.taxAmount;
  }

  return currencyRounding(totalTax);
};

exports.getCartTax = getCartTax;

function getOrderCartItems(_ref42) {
  var cartItems = _ref42.cartItems,
      products = _ref42.products,
      _ref42$modifierGroups = _ref42.modifierGroups,
      modifierGroups = _ref42$modifierGroups === void 0 ? {} : _ref42$modifierGroups;
  return Object.entries(cartItems).reduce(function (prev, _ref43) {
    var _ref44 = _slicedToArray(_ref43, 2),
        cartItemId = _ref44[0],
        cartItem = _ref44[1];

    var count = cartItem.count;
    var selectedModifiers = cartItem.selectedModifiers || {};

    if (!products[cartItem.productId]) {
      return prev;
    }

    if (products[cartItem.productId].activeAfter && products[cartItem.productId].activeAfter > moment().valueOf()) {
      return prev;
    }

    prev[cartItemId] = _objectSpread(_objectSpread({}, products[cartItem.productId]), {}, {
      productId: cartItem.productId,
      count: count,
      notes: cartItem.notes || '',
      selectedModifierGroupsWithDetails: Object.entries(selectedModifiers).reduce(function (_prev, _ref45) {
        var _ref46 = _slicedToArray(_ref45, 2),
            modifierGroupId = _ref46[0],
            selectedModifierItemsObj = _ref46[1];

        if (!modifierGroups[modifierGroupId]) {
          return _prev;
        }

        _prev.push(_objectSpread(_objectSpread({
          id: modifierGroupId
        }, modifierGroups[modifierGroupId]), {}, {
          selectedModifierItemsObj: selectedModifierItemsObj
        }));

        return _prev;
      }, [])
    });
    return prev;
  }, {});
}

function getSubtotalBeforeDiscount(_ref47) {
  var orderCartItems = _ref47.orderCartItems;

  if (!orderCartItems) {
    return 0;
  }

  var subtotalBeforeDiscount = 0;

  for (var _i10 = 0, _Object$values9 = Object.values(orderCartItems); _i10 < _Object$values9.length; _i10++) {
    var cartItemWithProductDetails = _Object$values9[_i10];
    var modifierTotalPrice = 0;

    if (cartItemWithProductDetails.selectedModifierGroupsWithDetails) {
      cartItemWithProductDetails.selectedModifierGroupsWithDetails.forEach(function (modifierGroupDetails) {
        if (modifierGroupDetails) {
          for (var _i11 = 0, _Object$entries = Object.entries(modifierGroupDetails.selectedModifierItemsObj); _i11 < _Object$entries.length; _i11++) {
            var _Object$entries$_i = _slicedToArray(_Object$entries[_i11], 2),
                modifierItemId = _Object$entries$_i[0],
                isSelected = _Object$entries$_i[1];

            if (modifierGroupDetails.modifierItems[modifierItemId] && isSelected) {
              modifierTotalPrice += modifierGroupDetails.modifierItems[modifierItemId].price;
            }
          }
        }
      });
    }

    subtotalBeforeDiscount += Math.max((cartItemWithProductDetails.price + modifierTotalPrice) * cartItemWithProductDetails.count, 0);
  }

  return currencyRounding(subtotalBeforeDiscount);
}

function getOrderCartItemsWithTaxes(_ref48) {
  var cartItems = _ref48.cartItems,
      products = _ref48.products,
      modifierGroups = _ref48.modifierGroups,
      publicTaxes = _ref48.publicTaxes;
  var orderCartItems = getOrderCartItems({
    cartItems: cartItems,
    products: products,
    modifierGroups: modifierGroups
  });

  for (var _i12 = 0, _Object$values10 = Object.values(orderCartItems); _i12 < _Object$values10.length; _i12++) {
    var cartItemWithProductDetails = _Object$values10[_i12];
    var modifierTotalPrice = 0;

    if (cartItemWithProductDetails.selectedModifierGroupsWithDetails) {
      cartItemWithProductDetails.selectedModifierGroupsWithDetails.forEach(function (modifierGroupDetails) {
        if (modifierGroupDetails) {
          for (var _i13 = 0, _Object$entries2 = Object.entries(modifierGroupDetails.selectedModifierItemsObj); _i13 < _Object$entries2.length; _i13++) {
            var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i13], 2),
                modifierItemId = _Object$entries2$_i[0],
                isSelected = _Object$entries2$_i[1];

            if (modifierGroupDetails.modifierItems[modifierItemId] && isSelected) {
              modifierTotalPrice += modifierGroupDetails.modifierItems[modifierItemId].price;
            }
          }
        }
      });
    }

    var cartItemSubtotal = Math.max((cartItemWithProductDetails.price + modifierTotalPrice) * cartItemWithProductDetails.count, 0);
    var taxes = {};
    var taxIds = (0, _lodash.get)(cartItemWithProductDetails, 'taxIds', ['cad_gst']);

    var _iterator8 = _createForOfIteratorHelper(taxIds),
        _step8;

    try {
      for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
        var taxId = _step8.value;
        var rate = publicTaxes[taxId].rate;
        taxes[taxId] = _objectSpread(_objectSpread({}, publicTaxes[taxId]), {}, {
          taxAmount: currencyRounding(cartItemSubtotal * rate),
          subtotal: cartItemSubtotal
        });
      }
    } catch (err) {
      _iterator8.e(err);
    } finally {
      _iterator8.f();
    }

    cartItemWithProductDetails.taxes = taxes;
  }

  return orderCartItems;
}

function getProductIdToDiscount(products, rewards) {
  var rewardIdToTimesUsed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var productIdToDiscount = {};
  Object.values(rewards || {}).forEach(function (rewardItem) {
    if (!rewardItem.productIds || rewardItem.discountType === 'free' || rewardItem.count >= rewardItem.qtyLimit || rewardItem.count >= rewardItem.qty || rewardItem.count + (rewardIdToTimesUsed[rewardItem.rewardId] || 0) >= rewardItem.qtyLimitPerUser) {
      return;
    }

    Object.keys(rewardItem.productIds).forEach(function (productId) {
      var discountAmount = 0;
      var productPrice = products[productId] && products[productId].price || 0;

      if (rewardItem.discountType === 'flat') {
        discountAmount = rewardItem.discountValue;
      }

      if (rewardItem.discountType === 'percent') {
        discountAmount = rewardItem.discountValue * productPrice;
      }

      discountAmount = Math.min(discountAmount, productPrice);
      productIdToDiscount[productId] = (productIdToDiscount[productId] || 0) + discountAmount;
    });
  });
  return productIdToDiscount;
}