import React, {useState, useRef} from 'react'
import {FView, FText, FTextField, ButtonFillView} from 'components'
import {colors} from 'styles'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {MenuItem} from '@material-ui/core'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

const PointOfSaleInfo = ({pointOfSaleId, isCreateNew}) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const isEditLocked = useSelector(dispatch.portal.getIsAdmin)
  const pointOfSalesData = useSelector(dispatch.pointOfSales.getPointOfSales)
  const locationsData = useSelector(dispatch.restaurants.getLocations)

  const nameRef = useRef()
  const restaurantRef = useRef()
  const statusRef = useRef()

  const [isSaving, setIsSaving] = useState(false)
  const [isDataChanged, setIsDataChanged] = useState(false)

  const isUpSm = useMediaQueryUp('sm')
  const isUpMd = useMediaQueryUp('md')

  const handleNameTextField = (event) => {
    if (event.target?.value === pointOfSalesData[pointOfSaleId]?.name) {
      setIsDataChanged(false)
    } else {
      setIsDataChanged(true)
    }
  }

  const handleRestaurantTextField = (event) => {
    const restaurantId =
      pointOfSalesData[pointOfSaleId]?.restaurantId + '/' + pointOfSalesData[pointOfSaleId]?.locationId
    if (event.target?.value === restaurantId) {
      setIsDataChanged(false)
    } else {
      setIsDataChanged(true)
    }
  }

  const handleStatusTextField = (event) => {
    if (event.target?.value === pointOfSalesData[pointOfSaleId]?.status) {
      setIsDataChanged(false)
    } else {
      setIsDataChanged(true)
    }
  }

  const handleCreatePointOfSale = () => {
    if (nameRef.current.value === '' || nameRef.current.value === null) {
      alert('Please enter name')
      return
    }
    setIsSaving(true)
    const [restaurantId, locationId] = restaurantRef.current.value.split('/')
    dispatch.pointOfSales
      .createNewPointOfSale({
        restaurantId,
        locationId,
        status: statusRef.current.value,
        name: nameRef.current.value,
      })
      .then((doc) => {
        history.push('/point-of-sales/info/' + doc.id)
      })
      .catch((e) => {
        alert(e.message)
      })
      .finally(setIsSaving(false))
  }

  const handleUpdatePointOfSale = async () => {
    if (nameRef.current.value === '' || nameRef.current.value === null) {
      alert('Please enter name')
      return
    }
    setIsSaving(true)
    const [restaurantId, locationId] = restaurantRef.current.value.split('/')
    try {
      await dispatch.pointOfSales.updatePointOfSale(pointOfSaleId, {
        restaurantId,
        locationId,
        status: statusRef.current.value,
        name: nameRef.current.value,
      })
      setIsDataChanged(false)
    } catch (e) {
      alert(e.message)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <FView>
      <FView bg={colors.white} p={25} maxWidth="100%">
        <FText h5 medium gutterBottom>
          Point Of Sale Info
        </FText>
        <FView grid gridTemplateColumns={isUpSm ? '1fr 1fr 1fr' : '1fr'} columnGap={15}>
          <FTextField
            name="posNameTextField"
            inputRef={nameRef}
            required
            disabled={isEditLocked}
            label="Point Of Sale Name"
            onChange={handleNameTextField}
            defaultValue={pointOfSaleId ? pointOfSalesData[pointOfSaleId]?.name : null}
          />
          <FTextField
            name="restaurantTextField"
            inputRef={restaurantRef}
            required
            disabled
            select
            label="Restaurant"
            onChange={handleRestaurantTextField}
            defaultValue={
              pointOfSaleId
                ? pointOfSalesData[pointOfSaleId]?.restaurantId + '/' + pointOfSalesData[pointOfSaleId]?.locationId
                : ''
            }>
            {Object.keys(locationsData).map((locationId) => {
              const menuValue = locationsData[locationId].restaurantId + '/' + locationsData[locationId].locationId
              return (
                <MenuItem key={locationId} value={menuValue}>
                  {locationsData[locationId].restaurantName}
                </MenuItem>
              )
            })}
          </FTextField>
          <FTextField
            name="statusTextField"
            inputRef={statusRef}
            required
            disabled={isEditLocked}
            select
            label="Status"
            onChange={handleStatusTextField}
            defaultValue={pointOfSaleId ? pointOfSalesData[pointOfSaleId]?.status : 'activated'}>
            <MenuItem value={'activated'}>activated</MenuItem>
            <MenuItem value={'disabled'}>disabled</MenuItem>
          </FTextField>
        </FView>
        <FView center>
          {isCreateNew ? (
            <ButtonFillView
              w={isUpMd ? 250 : 150}
              rounded
              disabled={isSaving}
              onClick={() => handleCreatePointOfSale()}>
              <FText primaryContrast body1 bold>
                {isSaving ? 'Creating...' : 'Create'}
              </FText>
            </ButtonFillView>
          ) : (
            <ButtonFillView
              w={isUpMd ? 250 : 150}
              rounded
              disabled={isSaving || !isDataChanged}
              onClick={() => handleUpdatePointOfSale()}>
              <FText primaryContrast body1 bold>
                {isSaving ? 'Updating...' : 'Update'}
              </FText>
            </ButtonFillView>
          )}
        </FView>
      </FView>
    </FView>
  )
}

export default PointOfSaleInfo
