import React from 'react'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import {Cancel} from '@material-ui/icons'
import {moment} from 'f-utils'
import MomentUtils from '@date-io/moment'
import {FButton, FView, MenuTimeRow, FText} from 'components'

export default function RegionTimePicker({newHoursLT, setNewHoursLT}) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {[1, 2, 3, 4, 5, 6, 0].map((day) => {
        return (
          <FView key={day} mv={8}>
            {(newHoursLT?.[day] ?? [{openLT: null, closeLT: null}]).map((openCloseLT, index) => {
              const startDate = openCloseLT?.openLT ? moment(openCloseLT?.openLT, 'LT') : null
              const endDate = openCloseLT?.closeLT ? moment(openCloseLT?.closeLT, 'LT') : null
              return (
                <FView row key={String(day + index)}>
                  <MenuTimeRow
                    day={index === 0 ? moment().day(day).format('dddd') : ''}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={(startMoment) => {
                      if (startMoment == null) {
                        return
                      } else {
                        const _newHoursLTDay = newHoursLT[day] ?? [{closeLT: null}]
                        _newHoursLTDay[index].openLT = startMoment.format('LT')
                        newHoursLT[day] = _newHoursLTDay
                        setNewHoursLT({...newHoursLT})
                      }
                    }}
                    setEndDate={(endMoment) => {
                      if (endMoment == null) {
                        return
                      } else {
                        const _newHoursLTDay = newHoursLT[day] ?? [{openLT: null}]
                        _newHoursLTDay[index].closeLT = endMoment.format('LT')
                        newHoursLT[day] = _newHoursLTDay
                        setNewHoursLT({...newHoursLT})
                      }
                    }}
                  />
                  {!newHoursLT?.[day] ||
                  (newHoursLT[day][0]?.openLT == null &&
                    newHoursLT[day][0]?.closeLT == null &&
                    newHoursLT[day].length <= 1) ? (
                    <FView ml={15}>
                      <FText body1 grey800>
                        Closed
                      </FText>
                    </FView>
                  ) : (
                    <FButton
                      IconButton
                      onPress={() => {
                        if (newHoursLT[day].length > 1) {
                          newHoursLT[day].splice(index, 1)
                        } else {
                          newHoursLT[day][0] = {openLT: null, closeLT: null}
                        }
                        setNewHoursLT({...newHoursLT})
                      }}>
                      <Cancel />
                    </FButton>
                  )}
                </FView>
              )
            })}
            {newHoursLT?.[day] && (
              <FView alignStart ml={112}>
                <FButton
                  onPress={() => {
                    newHoursLT[day].push({openLT: null, closeLT: null})
                    setNewHoursLT({...newHoursLT})
                  }}>
                  <FText body1 primary>
                    Add more hours
                  </FText>
                </FButton>
              </FView>
            )}
          </FView>
        )
      })}
    </MuiPickersUtilsProvider>
  )
}
