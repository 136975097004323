import React, {useState} from 'react'
import {FText, FView, FButton} from 'components'
import {colors} from 'styles'
import {Collapse} from '@material-ui/core'
import * as utils from 'f-utils'
import {isEmpty} from 'lodash'
import {LocalAtm, Redeem, Restaurant} from '@material-ui/icons'

export default function OrderProductsDetails({orderData}) {
  const [showProducts, setShowProducts] = useState(false)
  return (
    <FView p={15}>
      <FView alignStart>
        <FButton onPress={() => setShowProducts(!showProducts)}>
          <FView>
            <FText body2 bold primary>
              {showProducts ? 'Hide Product Details' : 'Show Product Details'}
            </FText>
          </FView>
        </FButton>
      </FView>
      <Collapse in={showProducts}>
        {Object.entries(orderData?.orderCartItems ?? {}).map(([cartItemId, cartItem]) => {
          const {name: productName, count, price, selectedModifierGroupsWithDetails} = cartItem
          return (
            <FView key={cartItemId} fill row alignCenter mb={4}>
              <FView selfStart rounded bg={count > 1 ? colors.primary : colors.grey} size={25} center mr={15}>
                <FText white primaryContrast={count > 1} body2 bold alignCenter lh={22}>
                  x{count}
                </FText>
              </FView>
              <FView fill>
                <FView row>
                  <FView fill>
                    <FView row alignCenter>
                      <FView fill>
                        <FText body2 bold>
                          {` ${productName}`}
                        </FText>
                      </FView>
                      <FView row alignCenter>
                        <FText body2 bold>{`$${utils
                          .getCartItemTotal({price, count, selectedModifierGroupsWithDetails})
                          ?.toFixed(2)}`}</FText>
                      </FView>
                    </FView>

                    <FView>
                      {!!selectedModifierGroupsWithDetails &&
                        selectedModifierGroupsWithDetails.map((modifierGroupDetails) =>
                          Object.entries(modifierGroupDetails.selectedModifierItemsObj).map(([itemId, isSelected]) => {
                            if (!modifierGroupDetails.modifierItems || !modifierGroupDetails.modifierItems[itemId]) {
                              return null
                            }
                            const {
                              name: optionName,
                              price: optionPrice,
                              defaultValue,
                            } = modifierGroupDetails.modifierItems[itemId]
                            if (defaultValue === isSelected) {
                              return null
                            }
                            let isNegative = optionPrice < 0
                            if (!isSelected) {
                              isNegative = !isNegative
                            }
                            const modifierItemPriceStr =
                              optionPrice === 0
                                ? ''
                                : `${isNegative ? '-' : ''}$${Math.abs(optionPrice * count).toFixed(2)}`
                            return (
                              <FView fill key={itemId} row>
                                <FView fill pl={6}>
                                  <FText body2>{`• ${!isSelected ? 'No ' : ''}${optionName}`}</FText>
                                </FView>

                                <FView>
                                  <FText body2>{modifierItemPriceStr}</FText>
                                </FView>
                              </FView>
                            )
                          }),
                        )}
                    </FView>
                  </FView>
                </FView>
              </FView>
            </FView>
          )
        })}
        {!isEmpty(orderData?.rewards) &&
          Object.values(orderData?.rewards).map((reward, index) => {
            const {name: rewardName, count, totalDiscountSubtotal} = reward
            return (
              <FView key={index} fill row alignCenter mb={4}>
                <FView selfStart rounded bg={count > 1 ? colors.primary : colors.grey} size={25} center mr={15}>
                  <FText white primaryContrast={count > 1} body2 bold alignCenter lh={22}>
                    x{count}
                  </FText>
                </FView>
                <FView fill row justifyBetween>
                  <FView fill>
                    <FView row alignCenter>
                      <Redeem />
                      <FView size={8} />
                      <FView fill>
                        <FText body2 bold>{`${rewardName}`}</FText>
                      </FView>
                    </FView>
                  </FView>
                  <FView row alignCenter>
                    <FText body2 bold>
                      {totalDiscountSubtotal ? `-$${totalDiscountSubtotal?.toFixed(2)}` : 'FREE'}
                    </FText>
                  </FView>
                </FView>
              </FView>
            )
          })}
        {orderData?.addedCharges?.map((charge, index) => (
          <FView fill key={index} mb={4} row alignCenter>
            <FView fill row alignCenter>
              <FView mt={4}>
                <LocalAtm />
              </FView>
              <FView w={15} />
              <FText body2 bold>
                {`${charge?.name}`}
              </FText>
            </FView>
            <FText body2 bold>{`$${charge?.price?.toFixed(2)}`}</FText>
          </FView>
        ))}
        <FView row alignCenter mb={4}>
          <Restaurant />
          <FView w={16} />
          <FText bold body2>
            {orderData.includeUtensils ? 'Utensils Included' : 'Utensils Not Included'}
          </FText>
        </FView>
        <FView fill row alignCenter justifyBetween mb={4}>
          <FText body1 bold>
            Subtotal:
          </FText>
          <FText body1 bold>{`$${orderData?.subTotal?.toFixed(2)}`}</FText>
        </FView>
        <FView fill row alignCenter justifyBetween mb={4}>
          <FText body1 bold>
            Food Total:
          </FText>
          <FText body1 bold>{`$${(orderData?.subTotal + orderData?.subTotalTax)?.toFixed(2)}`}</FText>
        </FView>
      </Collapse>
    </FView>
  )
}
