import React from 'react'
import {FView, FNavBar, FDrawer, FText} from 'components'
import {colors} from 'styles'
import {useDispatch, useSelector} from 'react-redux'
import useReactRouter from 'use-react-router'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {Checkbox, CircularProgress, FormControlLabel} from '@material-ui/core'
import HoursList from './HoursList'

const Hours = () => {
  const {match} = useReactRouter()
  const {restaurantId, locationId} = match.params
  const dispatch = useDispatch()
  const isUpSm = useMediaQueryUp('sm')
  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const isEditLocked = useSelector(dispatch.portal.getIsAdmin)

  const isDeliveryHoursExist = locationData?.deliveryHoursLT ?? false

  if (!locationData) {
    return (
      <FView h={'100vh'} center>
        <CircularProgress />
      </FView>
    )
  }

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow={isUpSm} />
        </FView>
        <FView fill bg={colors.background} row>
          <FDrawer locationData={locationData} />
          <FView fill alignCenter pt={isUpSm ? 25 : 75} pb={25}>
            <FText h6 bold gutterBottom>
              Ordering Hours
            </FText>
            <HoursList hoursLT={locationData.hoursLT} restaurantId={restaurantId} locationId={locationId} />
            <FText h6 bold gutterBottom>
              Delivery Hours
            </FText>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!isDeliveryHoursExist}
                  disabled={isEditLocked}
                  color="primary"
                  disableRipple
                  onChange={(event) => {
                    if (event.target.checked) {
                      // Delete deliveryHoursLT
                      dispatch.restaurants.deleteDeliveryHoursLT({restaurantId, locationId})
                    } else {
                      // Create deliveryHoursLT with hoursLT
                      dispatch.restaurants.updateDeliveryHoursLT({
                        deliveryHoursLT: locationData.hoursLT,
                        restaurantId,
                        locationId,
                      })
                    }
                  }}
                />
              }
              label={
                isDeliveryHoursExist ? 'Setting custom delivery hours' : 'Delivery hours are same as ordering hours'
              }
            />
            {isDeliveryHoursExist && (
              <HoursList
                hoursLT={locationData.deliveryHoursLT}
                restaurantId={restaurantId}
                locationId={locationId}
                isDeliveryHoursLT
              />
            )}
          </FView>
        </FView>
      </FView>
    </FView>
  )
}

export default Hours
