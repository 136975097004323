import React from 'react'
import {FView, FNavBar} from 'components'
import {colors} from 'styles'
import {useMediaQueryUp} from 'f-web/src/hooks'
import PointOfSaleInfo from './PointOfSaleInfo'

const PointOfSale = () => {
  const isUpSm = useMediaQueryUp('sm')

  return (
    <>
      <FView h={80}>
        <FNavBar shadow={isUpSm} />
      </FView>
      <FView fill bg={colors.background} row>
        <FView fill alignCenter pt={isUpSm ? 100 : 150} pb={25}>
          <PointOfSaleInfo isCreateNew />
        </FView>
      </FView>
    </>
  )
}

export default PointOfSale
