import React, {useRef, useState} from 'react'
import {clamp} from 'lodash'
import {parseCurrency, moment} from 'f-utils'
import {useDispatch, useSelector} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useTheme, makeStyles} from '@material-ui/core/styles'
import {DELETE_FIELD_VALUE} from 'f-core/src/config/firebase'
import {Divider, Dialog, DialogTitle, DialogActions, DialogContent, FormControlLabel} from '@material-ui/core'
import {Collapse, TextField, MenuItem, Checkbox} from '@material-ui/core'
import {Add, Edit, Delete, Close} from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {FView, FText, ButtonOutlineView, ButtonFillView, FButton, FTextField, FInput} from 'components'
import {auth} from 'f-core/src/config/firebase'
import ModifierEditDialog from './ModifierEditDialog'
import ModifierItem from './ModifierItem'
import * as api from 'models/api'

const ProductItem = ({restaurantId, locationId, productIndex, productId, categoryId, productData}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyle()

  const selectedFileRef = useRef()
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [saving, setSaving] = useState(false)
  const [hasLimitedStock, setHasLimitedStock] = useState(typeof productData.qty === 'number')
  const [hasLimitPerOrder, setHasLimitPerOrder] = useState(typeof productData.qtyLimit === 'number')
  const [stockLimit, setStockLimit] = useState(hasLimitedStock ? String(productData.qty) : '')
  const [limitPerOrder, setLimitPerOrder] = useState(hasLimitPerOrder ? String(productData.qtyLimit) : '')

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  // taxes
  const [selectedTaxIds, setSelectedTaxIds] = useState(productData.taxIds || ['cad_gst'])
  const publicTaxes = useSelector(dispatch.public.getPublicTaxes)
  const productTags = useSelector(dispatch.public.getPublicProductTags)
  const taxIds = Object.keys(publicTaxes)
  const tagIds = Object.keys(productTags)

  const [editModifierGroupId, setEditModifierGroupId] = useState(null)

  const modifierGroups = useSelector(() => dispatch.restaurants.getModifierGroups({locationId}))
  const modifierGroupIds = Object.keys(modifierGroups)
  const defaultModifierGroupIds = []
  const defaultModifierGroups = []
  if (productData.modifierGroups) {
    for (const modifierGroupId of productData.modifierGroups) {
      if (modifierGroups[modifierGroupId]) {
        defaultModifierGroups.push(modifierGroups[modifierGroupId])
        defaultModifierGroupIds.push(modifierGroupId)
      }
    }
  }
  const [productActiveAfterIndex, setProductActiveAfterIndex] = useState(0)

  const currentMoment = moment()
  const currentTimestamp = currentMoment.valueOf()
  const productActiveAfterDescription =
    productData?.activeAfter < currentTimestamp || productData?.activeAfter == null
      ? 'In Stock'
      : (productData?.activeAfter ?? 1e15) >= 1e15
      ? 'Out of Stock'
      : 'Out of Stock until ' + moment(productData?.activeAfter).calendar()
  const [selectedModifierGroupIds, setSelectedModifierGroupIds] = useState(defaultModifierGroupIds)
  const [selectedTags, setSelectedTags] = useState(productData.tags ?? [])
  const nameRef = useRef()
  const priceRef = useRef()
  const descriptionRef = useRef()
  const isUpMd = useMediaQueryUp('md')

  function handleCancel() {
    if (!editing || saving || deleting) {
      return
    }
    setEditing(false)
    if (selectedFileRef.current) {
      selectedFileRef.current.files = null
    }
  }

  function handleCancelOnEscape(e) {
    e.key === 'Escape' && handleCancel()
  }

  async function handleDeleteImage() {
    if (saving || deleting) {
      return
    } else if (!productData.imageUrl) {
      alert('no image attached to product')
      return
    }

    if (!window.confirm('Are you sure you want to delete?')) {
      return
    }
    const authToken = await auth.currentUser.getIdToken()
    setDeleting(true)
    try {
      await api.deleteImageFromBucket({
        serverUrl: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL,
        authToken,
        restaurantId,
        productId,
        imageType: 'product',
      })
    } catch (e) {
      // Image may not be stored in firebase storage, safely ignore.
    }
    try {
      await dispatch.restaurants.updateProduct({
        restaurantId,
        locationId,
        productId,
        product: {
          imageUrl: DELETE_FIELD_VALUE,
        },
      })
    } catch (e) {
      alert(e.message)
    } finally {
      setDeleting(false)
    }
  }
  function handleDelete() {
    if (saving || deleting) {
      return
    }

    setDeleting(true)
    dispatch.restaurants.deleteProduct({restaurantId, locationId, productId, categoryId}).finally(() => {
      setDeleting(false)
    })
  }

  async function handleImageUpload() {
    const authToken = await auth.currentUser.getIdToken()
    try {
      const response = await api.uploadImage({
        serverUrl: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL,
        authToken,
        restaurantId,
        productId,
        imageFile: selectedFileRef.current?.files[0],
        imageType: 'product',
      })
      return response.data.imageUrl
    } catch (e) {
      alert(e.message)
    }
  }
  async function handleEdit() {
    if (saving || deleting) {
      return
    }

    if (!nameRef.current.value) {
      alert('Name field cannot be empty')
      return
    }
    if (!priceRef.current.value) {
      alert('Price field cannot be empty')
      return
    }
    const priceNumber = parseCurrency(priceRef.current.value)
    if (priceNumber < 0) {
      alert('Price cannot be negative')
      return
    }

    let qty = stockLimit === '' ? null : Number(stockLimit)
    if (isNaN(qty) || (typeof qty === 'number' && qty < 0)) {
      alert('Stock Limit must be 0 or greater.')
      return
    }
    let qtyLimit = limitPerOrder === '' ? null : Number(limitPerOrder)
    if (isNaN(qtyLimit) || (typeof qtyLimit === 'number' && qtyLimit <= 0)) {
      alert('Max Quantity Per Order must be a positive number.')
      return
    }
    const imageUrl =
      selectedFileRef.current?.files.length > 0 ? await handleImageUpload() : productData.imageUrl ?? null
    const newProductData = {
      name: nameRef.current.value,
      price: priceNumber,
      description: descriptionRef.current.value || '',
      modifierGroups: selectedModifierGroupIds.length > 0 ? selectedModifierGroupIds : DELETE_FIELD_VALUE,
      imageUrl: imageUrl ? imageUrl : DELETE_FIELD_VALUE,
      taxIds: selectedTaxIds,
      tags: selectedTags,
      qty: hasLimitedStock ? qty : DELETE_FIELD_VALUE,
      qtyLimit: hasLimitPerOrder ? qtyLimit : DELETE_FIELD_VALUE,
    }
    if (productActiveAfterIndex > 0) {
      newProductData.activeAfter = getActiveAfterTimestamp(productActiveAfterIndex)
    }

    setSaving(true)
    dispatch.restaurants
      .updateProduct({
        restaurantId,
        locationId,
        productId,
        product: newProductData,
      })
      .then(() => {
        setEditing(false)
      })
      .finally(() => {
        if (productActiveAfterIndex > 0) {
          setProductActiveAfterIndex(0)
        }
        selectedFileRef.current.value = null
        setSaving(false)
      })
  }

  function handleEditOnEnter(e) {
    e.key === 'Enter' && handleEdit()
  }

  function updateProductIndex({fromIndex, toIndex}) {
    if (saving || deleting) {
      return
    }

    return dispatch.restaurants.updateProductOrder({
      restaurantId,
      locationId,
      categoryId,
      fromIndex,
      toIndex,
    })
  }

  return (
    <>
      <FView pl={16} bg={theme.palette.common.white} row={!editing || isUpMd}>
        <FView fill row>
          {!editing && (
            <FView row alignCenter>
              <FView w={50} m={8}>
                <FTextField
                  // force re-render whenever productIndex changes
                  key={productIndex}
                  className={classes.indexTextField}
                  margin="dense"
                  inputProps={{className: classes.indexInputNativeStyle}}
                  onKeyDown={(e) => {
                    if (e.key === 'Escape') {
                      e.target.value = productIndex
                      e.target.blur()
                    }
                  }}
                  onKeyPress={(e) => {
                    e.key === 'Enter' && updateProductIndex({fromIndex: productIndex, toIndex: e.target.value})
                  }}
                  defaultValue={productIndex}
                  type="number"
                />
              </FView>
              <FView w={1} selfStretch bg={theme.palette.grey['300']} mv={8} />
            </FView>
          )}
          <FView p={8} fill row alignCenter>
            <FView fill>
              <Collapse in={!editing} unmountOnExit>
                <FView row fill maxHeight={100} justifyBetween>
                  <FView fill overflow="auto">
                    <FText body1 medium alignLeft>
                      {productData.name}
                    </FText>
                    <FText alignLeft body2 grey700>
                      {productData.description}
                    </FText>
                    {productActiveAfterDescription !== 'In Stock' && (
                      <FText alignLeft body2 error bold>
                        {productActiveAfterDescription}
                      </FText>
                    )}
                    {selectedModifierGroupIds.length > 0 && (
                      <FText alignLeft body2>
                        Modifiers:{' '}
                        <FText span grey700>
                          {selectedModifierGroupIds
                            .map((groupId) => {
                              return modifierGroups[groupId]?.title ?? ''
                            })
                            .join(', ')}
                        </FText>
                      </FText>
                    )}
                  </FView>
                  {!!productData.imageUrl && (
                    <FView ml={15}>
                      <img src={productData.imageUrl} alt="product" className={classes.productImg} />
                    </FView>
                  )}
                  <FView justifyCenter ml={15}>
                    <FText body2>${productData.price.toFixed(2)}</FText>
                  </FView>
                </FView>
              </Collapse>
              <Collapse in={editing} unmountOnExit>
                <FView fill>
                  <FView row fill>
                    <FTextField
                      margin="dense"
                      inputRef={nameRef}
                      autoFocus
                      required
                      defaultValue={productData.name}
                      label="Product Name"
                      onKeyDown={handleCancelOnEscape}
                      onKeyPress={handleEditOnEnter}
                    />
                    <FView size={16} />
                    <FView w={100}>
                      <FTextField
                        margin="dense"
                        className={classes.indexTextField}
                        inputRef={priceRef}
                        defaultValue={productData.price}
                        label="Price"
                        onKeyDown={handleCancelOnEscape}
                        onKeyPress={handleEditOnEnter}
                        type="number"
                      />
                    </FView>
                  </FView>
                  <FTextField
                    margin="dense"
                    inputRef={descriptionRef}
                    defaultValue={productData.description}
                    label="Product Description"
                    onKeyDown={handleCancelOnEscape}
                    onKeyPress={handleEditOnEnter}
                  />
                  <FView row>
                    {!!productData.imageUrl && (
                      <FView center>
                        <img src={productData.imageUrl} alt="product" className={classes.productImg} />
                        <FButton onClick={() => handleDeleteImage()} disabled={deleting || saving}>
                          <FView row alignCenter>
                            <Delete color={deleting || saving ? 'disabled' : 'primary'} />
                            <FView size={8} />
                            <FText button primary grey300={deleting || saving}>
                              {deleting ? 'Deleting...' : 'Delete Image'}
                            </FText>
                          </FView>
                        </FButton>
                      </FView>
                    )}
                    <FormControlLabel control={<FInput ref={selectedFileRef} type="file" accept=".jpg,.png,.jpeg" />} />
                  </FView>
                  <FTextField
                    margin="dense"
                    value={productActiveAfterIndex}
                    required
                    select
                    label="Product Stock"
                    onChange={(event) => {
                      setProductActiveAfterIndex(event.target.value)
                    }}>
                    <MenuItem value={0}>{productActiveAfterDescription}</MenuItem>
                    <MenuItem value={1}>Change to In Stock</MenuItem>
                    <MenuItem value={2}>Change to Out of Stock for 20 mins</MenuItem>
                    <MenuItem value={3}>Change to Out of Stock for 40 mins</MenuItem>
                    <MenuItem value={4}>Change to Out of Stock for 1 hour</MenuItem>
                    <MenuItem value={5}>Change to Out of Stock for 4 hours</MenuItem>
                    <MenuItem value={6}>Change to Out of Stock until tmr 3 AM</MenuItem>
                    <MenuItem value={7}>Change to Out of Stock indefinitely</MenuItem>
                  </FTextField>
                  <FView size={10} />
                  <FView row>
                    <FView fill>
                      <Autocomplete
                        options={modifierGroupIds}
                        value={selectedModifierGroupIds}
                        onChange={(e, values) => {
                          setSelectedModifierGroupIds(values)
                        }}
                        limitTags={3}
                        size="small"
                        disableClearable
                        autoHighlight
                        multiple
                        getOptionLabel={(groupId) => modifierGroups[groupId]?.title ?? ''}
                        renderOption={(groupId) => `${groupId.substring(0, 3)} - ${modifierGroups[groupId].title}`}
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Modifier Groups" />}
                      />
                    </FView>
                    <FView size={10} />
                    <FView size={40}>
                      <FButton onClick={() => setEditModifierGroupId('new')}>
                        <ButtonFillView size={40} rounded>
                          <Add style={{color: theme.palette.primary.contrastText}} />
                        </ButtonFillView>
                      </FButton>
                    </FView>
                  </FView>
                  {selectedModifierGroupIds.map((groupId, groupIndex) => {
                    return (
                      <FView key={groupId} bc={theme.palette.grey['300']} bw={1} rounded p={8}>
                        <FView row fill alignCenter>
                          <FView w={40} ml={8}>
                            <FTextField
                              margin="dense"
                              key={groupIndex}
                              className={classes.indexTextField}
                              inputProps={{className: classes.indexInputNativeStyle}}
                              onKeyDown={(e) => {
                                if (e.key === 'Escape') {
                                  e.target.value = groupIndex
                                  e.target.blur()
                                }
                              }}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  const toIndexClamped = clamp(e.target.value, 0, selectedModifierGroupIds.length - 1)
                                  if (toIndexClamped === groupIndex) {
                                    return
                                  }
                                  const newSelectedModifierGroupIds = [...selectedModifierGroupIds]
                                  newSelectedModifierGroupIds[groupIndex] = selectedModifierGroupIds[toIndexClamped]
                                  newSelectedModifierGroupIds[toIndexClamped] = selectedModifierGroupIds[groupIndex]
                                  setSelectedModifierGroupIds(newSelectedModifierGroupIds)
                                }
                              }}
                              defaultValue={groupIndex}
                              type="number"
                            />
                          </FView>
                          <FView fill>
                            <ModifierItem
                              locationId={locationId}
                              modifierGroupId={groupId}
                              handleEditModifier={() => setEditModifierGroupId(groupId)}
                              handleClearModifier={() => {
                                setSelectedModifierGroupIds(
                                  selectedModifierGroupIds.filter((selectedGroupId) => selectedGroupId !== groupId),
                                )
                              }}
                            />
                          </FView>
                        </FView>
                      </FView>
                    )
                  })}
                  <FView size={4} />
                  <FView row alignCenter>
                    <FView row alignCenter w={120}>
                      <FText>Stock Quantity</FText>
                    </FView>
                    <Checkbox
                      checked={hasLimitedStock}
                      onChange={(_, checked) => {
                        setHasLimitedStock(checked)
                        checked || setStockLimit('')
                      }}
                    />
                    <FView w={10} />
                    <TextField
                      margin="dense"
                      className={classes.indexTextField}
                      value={stockLimit}
                      label="Quantity"
                      variant="outlined"
                      type="number"
                      disabled={!hasLimitedStock}
                      onChange={(e) => setStockLimit(e.target.value)}
                    />
                  </FView>
                  <FView row alignCenter>
                    <FView row alignCenter w={120}>
                      <FText>Limit Per Order</FText>
                    </FView>
                    <Checkbox
                      checked={hasLimitPerOrder}
                      onChange={(_, checked) => {
                        setHasLimitPerOrder(checked)
                        checked || setLimitPerOrder('')
                      }}
                    />
                    <FView w={10} />
                    <TextField
                      margin="dense"
                      className={classes.indexTextField}
                      value={limitPerOrder}
                      label="Max Per Order"
                      variant="outlined"
                      type="number"
                      disabled={!hasLimitPerOrder}
                      onChange={(e) => setLimitPerOrder(e.target.value)}
                    />
                  </FView>
                  <FView size={8} />
                  <Autocomplete
                    options={taxIds}
                    value={selectedTaxIds}
                    onChange={(e, values) => setSelectedTaxIds(values)}
                    size="small"
                    disableClearable
                    autoHighlight
                    multiple
                    getOptionLabel={(taxId) => publicTaxes[taxId]?.name ?? ''}
                    renderOption={(taxId) => `${taxId} - ${publicTaxes[taxId].name}`}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Taxes" />}
                  />
                  <FView size={16} />
                  <Autocomplete
                    options={tagIds}
                    value={selectedTags}
                    onChange={(e, values) => {
                      setSelectedTags(values)
                    }}
                    limitTags={3}
                    size="small"
                    disableClearable
                    autoHighlight
                    multiple
                    getOptionLabel={(options) => options ?? ''}
                    renderOption={(options) => `${options}`}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Tags" />}
                  />
                  <FView size={16} />
                </FView>
              </Collapse>
            </FView>
          </FView>
        </FView>
        {editing ? (
          <FView justifyCenter p={15} alignEnd>
            <FView row={!isUpMd}>
              <FButton onClick={handleCancel} disabled={saving || deleting}>
                <ButtonOutlineView rounded selected w={125} disabled={saving}>
                  <FText button primary grey500={saving || deleting}>
                    cancel
                  </FText>
                </ButtonOutlineView>
              </FButton>
              <FView size={15} />
              <FButton onClick={handleEdit} disabled={saving || deleting}>
                <ButtonFillView rounded w={125} disabled={saving}>
                  <FText button primaryContrast>
                    {saving ? 'saving...' : 'save'}
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
          </FView>
        ) : (
          <FView row>
            <FView w={1} bg={theme.palette.grey['300']} mv={8} />
            <FButton
              disabled={deleting || saving}
              onClick={() => {
                setEditing(true)
              }}>
              <FView row alignCenter ph={10}>
                <Edit color="primary" />
                {isUpMd && (
                  <>
                    <FView size={8} />
                    <FText button primary>
                      Edit
                    </FText>
                  </>
                )}
              </FView>
            </FButton>
            <FView w={1} bg={theme.palette.grey['300']} mv={8} />
            <FButton onClick={() => setShowDeleteModal(true)} disabled={deleting || saving}>
              <FView row alignCenter ph={10}>
                <Delete color={deleting || saving ? 'disabled' : 'primary'} />
                {isUpMd && (
                  <>
                    <FView size={8} />
                    <FText button primary grey300={deleting || saving}>
                      {deleting ? 'Deleting...' : 'Delete'}
                    </FText>
                  </>
                )}
              </FView>
            </FButton>
          </FView>
        )}
      </FView>
      {showDeleteModal && (
        <Dialog
          fullWidth
          onClose={() => {
            setShowDeleteModal(false)
          }}
          open>
          <DialogTitle>
            <FView row alignCenter>
              <FView fill>
                <FText bold h5 alignCenter>
                  Delete {productData.name}
                </FText>
              </FView>
              <FButton
                onClick={() => {
                  setShowDeleteModal(false)
                }}>
                <Close />
              </FButton>
            </FView>
          </DialogTitle>
          <DialogContent>
            <FView mv={15}>
              <FText body1 grey800>
                Please confirm if you would like to delete {productData.name}
              </FText>
            </FView>
          </DialogContent>
          <Divider />
          <DialogActions>
            <FView fill center row>
              <FButton
                onClick={() => {
                  setShowDeleteModal(false)
                }}>
                <ButtonFillView w={150} rounded>
                  <FText bold primaryContrast>
                    Cancel
                  </FText>
                </ButtonFillView>
              </FButton>
              <FView size={10} />
              <FButton
                onClick={() => {
                  handleDelete()
                  setShowDeleteModal(false)
                }}>
                <ButtonFillView w={150} rounded>
                  <FText bold primaryContrast>
                    Confirm
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
          </DialogActions>
        </Dialog>
      )}
      {!!editModifierGroupId && (
        <ModifierEditDialog
          restaurantId={restaurantId}
          locationId={locationId}
          createNew={editModifierGroupId === 'new'}
          modifierGroupId={editModifierGroupId}
          open
          onCreateNew={(newModifierGroup) =>
            setSelectedModifierGroupIds([...selectedModifierGroupIds, newModifierGroup.id])
          }
          handleClose={() => setEditModifierGroupId(null)}
          handleDelete={() => {
            dispatch.restaurants
              .deleteModifierGroup({
                restaurantId,
                locationId,
                productId,
                modifierGroupId: editModifierGroupId,
              })
              .then(() => {
                setSelectedModifierGroupIds(
                  selectedModifierGroupIds.filter((selectedGroupId) => selectedGroupId !== editModifierGroupId),
                )
                setEditModifierGroupId(null)
              })
          }}
        />
      )}
    </>
  )
}

const useStyle = makeStyles((theme) => ({
  // Remove number up down arrows as it blocks index number from showing
  indexTextField: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  indexInputNativeStyle: {
    textAlign: 'center',
  },
  productImg: {objectFit: 'contain', width: 110},
}))

export default React.memo(ProductItem)

function getActiveAfterTimestamp(activeAfterIndex) {
  if (activeAfterIndex > 0) {
    const currentMoment = moment()
    const currentTimestamp = currentMoment.valueOf()
    switch (activeAfterIndex) {
      case 1:
        return currentTimestamp - 5 * 60000

      case 2:
        return currentTimestamp + 20 * 60000

      case 3:
        return currentTimestamp + 40 * 60000

      case 4:
        return currentTimestamp + 60 * 60000

      case 5:
        return currentTimestamp + 4 * 60 * 60000

      case 6:
        const offUntilMoment = currentMoment.startOf('day').add(1, 'days').hour(3)
        return offUntilMoment.valueOf()

      case 7:
      default:
        return 1e15
    }
  }
}
