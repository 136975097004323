import {auth, getCollection, getDocument} from 'f-core/src/config/firebase'

const usersModel = {
  state: {
    isLoadingAdminAnalytics: false,
    adminAnalytics: {},
  },
  reducers: {
    setIsLoadingAdminAnalytics: (state, isLoadingAdminAnalytics) => ({
      ...state,
      isLoadingAdminAnalytics,
    }),
    setAdminAnalytics: (state, adminAnalytics) => ({
      ...state,
      adminAnalytics,
      isLoadingAdminAnalytics: false,
    }),
  },
  actions: ({dispatch, getState}) => ({
    getIsLoadingAdminAnalytics() {
      return getState().users.isLoadingAdminAnalytics
    },
    getAdminAnalytics() {
      return getState().users.adminAnalytics
    },
    subscribeAdminAnalytics() {
      return getCollection('Analytics')
        .where('deviceInfo.bundleId', 'in', ['tech.foodly.admin', 'tech.foodly.admin.staging'])
        .onSnapshot((snapshot) => {
          const adminAnalytics = {}
          for (const doc of snapshot.docs) {
            adminAnalytics[doc.id] = doc.data()
            adminAnalytics[doc.id].id = doc.id
          }
          dispatch.users.setAdminAnalytics(adminAnalytics)
        })
    },

    updateUserAddresses(i, myAddresses, address, userId) {
      if (myAddresses && myAddresses.length !== 0) {
        const addresses = myAddresses.slice(0)
        addresses.splice(i, 1, address)
        return getDocument('readwrite', {userId}).update({addresses})
      } else {
        const addresses = []
        addresses.push(address)
        return getDocument('readwrite', {userId}).set({addresses}, {merge: true})
      }
    },
    getUserToken() {
      return auth?.currentUser?.getIdToken() ?? Promise.reject(new Error('User Not Logged In'))
    },
  }),
}

export default usersModel
