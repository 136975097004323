import React from 'react'
import {NavLink} from 'react-router-dom'
import {css} from 'emotion'

export default function FLinkAnimate(props) {
  return <NavLink className={classes.animateOnHover} activeClassName={classes.noTransform} exact {...props} />
}

const classes = {
  animateOnHover: css({
    ':hover': {
      transform: 'translateY(-4px)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
    ':link': {
      textDecoration: 'none',
      color: 'inherit',
    },
    ':visited': {
      textDecoration: 'none',
      color: 'inherit',
    },
  }),
  noTransform: css({
    ':hover': {
      transform: 'none',
    },
    ':link': {
      textDecoration: 'none',
      color: 'inherit',
    },
    ':visited': {
      textDecoration: 'none',
      color: 'inherit',
    },
  }),
}
