import React, {useEffect, useState, useRef} from 'react'
import useReactRouter from 'use-react-router'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import DateMomentUtils from '@date-io/moment'
import {useTheme} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import {FView, FText, FNavBar, FDrawer, ButtonFillView, FButton, FTextField} from 'components'
import {getReports, sendReport, resendReport, getDocument} from 'f-core/src/config/firebase'

const Reports = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const {match} = useReactRouter()
  const {restaurantId, locationId} = match.params

  const [selectedStartDate, setSelectedStartDate] = useState(moment().startOf('day'))
  const [selectedEndDate, setSelectedEndDate] = useState(moment().endOf('day'))
  const emailAddressRef = useRef(null)
  const [isFetching, setIsFetching] = useState(false)
  const [reportHistory, setReportHistory] = useState([])

  const weeklyEmailRef = useRef()
  const [isEditing, setIsEditing] = useState(false)

  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))

  useEffect(() => {
    getDocument('admin-readwrite', {restaurantId})
      .get()
      .then((snapshot) => {
        const reportEmail = snapshot?.data()?.reportEmail ?? null
        if (reportEmail && weeklyEmailRef.current) {
          weeklyEmailRef.current.value = reportEmail
        }
      })
  }, [restaurantId])

  useEffect(() => {
    return getReports({locationId}).onSnapshot((snapshot) => {
      const newReportHistory = []
      snapshot.forEach((doc) => {
        const report = doc.data()
        report.id = doc.id
        newReportHistory.push(report)
      })
      setReportHistory(newReportHistory)
    })
  }, [locationId])

  const createReport = () => {
    const emailAddress = emailAddressRef.current?.value ?? ''
    if (!emailAddress.match(/[\w-]+@([\w-]+\.)+[\w-]+/)) {
      alert('Invalid email')
      return
    }
    setIsFetching(true)
    const createdAtMin = selectedStartDate.valueOf()
    const createdAtMax = selectedEndDate.valueOf()
    sendReport({restaurantId, locationId, emailAddress, createdAtMin, createdAtMax})
      .then(() => (emailAddressRef.current.value = ''))
      .catch((e) => alert('Failed to send report. ' + e.message))
      .finally(() => setIsFetching(false))
  }
  return (
    <FView block h="100vh" bg={theme.palette.common.background}>
      <FView h={80}>
        <FNavBar shadow />
      </FView>
      <FView fill row bg={theme.palette.common.background} p={25}>
        <FDrawer locationData={locationData} />
        <FView fill justifyCenter alignCenter>
          <FView size={24} />
          <FView alignCenter>
            <FView size={20} />
            <FView h={500} w={300}>
              <FText bold h5>
                Weekly Report
              </FText>
              <FView size={16} />
              <FText body2>Add email to send last week's report every Wednesday at 8 AM.</FText>
              <FView size={4} />
              <FTextField margin="none" disabled={!isEditing} inputRef={weeklyEmailRef} />
              <FView size={16} />

              <FButton
                onClick={() => {
                  if (!isEditing) {
                    setIsEditing(true)
                  } else {
                    if (
                      !weeklyEmailRef.current.value.match(/[\w-]+@([\w-]+\.)+[\w-]+/) &&
                      weeklyEmailRef.current.value !== ''
                    ) {
                      alert(
                        'Invalid Report Email: Please enter a valid email address or leave it blank if you do not want to receive any emails',
                      )
                      return
                    }
                    getDocument('admin-readwrite', {restaurantId})
                      .set({reportEmail: weeklyEmailRef.current.value || null}, {merge: true})
                      .catch((e) => {
                        alert(e.message)
                      })
                      .finally(() => {
                        setIsEditing(false)
                      })
                  }
                }}>
                <ButtonFillView p={10} rounded>
                  <FText white>{isEditing ? 'Save' : 'Edit'}</FText>
                </ButtonFillView>
              </FButton>
              <FView size={48} />
              <FText bold h5>
                Generate Report Manually
              </FText>
              <FView h={16} />
              <MuiPickersUtilsProvider utils={DateMomentUtils}>
                <KeyboardDatePicker
                  margin="none"
                  label="Start Date"
                  format="MM/DD/YYYY"
                  value={selectedStartDate}
                  onChange={(date) => setSelectedStartDate(date.startOf('day'))}
                  KeyboardButtonProps={{
                    'aria-label': 'change start date',
                  }}
                />
                <FView h={16} />
                <KeyboardDatePicker
                  margin="none"
                  label="End Date"
                  format="MM/DD/YYYY"
                  value={selectedEndDate}
                  onChange={(date) => setSelectedEndDate(date.endOf('day'))}
                  KeyboardButtonProps={{
                    'aria-label': 'change end date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <FView h={16} />
              <FTextField margin="none" inputRef={emailAddressRef} placeholder="email address" type="email" />
              <FView h={16} />
              <FButton disabled={isFetching} onClick={createReport}>
                <ButtonFillView disabled={isFetching} p={10} rounded>
                  <FText white>{isFetching ? 'Generating...' : 'Send Report'}</FText>
                </ButtonFillView>
              </FButton>
            </FView>
          </FView>
          <FView mt={50} mh={100} maxWidth={600} maxHeight={'50vh'} overflowX="hidden" overflowY="auto">
            <FText h5>Report History</FText>
            <Grid container>
              {reportHistory.map((report) => {
                return (
                  <React.Fragment key={report.createdAt.toMillis()}>
                    <Grid spacing={0} container direction="row" justify="space-between" alignItems="center">
                      <Grid item>
                        <FText caption>{moment(report.reportStart.toMillis()).format('L')}</FText>
                        <FText caption>{moment(report.reportEnd.toMillis()).format('L')}</FText>
                      </Grid>
                      <Grid item>
                        <FView>
                          <FText>{report.to}</FText>
                        </FView>
                      </Grid>
                      <Grid item>
                        <FView>
                          <FText>{report.delivery ? report.delivery.state : 'pending'}</FText>
                        </FView>
                      </Grid>
                      <Grid item>
                        <FButton
                          disabled={!(report.delivery?.state === 'ERROR' || report.delivery?.state === 'SUCCESS')}
                          onClick={() => resendReport({emailId: report.id})}>
                          <ButtonFillView
                            disabled={!(report.delivery?.state === 'ERROR' || report.delivery?.state === 'SUCCESS')}
                            rounded
                            ph={10}>
                            <FText white>Resend</FText>
                          </ButtonFillView>
                        </FButton>
                      </Grid>
                    </Grid>
                    <FView h={60} />
                  </React.Fragment>
                )
              })}
            </Grid>
          </FView>
        </FView>
      </FView>
    </FView>
  )
}

export default Reports
