import {db, FieldValue, getCollection} from 'f-core/src/config/firebase'

let unsubOrders
let unsubDeliveryOrders

const ordersModel = {
  state: {
    orders: {},
    isLoadingOrders: false,
    activeDeliveryOrders: {},
    isLoadingDeliveryOrders: false,
    newOrderIds: [],
    prepOrderIds: [],
    doneOrderIds: [],
  },
  reducers: {
    setNewOrderIds(state, newOrderIds) {
      return {...state, newOrderIds}
    },
    setPrepOrderIds(state, prepOrderIds) {
      return {...state, prepOrderIds}
    },
    setDoneOrderIds(state, doneOrderIds) {
      return {...state, doneOrderIds}
    },
    setOrders(state, orders) {
      return {...state, orders}
    },
    setActiveDeliveryOrders(state, activeDeliveryOrders) {
      return {...state, activeDeliveryOrders, isLoadingDeliveryOrders: false}
    },
    setIsLoadingOrders(state, isLoadingOrders) {
      return {...state, isLoadingOrders}
    },
    setIsLoadingDeliveryOrders(state, isLoadingDeliveryOrders) {
      return {...state, isLoadingDeliveryOrders}
    },
  },
  actions: ({dispatch, getState}) => ({
    getOrders() {
      return getState().orders.orders
    },
    getNewOrderIds() {
      return getState().orders.newOrderIds
    },
    getPrepOrderIds() {
      return getState().orders.prepOrderIds
    },
    getDoneOrderIds() {
      return getState().orders.doneOrderIds
    },
    getIsLoadingOrders() {
      return getState().orders.isLoadingOrders
    },
    getIsLoadingDeliveryOrders() {
      return getState().orders.isLoadingDeliveryOrders
    },
    getActiveDeliveryOrders() {
      return getState().orders.activeDeliveryOrders
    },
    updateOrderCompletionTime({restaurantId, orderId, completionTime}) {
      return getCollection('Orders', {restaurantId}).doc(orderId).update({completionTime})
    },
    setOrderAdjustments({restaurantId, orderId, otherAdjustments}) {
      return getCollection('Orders', {restaurantId}).doc(orderId).update({otherAdjustments})
    },
    setOrderDeliveryStatus({restaurantId, orderId, deliveryStatus}) {
      switch (deliveryStatus) {
        case 'HeadingToCustomer':
          const pickupCompletionTime = new Date().valueOf()
          return getCollection('DeliveryInfo', {restaurantId})
            .doc(orderId)
            .update({deliveryStatus, pickupCompletionTime})
        case 'HeadingToRestaurant':
          return getCollection('DeliveryInfo', {restaurantId})
            .doc(orderId)
            .update({deliveryStatus, pickupCompletionTime: FieldValue.delete()})
        default:
          return new Promise.reject()
      }
    },
    subscribeActiveDeliveryOrders() {
      dispatch.orders.setIsLoadingDeliveryOrders(true)
      unsubDeliveryOrders && unsubDeliveryOrders()
      unsubDeliveryOrders = db
        .collectionGroup('DeliveryInfo')
        .where('deliveryStatus', 'in', [
          'Matching',
          'MatchingManager',
          'HeadingToRestaurant',
          'ArrivedAtRestaurant',
          'HeadingToCustomer',
        ])
        .onSnapshot((snapshot) => {
          const deliveryOrders = {}
          for (const doc of snapshot.docs) {
            deliveryOrders[doc.id] = doc.data()
            deliveryOrders[doc.id].id = doc.id
          }
          dispatch.orders.setActiveDeliveryOrders(deliveryOrders)
          if (dispatch.orders.getIsLoadingDeliveryOrders()) {
            dispatch.orders.setIsLoadingDeliveryOrders(false)
          }
        })
    },
    subscribeActiveOrders() {
      dispatch.orders.setIsLoadingOrders(true)
      unsubOrders && unsubOrders()
      unsubOrders = db
        .collectionGroup('Orders')
        .where('status', 'in', ['New', 'Preparing'])
        .orderBy('createdAt', 'desc')
        .limit(100)
        .onSnapshot((snapshot) => {
          const orders = {}
          const newOrderIds = []
          const prepOrderIds = []
          const doneOrderIds = []
          for (const doc of snapshot.docs) {
            const order = doc.data()
            order.id = doc.id
            orders[doc.id] = order
            if (order.status === 'New') {
              newOrderIds.push(doc.id)
            } else if (order.status === 'Preparing') {
              prepOrderIds.push(doc.id)
            } else {
              doneOrderIds.push(doc.id)
            }
          }
          dispatch.orders.setOrders(orders)
          dispatch.orders.setNewOrderIds(newOrderIds)
          dispatch.orders.setPrepOrderIds(prepOrderIds)
          dispatch.orders.setDoneOrderIds(doneOrderIds)
          if (dispatch.orders.getIsLoadingOrders()) {
            dispatch.orders.setIsLoadingOrders(false)
          }
        })

      return unsubOrders
    },
  }),
}

export default ordersModel
