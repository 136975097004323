import React, {useState, useRef, useEffect} from 'react'
import {colors} from 'styles'
import {moment} from 'f-utils'
import {useTheme, makeStyles} from '@material-ui/core/styles'
import {useDispatch, useSelector} from 'react-redux'
import {ChevronLeft, ChevronRight, Close} from '@material-ui/icons'
import {Grid, Paper, MenuItem} from '@material-ui/core'
import {Timestamp} from 'f-core/src/config/firebase'
import {Autocomplete} from '@material-ui/lab'
import DateMomentUtils from '@date-io/moment'
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers'
import {FView, FNavBar, FButton, FText, FTextField, ButtonFillView, LabelInputBox, FInput} from 'components'

let unsubThisWeeksSchedule = null

export default function Schedules() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isAdding, setIsAdding] = useState(false)
  const [dateVal, setDateVal] = useState(moment().startOf('isoWeek'))
  const [selectedName, setSelectedName] = useState(null)
  const [selectedStartDate, setSelectedStartDate] = useState(moment().startOf('day'))
  const [selectedEndDate, setSelectedEndDate] = useState(moment().endOf('day'))
  const regionRef = useRef()
  // Gets the couriers/riders from state
  useEffect(() => {
    return dispatch.riders.subscribeRiders()
  }, [dispatch.riders])
  const riders = useSelector(dispatch.riders.getRiders)

  const nameOptions = Object.values(riders).map((riderData) => ({
    name: riderData.name || 'Unknown',
    userId: riderData.id,
  }))
  // Gets all the schedules of this week
  useEffect(() => {
    unsubThisWeeksSchedule && unsubThisWeeksSchedule()
    const startDate = Timestamp.fromMillis(dateVal.valueOf())
    const endDate = Timestamp.fromMillis(moment(dateVal).add(6, 'days').endOf('day').valueOf())
    unsubThisWeeksSchedule = dispatch.schedules.subscribeThisWeeksSchedule({startDate, endDate})
    return unsubThisWeeksSchedule
  }, [dispatch.schedules, dateVal])
  const thisWeeksSchedules = useSelector(dispatch.schedules.getSchedules)
  // Filter and sort schedules according to weekday
  const dayScheduleRegions = {
    // [Region]: [[isoWeekday-1]:schedules]
  }
  const regionsArray = ['Coquitlam Centre', 'Lougheed', 'South Surrey']
  for (const region of regionsArray) {
    dayScheduleRegions[region] = [[], [], [], [], [], [], []]
  }
  for (const scheduleId in thisWeeksSchedules) {
    const dayOfWeek = moment(thisWeeksSchedules[scheduleId].start.toDate()).isoWeekday() - 1
    const region = thisWeeksSchedules[scheduleId].region
    if (!dayScheduleRegions[region]) {
      dayScheduleRegions[region] = [[], [], [], [], [], [], []]
    }
    dayScheduleRegions[region][dayOfWeek].push(thisWeeksSchedules[scheduleId])
  }
  for (const region in dayScheduleRegions) {
    for (let i = 0; i < dayScheduleRegions[region].length; i++) {
      dayScheduleRegions[region][i] = dayScheduleRegions[region][i].sort((a, b) => (a.start > b.start ? 1 : -1))
    }
  }

  const handleAdd = () => {
    setIsAdding(true)
    if (selectedStartDate > selectedEndDate) {
      setIsAdding(false)
      alert('Start date cannot be after end date')
      return
    } else if (!selectedName) {
      setIsAdding(false)
      alert('Please select a courier')
      return
    } else if (regionRef.current.value === '') {
      setIsAdding(false)
      alert('Please select a region')
      return
    }
    const scheduleData = {
      name: selectedName.name,
      userId: selectedName.userId,
      start: Timestamp.fromMillis(selectedStartDate.valueOf()),
      end: Timestamp.fromMillis(selectedEndDate.valueOf()),
      region: regionRef.current.value,
    }

    dispatch.schedules
      .addSchedule({scheduleData})
      .catch((e) => {
        alert(e)
      })
      .finally(() => setIsAdding(false))
  }
  const handleDelete = (scheduleId) => {
    dispatch.schedules.deleteSchedule({scheduleId})
  }
  const checkNoSchedules = (schedules, region) => {
    for (let i = 0; i < schedules.length; i++) {
      if (schedules[i].region === region) {
        return false
      }
    }
    return true
  }

  return (
    <FView fill bg={theme.palette.common.background}>
      <FNavBar shadow />
      <FView h={100} />
      <FView w="100%" maxWidth={440} bg={colors.white} p={15} mt={10} selfCenter>
        <MuiPickersUtilsProvider utils={DateMomentUtils}>
          <Grid container align="center" justify="center" spacing={2}>
            <Grid item xs={12}>
              <FView row center>
                <FButton
                  onClick={() => {
                    setDateVal(moment(dateVal).subtract(7, 'days'))
                  }}
                  disabled={isAdding}>
                  <ChevronLeft color="primary" fontSize="large" />
                </FButton>
                <FView w={120}>
                  <LabelInputBox>
                    <FInput disabled value={dateVal.format('YYYY-MM-DD')} />
                  </LabelInputBox>
                </FView>
                <FButton
                  onClick={() => {
                    setDateVal(moment(dateVal).add(7, 'days'))
                  }}
                  disabled={isAdding}>
                  <ChevronRight color="primary" fontSize="large" />
                </FButton>
              </FView>
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePicker
                margin="none"
                label="Date"
                format="MM/DD/YYYY"
                value={selectedStartDate}
                onChange={(date) => {
                  setSelectedStartDate(date)
                  setSelectedEndDate(moment(selectedEndDate).date(date.date()).month(date.month()).year(date.year()))
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change start date',
                }}
                disabled={isAdding}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyboardTimePicker
                margin="none"
                label="Start Time"
                value={selectedStartDate}
                onChange={setSelectedStartDate}
                KeyboardButtonProps={{
                  'aria-label': 'change start time',
                }}
                disabled={isAdding}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyboardTimePicker
                margin="none"
                label="End Time"
                value={selectedEndDate}
                onChange={setSelectedEndDate}
                KeyboardButtonProps={{
                  'aria-label': 'change end time',
                }}
                disabled={isAdding}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={nameOptions}
                onChange={(event, value) => {
                  setSelectedName(value)
                }}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.name === value.name}
                size="medium"
                disableClearable
                autoHighlight
                disabled={isAdding}
                renderInput={(params) => <FTextField {...params} required label="Name" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FTextField inputRef={regionRef} required disabled={isAdding} select defaultValue="" label="Region">
                {regionsArray.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </FTextField>
            </Grid>
            <Grid item xs={12}>
              <FButton
                onClick={(e) => {
                  if (e.keyCode === 13 || e.keyCode === 32) {
                    return
                  }
                  handleAdd()
                }}>
                <ButtonFillView w={200} rounded disabled={isAdding}>
                  <FText bold white>
                    {isAdding ? 'Adding...' : 'Add'}
                  </FText>
                </ButtonFillView>
              </FButton>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </FView>
      <FView h={20} />
      {/* Calendar */}
      <FView alignCenter>
        <FView maxWidth="100vw" overflowX="auto">
          <FView w={1440} bg={colors.white} mb={70}>
            <Grid container spacing={0} className={classes.grid}>
              {/* Days of the week */}
              <Grid container spacing={0}>
                <Grid item xs={1} />
                {[0, 1, 2, 3, 4, 5, 6].map((day) => {
                  const dayMoment = moment(dateVal).add(day, 'days')
                  const isSameDay = dayMoment.format('L') === moment().format('L')
                  return (
                    <Grid item xs key={day}>
                      <Paper variant="outlined" square>
                        <FView bc={isSameDay ? colors.primary : 'white'} bw={2}>
                          <FText bold={isSameDay} alignCenter>
                            {dayMoment.format('MM/DD')}
                          </FText>
                          <FText bold alignCenter>
                            {dayMoment.format('ddd')}
                          </FText>
                        </FView>
                      </Paper>
                    </Grid>
                  )
                })}
              </Grid>
              {Object.keys(dayScheduleRegions).map((region) => {
                const daySchedules = dayScheduleRegions[region]
                return (
                  <Grid container spacing={0} key={region}>
                    <Grid item xs={1}>
                      <Paper variant="outlined" square className={classes.paper}>
                        <FText bold alignCenter>
                          {region}
                        </FText>
                      </Paper>
                    </Grid>
                    {daySchedules.map((daySchedule, dayOfWeek) => {
                      return (
                        <Grid item xs key={dayOfWeek}>
                          <Paper
                            variant="outlined"
                            square
                            className={
                              checkNoSchedules(daySchedule, region) ? classes.paperNoSchedules : classes.paper
                            }>
                            {daySchedule.map((schedule) => {
                              return (
                                <FView
                                  row
                                  bg={`hsl(${intToDecimal(hashCode(schedule.name)) * 360}, 50%, 30%)`}
                                  rounded
                                  m={4}
                                  key={schedule.id}>
                                  <FView ml={5} mr={10}>
                                    <FText bold white>
                                      {schedule.name +
                                        ', ' +
                                        moment(schedule.start.toDate()).format('LT') +
                                        ' - ' +
                                        moment(schedule.end.toDate()).format('LT')}
                                    </FText>
                                  </FView>
                                  <FButton
                                    onClick={() => {
                                      handleDelete(schedule.id)
                                    }}>
                                    <FView center>
                                      <FText white noLineHeight>
                                        <Close fontSize="small" />
                                      </FText>
                                    </FView>
                                  </FButton>
                                </FView>
                              )
                            })}
                            <FView h={15} />
                          </Paper>
                        </Grid>
                      )
                    })}
                  </Grid>
                )
              })}
            </Grid>
          </FView>
        </FView>
      </FView>
    </FView>
  )
}

const useStyles = makeStyles((theme) => ({
  grid: {
    minWidth: 900,
  },
  paper: {
    minHeight: '170px',
    height: '100%',
  },
  paperNoSchedules: {
    minHeight: '170px',
    height: '100%',
    backgroundColor: getColor(0, 0.8, 0.5),
  },
}))
const hashCode = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}
const intToDecimal = (i) => {
  return (i & 0x7fffffff) / Math.pow(2, 31)
}
const getColor = (h, s, l) => {
  const [r, g, b] = hslToRgb(h, s, l)
  return `rgba(${r}, ${g}, ${b}, 0.7)`
}
/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
function hslToRgb(h, s, l) {
  var r, g, b
  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    var hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }
    var q = l < 0.5 ? l * (1 + s) : l + s - l * s
    var p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}
