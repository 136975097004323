import React, {useEffect, useState, useMemo} from 'react'
import {FView, FNavBar, FText, FButton} from 'components'
import {colors} from 'styles'
import {useDispatch, useSelector} from 'react-redux'
import ReactMapboxGl, {Marker, Layer, Feature} from 'react-mapbox-gl'
import {css} from 'emotion'
import {map} from 'lodash'
import {Store, LocalShipping, AccountCircle, DirectionsCar, ExpandLess, ExpandMore} from '@material-ui/icons'
import {useTheme} from '@material-ui/core/styles'
import {CircularProgress} from '@material-ui/core'
import {formatPhoneNumber} from 'f-utils'

const MapBox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
})
const CENTER = [-122.843813, 49.261139] // Half way between Lougheed and Coquitlam Centre
const INITIAL_ZOOM = [12]
const MAPBOX_STYLE = 'mapbox://styles/mapbox/streets-v9'
export default function DeliveryOrdersMap() {
  const [hideMap, setHideMap] = useState(false)
  const dispatch = useDispatch()

  const riders = useSelector(dispatch.riders.getActiveRiders)
  const deliveryOrders = useSelector(dispatch.orders.getActiveDeliveryOrders)
  const isLoadingDeliveryOrders = useSelector(dispatch.orders.getIsLoadingDeliveryOrders)
  useEffect(() => {
    return dispatch.riders.subscribeRiders()
  }, [dispatch.riders])
  useEffect(() => {
    return dispatch.orders.subscribeActiveDeliveryOrders()
  }, [dispatch.orders])

  const assignedRiders = useMemo(() => {
    const assignedRiders = {}
    for (const deliveryInfo of Object.values(deliveryOrders)) {
      if (deliveryInfo.riderUserId) {
        assignedRiders[deliveryInfo.riderUserId] = true
      }
    }
    return assignedRiders
  }, [deliveryOrders])
  return (
    <FView bg={colors.background} minHeight={'100vh'} overflowY="auto" overflowX="hidden">
      <FNavBar />
      <FView h={80} />
      <FView
        h={hideMap ? 80 : 300}
        w={300}
        overflowY="auto"
        absolute
        bottom={0}
        left={0}
        p={16}
        zIndex={420}
        bg="rgba(255,255,255,0.5)">
        {hideMap ? (
          <FButton onClick={() => setHideMap(false)} fill center>
            <FView>
              <ExpandLess />
            </FView>
          </FButton>
        ) : (
          <>
            <FView>
              <FButton onClick={() => setHideMap(true)} fill center>
                <ExpandMore />
              </FButton>
            </FView>
            <FView row justifyBetween alignCenter>
              <FText bold>Number of Active Riders: </FText> <FText bold>{Object.keys(riders).length}</FText>
            </FView>
            <FView h={8} />
            {Object.values(riders).map((rider) => (
              <FView key={rider.id} row justifyBetween alignCenter>
                <FText>{rider.name}</FText>
                <a href={'tel:' + rider.phoneNumber}>
                  <FText primary>{formatPhoneNumber(rider.phoneNumber)}</FText>
                </a>
              </FView>
            ))}
          </>
        )}
      </FView>

      {isLoadingDeliveryOrders && (
        <FView alignCenter center h={'100vh'}>
          <CircularProgress />
        </FView>
      )}
      <MapBox
        zoom={INITIAL_ZOOM}
        center={CENTER}
        // eslint-disable-next-line react/style-prop-object
        style={MAPBOX_STYLE}
        containerStyle={styles.mapboxContainerStyle}>
        {map(riders, (riderData) => {
          const {userLocation, name, id} = riderData
          if (!userLocation) {
            return null
          }
          const nowTimestamp = new Date().valueOf()
          const diff = nowTimestamp - userLocation.timestamp
          const maxDuration = 1000 * 60 * 60 * 12
          const opacity = diff > maxDuration ? 0.2 : 1 - (diff / maxDuration) * 0.8

          const minutes = diff / (1000 * 60)
          if (assignedRiders[id] !== true) {
            return (
              <Marker key={id} coordinates={[userLocation.longitude, userLocation.latitude]} className={classes.mapPin}>
                <FView relative alignCenter w={80}>
                  <DirectionsCar style={{opacity, fill: 'green'}} fontSize="large" />
                  <FView absolute center bottom={0} top={0} left={0} right={0}>
                    <PinText>{name}</PinText>
                    <PinText>{minutes > 60 ? (minutes / 60).toFixed(0) + 'h' : minutes.toFixed(0) + 'm'}</PinText>
                  </FView>
                </FView>
              </Marker>
            )
          }
        })}
        <DeliveryOrderMarkers deliveryOrders={deliveryOrders} />
      </MapBox>
    </FView>
  )
}

function DeliveryOrderMarkers({deliveryOrders}) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const riders = useSelector(dispatch.riders.getActiveRiders)
  return map(deliveryOrders, (deliveryOrder, orderId) => {
    const {
      deliveryAddressLatLng,
      deliveryStatus,
      orderNumber,
      restaurantName,
      customerName,
      restaurantAddressLatLng,
      riderUserId,

      riderName,
    } = deliveryOrder
    if (!deliveryAddressLatLng) {
      return null
    }

    const assignedRider = riders[riderUserId] ?? null
    const riderLocation = assignedRider?.userLocation
    return (
      <React.Fragment key={orderId}>
        <Marker coordinates={[restaurantAddressLatLng.lng, restaurantAddressLatLng.lat]} className={classes.mapPin}>
          <FView relative alignCenter w={80}>
            <Store style={{color: riderUserId ? theme.palette.info.main : theme.palette.primary.main}} />
            <FView absolute center bottom={0} top={0} left={0} right={0}>
              <PinText>{restaurantName}</PinText>
              <PinText>#{orderNumber}</PinText>
            </FView>
          </FView>
        </Marker>
        <Marker coordinates={[deliveryAddressLatLng.lng, deliveryAddressLatLng.lat]} className={classes.mapPin}>
          <FView relative alignCenter w={80}>
            <AccountCircle style={{color: riderUserId ? theme.palette.info.main : theme.palette.primary.main}} />
            <FView absolute center bottom={0} top={0} left={0} right={0}>
              <PinText>{customerName}</PinText>
            </FView>
          </FView>
        </Marker>

        {riderLocation && riderLocation.timestamp > 0 ? (
          <>
            <Marker coordinates={[riderLocation.longitude, riderLocation.latitude]} className={classes.mapPin}>
              <FView relative alignCenter w={80}>
                <LocalShipping style={{color: riderUserId ? theme.palette.info.main : theme.palette.secondary.main}} />
                <FView absolute center bottom={0} top={0} left={0} right={0}>
                  <PinText>{riderName}</PinText>
                </FView>
              </FView>
            </Marker>
            {deliveryStatus === 'HeadingToRestaurant' && (
              <Layer
                type="line"
                paint={{
                  'line-color': theme.palette.info.main,
                  'line-width': 2,
                }}>
                <Feature
                  coordinates={[
                    [riderLocation.longitude, riderLocation.latitude],
                    [restaurantAddressLatLng.lng, restaurantAddressLatLng.lat],
                    [deliveryAddressLatLng.lng, deliveryAddressLatLng.lat],
                  ]}
                />
              </Layer>
            )}

            {(deliveryStatus === 'ArrivedAtRestaurant' || deliveryStatus === 'HeadingToCustomer') && (
              <Layer
                type="line"
                paint={{
                  'line-color': theme.palette.info.main,
                  'line-width': 2,
                }}>
                <Feature
                  coordinates={[
                    [riderLocation.longitude, riderLocation.latitude],
                    [deliveryAddressLatLng.lng, deliveryAddressLatLng.lat],
                  ]}
                />
              </Layer>
            )}
          </>
        ) : (
          <Layer
            type="line"
            paint={{
              'line-color': riderUserId ? theme.palette.info.main : theme.palette.primary.main,
              'line-width': 2,
            }}>
            <Feature
              coordinates={[
                [restaurantAddressLatLng.lng, restaurantAddressLatLng.lat],
                [deliveryAddressLatLng.lng, deliveryAddressLatLng.lat],
              ]}
            />
          </Layer>
        )}
      </React.Fragment>
    )
  })
}

function PinText({children}) {
  return (
    <FText
      body2
      medium
      alignCenter
      style={{
        fontSize: '1rem',
        textShadow: '0px 0px 2px #FFF',
      }}>
      {children}
    </FText>
  )
}

const styles = {
  mapboxContainerStyle: {
    width: '100vw',
    height: 'calc(100vh - 80px)',
  },
}

const classes = {
  mapPin: css({
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  }),
  restaurantIconStyle: css({width: 85, height: 85, resizeMode: 'contain'}),
}
