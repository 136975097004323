import {db} from 'f-core/src/config/firebase'

const DEFAULT_STATE = {
  balance: 0,
  bankAccount: {
    accountNumber: '',
    address1: '',
    branchTransitNumber: '',
    city: '',
    companyName: '',
    firstName: '',
    lastName: '',
    country: '',
    financialInstitutionNumber: '',
    postalCode: '',
    province: '',
  },
}
const accountsModel = {
  state: DEFAULT_STATE,
  reducers: {
    setAccount(state, account) {
      return {...account}
    },
  },
  actions: ({dispatch, getState}) => ({
    getAccount() {
      return getState().accounts
    },
    updateAccount({accountId, account}) {
      return db.collection('Accounts').doc(accountId).update(account)
    },
    subscribeAccount(accountId) {
      return db
        .collection('Accounts')
        .doc(accountId)
        .onSnapshot((snapshot) => {
          dispatch.accounts.setAccount({...DEFAULT_STATE, ...snapshot.data()})
        })
    },
  }),
}

export default accountsModel
