import {FText, FView, FButton, ButtonFillView} from 'components'
import {Close} from '@material-ui/icons'
import {Divider, Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as utils from 'f-utils'

const ChangeStateDialog = React.memo(({open, setOpenChangeStatusDialog, orderId, restaurantId}) => {
  const dispatch = useDispatch()
  const orders = useSelector(dispatch.orders.getActiveDeliveryOrders)

  const handleClose = () => {
    setOpenChangeStatusDialog(false)
  }
  return (
    <Dialog fullWidth={false} maxWidth={'md'} open={open} onClose={handleClose}>
      <DialogTitle>
        <FView row alignCenter>
          <FView fill alignCenter ml={56}>
            <FText bold h5>
              Change Delivery Status
            </FText>
          </FView>
          <FButton onClick={handleClose}>
            <Close />
          </FButton>
        </FView>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <FView row fill mr={16}>
          <FText>Order number: #</FText>
          <FText bold>{orders[orderId]?.orderNumber}</FText>
        </FView>
        <FView row fill mr={16}>
          <FText>
            Delivery Status:{' '}
            <FText inline bold primary>
              {orders[orderId]?.deliveryStatus}
            </FText>
          </FText>
        </FView>
        <FView row fill mr={16} justifyBetween>
          <FView row>
            <FText span>
              Rider name:{' '}
              <FText inline bold>
                {orders[orderId]?.riderName}
              </FText>
            </FText>
          </FView>
        </FView>
        <FView row>
          <FText>
            Rider Phone Number:{' '}
            <a href={'tel:+1' + orders[orderId]?.riderPhoneNumber}>
              <FText inline bold primary>
                {utils.formatPhoneNumber(orders[orderId]?.riderPhoneNumber)}
              </FText>
            </a>
          </FText>
        </FView>
      </DialogContent>
      <DialogActions>
        <FView row fill mr={16} w={390} centerjustifyBetween>
          <FView h={42} mt={8} fill>
            <FButton
              disabled={orders[orderId]?.deliveryStatus === 'HeadingToRestaurant'}
              onClick={() => {
                if (orders[orderId].deliveryStatus === 'HeadingToRestaurant') {
                  alert('Already heading to restaurant')
                } else {
                  dispatch.orders.setOrderDeliveryStatus({restaurantId, orderId, deliveryStatus: 'HeadingToRestaurant'})
                  setOpenChangeStatusDialog(false)
                }
              }}>
              <ButtonFillView rounded disabled={orders[orderId]?.deliveryStatus === 'HeadingToRestaurant'}>
                <FText white bold>
                  Heading to Restaurant
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
          <FView w={16} />
          <FView h={42} mt={8} fill>
            <FButton
              disabled={orders[orderId]?.deliveryStatus === 'HeadingToCustomer'}
              onClick={() => {
                if (orders[orderId].deliveryStatus === 'HeadingToCustomer') {
                  alert('Already heading to customer')
                } else {
                  dispatch.orders.setOrderDeliveryStatus({restaurantId, orderId, deliveryStatus: 'HeadingToCustomer'})
                  setOpenChangeStatusDialog(false)
                }
              }}>
              <ButtonFillView rounded disabled={orders[orderId]?.deliveryStatus === 'HeadingToCustomer'}>
                <FText white bold>
                  Heading to Customer
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
      </DialogActions>
    </Dialog>
  )
})

export default ChangeStateDialog
