import React from 'react'
import {FView, FText, FNavBar, FButton} from 'components'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useTheme} from '@material-ui/core/styles'
import {Call, Map, MonetizationOn, NewReleases, People, Place, Block} from '@material-ui/icons'
import {Receipt, ShowChart, Store, SwapVert, LocalOffer, TableChart} from '@material-ui/icons'

export default function Home() {
  const isUpSm = useMediaQueryUp('sm')
  const theme = useTheme()

  return (
    <FView block h="100vh" bg={theme.palette.common.background}>
      <FView h={80}>
        <FNavBar shadow />
      </FView>
      <FView fill p={25} alignCenter bg={theme.palette.common.background}>
        <FView
          w={720}
          maxWidth="100vw"
          grid
          gridTemplateColumns={isUpSm ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)'}
          gap={20}>
          <FButton to="/analytics">
            <NavItemCard Icon={ShowChart} label="Analytics" />
          </FButton>
          <FButton to="/balances">
            <NavItemCard Icon={SwapVert} label="Balances" />
          </FButton>
          <FButton to="/riders-earnings">
            <NavItemCard Icon={MonetizationOn} label="Earnings" />
          </FButton>
          <FButton to="/deliveries">
            <NavItemCard Icon={Map} label="Map" />
          </FButton>
          <FButton to="/orders">
            <NavItemCard Icon={Receipt} label="Orders" />
          </FButton>
          {/* <FButton to="/point-of-sales">
            <NavItemCard Icon={Restaurant} label="Point of Sales" />
          </FButton> */}
          <FButton to="/promotions">
            <NavItemCard Icon={NewReleases} label="Promotions" />
          </FButton>
          <FButton to="/regions">
            <NavItemCard Icon={Place} label="Regions" />
          </FButton>
          <FButton to="/restaurants">
            <NavItemCard Icon={Store} label="Restaurants" />
          </FButton>
          {/* <FButton to="/schedules">
            <NavItemCard Icon={CalendarToday} label="Schedules" />
          </FButton> */}
          <FButton to="/support">
            <NavItemCard Icon={Call} label="Support" />
          </FButton>
          <FButton to="/tags">
            <NavItemCard Icon={LocalOffer} label="Tags" />
          </FButton>
          {/* <FButton to="/translations">
            <NavItemCard Icon={Translate} label="Translations" />
          </FButton> */}
          <FButton to="/users">
            <NavItemCard Icon={People} label="Users" />
          </FButton>
          <FButton to="/restaurants-info">
            <NavItemCard Icon={TableChart} label="Commissions" />
          </FButton>
          <FButton to="/blocklist">
            <NavItemCard Icon={Block} label="Blocklist" />
          </FButton>
        </FView>
      </FView>
    </FView>
  )
}

const NavItemCard = ({Icon, label}) => {
  const theme = useTheme()
  return (
    <FView fill center>
      <FView p={15} size={160} bg={theme.palette.common.white} center rounded shadow25>
        <Icon fontSize="large" />
        <FView size={10} />
        <FText body1 alignCenter>
          {label}
        </FText>
      </FView>
    </FView>
  )
}
