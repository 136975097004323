import React from 'react'
import {Provider} from 'react-redux'
import {store} from 'store'
import Router from './routes'
import {createMuiTheme, responsiveFontSizes, ThemeProvider} from '@material-ui/core/styles'
import {foodlyTheme} from 'f-web'
import {css} from 'emotion'

const theme = responsiveFontSizes(createMuiTheme(foodlyTheme))

export default function App() {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </Provider>
      {process.env.REACT_APP_ENVIRONMENT === 'dev' && <div className={classes.divDev} />}
    </>
  )
}

const classes = {
  divDev: css({
    position: 'absolute',
    zIndex: 9999,
    left: 0,
    top: 0,
    right: 0,
    height: 4,
    backgroundColor: 'orange',
  }),
}
