import React from 'react'
import {FText, FView, FButton, ButtonFillView} from 'components'
import {Error} from '@material-ui/icons'
import * as utils from 'f-utils'

const DeliveryOrderView = ({
  deliveryInfoData,
  setDialogOrderId,
  setDialogRestaurantId,
  setOpenAssignOrderDialog,
  setOpenDeliveryOrderDialog,
  setOpenChangeStatusDialog,
  assigned,
}) => {
  return (
    <FView bg={'#fff'} rounded p={16} mb={8} w={400} maxWidth={'100vw'} selfCenter>
      <FView row alignCenter justifyBetween>
        <FText body1 bold>
          #{deliveryInfoData.orderNumber}
        </FText>
        {!assigned && (
          <FView row alignCenter>
            <Error fontSize="small" color="error" />
            <FView w={4} />
            <FText bold error>
              Unassigned Order!!
            </FText>
          </FView>
        )}
      </FView>
      <FText body1>{deliveryInfoData.restaurantName}</FText>
      <FView alignStart>
        <a href={'tel:' + deliveryInfoData.restaurantPhoneNumber}>
          <FText body1 primary>
            {utils.formatPhoneNumber(deliveryInfoData.restaurantPhoneNumber)}
          </FText>
        </a>
      </FView>
      <FText span>
        Assigned to:{' '}
        <FText inline bold>
          {assigned ? deliveryInfoData.riderName ?? '' : 'Unassigned'}
        </FText>
      </FText>
      <FText span>
        Delivery Status:{' '}
        <FText inline bold primary>
          {deliveryInfoData.deliveryStatus}
        </FText>
      </FText>
      <FView size={16} />
      <FText body2 span>
        {'Pickup by: '}
        <FText body2 bold inline>
          {utils.moment(deliveryInfoData.pickupTime || deliveryInfoData.estimatedPickupTime).format('MMM D, LT')}
        </FText>
      </FText>
      <FText body2 span>
        {'Deliver by: '}
        <FText body2 bold inline>
          {utils.moment(deliveryInfoData.deliveryTime || deliveryInfoData.estimatedDeliveryTime).format('MMM D, LT')}
        </FText>
      </FText>
      <FView size={16} />
      <FText body1>{deliveryInfoData.customerName}</FText>
      <FView alignStart>
        <a href={'tel:' + deliveryInfoData.customerPhoneNumber}>
          <FText body2 primary>
            {utils.formatPhoneNumber(deliveryInfoData.customerPhoneNumber)}
          </FText>
        </a>
      </FView>
      <FView row alignCenter>
        <FView h={32} mt={8} fill>
          <FButton
            onClick={() => {
              setDialogOrderId(deliveryInfoData.orderId)
              setDialogRestaurantId(deliveryInfoData.restaurantId)
              setOpenAssignOrderDialog(true)
            }}>
            <ButtonFillView rounded>
              <FText white bold>
                {assigned ? 'Reassign Order' : 'Assign Order'}
              </FText>
            </ButtonFillView>
          </FButton>
        </FView>
        {assigned && (
          <>
            <FView w={8} />
            <FView h={32} mt={8} fill>
              <FButton
                onClick={() => {
                  setDialogOrderId(deliveryInfoData.orderId)
                  setDialogRestaurantId(deliveryInfoData.restaurantId)
                  setOpenChangeStatusDialog(true)
                }}>
                <ButtonFillView rounded>
                  <FText white bold>
                    Change Status
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
          </>
        )}
      </FView>
      <FView h={5} />
      <FView h={32} mt={8}>
        <FButton
          onClick={() => {
            setDialogOrderId(deliveryInfoData.orderId)
            setDialogRestaurantId(deliveryInfoData.restaurantId)
            setOpenDeliveryOrderDialog(true)
          }}>
          <ButtonFillView rounded>
            <FText white bold>
              Delay Order
            </FText>
          </ButtonFillView>
        </FButton>
      </FView>
    </FView>
  )
}

export default DeliveryOrderView
