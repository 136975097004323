import React from 'react'
import {useTheme} from '@material-ui/core/styles'
import {FNavLink, FView, FText, FNavBar} from 'components'

export default function Users() {
  const theme = useTheme()

  return (
    <FView block bg={theme.palette.common.background} minHeight="100vh">
      <FView h={80}>
        <FNavBar />
      </FView>
      <FView h={'100vh'} center w="100%">
        <FText h4 bold alignCenter>
          No Page Found
        </FText>
        <FNavLink to="/">
          <FText subtitle1>Go Home</FText>
        </FNavLink>
      </FView>
    </FView>
  )
}
