import React from 'react'
import {useDispatch} from 'react-redux'
import {Divider, Dialog, DialogTitle, DialogContent} from '@material-ui/core'
import {FView, FText, ButtonFillView, FButton} from 'components'
import moment from 'moment'

const DeleteConfirmationDialog = ({
  isDeletingAnnouncement,
  locationId,
  openDeleteConfirmation,
  restaurantId,
  setDescription,
  setIsDeletingAnnouncement,
  setOpenDeleteConfirmation,
  setSelectedEndDate,
  setSelectedStartDate,
  setTitle,
}) => {
  const dispatch = useDispatch()
  return (
    <Dialog fullWidth maxWidth="xs" open={openDeleteConfirmation} onClose={() => setOpenDeleteConfirmation(false)}>
      <DialogTitle>
        <FView center>
          <FText bold h6>
            Delete Announcement
          </FText>
        </FView>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <FText>Please confirm if you would like to delete this announcement.</FText>
        <FView alignCenter row mt={16} mb={8}>
          <FView fill>
            <FButton onClick={() => setOpenDeleteConfirmation(false)}>
              <ButtonFillView rounded>
                <FText white bold>
                  Cancel
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
          <FView w={16} />
          <FView fill>
            <FButton
              disabled={isDeletingAnnouncement}
              onClick={() => {
                setIsDeletingAnnouncement(true)
                dispatch.restaurants
                  .deleteAnnouncement({restaurantId, locationId})
                  .then(() => {
                    setSelectedStartDate(moment().startOf('day'))
                    setSelectedEndDate(moment().endOf('day'))
                    setTitle('')
                    setDescription('')
                    setOpenDeleteConfirmation(false)
                  })
                  .catch((e) => alert('Failed to delete announcement. ' + e.message))
                  .finally(() => setIsDeletingAnnouncement(false))
              }}>
              <ButtonFillView rounded disabled={isDeletingAnnouncement}>
                <FText white bold>
                  {isDeletingAnnouncement ? 'Deleting...' : 'Delete'}
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteConfirmationDialog
