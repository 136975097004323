import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {FView, FText, FNavBar} from 'components'
import {useTheme} from '@material-ui/core/styles'
import {DataGrid} from '@material-ui/data-grid'
import {isEmpty, merge} from 'lodash'

const columns = [
  {field: 'id', headerName: 'Location ID', width: 216},
  {field: 'restaurantName', headerName: 'Restaurant', width: 184},
  {field: 'availableOrderTypes', headerName: 'Order Types', width: 184},
  {field: 'commissionFee', headerName: 'Commission', width: 144},
  {field: 'commissionFeePickup', headerName: 'Pickup commission', width: 184},
  {field: 'commissionFeeDelivery', headerName: 'Delivery commission', width: 184},
  {field: 'commissionFeeDineIn', headerName: 'DineIn commission', width: 176},
  {field: 'commissionDirectPickup', headerName: 'Direct pickup commission', width: 208},
  {field: 'restaurantDeliveryFeeSubs', headerName: 'Restaurant delivery fee sub', width: 224},
  {
    field: 'restaurantDeliveryFeeSubsMinSubTotal',
    headerName: 'restaurantDeliveryFeeSubsMinSubTotal',
    width: 304,
  },
  {field: 'deliveryFee', headerName: 'Delivery Fee', width: 132},
  {field: 'deliveryFreeMinimumSubTotal', headerName: 'Minimum free delivery subtotal', width: 248},
  {field: 'isVisible', headerName: 'Visibility', width: 120},
]

const RestaurantsInfo = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [rows, setRows] = useState([])

  useEffect(() => {
    const getData = async () => {
      const locations = dispatch.restaurants.getLocations()
      const rowsData = []
      // a. go through all locations
      for (const locationData of Object.values(locations)) {
        const rowData = {id: locationData.locationId}

        // b. get all required fields needed
        for (const columnData of columns) {
          const {field} = columnData

          if (field !== 'id') {
            // c. check if field exists in either locationData or restaurantPrivateData
            if (locationData[field]) {
              // d. run extra checks for some specific fields
              if (field === 'availableOrderTypes') {
                rowData[field] = ''
                for (const orderType in locationData.availableOrderTypes) {
                  if (locationData.availableOrderTypes[orderType]) {
                    rowData[field] += orderType + ', '
                  }
                }
                rowData[field] = rowData[field].substring(0, rowData[field].length - 2)
              } else {
                rowData[field] =
                  typeof locationData[field] === 'object' && locationData[field] !== null
                    ? JSON.stringify(locationData[field])
                    : locationData[field]
              }
            } else {
              if (field === 'commissionFee') {
                rowData[field] = 'loading'
              } else {
                rowData[field] = null
              }
            }
          }
        }
        rowsData.push(rowData)
      }
      // e. set row data
      setRows(rowsData)

      let idIndex = 0
      for (const locationData of Object.values(locations)) {
        const restaurantPrivateData = await dispatch.restaurants.getPrivate({restaurantId: locationData.restaurantId})
        const rowData = {id: locationData.locationId}

        for (const columnData of columns) {
          const {field} = columnData
          if (field !== 'id') {
            if (restaurantPrivateData[field]) {
              rowData[field] =
                typeof restaurantPrivateData[field] === 'object' && restaurantPrivateData[field] !== null
                  ? JSON.stringify(restaurantPrivateData[field])
                  : restaurantPrivateData[field]
            }
          }
        }
        merge(rowsData[idIndex++], rowData)
        setRows([...rowsData])
      }
    }
    getData()
  }, [dispatch.restaurants])

  return (
    <FView fill bg={theme.palette.common.background}>
      <FNavBar shadow />
      <FView h={100} />
      <FView w="90%" selfCenter h="100%" p={16}>
        <FText h5 bold>
          Restaurants Data Table
        </FText>
        <FView bg="white" fill mt={8}>
          <DataGrid loading={isEmpty(rows)} rows={rows} columns={columns} />
        </FView>
      </FView>
    </FView>
  )
}

export default RestaurantsInfo
