import React, {useState, useEffect} from 'react'
import {moment} from 'f-utils'
import {FText} from 'components'

export default function Timer({createdAt, isSoundOn}) {
  const [timeString, setTimeString] = useState('--:--')

  useEffect(() => {
    const intervalID = setInterval(() => {
      const secondsDiff = Math.round((moment().valueOf() - createdAt) / 1000)
      const seconds = secondsDiff % 60
      const minutes = Math.floor(secondsDiff / 60) % 60
      const hours = Math.floor(secondsDiff / 60 / 60)
      setTimeString(
        `${hours.toFixed(0) + (hours > 0 ? ':' : '')}${minutes < 10 ? '0' : ''}${minutes.toFixed(0)}:${
          seconds < 10 ? '0' : ''
        }${seconds.toFixed(0)}`,
      )

      if (isSoundOn && minutes >= 3 && seconds === 0) {
        const notificationSound = new Audio('done-for-you.mp3')
        notificationSound.play()
      }
    }, 1000)
    return () => clearInterval(intervalID)
  }, [createdAt, isSoundOn])

  return (
    <>
      <FText bold>Ellapsed</FText>
      <FText bold error body2>
        {timeString}
      </FText>
    </>
  )
}
