import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FText, FView, FButton, FTextField, ButtonFillView} from 'components'
import {Close} from '@material-ui/icons'
import {Divider, Dialog, DialogTitle, DialogContent} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as utils from 'f-utils'
import {sortBy} from 'lodash'

const AssignOrderDialog = React.memo(({open, setOpenAssignOrderDialog, orderId, restaurantId}) => {
  const dispatch = useDispatch()

  const [isAssigning, setIsAssigning] = useState(false)
  const orders = useSelector(dispatch.orders.getActiveDeliveryOrders)
  const riders = useSelector(dispatch.riders.getRiders)
  const [riderItem, setRiderItem] = useState({name: null})
  const [riderItemInput, setRiderItemInput] = useState('')
  const sortedRiders = sortBy(Object.values(riders), (rider) => rider.name)

  useEffect(() => {
    if (open) {
      setRiderItem(riders[orders[orderId].riderUserId] ?? {name: null})
      setRiderItemInput(orders[orderId].riderName ?? '')
    }
  }, [open, orderId, orders, riders])

  const options = [
    {
      email: '',
      id: 'Matching',
      lastLogin: '',
      locationId: '',
      name: 'Unassign & Matching',
      notificationToken: '',
      phoneNumber: '',
      restaurantId: '',
      role: '',
    },
    {
      email: '',
      id: 'MatchingManager',
      lastLogin: '',
      locationId: '',
      name: 'Unassign & MatchingManager',
      notificationToken: '',
      phoneNumber: '',
      restaurantId: '',
      role: '',
    },
    {
      email: '',
      id: 'Failed',
      lastLogin: '',
      locationId: '',
      name: 'Failed',
      notificationToken: '',
      phoneNumber: '',
      restaurantId: '',
      role: '',
    },
    ...sortedRiders,
  ]

  const handleClose = () => {
    setRiderItem({name: null})
    setRiderItemInput('')
    setOpenAssignOrderDialog(false)
  }
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        <FView row alignCenter>
          <FView fill alignCenter ml={56}>
            <FText bold h5>
              Assign Order
            </FText>
          </FView>
          <FButton onClick={handleClose}>
            <Close />
          </FButton>
        </FView>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <FView row alignCenter>
          <FView fill mr={16}>
            <Autocomplete
              options={options}
              value={riderItem ?? {}}
              onChange={(event, value) => setRiderItem(value)}
              inputValue={riderItemInput ?? ''}
              onInputChange={(event, value) => setRiderItemInput(value)}
              getOptionLabel={(option) => option.name ?? ''}
              size="medium"
              disableClearable
              autoHighlight
              renderInput={(params) => <FTextField {...params} required />}
            />
          </FView>
          <FButton
            disabled={isAssigning || !riderItem.id}
            onClick={async () => {
              setIsAssigning(true)
              try {
                if (riderItem.id === 'Matching') {
                  await dispatch.riders.unassignRider({restaurantId, orderId, deliveryStatus: 'Matching'})
                } else if (riderItem.id === 'MatchingManager') {
                  await dispatch.riders.unassignRider({restaurantId, orderId, deliveryStatus: 'MatchingManager'})
                } else if (riderItem.id === 'Failed') {
                  await dispatch.riders.failDeliveryOrder({restaurantId, orderId, failedReason: 'Unknown'})
                } else {
                  await dispatch.riders.assignTargetRider({restaurantId, orderId, targetUserId: riderItem.id})
                }
              } catch (e) {
                alert('Error assigning order. ' + e.message)
              } finally {
                setIsAssigning(false)
                handleClose()
              }
            }}>
            <ButtonFillView rounded ph={16} disabled={isAssigning || !riderItem.id}>
              <FText white>{isAssigning ? 'Assigning...' : 'Assign'}</FText>
            </ButtonFillView>
          </FButton>
        </FView>
        <FText>
          Rider Phone Number:{' '}
          <FText inline bold>
            {utils.formatPhoneNumber(riderItem.phoneNumber)}
          </FText>
        </FText>
        <FView h={8} />
      </DialogContent>
    </Dialog>
  )
})

export default AssignOrderDialog
