import React, {useState, useRef} from 'react'
import {Dialog, DialogContent, DialogTitle, DialogActions, Select, MenuItem} from '@material-ui/core'
import {db, FieldValue, getCollection, getDocument} from 'f-core/src/config/firebase'
import {useTheme} from '@material-ui/core/styles'
import {FButton, FView, FText, FTextField} from 'components'
import {useDispatch} from 'react-redux'

const FPointsAddDialog = ({open, handleClose, userId}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [rewardType, setRewardType] = useState('Portal')
  const [rewardDescription, setRewardDescription] = useState('')
  const [rewardName, setRewardName] = useState('')
  const [isSending, setIsSending] = useState(false)
  const pointsRef = useRef()
  function checkValidity() {
    return pointsRef.current.reportValidity() && rewardDescription !== '' && rewardName !== ''
  }

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Add Points</DialogTitle>
      <DialogContent>
        <FView>
          <FText>Reward Type</FText>
          <Select value={rewardType} onChange={(e) => setRewardType(e.target.value)}>
            <MenuItem value={'Portal'}>Portal</MenuItem>
            <MenuItem value={'SubTotalPoints'}>SubTotalPoints</MenuItem>
            <MenuItem value={'RewardPointsRefund'}>RewardPointsRefund</MenuItem>
          </Select>
        </FView>
        <FView mt={15}>
          <FText>Title</FText>
          <FTextField margin={'none'} required onChange={(e) => setRewardName(e.target.value)} />
        </FView>
        <FView mt={16}>
          <FText>Description</FText>
          <FTextField margin={'none'} required onChange={(e) => setRewardDescription(e.target.value)} />
        </FView>
        <FView mt={16}>
          <FText>Points</FText>
          <FTextField margin={'none'} inputRef={pointsRef} required type="number" />
        </FView>
      </DialogContent>

      <DialogActions>
        <FView fill justifyEvenly bg={theme.palette.common.white} row>
          <FButton
            fill
            onClick={() => {
              handleClose()
            }}>
            <FView p={8} br={4} bg={theme.palette.primary.main} center>
              <FText bold white>
                Cancel
              </FText>
            </FView>
          </FButton>
          <FView w={8} />
          <FButton
            fill
            onClick={async () => {
              if (isSending) {
                return
              }
              if (!checkValidity()) {
                alert('rewardName and reward description cannot be empty')
                return
              }
              setIsSending(true)
              const pointsNum = Math.round(pointsRef.current.value)
              const rewardData = {
                createdAt: new Date().valueOf(),
                type: rewardType,
                points: pointsNum,
                name: rewardName,
                createdBy: dispatch.portal.getUserEmail(),
                description: rewardDescription,
              }

              const rewardHistoryDocRef = getCollection('RewardHistory', {userId}).doc()

              try {
                await db.runTransaction(async (t) => {
                  t.set(getDocument('readonly', {userId}), {fPoints: FieldValue.increment(pointsNum)}, {merge: true})
                  t.set(rewardHistoryDocRef, rewardData)
                })
                setRewardDescription('')
                setRewardName('')
                setRewardType('Portal')
                handleClose()
              } catch (e) {
                alert(e.message)
              } finally {
                setIsSending(false)
              }
            }}>
            <FView p={8} br={4} bg={isSending ? theme.palette.grey[400] : theme.palette.primary.main} center>
              <FText bold white>
                Add
              </FText>
            </FView>
          </FButton>
        </FView>
      </DialogActions>
    </Dialog>
  )
}
export default FPointsAddDialog
