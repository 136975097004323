import React, {useRef, useState, useEffect} from 'react'
import {useTheme, makeStyles} from '@material-ui/core/styles'
import {MenuItem} from '@material-ui/core'
import {getDocument, FieldValue} from 'f-core/src/config/firebase'
import {Delete} from '@material-ui/icons'
import {FNavBar, FView, FText, FTextField, ButtonFillView, FButtonA} from 'components'
import {clamp} from 'lodash'

export default function Tags() {
  const theme = useTheme()
  const [tagGroups, setTagGroups] = useState()
  const [productTags, setProductTags] = useState()
  const [tagColour, setTagColour] = useState()
  const classes = useStyle()

  useEffect(() => {
    return getDocument('public').onSnapshot((snapshot) => {
      const {tasteTags, productTags} = snapshot?.data()
      setTagGroups(tasteTags)
      setProductTags(productTags)
    })
  }, [])

  const tagInputRef = useRef()
  const imageInputRef = useRef()
  const [tagType, setTagType] = useState('None')

  return (
    <FView block bg={theme.palette.common.background} minHeight="100vh">
      <FView h={80}>
        <FNavBar />
      </FView>
      <FView fill p={25} alignCenter bg={theme.palette.common.background}>
        <FView w={600} maxWidth="100vw">
          <FText h4 bold>
            Tag Groups
          </FText>
          <FView size={16} />
          <FView row>
            <FTextField inputRef={tagInputRef} size="small" margin="none" label="Tag Name" />
            <FView w={16} />
            <FTextField
              value={tagType}
              onChange={(e) => {
                setTagType(e.target.value)
              }}
              select
              size="small"
              margin="none"
              label="Tag Type">
              <MenuItem value={'None'}>None</MenuItem>
              <MenuItem value={'ProductTag'}>Product Tag</MenuItem>
              <MenuItem value={'Category'}>Category</MenuItem>
            </FTextField>
            <FView w={16} />
            {tagType === 'ProductTag' && (
              <FView w={250}>
                <FTextField
                  inputProps={{
                    style: {padding: 4, paddingTop: 4, paddingBottom: 4, height: 32},
                  }}
                  value={tagColour}
                  onChange={(e) => setTagColour(e.target.value)}
                  size="small"
                  type="color"
                  margin="none"
                  label="Tag Colour"
                />
              </FView>
            )}
            <FButtonA
              onPress={() => {
                const tag = tagInputRef.current.value
                const type = tagType
                if (type === 'ProductTag') {
                  return getDocument('public').update({[`productTags.${tag}`]: tagColour})
                }
                const imageUrl = imageInputRef?.current?.value ?? ''
                return getDocument('public').update({
                  tasteTags: [
                    {
                      tag,
                      imageUrl,
                      type,
                    },
                    ...tagGroups,
                  ],
                })
              }}>
              <ButtonFillView w={100} h={40} mt={-10} rounded>
                <FText primaryContrast body1 bold>
                  Add
                </FText>
              </ButtonFillView>
            </FButtonA>
          </FView>
          <FView size={8} />
          <FView maxWidth={600} row alignCenter>
            {tagType === 'Category' && (
              <FTextField inputRef={imageInputRef} size="small" margin="none" label="Image Url" />
            )}
            <FView w={16} />
          </FView>
          <FView size={16} />
          <FText h5 bold>
            Product Tags
          </FText>
          <FView size={16} />
          <FView row flexWrap="wrap">
            {productTags &&
              Object.keys(productTags)
                ?.sort()
                .map((productTagName) => {
                  return (
                    <FView row alignCenter key={productTagName} mb={10}>
                      <FView w={60} mr={8} mb={24} />
                      <FView>
                        <FView size={56} br={6}>
                          <FView
                            size={56}
                            br={6}
                            style={{
                              backgroundColor: productTags[productTagName],
                            }}
                          />
                        </FView>
                        <FText>{productTagName}</FText>
                        <FText body2>(ProductTag)</FText>
                      </FView>
                      <FButtonA
                        mb={16}
                        onPress={() => {
                          getDocument('public').update({
                            [`productTags.${productTagName}`]: FieldValue.delete(),
                          })
                        }}>
                        <FView size={40} center>
                          <Delete color="primary" />
                        </FView>
                      </FButtonA>
                    </FView>
                  )
                })}
          </FView>
          <FView size={32} />
          <FText h5 bold>
            Category Tags
          </FText>
          <FView size={16} />
          <FView row flexWrap="wrap">
            {tagGroups?.map((tagGroup, index) => {
              return (
                <FView row alignCenter key={index} mb={10} mr={24}>
                  <FView w={60} mr={8} mb={24}>
                    <FTextField
                      className={classes.categoryIndexTextField}
                      InputProps={{className: classes.indexInputStyle}}
                      inputProps={{className: classes.indexInputNativeStyle}}
                      onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                          e.target.value = index
                          e.target.blur()
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          const fromIndex = index
                          const toIndex = e.target.value
                          const toIndexClamped = clamp(toIndex, 0, tagGroups.length - 1)
                          if (toIndexClamped === fromIndex) {
                            return
                          }
                          const newTagGroups = []
                          if (fromIndex > toIndexClamped) {
                            for (let i = 0; i < toIndexClamped; i++) {
                              if (i !== fromIndex) {
                                newTagGroups.push(tagGroups[i])
                              }
                            }
                            newTagGroups.push(tagGroups[fromIndex])
                            for (let i = toIndexClamped; i < tagGroups.length; i++) {
                              if (i !== fromIndex) {
                                newTagGroups.push(tagGroups[i])
                              }
                            }
                          } else {
                            for (let i = 0; i < toIndexClamped + 1; i++) {
                              if (i !== fromIndex) {
                                newTagGroups.push(tagGroups[i])
                              }
                            }
                            newTagGroups.push(tagGroups[fromIndex])
                            for (let i = toIndexClamped + 1; i < tagGroups.length; i++) {
                              if (i !== fromIndex) {
                                newTagGroups.push(tagGroups[i])
                              }
                            }
                          }
                          getDocument('public').update({
                            tasteTags: newTagGroups,
                          })
                        }
                      }}
                      defaultValue={index}
                      type="number"
                    />
                  </FView>
                  <FView>
                    <FView size={56} br={6}>
                      <img src={tagGroup.imageUrl} alt={tagGroup.tag} />
                    </FView>
                    <FText>{tagGroup.tag}</FText>
                    <FText body2>({tagGroup.type})</FText>
                  </FView>
                  <FButtonA
                    mb={16}
                    onPress={() => {
                      getDocument('public').update({
                        tasteTags: FieldValue.arrayRemove(tagGroup),
                      })
                    }}>
                    <FView size={40} center>
                      <Delete color="primary" />
                    </FView>
                  </FButtonA>
                </FView>
              )
            })}
          </FView>
        </FView>
      </FView>
    </FView>
  )
}

const useStyle = makeStyles((theme) => ({
  // Remove number up down arrows as it blocks index number from showing
  categoryIndexTextField: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  indexInputStyle: {
    height: 40,
  },
  indexInputNativeStyle: {
    textAlign: 'center',
  },
}))
