import React, {useRef, useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Close, Cancel} from '@material-ui/icons'
import {Dialog, DialogTitle, DialogActions, DialogContent} from '@material-ui/core'
import {KeyboardDateTimePicker, MuiPickersUtilsProvider, KeyboardTimePicker} from '@material-ui/pickers'
import {useTheme} from '@material-ui/core/styles'
import MomentUtils from '@date-io/moment'
import {moment} from 'f-utils'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {FView, FText, FButton, FTextField, ButtonOutlineView, ButtonFillView} from 'components'
import {Timestamp} from 'f-core/src/config/firebase'

const MenuEditDialog = React.memo(({locationId, restaurantId, menuId, open, setEditMenuId}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isUpSm = useMediaQueryUp('sm')

  const menuData = useSelector(() => dispatch.restaurants.getMenu({locationId, menuId}))

  const menuNameInputRef = useRef()
  const [activeFrom, setActiveFrom] = useState(null)
  const [activeUntil, setActiveUntil] = useState(null)
  const [updating, setUpdating] = useState(false)

  const [startDates, setStartDates] = useState({0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null})
  const [endDates, setEndDates] = useState({0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null})

  useEffect(() => {
    setStartDates({
      0: menuData?.hours?.[0]?.open ? moment(menuData.hours[0].open) : null,
      1: menuData?.hours?.[1]?.open ? moment(menuData.hours[1].open) : null,
      2: menuData?.hours?.[2]?.open ? moment(menuData.hours[2].open) : null,
      3: menuData?.hours?.[3]?.open ? moment(menuData.hours[3].open) : null,
      4: menuData?.hours?.[4]?.open ? moment(menuData.hours[4].open) : null,
      5: menuData?.hours?.[5]?.open ? moment(menuData.hours[5].open) : null,
      6: menuData?.hours?.[6]?.open ? moment(menuData.hours[6].open) : null,
    })
    setEndDates({
      0: menuData?.hours?.[0]?.close ? moment(menuData.hours[0].close) : null,
      1: menuData?.hours?.[1]?.close ? moment(menuData.hours[1].close) : null,
      2: menuData?.hours?.[2]?.close ? moment(menuData.hours[2].close) : null,
      3: menuData?.hours?.[3]?.close ? moment(menuData.hours[3].close) : null,
      4: menuData?.hours?.[4]?.close ? moment(menuData.hours[4].close) : null,
      5: menuData?.hours?.[5]?.close ? moment(menuData.hours[5].close) : null,
      6: menuData?.hours?.[6]?.close ? moment(menuData.hours[6].close) : null,
    })
    setActiveFrom(menuData?.activeFrom ? moment(menuData.activeFrom.toDate()) : null)
    setActiveUntil(menuData?.activeUntil ? moment(menuData.activeUntil.toDate()) : null)
  }, [menuData.hours, menuData.activeFrom, menuData.activeUntil])

  const handleSubmit = () => {
    if (!menuNameInputRef.current.value) {
      alert('Please fill out the name field.')
      return
    }
    if (activeFrom > activeUntil) {
      alert('Active from date cannot be after active until date.')
      return
    }
    const newMenu = {
      active: true,
      categoryOrder: [],
      hours: {
        0: {open: 0, close: 0},
        1: {open: 0, close: 0},
        2: {open: 0, close: 0},
        3: {open: 0, close: 0},
        4: {open: 0, close: 0},
        5: {open: 0, close: 0},
        6: {open: 0, close: 0},
      },
      ...menuData,
      activeFrom: activeFrom ? Timestamp.fromMillis(activeFrom.valueOf()) : null,
      activeUntil: activeUntil ? Timestamp.fromMillis(activeUntil.valueOf()) : null,
      name: menuNameInputRef.current.value,
    }

    for (const key in startDates) {
      newMenu.hours[key].open = startDates[key]?.valueOf() ?? null
      newMenu.hours[key].close = endDates[key]?.valueOf() ?? null
    }
    setUpdating(true)
    dispatch.restaurants
      .updateMenu({menuId, restaurantId, locationId, menu: newMenu})
      .then(() => setEditMenuId(null))
      .catch((e) => alert('Error updating menu. ' + e.message))
      .finally(() => setUpdating(false))
  }

  return (
    <Dialog fullWidth maxWidth="md" fullScreen={!isUpSm} open={open} onClose={() => setEditMenuId(null)}>
      <DialogTitle>
        <FView alignCenter row>
          <FView fill alignCenter ml={64}>
            <FText medium h5>
              {menuData?.name ? 'Update Menu' : 'Create Menu'}
            </FText>
          </FView>
          <FButton onClick={() => setEditMenuId(null)}>
            <Close />
          </FButton>
        </FView>
      </DialogTitle>
      <DialogContent>
        <FView fill center>
          <FTextField defaultValue={menuData.name} autoFocus inputRef={menuNameInputRef} required label="Menu Name" />
          <FView row mv={16}>
            <FView>
              <FText bold>Active From</FText>
              <FView row alignCenter>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker format="lll" value={activeFrom} onChange={(date) => setActiveFrom(date)} />
                </MuiPickersUtilsProvider>
                <FButton onClick={() => setActiveFrom(null)}>
                  <FView alignCenter>
                    <FText grey700 noLineHeight>
                      <Cancel />
                    </FText>
                  </FView>
                </FButton>
              </FView>
            </FView>
            <FView w={16} />
            <FView>
              <FText bold>Active Until</FText>
              <FView alignCenter row>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker format="lll" value={activeUntil} onChange={(date) => setActiveUntil(date)} />
                </MuiPickersUtilsProvider>
                <FButton onClick={() => setActiveUntil(null)}>
                  <FView alignCenter>
                    <FText grey700 noLineHeight>
                      <Cancel />
                    </FText>
                  </FView>
                </FButton>
              </FView>
            </FView>
          </FView>
          <FText h6 gutterBottom>
            Menu Available Hours
          </FText>
          {[1, 2, 3, 4, 5, 6, 0].map((day) => {
            return (
              <FView key={day} mv={8}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <FView row fill>
                    <FView selfCenter w={112}>
                      <FText bold>{moment().day(day).format('dddd')}:</FText>
                    </FView>
                    <FView row fill alignCenter>
                      <FView maxWidth={152}>
                        <KeyboardTimePicker
                          autoOk
                          value={startDates[day]}
                          onChange={(date) =>
                            setStartDates({
                              ...startDates,
                              [day]: date,
                            })
                          }
                          minutesStep={5}
                          clearable
                        />
                      </FView>
                      <FView mh={16}>
                        <FText>to</FText>
                      </FView>
                      <FView maxWidth={152}>
                        <KeyboardTimePicker
                          autoOk
                          value={endDates[day]}
                          onChange={(date) =>
                            setEndDates({
                              ...endDates,
                              [day]: date,
                            })
                          }
                          minutesStep={5}
                          clearable
                        />
                      </FView>
                    </FView>
                  </FView>
                </MuiPickersUtilsProvider>
              </FView>
            )
          })}
        </FView>
      </DialogContent>
      <FView h={1} bg={theme.palette.grey[300]} />
      <DialogActions>
        <FView fill center mv={8} row>
          <FView fill mh={8}>
            <FButton onClick={() => setEditMenuId(null)}>
              <ButtonOutlineView rounded selected={!updating}>
                <FText bold button primary grey500={updating}>
                  Cancel
                </FText>
              </ButtonOutlineView>
            </FButton>
          </FView>
          <FView fill mh={8}>
            <FButton onClick={handleSubmit} disabled={updating}>
              <ButtonFillView rounded disabled={updating}>
                <FText bold button white>
                  {updating ? 'Saving...' : 'Save'}
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
      </DialogActions>
    </Dialog>
  )
})

export default MenuEditDialog
