import {db} from 'f-core/src/config/firebase'
import {filter} from 'lodash'
import * as api from './api'

const ridersModel = {
  state: {
    courierRiders: {},
    superAdminRiders: {},
    riders: {},

    isLoadingDeliveryInfos: false,
    deliveryInfos: [],
  },
  reducers: {
    setCourierRiders(state, courierRiders) {
      return {...state, courierRiders}
    },
    setSuperAdminRiders(state, superAdminRiders) {
      return {...state, superAdminRiders}
    },
    setRiders(state, riders) {
      return {
        ...state,
        riders,
      }
    },
    updateRider(state, {riderId, riderData}) {
      return {
        ...state,
        riders: {
          ...state.riders,
          [riderId]: state.riders[riderId] ? {...state.riders[riderId], ...riderData} : riderData,
        },
      }
    },
    setDeliveryInfos(state, deliveryInfos) {
      return {...state, deliveryInfos, isLoadingDeliveryInfos: false}
    },
    setIsLoadingDeliveryInfos(state, isLoadingDeliveryInfos) {
      return {...state, isLoadingDeliveryInfos}
    },
  },
  actions: ({dispatch, getState}) => ({
    getRiders() {
      return getState().riders.riders
    },
    getActiveRiders() {
      const now = new Date()
      const result = {}
      const riders = getState().riders.riders
      for (const key of Object.keys(riders)) {
        if (riders[key]?.riderActiveUntil?.toDate() > now) {
          result[key] = riders[key]
        }
      }
      return result
    },
    getDeliveryInfos() {
      return getState().riders.deliveryInfos
    },
    getIsLoadingDeliveryInfos() {
      return getState().riders.isLoadingDeliveryInfos
    },
    async assignTargetRider({restaurantId, orderId, targetUserId}) {
      const authToken = await dispatch.users.getUserToken()
      return api.assignTargetRider({authToken, restaurantId, orderId, targetUserId})
    },
    async unassignRider({restaurantId, orderId, deliveryStatus}) {
      const authToken = await dispatch.users.getUserToken()
      return api.unassignRider({authToken, restaurantId, orderId, deliveryStatus})
    },
    async failDeliveryOrder({restaurantId, orderId, failedReason}) {
      const authToken = await dispatch.users.getUserToken()
      return api.failDeliveryOrder({authToken, restaurantId, orderId, failedReason})
    },
    subscribeDeliveryInfos({startTimestamp, endTimestamp}) {
      dispatch.riders.setIsLoadingDeliveryInfos(true)
      return db
        .collectionGroup('DeliveryInfo')
        .orderBy('createdAt', 'desc')
        .where('createdAt', '>=', startTimestamp)
        .where('createdAt', '<=', endTimestamp)
        .onSnapshot((snapshot) => {
          const deliveryInfos = []
          for (const doc of snapshot.docs) {
            deliveryInfos.push(doc.data())
          }
          dispatch.riders.setDeliveryInfos(deliveryInfos)
        })
    },

    subscribeRiders() {
      let unsubsRidersReadWrites = []
      const unsubRiders = db
        .collection('Users')
        .where('role', 'in', ['courier', 'courier_manager', 'super_admin', 'admin'])
        .onSnapshot((snapshot) => {
          const riders = {}
          for (const doc of snapshot.docs) {
            riders[doc.id] = doc.data()
            riders[doc.id].id = doc.id
            unsubsRidersReadWrites.push(
              doc.ref
                .collection('UserData')
                .doc('readwrite')
                .onSnapshot((readWriteDoc) => {
                  dispatch.riders.updateRider({
                    riderId: doc.id,
                    riderData: {userLocation: readWriteDoc.get('userLocation')},
                  })
                }),
            )
          }
          dispatch.riders.setRiders(riders)
        })
      return () => {
        unsubsRidersReadWrites.forEach((unsub) => unsub())
        unsubRiders()
      }
    },
  }),
}

export default ridersModel
