import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useReactRouter from 'use-react-router'
import {colors} from 'styles'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {FView, FText, FNavBar} from 'components'
import {Divider, Checkbox, CircularProgress} from '@material-ui/core'
import RegionDrawer from './RegionDrawer'
import RegionHours from './RegionHours'

export default function RegionInfo() {
  const {match} = useReactRouter()
  const {regionId} = match.params
  const dispatch = useDispatch()
  const isUpSm = useMediaQueryUp('sm')

  const regions = useSelector(dispatch.regions.getRegions)
  const locations = useSelector(dispatch.restaurants.getLocations)
  const filteredLocations = Object.values(locations).filter((location) => {
    return location.region === regionId
  })
  const unAssignedLocations = Object.values(locations).filter((location) => {
    return !location.region
  })
  const defaultChecked = {}
  for (const location of filteredLocations) {
    defaultChecked[location.locationId] = true
  }
  useEffect(() => {
    const _locations = dispatch.restaurants.getLocations()
    const _filteredLocations = Object.values(_locations).filter((location) => {
      return location.region === regionId
    })
    const _defaultChecked = {}
    for (const location of _filteredLocations) {
      _defaultChecked[location.locationId] = true
    }
    setLocationsChecked(_defaultChecked)
  }, [regionId, dispatch.restaurants])

  const [locationsChecked, setLocationsChecked] = useState({})
  const locationSize = Object.keys(filteredLocations).length
  const checkedSize = Object.keys(locationsChecked).length
  const isAllChecked = locationSize === Object.keys(locationsChecked).length

  useEffect(() => {
    return dispatch.regions.subscribeRegions()
  }, [dispatch.regions])

  if (!regions[regionId]) {
    return (
      <FView h="100vh" center>
        <CircularProgress />
      </FView>
    )
  }

  return (
    <FView fill bg={colors.background}>
      <FView h={80}>
        <FNavBar shadow={isUpSm} />
      </FView>
      <FView fill bg={colors.background} row>
        <RegionDrawer />
        <FView pt={isUpSm ? 16 : 64} w={256}>
          <FView fill mr={16}>
            <FView ph={8}>
              <FText h6>Restaurants</FText>
              <FText bold h7>
                Assigned Restaurants
              </FText>
            </FView>
            <FView row alignCenter>
              <Checkbox
                color="primary"
                disableRipple
                checked={checkedSize > 0}
                indeterminate={checkedSize > 0 && checkedSize < locationSize}
                onChange={(e, checked) => {
                  if (checked) {
                    setLocationsChecked(defaultChecked)
                  } else {
                    setLocationsChecked({})
                  }
                }}
              />

              <FText>All</FText>
            </FView>
            {filteredLocations.map((location) => (
              <FView row alignCenter key={location.locationId}>
                <Checkbox
                  color="primary"
                  disableRipple
                  checked={locationsChecked[location.locationId] ?? false}
                  onChange={(e, checked) => {
                    const newLocationsChecked = {...locationsChecked}
                    if (checked) {
                      newLocationsChecked[location.locationId] = true
                    } else {
                      delete newLocationsChecked[location.locationId]
                    }
                    setLocationsChecked(newLocationsChecked)
                  }}
                />
                <FView maxWidth={200}>
                  <FText>{location.restaurantName}</FText>
                </FView>
              </FView>
            ))}
            <Divider />

            <FView ph={8} mt={24}>
              <FText bold h7>
                Unassigned Restaurants
              </FText>
            </FView>
            {unAssignedLocations.map((location) => (
              <FView row alignCenter key={location.locationId}>
                <Checkbox
                  color="primary"
                  disableRipple
                  checked={locationsChecked[location.locationId] ?? false}
                  onChange={(e, checked) => {
                    const newLocationsChecked = {...locationsChecked}
                    if (checked) {
                      newLocationsChecked[location.locationId] = true
                    } else {
                      delete newLocationsChecked[location.locationId]
                    }
                    setLocationsChecked(newLocationsChecked)
                  }}
                />
                <FView maxWidth={200}>
                  <FText>{location.restaurantName}</FText>
                </FView>
              </FView>
            ))}
          </FView>
        </FView>
        <Divider orientation="vertical" />
        <FView fill alignCenter pt={isUpSm ? 16 : 64}>
          <FText h6>Delivery Hours</FText>
          <RegionHours
            hoursLT={regions[regionId].deliveryHoursLT}
            locationsChecked={locationsChecked}
            isAllChecked={isAllChecked}
            regionId={regionId}
          />
        </FView>
      </FView>
    </FView>
  )
}
