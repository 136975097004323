import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FView, FText} from 'components'
import {Select, MenuItem, LinearProgress} from '@material-ui/core'
import {getSquareLocations} from 'f-core/src/config/firebase'

const SquareLocations = ({restaurantId, locationId}) => {
  const dispatch = useDispatch()

  const selectedSquareLocation = useSelector(() => dispatch.restaurants.getSquareLocationId({locationId})) ?? ''
  const [squareLocations, setSquareLocations] = useState([])

  useEffect(() => {
    getSquareLocations({restaurantId, locationId})
      .then((res) => setSquareLocations(res.data))
      .catch((e) => console.warn(e.message))
  }, [locationId, restaurantId])

  return (
    <FView>
      <FText bold>Location: </FText>

      {squareLocations.length > 0 ? (
        <Select
          value={selectedSquareLocation}
          onChange={(e) => {
            dispatch.restaurants.updateSquareLocationId({restaurantId, locationId, squareLocationId: e.target.value})
          }}>
          {squareLocations.map((squareLocation) => {
            return (
              <MenuItem key={squareLocation.id} value={squareLocation.id}>
                {squareLocation.name}
              </MenuItem>
            )
          })}
        </Select>
      ) : (
        <FView mv={15}>
          <LinearProgress />
        </FView>
      )}
    </FView>
  )
}

export default SquareLocations
