import React, {useState, useEffect} from 'react'
import {FButton, FView, ButtonFillView, FText} from 'components'
import {useDispatch, useSelector} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import RegionTimePicker from './RegionTimePicker'

const RegionHours = ({hoursLT, regionId, locationsChecked, isAllChecked}) => {
  const dispatch = useDispatch()
  const [updating, setUpdating] = useState(false)
  const isUpSm = useMediaQueryUp('sm')
  const [newHoursLT, setNewHoursLT] = useState(hoursLT)
  const locations = useSelector(dispatch.restaurants.getLocations)

  useEffect(() => {
    setNewHoursLT(
      hoursLT ?? {
        0: [{openLT: null, closeLT: null}],
        1: [{openLT: null, closeLT: null}],
        2: [{openLT: null, closeLT: null}],
        3: [{openLT: null, closeLT: null}],
        4: [{openLT: null, closeLT: null}],
        5: [{openLT: null, closeLT: null}],
        6: [{openLT: null, closeLT: null}],
      },
    )
  }, [hoursLT])

  return (
    <FView>
      <RegionTimePicker newHoursLT={newHoursLT} setNewHoursLT={setNewHoursLT} />
      <FView fill center pv={isUpSm ? 16 : 8}>
        <FButton
          disabled={updating}
          onClick={() => {
            setUpdating(true)
            const updatePromises = []
            if (isAllChecked) {
              updatePromises.push(dispatch.regions.updateRegionHours({regionId, deliveryHoursLT: newHoursLT}))
            }
            for (const key in locationsChecked) {
              if (locationsChecked[key]) {
                const {restaurantId, locationId} = locations[key]
                updatePromises.push(
                  dispatch.restaurants.updateDeliveryHoursLT({restaurantId, locationId, deliveryHoursLT: newHoursLT}),
                )
              }
            }
            Promise.all(updatePromises)
              .catch((e) => alert('Error updating delivery times. ' + e.message))
              .finally(() => setUpdating(false))
          }}>
          <ButtonFillView rounded w={250} disabled={updating}>
            <FText body1 bold primaryContrast>
              {updating ? 'Updating Delivery Hours...' : 'Update Delivery Hours'}
            </FText>
          </ButtonFillView>
        </FButton>
      </FView>
    </FView>
  )
}

export default RegionHours
