import React, {useEffect, useState} from 'react'
import useReactRouter from 'use-react-router'
import {useDispatch, useSelector} from 'react-redux'
import {colors} from 'styles'
import {Checkbox, Switch} from '@material-ui/core'
import {FView, FText, FNavBar, FDrawer, FButton, FTextField, ButtonOutlineView} from 'components'
import {getDocument} from 'f-core/src/config/firebase'
import Delete from '@material-ui/icons/Delete'
import AddPrinterModal from './AddPrinterModal'
import {useMediaMinSM} from 'f-web/src/hooks'

const Receipt = () => {
  const dispatch = useDispatch()
  const {match} = useReactRouter()
  const {restaurantId, locationId} = match.params

  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const categories = useSelector(() => dispatch.restaurants.getCategoriesAll({locationId}))
  const printers = useSelector(() => dispatch.restaurants.getPrinters({locationId}))
  const isMinSM = useMediaMinSM()
  const [selectedDestination, setSelectedDestination] = useState(null)
  const [receiptDestinations, setReceiptDestinations] = useState({})
  const [isPrintOnConfirm, setIsPrintOnConfirm] = useState(false)
  const [newDstName, setNewDstName] = useState('')
  const [addPrinterModalOpen, setAddPrinterModalOpen] = useState(false)

  const privateDocRef = getDocument('locationPrivate', {restaurantId, locationId})

  const updateReceipts = async ({receiptDestinations}) => {
    try {
      return await privateDocRef.update({receiptDestinations})
    } catch (_) {
      return await privateDocRef.set({receiptDestinations}, {merge: true})
    }
  }

  const updatePrintOnConfirm = async (printOnConfirm) => {
    try {
      return await privateDocRef.update({printOnConfirm})
    } catch (_) {
      return await privateDocRef.set({printOnConfirm}, {merge: true})
    }
  }

  useEffect(() => {
    return dispatch.restaurants.subscribeCategories(restaurantId, locationId)
  }, [dispatch.restaurants, locationId, restaurantId])
  useEffect(() => {
    return dispatch.restaurants.subscribeProducts(restaurantId, locationId)
  }, [dispatch.restaurants, locationId, restaurantId])
  useEffect(() => {
    return dispatch.restaurants.subscribePrinters(restaurantId, locationId)
  }, [dispatch.restaurants, locationId, restaurantId])

  useEffect(() => {
    return getDocument('locationPrivate', {restaurantId, locationId}).onSnapshot((snapshot) => {
      if (snapshot) {
        setReceiptDestinations(snapshot.data()?.receiptDestinations ?? {})
        setIsPrintOnConfirm(snapshot.data()?.printOnConfirm ?? true)
      }
    })
  }, [locationId, restaurantId])
  return (
    <>
      <AddPrinterModal
        locationId={locationId}
        restaurantId={restaurantId}
        open={addPrinterModalOpen}
        handleClose={() => setAddPrinterModalOpen(false)}
      />
      <FView fill bg={colors.background}>
        <FView h={80}>
          <FNavBar shadow />
        </FView>
        <FView h={10} />
        <FView row>
          <FDrawer locationData={locationData} />
          <FView fill alignCenter>
            {!!selectedDestination || (
              <FView>
                <FText h6>Select or create a receipt to start!</FText>
              </FView>
            )}
            <FView row wrap mt={isMinSM ? '0' : '15%'} fill>
              {selectedDestination && (
                <FView shadow br={5} bg={colors.white} maxWidth={'600'} p={20} maxHeight={'80vh'} overflowY="auto">
                  <FView alignCenter>
                    <FText h5>Items</FText>
                    <FText body1>check items to be included at the selected receipt</FText>
                  </FView>
                  <FView ml={30} mt={30}>
                    <FView row alignCenter>
                      <FText>Is Customer Copy?</FText>
                      <Checkbox
                        color="primary"
                        checked={receiptDestinations[selectedDestination]?.isCustomerCopy ?? false}
                        onChange={(e) => {
                          if (selectedDestination === null || !receiptDestinations[selectedDestination]) {
                            alert('select a printer destination first')
                            return
                          }
                          receiptDestinations[selectedDestination].isCustomerCopy = e.target.checked
                          updateReceipts({receiptDestinations})
                        }}
                      />
                    </FView>
                    {Object.values(categories).map((category) => {
                      const products = dispatch.restaurants.getCategoryProductsArray({
                        locationId,
                        categoryId: category.categoryId,
                      })

                      const every = products.every((product) => {
                        return receiptDestinations[selectedDestination]?.productIds[product.productId] !== false
                      })
                      const some = products.some((product) => {
                        return receiptDestinations[selectedDestination]?.productIds[product.productId] !== false
                      })
                      return (
                        <FView key={category.categoryId}>
                          <FView row alignCenter>
                            <Checkbox
                              indeterminate={some && !every}
                              checked={some}
                              onChange={(e) => {
                                if (selectedDestination === null) {
                                  alert('select a printer destination first')
                                  return
                                }
                                products.forEach((product) => {
                                  receiptDestinations[selectedDestination].productIds[product.productId] =
                                    e.target.checked
                                })
                                updateReceipts({receiptDestinations})
                              }}
                              color="primary"
                            />
                            <FText body1>{category.name}</FText>
                          </FView>
                          {products.map((product) => {
                            const hasProduct =
                              receiptDestinations[selectedDestination]?.productIds[product.productId] !== false

                            return (
                              <FView key={product.productId} alignStart>
                                <FView row alignCenter ml={10}>
                                  <Checkbox
                                    checked={hasProduct}
                                    onChange={() => {
                                      if (selectedDestination === null || !receiptDestinations[selectedDestination]) {
                                        alert('select a printer destination first')
                                        return
                                      }

                                      if (hasProduct) {
                                        receiptDestinations[selectedDestination].productIds[product.productId] = false
                                        updateReceipts({receiptDestinations})
                                      } else {
                                        receiptDestinations[selectedDestination].productIds[product.productId] = true
                                        updateReceipts({receiptDestinations})
                                      }
                                    }}
                                    color="primary"
                                  />
                                  <FText body2>{product.name}</FText>
                                </FView>
                              </FView>
                            )
                          })}
                        </FView>
                      )
                    })}
                    <FText h6>Target Printers: </FText>
                    {Object.entries(printers || {}).map(([printerId, printer]) => {
                      const hasPrinter = !!receiptDestinations[selectedDestination]?.printerIds?.[printerId]
                      return (
                        <FView key={printerId} row alignCenter>
                          <Checkbox
                            checked={hasPrinter}
                            onChange={(e) => {
                              receiptDestinations[selectedDestination].printerIds =
                                receiptDestinations[selectedDestination].printerIds || {}
                              if (e.target.checked) {
                                receiptDestinations[selectedDestination].printerIds[printerId] = printerId
                              } else {
                                delete receiptDestinations[selectedDestination].printerIds[printerId]
                              }
                              updateReceipts({receiptDestinations})
                            }}
                            color="primary"
                          />
                          <FText body1>{printer.name}</FText>
                        </FView>
                      )
                    })}
                  </FView>
                </FView>
              )}
              <FView fill mt={32} ml={isMinSM ? 32 : 0} maxWidth="50vh">
                <FView>
                  <FView p={5} row alignCenter>
                    <FTextField
                      margin="none"
                      placeholder="Receipt Name"
                      onChange={(e) => {
                        setNewDstName(e.target.value)
                      }}
                    />
                    <FView mh={10}>
                      <FButton
                        onClick={() => {
                          if (!newDstName) {
                            alert('receipt name cannot be empty')
                            return
                          }
                          const receiptDestinationId = new Date().valueOf().toString()
                          receiptDestinations[receiptDestinationId] = {name: newDstName, productIds: {}, printerIds: {}}
                          updateReceipts({receiptDestinations})
                          setSelectedDestination(receiptDestinationId)
                        }}>
                        <FView br={5} shadow h={56} w={75} bg={colors.primary} justifyCenter>
                          <FText white>Add</FText>
                        </FView>
                      </FButton>
                    </FView>
                  </FView>

                  {Object.entries(receiptDestinations).map(([id, destination]) => {
                    return (
                      <FView key={id} row>
                        <FButton
                          onClick={() => {
                            setSelectedDestination(id)
                          }}
                          fill>
                          <ButtonOutlineView rounded m={5} selected={selectedDestination === id} h={60} row>
                            <FView p={10}>
                              <FText
                                subtitle1
                                color={selectedDestination === id ? colors.primary : colors.grey75}
                                h6
                                overflow="hidden"
                                textOverflow="ellipsis">
                                {destination.name}
                              </FText>
                            </FView>
                          </ButtonOutlineView>
                        </FButton>

                        <FButton
                          onClick={() => {
                            delete receiptDestinations[id]
                            setSelectedDestination(null)
                            updateReceipts({receiptDestinations})
                          }}>
                          <FView size={56} mh={20} center>
                            <Delete color="primary" />
                          </FView>
                        </FButton>
                      </FView>
                    )
                  })}
                </FView>
                <FView h={20} />
                <FView row alignCenter justifyBetween>
                  <FText>Toggle Print On Confirm</FText>
                  <FView mr={20}>
                    <Switch
                      color="primary"
                      checked={isPrintOnConfirm}
                      onChange={(e) => updatePrintOnConfirm(e.target.checked)}
                    />
                  </FView>
                </FView>
                <FView>
                  <FButton onClick={() => setAddPrinterModalOpen(true)}>
                    <FView mv={10} mr={30} p={20} br={5} bg={colors.primary} shadow>
                      <FText white>Add a printer manually</FText>
                    </FView>
                  </FButton>
                </FView>
              </FView>
            </FView>
          </FView>
        </FView>
      </FView>
    </>
  )
}

export default Receipt
