import React, {useState} from 'react'
import {colors} from 'styles'
import {filter} from 'lodash'
import {getCollection, getPrivateGroupDepositDesc} from 'f-core/src/config/firebase'
import {useDispatch, useSelector} from 'react-redux'
import {useTheme} from '@material-ui/core/styles'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {IconButton} from '@material-ui/core'
import {FView, FNavBar, ButtonFillView, FText, FInput, FButton} from 'components'
import {Visibility, VisibilityOff, Search} from '@material-ui/icons'
import PayoutDialog from './PayoutDialog'
import TransferDialog from './TransferDialog'

export default function Balances() {
  const theme = useTheme()
  const isUpSm = useMediaQueryUp('sm')

  const [accountDataList, setAccountDataList] = React.useState({})
  const [activePayoutAccountId, setActivePayoutAccountId] = useState(null)

  const [searchTerm, setSearchTerm] = React.useState('')
  const [showTakeFunds, setShowTakeFunds] = useState(false)

  const [activeTransferAccountId, setActiveTransferAccountId] = useState(null)
  const [activeTakeFundsAccountId, setActiveTakeFundsAccountId] = useState(null)

  React.useEffect(() => {
    return getCollection('Accounts').onSnapshot((snapshot) => {
      const accountDataList = {}
      for (const doc of snapshot.docs) {
        accountDataList[doc.id] = doc.data()
        accountDataList[doc.id].id = doc.id
      }
      setAccountDataList(accountDataList)
    })
  }, [])

  const onSearchTermChange = React.useCallback((e) => {
    setSearchTerm(e.target.value)
  }, [])

  const accountsList = Object.values(accountDataList)
  const filteredAccountsList = !!searchTerm
    ? filter(accountsList, (accountData) => accountData.ownerEmail?.toLowerCase().includes(searchTerm.toLowerCase()))
    : accountsList

  return (
    <>
      <FView block bg={theme.palette.common.background} h={'100vh'}>
        <FView h={130}>
          <FNavBar />
          <FView fixed zIndex={theme.zIndex.appBar} top={80} left={0} right={0} shadow10>
            <FView h={1} bg={theme.palette.grey[300]} />
            <FView row alignCenter ph={isUpSm ? 64 : 16} h={50} bg={colors.white}>
              <Search color="action" />
              <FInput value={searchTerm} onChange={onSearchTermChange} placeholder="Search for an account email" />
            </FView>
          </FView>
        </FView>
        <FView bg={colors.background} p={25} alignCenter>
          <FView w={600} maxWidth="100vw">
            <FView ph={isUpSm ? 0 : 16} pv={16} row justifyBetween alignCenter>
              <FText medium h4>
                Accounts Balances
              </FText>
            </FView>
            <FView maxWidth={150}>
              <FButton onPress={() => setShowTakeFunds(!showTakeFunds)}>
                <ButtonFillView rounded>
                  <FText primaryContrast body1 bold>
                    {showTakeFunds ? 'Hide' : 'Show'} Take Funds
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
            <FView w="100%" maxWidth={768} bg={colors.white} p={15}>
              {filteredAccountsList.map((accountData) => {
                return (
                  <FView key={accountData.id}>
                    <FView row justifyBetween alignCenter mb={10}>
                      <FText body1 gutterBottom>
                        {accountData.ownerEmail}
                      </FText>
                      <FView w={10} />
                      <FView row alignCenter>
                        <FView fill alignStart>
                          <FText body1>${accountData.balance?.toFixed(2) ?? 'loading...'}</FText>
                        </FView>
                        {showTakeFunds && (
                          <>
                            <FView w={10} />
                            <FButton
                              onPress={() => {
                                setActiveTakeFundsAccountId(accountData.id)
                              }}>
                              <ButtonFillView w={80} rounded p={10}>
                                <FText primaryContrast body1 bold>
                                  Take $
                                </FText>
                              </ButtonFillView>
                            </FButton>
                          </>
                        )}
                        <FView w={10} />
                        <FButton
                          onPress={() => {
                            setActiveTransferAccountId(accountData.id)
                          }}>
                          <ButtonFillView w={80} rounded p={10}>
                            <FText primaryContrast body1 bold>
                              Send $
                            </FText>
                          </ButtonFillView>
                        </FButton>
                        <FView w={10} />
                        <FButton
                          onPress={() => {
                            setActivePayoutAccountId(accountData.id)
                          }}>
                          <ButtonFillView w={80} rounded p={10}>
                            <FText primaryContrast body1 bold>
                              Payout
                            </FText>
                          </ButtonFillView>
                        </FButton>
                      </FView>
                    </FView>
                  </FView>
                )
              })}
            </FView>
          </FView>
        </FView>
      </FView>
      <TransferDialog
        open={!!activeTakeFundsAccountId}
        handleClose={() => {
          setActiveTakeFundsAccountId(null)
        }}
        isTakeFunds
        sourceAccountId={activeTakeFundsAccountId}
        destinationAccountId={'foodly'}
        balance={accountDataList[activeTakeFundsAccountId]?.balance}
        ownerEmail={accountDataList[activePayoutAccountId]?.ownerEmail}
      />
      <TransferDialog
        open={!!activeTransferAccountId}
        handleClose={() => {
          setActiveTransferAccountId(null)
        }}
        sourceAccountId={'foodly'}
        destinationAccountId={activeTransferAccountId}
        balance={accountDataList[activeTransferAccountId]?.balance}
        ownerEmail={accountDataList[activePayoutAccountId]?.ownerEmail}
      />
      <PayoutDialog
        open={!!activePayoutAccountId}
        handleClose={() => {
          setActivePayoutAccountId(null)
        }}
        balance={accountDataList[activePayoutAccountId]?.balance}
        accountId={activePayoutAccountId}
        ownerEmail={accountDataList[activePayoutAccountId]?.ownerEmail}
      />
    </>
  )
}
