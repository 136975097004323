import React, {useEffect} from 'react'
import {colors} from 'styles'
import {useDispatch} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {FView, FNavBar, FText} from 'components'
import RegionDrawer from './RegionDrawer'

export default function Regions() {
  const dispatch = useDispatch()
  const isUpSm = useMediaQueryUp('sm')

  useEffect(() => {
    return dispatch.regions.subscribeRegions()
  }, [dispatch.regions])

  return (
    <FView fill bg={colors.background}>
      <FView h={80}>
        <FNavBar shadow={isUpSm} />
      </FView>
      <FView fill bg={colors.background} row>
        <RegionDrawer />
        <FView fill alignCenter pt={isUpSm ? 25 : 75} pb={25}>
          <FText h4 bold>
            Select a Region on the left
          </FText>
        </FView>
      </FView>
    </FView>
  )
}
