import React, {useState} from 'react'
import {FView, FText, FButton, ButtonFillView, ButtonOutlineView} from 'components'
import {useDispatch} from 'react-redux'
import {Dialog, DialogContent, DialogTitle, Divider} from '@material-ui/core'

const MenuDeleteDialog = React.memo(({open, setDeleteMenuId, menuId, restaurantId, locationId}) => {
  const dispatch = useDispatch()
  const [isDeletingMenu, setIsDeletingMenu] = useState(false)

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setDeleteMenuId(null)}>
      <DialogTitle>
        <FView center>
          <FText h5 bold>
            Delete Menu
          </FText>
        </FView>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <FText>Please confirm to delete menu.</FText>
        <FView row alignCenter mt={16} mb={8}>
          <FView fill>
            <FButton onClick={() => setDeleteMenuId(null)}>
              <ButtonOutlineView selected rounded>
                <FText primary bold>
                  Cancel
                </FText>
              </ButtonOutlineView>
            </FButton>
          </FView>
          <FView w={16} />
          <FView fill>
            <FButton
              disabled={isDeletingMenu}
              onClick={() => {
                setIsDeletingMenu(true)
                dispatch.restaurants
                  .deleteMenu({restaurantId, locationId, menuId})
                  .then(() => setDeleteMenuId(null))
                  .catch((e) => alert('Error deleting menu. ' + e.message))
                  .finally(() => setIsDeletingMenu(false))
              }}>
              <ButtonFillView rounded disabled={isDeletingMenu}>
                <FText white bold>
                  {isDeletingMenu ? 'Deleting...' : 'Delete'}
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
      </DialogContent>
    </Dialog>
  )
})

export default MenuDeleteDialog
