import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useReactRouter from 'use-react-router'
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers'
import {Grid, Divider} from '@material-ui/core'
import DateMomentUtils from '@date-io/moment'
import {Timestamp} from 'f-core/src/config/firebase'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {FView, FText, FNavBar, FDrawer, ButtonFillView, FButton, FTextField} from 'components'
import moment from 'moment'
import {colors} from 'styles'

import DeleteConfirmationDialog from './DeleteConfirmationDialog'

export default function Announcement() {
  const {match} = useReactRouter()
  const dispatch = useDispatch()
  const {restaurantId, locationId} = match.params
  const isUpSm = useMediaQueryUp('sm')

  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const [selectedStartDate, setSelectedStartDate] = useState(moment().startOf('day'))
  const [selectedEndDate, setSelectedEndDate] = useState(moment().endOf('day'))

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [isDeletingAnnouncement, setIsDeletingAnnouncement] = useState(false)
  const [isSavingAnnouncement, setIsSavingAnnouncement] = useState(false)

  useEffect(() => {
    if (locationData.announcement) {
      const announcementData = locationData.announcement
      setSelectedStartDate(moment(announcementData.activeAfter?.toDate()))
      setSelectedEndDate(moment(announcementData.activeUntil?.toDate()))
      setTitle(announcementData.title)
      setDescription(announcementData.message)
    }
  }, [locationData])

  return (
    <FView fill bg={colors.background}>
      <FView h={80}>
        <FNavBar shadow />
      </FView>
      <FView fill bg={colors.background} row>
        <FDrawer locationData={locationData} />
        <FView fill alignCenter pt={isUpSm ? 25 : 75} pb={24}>
          <FView bg="white" maxWidth={600}>
            <FView pv={16} center>
              <FText h5 bold>
                Announcement
              </FText>
            </FView>
            <MuiPickersUtilsProvider utils={DateMomentUtils}>
              <Grid container align="center" justify="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    margin="none"
                    label="Start Date"
                    format="MM/DD/YYYY"
                    value={selectedStartDate}
                    onChange={(date) => setSelectedStartDate(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change start date',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    margin="none"
                    label="End Date"
                    format="MM/DD/YYYY"
                    value={selectedEndDate}
                    onChange={(date) => setSelectedEndDate(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change end date',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardTimePicker
                    margin="none"
                    label="Start Time"
                    value={selectedStartDate}
                    onChange={(date) => setSelectedStartDate(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change start time',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardTimePicker
                    margin="none"
                    label="End Time"
                    value={selectedEndDate}
                    onChange={(date) => setSelectedEndDate(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change end time',
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <FView mh={40} mv={16}>
              <FTextField label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
              <FTextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows="8"
              />
            </FView>
            <Divider />
            <FView row alignCenter mv={16} mh={24}>
              <FView>
                <FButton onClick={() => setOpenDeleteConfirmation(true)}>
                  <FView ph={16}>
                    <FText>Delete</FText>
                  </FView>
                </FButton>
              </FView>
              <FView w={80} />
              <FView fill>
                <FButton
                  disabled={isSavingAnnouncement}
                  onClick={() => {
                    if (!title) {
                      alert('Please input a title.')
                      return
                    }
                    if (!description) {
                      alert('Please input a description.')
                      return
                    }
                    if (selectedStartDate > selectedEndDate) {
                      alert('Start date cannot be after end date.')
                      return
                    }
                    setIsSavingAnnouncement(true)
                    const announcement = {
                      activeAfter: Timestamp.fromMillis(selectedStartDate.valueOf()),
                      activeUntil: Timestamp.fromMillis(selectedEndDate.valueOf()),
                      title: title,
                      message: description,
                    }
                    dispatch.restaurants
                      .addAnnouncement({restaurantId, locationId, announcement})
                      .catch((e) => alert('Failed to save announcement. ' + e.message))
                      .finally(() => setIsSavingAnnouncement(false))
                  }}>
                  <ButtonFillView disabled={isSavingAnnouncement} rounded>
                    <FText white bold>
                      {isSavingAnnouncement ? 'Saving...' : 'Save'}
                    </FText>
                  </ButtonFillView>
                </FButton>
              </FView>
            </FView>
          </FView>
        </FView>
      </FView>
      <DeleteConfirmationDialog
        isDeletingAnnouncement={isDeletingAnnouncement}
        locationId={locationId}
        openDeleteConfirmation={openDeleteConfirmation}
        restaurantId={restaurantId}
        setDescription={setDescription}
        setIsDeletingAnnouncement={setIsDeletingAnnouncement}
        setOpenDeleteConfirmation={setOpenDeleteConfirmation}
        setSelectedEndDate={setSelectedEndDate}
        setSelectedStartDate={setSelectedStartDate}
        setTitle={setTitle}
      />
    </FView>
  )
}
