import React from 'react'
import {Grid} from '@material-ui/core'
import {FView, FText} from 'components'
import {isEmpty} from 'lodash'

const AnalyticsData = ({
  cashRevenue,
  commissionTaxes,
  courierTips,
  deliveryFees,
  foodDelayContributions,
  foodSubTotal,
  fPointsDiscount,
  grossEarnings,
  inpersonCourierTips,
  inpersonPickupTips,
  inpersonTotal,
  netDirectDeposit,
  networkAccessFees,
  onlineTotal,
  otherAdjustments,
  pickupTips,
  revenue,
  subsidizedDeliveryFees,
  taxAmount,
  taxes,
  total,
  totalCommission,

  foodlyEarnedOnlineTip,
  foodlyEarnedOnlineDeliveryFee,
  foodlyEarnedOnlineDeliveryFeeTax,
}) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FView row justifyBetween alignCenter>
            <FText h6 bold>
              Total:
            </FText>
            <FText h6 bold primary>
              ${total.toFixed(2)}
            </FText>
          </FView>
          <FView row justifyBetween alignCenter>
            <FText body1 bold grey700>
              Online Total:
            </FText>
            <FText body1 bold grey700>
              ${onlineTotal.toFixed(2)}
            </FText>
          </FView>
          <FView row justifyBetween alignCenter>
            <FText body2 bold grey700>
              Foodly Earned Online Delivery Tip:
            </FText>
            <FText body2 bold grey700>
              ${foodlyEarnedOnlineTip.toFixed(2)}
            </FText>
          </FView>
          <FView row justifyBetween alignCenter>
            <FText body2 bold grey700>
              Foodly Earned Online Delivery Fee:
            </FText>
            <FText body2 bold grey700>
              ${foodlyEarnedOnlineDeliveryFee.toFixed(2)}
            </FText>
          </FView>
          <FView row justifyBetween alignCenter>
            <FText body2 bold grey700>
              Foodly Earned Online Delivery Fee Tax:
            </FText>
            <FText body2 bold grey700>
              ${foodlyEarnedOnlineDeliveryFeeTax.toFixed(2)}
            </FText>
          </FView>
          <FView row justifyBetween alignCenter>
            <FText body1 bold grey700>
              Inperson Total:
            </FText>
            <FText body1 bold grey700>
              ${inpersonTotal.toFixed(2)}
            </FText>
          </FView>
          {fPointsDiscount > 0 && (
            <>
              <FView row justifyBetween alignCenter>
                <FText body1 bold grey700>
                  Foodly Discount:
                </FText>
                <FText body1 bold grey700>
                  (${fPointsDiscount.toFixed(2)})
                </FText>
              </FView>
              <FText body2 bold grey700>
                (Foodly paid for the discount)
              </FText>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FView row justifyBetween alignCenter>
            <FText h6 bold>
              Gross Earnings:
            </FText>
            <FText h6 bold primary>
              ${grossEarnings.toFixed(2)}
            </FText>
          </FView>
          <FView row justifyBetween alignCenter>
            <FText body1 bold grey700>
              Food Subtotal:
            </FText>
            <FText body1 bold grey700>
              ${foodSubTotal.toFixed(2)}
            </FText>
          </FView>
          {deliveryFees != null && deliveryFees !== 0 && (
            <FView row justifyBetween alignCenter>
              <FText body1 bold grey700>
                Delivery Fees:
              </FText>
              <FText body1 bold grey700>
                ${deliveryFees.toFixed(2)}
              </FText>
            </FView>
          )}
          {isEmpty(taxes) ? (
            <FView row justifyBetween alignCenter>
              <FText body1 bold grey700>
                Taxes:
              </FText>
              <FText body1 bold grey700>
                ${taxAmount.toFixed(2)}
              </FText>
            </FView>
          ) : (
            Object.entries(taxes).map(([taxId, taxData]) => (
              <FView row justifyBetween alignCenter key={taxId}>
                <FText body1 bold grey700>
                  {taxData.name}:
                </FText>
                <FText body1 bold grey700>
                  ${taxData.taxAmount.toFixed(2)}
                </FText>
              </FView>
            ))
          )}
          <FView row justifyBetween alignCenter>
            <FText body1 bold grey700>
              Pickup Online Tips:
            </FText>
            <FText body1 bold grey700>
              ${pickupTips.toFixed(2)}
            </FText>
          </FView>
          {inpersonPickupTips != null && inpersonPickupTips !== 0 && (
            <FView row justifyBetween alignCenter>
              <FText body1 bold grey700>
                Pickup Cash Tips:
              </FText>
              <FText body1 bold grey700>
                ${inpersonPickupTips.toFixed(2)}
              </FText>
            </FView>
          )}
          {courierTips != null && courierTips !== 0 && (
            <FView row justifyBetween alignCenter>
              <FText body1 bold grey700>
                Courier Online Tips:
              </FText>
              <FText body1 bold grey700>
                ${courierTips.toFixed(2)}
              </FText>
            </FView>
          )}
          {inpersonCourierTips != null && inpersonCourierTips !== 0 && (
            <FView row justifyBetween alignCenter>
              <FText body1 bold grey700>
                Courier Cash Tips:
              </FText>
              <FText body1 bold grey700>
                ${inpersonCourierTips.toFixed(2)}
              </FText>
            </FView>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FView row justifyBetween alignCenter>
            <FText h6 bold>
              Total Commission:
            </FText>
            <FText h6 bold primary>
              (${totalCommission.toFixed(2)})
            </FText>
          </FView>
          <FView row justifyBetween alignCenter>
            <FText body1 bold grey700>
              Network Access Contribution:
            </FText>
            <FText body1 bold grey700>
              (${networkAccessFees.toFixed(2)})
            </FText>
          </FView>
          {subsidizedDeliveryFees != null && subsidizedDeliveryFees !== 0 && (
            <FView row justifyBetween alignCenter>
              <FText body1 bold grey700>
                Subsidized Delivery Fees:
              </FText>
              <FText body1 bold grey700>
                (${subsidizedDeliveryFees.toFixed(2)})
              </FText>
            </FView>
          )}
          {foodDelayContributions != null && foodDelayContributions !== 0 && (
            <FView row justifyBetween alignCenter>
              <FText body1 bold grey700>
                Courier Delay Contribution:
              </FText>
              <FText body1 bold grey700>
                (${foodDelayContributions.toFixed(2)})
              </FText>
            </FView>
          )}
          <FView row justifyBetween alignCenter>
            <FText body1 bold grey700>
              GST:
            </FText>
            <FText body1 bold grey700>
              (${commissionTaxes.toFixed(2)})
            </FText>
          </FView>
        </Grid>
        {otherAdjustments != null && otherAdjustments !== 0 && (
          <Grid item xs={12} md={6}>
            <FView row justifyBetween alignCenter>
              <FText h6 bold>
                Other Adjustments:
              </FText>
              <FText h6 bold primary>
                {otherAdjustments < 0 && '('}${Math.abs(otherAdjustments).toFixed(2)}
                {otherAdjustments < 0 && ')'}
              </FText>
            </FView>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FView row justifyBetween alignCenter>
            <FText h6 bold>
              Revenue:
            </FText>
            <FText h6 bold primary>
              ${revenue.toFixed(2)}
            </FText>
          </FView>
          <FView row justifyBetween alignCenter>
            <FText body1 bold grey700>
              Total Cash Received:
            </FText>
            <FText body1 bold grey700>
              ${cashRevenue.toFixed(2)}
            </FText>
          </FView>
          <FView row justifyBetween alignCenter>
            <FText body1 bold grey700>
              Net Amount (Actual Deposit):
            </FText>
            <FText body1 bold grey700>
              ${netDirectDeposit.toFixed(2)}
            </FText>
          </FView>
          {/* <FView row justifyBetween alignCenter>
        <FText body1 bold grey700>
          Previously Owed Commission:
        </FText>
        <FText body1 bold grey700>
          (${(netDirectDeposit - stripeTransfers).toFixed(2)})
        </FText>
      </FView>
      <FView row justifyBetween alignCenter>
        <FText body1 bold grey700>
          Actual Direct Deposit:
        </FText>
        <FText body1 bold grey700>
          ${stripeTransfers.toFixed(2)}
        </FText>
      </FView>
      {stripeTransfers > netDirectDeposit && (
        <FView row justifyBetween alignCenter>
          <FText body1 bold grey700>
            Commission Still Owed:
          </FText>
          <FText body1 bold grey700>
            ${(stripeTransfers - netDirectDeposit).toFixed(2)}
          </FText>
        </FView>
      )} */}
        </Grid>
      </Grid>
    </>
  )
}

export default AnalyticsData
