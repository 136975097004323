import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import useReactRouter from 'use-react-router'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {colors} from 'styles'
import {Checkbox} from '@material-ui/core'
import {FView, FText, FNavBar, FDrawer, FButton, ButtonOutlineView} from 'components'
import {getCollection, db, FieldValue} from 'f-core/src/config/firebase'

export default function ProductTaxes() {
  const {match} = useReactRouter()
  const dispatch = useDispatch()
  const {restaurantId, locationId} = match.params
  const isUpSm = useMediaQueryUp('sm')

  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const publicTaxes = useSelector(dispatch.public.getPublicTaxes)
  const categories = useSelector(() => dispatch.restaurants.getCategoriesAll({locationId}))
  const [selectedTaxId, setSelectedTaxId] = useState(null)
  // Redener whenever any products are updated
  useSelector(() => dispatch.restaurants.getProductsAll({locationId}))

  useEffect(() => {
    return dispatch.restaurants.subscribeCategories(restaurantId, locationId)
  }, [dispatch.restaurants, locationId, restaurantId])
  useEffect(() => {
    return dispatch.restaurants.subscribeProducts(restaurantId, locationId)
  }, [dispatch.restaurants, locationId, restaurantId])

  return (
    <FView fill bg={colors.background}>
      <FView h={80}>
        <FNavBar shadow />
      </FView>
      <FView row>
        <FDrawer locationData={locationData} />
        <FView fill pt={isUpSm ? 25 : 75} pb={24} ph={24} alignCenter>
          <FText h4>Product Taxes</FText>
          <FView minWidth={600} row>
            <FView mt={24} w={200}>
              {Object.entries(publicTaxes).map(([taxId, taxData]) => {
                return (
                  <FButton key={taxId} onClick={() => setSelectedTaxId(taxId)}>
                    <ButtonOutlineView rounded m={5} selected={selectedTaxId === taxId} h={60}>
                      <FText
                        subtitle1
                        color={selectedTaxId === taxId ? colors.primary : colors.grey75}
                        h6
                        overflow="hidden"
                        textOverflow="ellipsis">
                        {taxData.name}
                      </FText>
                    </ButtonOutlineView>
                  </FButton>
                )
              })}
            </FView>
            {selectedTaxId ? (
              <FView shadow br={5} bg={colors.white} p={20} maxHeight={'80vh'} overflowY="auto">
                <FView ml={10}>
                  <FText h5>Products</FText>
                  <FText body1>Check products to add selected selected taxId.</FText>
                </FView>
                <FView mt={10}>
                  {Object.values(categories).map((category) => {
                    const products = dispatch.restaurants.getCategoryProductsArray({
                      locationId,
                      categoryId: category.categoryId,
                    })

                    const every = products.every(
                      (product) =>
                        (!product.taxIds && selectedTaxId === 'cad_gst') || product.taxIds?.includes(selectedTaxId),
                    )
                    const some = products.some(
                      (product) =>
                        (!product.taxIds && selectedTaxId === 'cad_gst') || product.taxIds?.includes(selectedTaxId),
                    )
                    return (
                      <FView key={category.categoryId}>
                        <FView row alignCenter>
                          <Checkbox
                            indeterminate={some && !every}
                            checked={some}
                            onChange={(e) => {
                              if (selectedTaxId === null) {
                                alert('select a tax first')
                                return
                              }
                              const batch = db.batch()
                              for (const product of products) {
                                const productDocRef = getCollection('Products', {restaurantId, locationId}).doc(
                                  product.productId,
                                )
                                batch.update(productDocRef, {
                                  taxIds: e.target.checked
                                    ? FieldValue.arrayUnion(selectedTaxId)
                                    : FieldValue.arrayRemove(selectedTaxId),
                                })
                              }
                              batch.commit()
                            }}
                            color="primary"
                          />
                          <FText body1>{category.name}</FText>
                        </FView>
                        {products.map((product) => {
                          const hasProduct =
                            (!product.taxIds && selectedTaxId === 'cad_gst') || product.taxIds?.includes(selectedTaxId)
                          return (
                            <FView key={product.productId} alignStart>
                              <FView row alignCenter ml={10}>
                                <Checkbox
                                  checked={hasProduct}
                                  onChange={() => {
                                    if (selectedTaxId === null) {
                                      alert('select a tax first')
                                      return
                                    }
                                    getCollection('Products', {restaurantId, locationId})
                                      .doc(product.productId)
                                      .update({
                                        taxIds: hasProduct
                                          ? FieldValue.arrayRemove(selectedTaxId)
                                          : FieldValue.arrayUnion(selectedTaxId),
                                      })
                                  }}
                                  color="primary"
                                />
                                <FText body2>{product.name}</FText>
                              </FView>
                            </FView>
                          )
                        })}
                      </FView>
                    )
                  })}
                </FView>
              </FView>
            ) : (
              <FView selfCenter>
                <FText h6>Select a taxId to apply it to products</FText>
              </FView>
            )}
          </FView>
        </FView>
      </FView>
    </FView>
  )
}
