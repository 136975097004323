import axios from 'axios'

async function authFetch({authToken, url, ...restParams}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(restParams),
  })
  const text = await response.text()
  let json
  try {
    json = JSON.parse(text)
  } catch (e) {
    if (response.ok) {
      return text
    } else {
      throw new Error(text)
    }
  }
  if (response.ok) {
    return json
  } else {
    throw new Error(json.message)
  }
}

export const createNewRestaurant = async ({authToken, restaurantData}) => {
  return await authFetch({
    authToken,
    url: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL + '/createNewRestaurant',
    ...restaurantData,
  })
}

export const assignTargetRider = async ({authToken, restaurantId, orderId, targetUserId}) => {
  return await authFetch({
    authToken,
    url: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL + '/assignTargetRider',
    restaurantId,
    orderId,
    targetUserId,
  })
}

export const unassignRider = async ({authToken, restaurantId, orderId, deliveryStatus}) => {
  return await authFetch({
    authToken,
    url: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL + '/unassignRider',
    restaurantId,
    orderId,
    deliveryStatus,
  })
}
export const failDeliveryOrder = async ({authToken, restaurantId, orderId, failedReason}) => {
  return await authFetch({
    authToken,
    url: process.env.REACT_APP_FIREBASE_FUNCTIONS_URL + '/failDeliveryOrder',
    restaurantId,
    orderId,
    failedReason,
  })
}

export function addChargeToOrder(serverUrl, authToken, addedCharge) {
  const config = {
    url: `${serverUrl}/api/add_charge`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: addedCharge,
  }
  return axios(config)
}

export function refundOrder(serverUrl, authToken, refund) {
  const config = {
    url: `${serverUrl}/api/refund_order`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: refund,
  }
  return axios(config)
}

export function refundTipAmount(serverUrl, authToken, refund) {
  const config = {
    url: `${serverUrl}/api/refund_tip_amount`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: refund,
  }
  return axios(config)
}

export function uploadImage({serverUrl, authToken, restaurantId, productId, categoryId, imageFile, imageType}) {
  const formData = new FormData()

  restaurantId && formData.append('restaurantId', restaurantId)
  productId && formData.append('productId', productId)
  categoryId && formData.append('categoryId', categoryId)
  formData.append('imageFile', imageFile)
  formData.append('imageType', imageType)
  const config = {
    url: `${serverUrl}/images/upload`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: authToken,
    },
    data: formData,
  }
  return axios(config)
}

export function deleteImageFromBucket({serverUrl, authToken, restaurantId, productId, categoryId, imageType}) {
  const config = {
    url: `${serverUrl}/images/delete`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: {restaurantId, productId, categoryId, imageType},
  }
  return axios(config)
}
