import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as utils from 'f-utils'
import {colors} from 'styles'
import moment from 'moment-timezone'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {getOrdersGroupCreatedAtDesc} from 'f-core/src/config/firebase'
import {FView, FNavBar, FText} from 'components'
import {isEmpty} from 'lodash'

import FetchOrders from './FetchOrders'
import Filters from './Filters'
import AnalyticsData from './AnalyticsData'
import DataCharts from './DataCharts'
import TimeZone from './TimeZone'

const inside = require('point-in-polygon')

export default function Analytics() {
  const dispatch = useDispatch()
  const isUpSm = useMediaQueryUp('sm')

  useEffect(() => {
    return dispatch.regions.subscribeRegions()
  }, [dispatch.regions])
  const regions = useSelector(dispatch.regions.getRegions)
  const [allRegionFilter, setAllRegionFilter] = useState(true)
  const [regionFilter, setRegionFilter] = useState({})

  const defaultRegionFitler = {}
  for (const regionId in regions) {
    defaultRegionFitler[regionId] = true
  }

  useEffect(() => {
    if (isEmpty(regionFilter)) {
      const _regions = dispatch.regions.getRegions()
      const _regionFilter = {}
      for (const regionId in _regions) {
        _regionFilter[regionId] = true
      }
      setRegionFilter(_regionFilter)
    }
  }, [dispatch.regions, regionFilter, regions])

  const [orderTypeFilter, setOrderTypeFilter] = useState('all')
  const [sourceClientFilter, setSourceClientFilter] = useState('all')
  const [selectedLocations, setSelectedLocations] = useState([])

  const timeZoneList = [
    {region: 'Vancouver (PDT)', timezone: 'America/Vancouver'},
    {region: 'Edmonton (MDT)', timezone: 'America/Edmonton'},
    {region: 'Winnipeg (CDT)', timezone: 'America/Winnipeg'},
    {region: 'Toronto (EDT)', timezone: 'America/Toronto'},
    {region: 'Halifax (ADT)', timezone: 'America/Halifax'},
    {region: 'Seoul (KST)', timezone: 'Asia/Seoul'},
  ]
  const [selectedTimeZone, setSelectedTimeZone] = useState(timeZoneList[0])

  const convertTime = (date, timezone) => {
    return moment.tz(date.format('YYYY-MM-DD HH:mm'), timezone)
  }

  const [chartRange, setChartRange] = useState('daily')

  const locationsList = Object.values(useSelector(dispatch.restaurants.getLocations) ?? [])

  const [fetchedStartDate, setFetchedStartDate] = useState(null)
  const [fetchedEndDate, setFetchedEndDate] = useState(null)
  const [selectedStartDate, setSelectedStartDate] = useState(moment().startOf('day'))
  const [selectedEndDate, setSelectedEndDate] = useState(moment().endOf('day'))
  const [isFetching, setIsFetching] = useState(false)
  const [orders, setOrders] = useState({})

  const orderTypes = {}
  const sourceClients = {}

  const handleOrdersFetch = () => {
    if (isFetching) {
      return
    }
    if (selectedTimeZone === null) {
      return
    }
    if (selectedStartDate > selectedEndDate) {
      alert(`Error : Please choose the Start Date earlier than the End Date`)
      return
    }
    setIsFetching(true)
    const startDate = convertTime(selectedStartDate, selectedTimeZone.timezone)
    const endDate = convertTime(selectedEndDate, selectedTimeZone.timezone)
    setFetchedStartDate(startDate)
    setFetchedEndDate(endDate)
    const createdAtMin = startDate.valueOf()
    const createdAtMax = endDate.valueOf()
    const limit = 5000

    getOrdersGroupCreatedAtDesc({status: ['New', 'Preparing', 'Done', 'Cancelled'], createdAtMin, createdAtMax, limit})
      .get()
      .then((snapshot) => {
        setIsFetching(false)
        const orders = {}
        for (const doc of snapshot.docs) {
          orders[doc.id] = doc.data()
          orders[doc.id].id = doc.id
        }
        setOrders(orders)
        if (snapshot.docs.length >= limit) {
          alert(`Too many orders. Only ${limit} orders were fetched.`)
        }
      })
  }

  let ordersCount = 0

  let total = 0
  let inpersonTotal = 0
  let onlineTotal = 0
  let fPointsDiscount = 0

  let foodSubTotal = 0
  let deliveryFees = 0
  let taxAmount = 0
  let taxes = {}
  let pickupTips = 0
  let inpersonPickupTips = 0
  let courierTips = 0
  let totalCommission = 0
  let networkAccessFees = 0
  let subsidizedDeliveryFees = 0
  let foodDelayContributions = 0
  let commissionTaxes = 0
  let otherAdjustments = 0
  let inpersonCourierTips = 0
  // let stripeTransfers = 0
  let grossEarnings = 0
  let cashRevenue = 0
  let netDirectDeposit = 0
  let revenue = 0

  let foodlyEarnedOnlineTip = 0
  let foodlyEarnedOnlineDeliveryFee = 0
  let foodlyEarnedOnlineDeliveryFeeTax = 0

  const orderDatas = Object.values(orders)
    .filter((orderData) => {
      if (orderData.orderType) {
        orderTypes[orderData.orderType] = true
      }
      if (orderData.sourceClient) {
        sourceClients[orderData.sourceClient] = true
      }

      if (orderTypeFilter !== 'all') {
        if (orderData.orderType !== orderTypeFilter) {
          return false
        }
      }
      if (sourceClientFilter !== 'all') {
        if (orderData.sourceClient !== sourceClientFilter) {
          return false
        }
      }
      if (!allRegionFilter) {
        const locationData = dispatch.restaurants.getLocation({locationId: orderData.locationId})
        if (regionFilter[locationData.region]) {
          return true
        }
        for (const region of Object.values(regions)) {
          const {lat, lng} = locationData.restaurantLatLng
          const polygon = region.polygonLatLng?.map(({lng, lat}) => [lng, lat]) ?? []
          if (inside([lng, lat], polygon)) {
            return true
          }
        }
        return false
      }
      return true
    })
    .filter((orderData) => {
      if (selectedLocations.length === 0) {
        return true
      }
      for (const location of selectedLocations) {
        if (orderData.locationId === location.locationId) {
          return true
        }
      }
      return false
    })

  // eslint-disable-next-line no-unused-vars
  for (const orderData of orderDatas) {
    if (orderData.status !== 'Cancelled') {
      ordersCount++
    }

    const orderReportDetails = utils.getOrderReportDetails({orderData})

    total += orderReportDetails.total
    inpersonTotal += orderReportDetails.inpersonTotal
    onlineTotal += orderReportDetails.onlineTotal
    fPointsDiscount += orderReportDetails.fPointsDiscount

    foodSubTotal += orderReportDetails.foodSubTotal
    deliveryFees += orderReportDetails.deliveryFee
    taxAmount += orderReportDetails.taxAmount
    if (taxes && !orderReportDetails.taxes) {
      taxes = null
    }
    if (taxes && orderReportDetails.taxes) {
      for (const [taxId, taxData] of Object.entries(orderReportDetails.taxes)) {
        if (!taxes[taxId]) {
          taxes[taxId] = {...taxData}
        } else {
          taxes[taxId].taxAmount += taxData.taxAmount
        }
      }
    }
    pickupTips += orderReportDetails.pickupTip
    inpersonPickupTips += orderReportDetails.inpersonPickupTip
    courierTips += orderReportDetails.courierTip
    totalCommission += orderReportDetails.totalCommission
    networkAccessFees += orderReportDetails.networkAccessFee
    subsidizedDeliveryFees += orderReportDetails.subsidizedDeliveryFee
    foodDelayContributions += orderReportDetails.foodDelayContribution
    commissionTaxes += orderReportDetails.commissionTax
    otherAdjustments += orderReportDetails.otherAdjustments
    inpersonCourierTips += orderReportDetails.inpersonCourierTip
    // stripeTransfers += orderReportDetails.stripeTransfer
    grossEarnings += orderReportDetails.grossEarning
    cashRevenue += orderReportDetails.cashRevenue
    netDirectDeposit += orderReportDetails.netDirectDeposit
    revenue += orderReportDetails.revenue

    foodlyEarnedOnlineTip += orderReportDetails.foodlyEarnedOnlineTip
    foodlyEarnedOnlineDeliveryFee += orderReportDetails.foodlyEarnedOnlineDeliveryFee
    foodlyEarnedOnlineDeliveryFeeTax += orderReportDetails.foodlyEarnedOnlineDeliveryFeeTax
  }

  foodSubTotal = utils.currencyRounding(foodSubTotal)
  deliveryFees = utils.currencyRounding(deliveryFees)
  taxAmount = utils.currencyRounding(taxAmount)
  pickupTips = utils.currencyRounding(pickupTips)
  inpersonPickupTips = utils.currencyRounding(inpersonPickupTips)
  courierTips = utils.currencyRounding(courierTips)
  totalCommission = utils.currencyRounding(totalCommission)
  networkAccessFees = utils.currencyRounding(networkAccessFees)
  subsidizedDeliveryFees = utils.currencyRounding(subsidizedDeliveryFees)
  foodDelayContributions = utils.currencyRounding(foodDelayContributions)
  commissionTaxes = utils.currencyRounding(commissionTaxes)
  otherAdjustments = utils.currencyRounding(otherAdjustments)
  inpersonCourierTips = utils.currencyRounding(inpersonCourierTips)
  // stripeTransfers = utils.currencyRounding(stripeTransfers)
  grossEarnings = utils.currencyRounding(grossEarnings)
  cashRevenue = utils.currencyRounding(cashRevenue)
  netDirectDeposit = utils.currencyRounding(netDirectDeposit)
  revenue = utils.currencyRounding(revenue)

  foodlyEarnedOnlineTip = utils.currencyRounding(foodlyEarnedOnlineTip)
  foodlyEarnedOnlineDeliveryFee = utils.currencyRounding(foodlyEarnedOnlineDeliveryFee)
  foodlyEarnedOnlineDeliveryFeeTax = utils.currencyRounding(foodlyEarnedOnlineDeliveryFeeTax)

  return (
    <FView fill bg={colors.background}>
      <FView block>
        <FView h={80}>
          <FNavBar shadow={isUpSm} />
        </FView>
        <FView fill bg={colors.background} mb={16}>
          <FView fill alignCenter pt={isUpSm ? 25 : 75} pb={25}>
            <FText h5 bold gutterBottom>
              Analytics
            </FText>
            <FView w="100%" maxWidth={768} bg={colors.white} p={25}>
              <TimeZone
                timeZoneList={timeZoneList}
                selectedTimeZone={selectedTimeZone}
                setSelectedTimeZone={setSelectedTimeZone}
                defaultValue={timeZoneList[0]}
              />
              <FetchOrders
                selectedTimeZone={selectedTimeZone}
                handleEndDateChange={(date) => setSelectedEndDate(date)}
                handleOrdersFetch={handleOrdersFetch}
                handleStartDateChange={(date) => setSelectedStartDate(date)}
                isFetching={isFetching}
                selectedEndDate={selectedEndDate}
                selectedStartDate={selectedStartDate}
              />
            </FView>
            <FView w="100%" maxWidth={768} bg={colors.white} ph={25}>
              <FText h6 bold>
                Orders Count: {ordersCount}
              </FText>
            </FView>
            <FView w="100%" maxWidth={768} bg={colors.white} p={25}>
              <Filters
                allRegionFilter={allRegionFilter}
                defaultRegionFitler={defaultRegionFitler}
                isUpSm={isUpSm}
                locationsList={locationsList}
                orderTypeFilter={orderTypeFilter}
                orderTypes={orderTypes}
                regionFilter={regionFilter}
                regions={regions}
                selectedLocations={selectedLocations}
                setAllRegionFilter={setAllRegionFilter}
                setOrderTypeFilter={setOrderTypeFilter}
                setRegionFilter={setRegionFilter}
                setSelectedLocations={setSelectedLocations}
                setSourceClientFilter={setSourceClientFilter}
                sourceClientFilter={sourceClientFilter}
                sourceClients={sourceClients}
              />
            </FView>
            <FView w="100%" maxWidth={768} bg={colors.white} p={25}>
              <AnalyticsData
                cashRevenue={cashRevenue}
                commissionTaxes={commissionTaxes}
                courierTips={courierTips}
                deliveryFees={deliveryFees}
                foodDelayContributions={foodDelayContributions}
                foodSubTotal={foodSubTotal}
                fPointsDiscount={fPointsDiscount}
                grossEarnings={grossEarnings}
                inpersonCourierTips={inpersonCourierTips}
                inpersonPickupTips={inpersonPickupTips}
                inpersonTotal={inpersonTotal}
                netDirectDeposit={netDirectDeposit}
                networkAccessFees={networkAccessFees}
                onlineTotal={onlineTotal}
                otherAdjustments={otherAdjustments}
                pickupTips={pickupTips}
                revenue={revenue}
                subsidizedDeliveryFees={subsidizedDeliveryFees}
                taxAmount={taxAmount}
                taxes={taxes}
                total={total}
                totalCommission={totalCommission}
                foodlyEarnedOnlineTip={foodlyEarnedOnlineTip}
                foodlyEarnedOnlineDeliveryFee={foodlyEarnedOnlineDeliveryFee}
                foodlyEarnedOnlineDeliveryFeeTax={foodlyEarnedOnlineDeliveryFeeTax}
              />
            </FView>
          </FView>
          {fetchedStartDate && (
            <DataCharts
              chartRange={chartRange}
              fetchedEndDate={fetchedEndDate}
              fetchedStartDate={fetchedStartDate}
              orderDatas={orderDatas}
              setChartRange={setChartRange}
              selectedTimeZone={selectedTimeZone}
            />
          )}
        </FView>
      </FView>
    </FView>
  )
}
