import React from 'react'
import {Delete} from '@material-ui/icons'
import {FieldValue, getCollection} from 'f-core/src/config/firebase'
import {FView, FText, FButton} from 'components'

const Payments = ({userId, payments}) => {
  return (
    <>
      <FView>
        <FView size={16} />
        <FText bold>Payments</FText>
        {payments?.map((payment, index) => (
          <FView key={index} row alignCenter justifyBetween>
            <FView p={10}>
              <FText>postal: {payment.avsZipcode}</FText>
              <FText>dataKey: {payment.dataKey}</FText>
              <FText>expdate (mm/yy): {`${payment.expdate.slice(2, 4)}/${payment.expdate.slice(0, 2)}`}</FText>
              <FText>maskedPan: {payment.maskedPan}</FText>
              <FText>customerId(Stripe): {payment.customerId}</FText>
            </FView>

            <FView row>
              <FButton
                onClick={() => {
                  getCollection('Users')
                    .doc(userId)
                    .update({payments: FieldValue.arrayRemove(payments[index])})
                }}>
                <FView row center w={90}>
                  <Delete color="primary" />
                  <FView size={8} />
                  <FText subtitle2 primary>
                    Delete
                  </FText>
                </FView>
              </FButton>
            </FView>
          </FView>
        ))}
      </FView>
    </>
  )
}

export default Payments
