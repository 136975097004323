import React, {useState, useCallback} from 'react'
import {colors} from 'styles'
import {filter} from 'lodash'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useSelector, useDispatch} from 'react-redux'
import {useTheme} from '@material-ui/core/styles'
import {IconButton} from '@material-ui/core'
import {Visibility, VisibilityOff, Search} from '@material-ui/icons'
import {FView, FText, FNavBar, ButtonFillView, FInput, FLinkAnimate} from 'components'
import PointOfSaleItemView from './PointOfSaleItemView'

const PointOfSales = () => {
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const pointOfSalesData = useSelector(dispatch.pointOfSales.getPointOfSales)
  const isUpSm = useMediaQueryUp('sm')
  const theme = useTheme()
  const [showDisabled, setShowDisabled] = useState(false)

  const onSearchTermChange = useCallback((e) => {
    setSearchTerm(e.target.value)
  }, [])

  const pointOfSalesList = Object.values(pointOfSalesData)
  const filteredpointOfSalesList = !!searchTerm
    ? filter(pointOfSalesList, (pointOfSalesData) =>
        pointOfSalesData.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : pointOfSalesList

  return (
    <FView block bg={theme.palette.common.background} h={'100vh'}>
      <FView h={130}>
        <FNavBar />
        <FView fixed zIndex={theme.zIndex.appBar} top={80} left={0} right={0} shadow10>
          <FView h={1} bg={theme.palette.grey[300]} />
          <FView row alignCenter ph={isUpSm ? 64 : 16} h={50} bg={colors.white}>
            <Search color="action" />
            <FInput value={searchTerm} onChange={onSearchTermChange} placeholder="Search for a point of sale" />
          </FView>
        </FView>
      </FView>
      <FView bg={colors.background} p={25} alignCenter>
        <FView w={600} maxWidth="100vw">
          <FView ph={isUpSm ? 0 : 16} pv={16} row justifyBetween alignCenter>
            <FText medium h4>
              Point of Sales
            </FText>
            <FLinkAnimate to="/point-of-sales/create">
              <ButtonFillView w={165} rounded>
                <FText button primaryContrast>
                  Create New
                </FText>
              </ButtonFillView>
            </FLinkAnimate>
          </FView>
          {showDisabled ? (
            <FView row alignCenter>
              <IconButton color="primary" onClick={() => setShowDisabled(false)}>
                <Visibility color="primary" fontSize="large" />
              </IconButton>
              <FText body1>Showing Disabled Point Of Sales</FText>
            </FView>
          ) : (
            <FView row alignCenter>
              <IconButton onClick={() => setShowDisabled(true)}>
                <VisibilityOff color="action" fontSize="large" />
              </IconButton>
              <FText body1>Hiding Disabled Point Of Sales</FText>
            </FView>
          )}
          {filteredpointOfSalesList.map((pointOfSalesData) => {
            if (!showDisabled && pointOfSalesData.status !== 'activated') {
              return null
            }
            return (
              <FLinkAnimate
                key={pointOfSalesData.pointOfSaleId}
                to={`/point-of-sales/info/${pointOfSalesData.pointOfSaleId}`}>
                <PointOfSaleItemView {...pointOfSalesData} />
              </FLinkAnimate>
            )
          })}
        </FView>
      </FView>
    </FView>
  )
}

export default PointOfSales
