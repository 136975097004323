import React from 'react'
import {FView} from 'components'
import {useDispatch, useSelector} from 'react-redux'
import {useTheme} from '@material-ui/core/styles'
import ProductItem from './ProductItem'

const ProductList = ({categoryId, locationId, restaurantId, searchTerm}) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  //get products for the category
  const categoryProductsArray = useSelector(() =>
    dispatch.restaurants.getCategoryProductsArray({locationId, categoryId}),
  )

  return categoryProductsArray.map((productData, index) => {
    if (searchTerm) {
      if (
        !productData.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !productData.description.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return null
      }
    }
    return (
      <FView key={productData.productId}>
        <ProductItem
          restaurantId={restaurantId}
          locationId={locationId}
          productIndex={index}
          productId={productData.productId}
          categoryId={categoryId}
          productData={productData}
        />
        <FView h={1} bg={theme.palette.grey['300']} />
      </FView>
    )
  })
}

export default ProductList
