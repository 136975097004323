import {db} from 'f-core/src/config/firebase'

const DEFAULT_STATE = {
  // Point Of Sales data
  Tables: {},
  Floors: {},
  Employees: {},
  PassCodes: {},
  PointOfSales: {},
}
const DEFAULT_POS_DATA = {
  deviceIds: [],
  floorOrderIds: [],
  locationId: null,
  restaurantId: null,
  status: null,
  name: null,
  currentShiftId: null,
}
const DEFAULT_EMPLOYEE_DATA = {
  clockInCode: null,
  currentClockId: null,
  name: null,
  phoneNumber: null,
  role: null,
  status: null,
}
const DEFAULT_FLOOR_DATA = {
  backgroundUrl: null,
  tableIds: [],
  name: null,
}
const DEFAULT_TABLE_DATA = {
  name: null,
  rotationDeg: null,
  scale: null,
  status: null,
  tableImageUrl: null,
  xPos: null,
  yPos: null,
}

const pointOfSalesModel = {
  state: DEFAULT_STATE,
  reducers: {
    setTables: (state, Tables) => {
      return {...state, Tables}
    },
    setFloors: (state, Floors) => {
      return {...state, Floors}
    },
    setEmployees: (state, Employees) => {
      return {...state, Employees}
    },
    setPassCodes: (state, PassCodes) => {
      return {...state, PassCodes}
    },
    setPointOfSales: (state, PointOfSales) => {
      return {...state, PointOfSales}
    },
  },
  actions: ({dispatch, getState}) => ({
    getPointOfSale(pointOfSaleId) {
      return getState().pointOfSales.PointOfSales[pointOfSaleId]
    },
    getPointOfSales() {
      return getState().pointOfSales.PointOfSales
    },
    getFloors() {
      return getState().pointOfSales.Floors
    },
    getTables() {
      return getState().pointOfSales.Tables
    },
    getEmployees() {
      return getState().pointOfSales.Employees
    },
    getPassCodes() {
      return getState().pointOfSales.PassCodes
    },
    createNewPassCode(pointOfSaleId, clockInCode, employeeId) {
      return db.collection('PointOfSales').doc(pointOfSaleId).collection('PassCodes').doc(clockInCode).set({
        employeeId: employeeId,
      })
    },
    deletePassCode(pointOfSaleId, clockInCode) {
      return db.collection('PointOfSales').doc(pointOfSaleId).collection('PassCodes').doc(clockInCode).delete()
    },
    createNewPointOfSale(newPointOfSaleData) {
      return db.collection('PointOfSales').add({
        ...DEFAULT_POS_DATA,
        ...newPointOfSaleData,
      })
    },
    updatePointOfSale(pointOfSaleId, pointOfSaleData) {
      return db.collection('PointOfSales').doc(pointOfSaleId).update(pointOfSaleData)
    },
    createNewEmployee(pointOfSaleId, newEmployeeData) {
      return db
        .collection('PointOfSales')
        .doc(pointOfSaleId)
        .collection('Employees')
        .add({
          ...DEFAULT_EMPLOYEE_DATA,
          ...newEmployeeData,
        })
    },
    updateEmployee(pointOfSaleId, employeeId, employeeData) {
      return db
        .collection('PointOfSales')
        .doc(pointOfSaleId)
        .collection('Employees')
        .doc(employeeId)
        .update(employeeData)
    },
    async disableEmployee(pointOfSaleId, employeeId, clockInCode) {
      await dispatch.pointOfSales.deletePassCode(pointOfSaleId, clockInCode)
      return db.collection('PointOfSales').doc(pointOfSaleId).collection('Employees').doc(employeeId).update({
        clockInCode: null,
        status: 'disabled',
      })
    },
    createNewFloor(pointOfSaleId, newFloorData) {
      return db
        .collection('PointOfSales')
        .doc(pointOfSaleId)
        .collection('Floors')
        .add({
          ...DEFAULT_FLOOR_DATA,
          ...newFloorData,
        })
    },
    updateFloor(pointOfSaleId, floorId, floorData) {
      return db.collection('PointOfSales').doc(pointOfSaleId).collection('Floors').doc(floorId).update(floorData)
    },
    deleteFloor(pointOfSaleId, floorId) {
      return db.collection('PointOfSales').doc(pointOfSaleId).collection('Floors').doc(floorId).delete()
    },
    createNewTable(pointOfSaleId, newTableData) {
      return db
        .collection('PointOfSales')
        .doc(pointOfSaleId)
        .collection('Tables')
        .add({
          ...DEFAULT_TABLE_DATA,
          ...newTableData,
        })
    },
    updateTable(pointOfSaleId, tableId, tableData) {
      return db.collection('PointOfSales').doc(pointOfSaleId).collection('Tables').doc(tableId).update(tableData)
    },
    deleteTable(pointOfSaleId, tableId) {
      return db.collection('PointOfSales').doc(pointOfSaleId).collection('Tables').doc(tableId).delete()
    },
    subscribePointOfSales() {
      return db.collection('PointOfSales').onSnapshot((snapshot) => {
        if (snapshot) {
          const PointOfSales = {}
          for (const doc of snapshot.docs) {
            const PointOfSale = doc.data()
            PointOfSale.pointOfSaleId = doc.id
            PointOfSales[doc.id] = PointOfSale
          }
          dispatch.pointOfSales.setPointOfSales(PointOfSales)
        }
      })
    },
    subscribeTables(pointOfSaleId) {
      return db
        .collection('PointOfSales')
        .doc(pointOfSaleId)
        .collection('Tables')
        .onSnapshot((snapshot) => {
          if (snapshot) {
            const Tables = {}
            for (const doc of snapshot.docs) {
              Tables[doc.id] = doc.data()
              Tables[doc.id].tableId = doc.id
            }
            dispatch.pointOfSales.setTables(Tables)
          }
        })
    },
    subscribeFloors(pointOfSaleId) {
      return db
        .collection('PointOfSales')
        .doc(pointOfSaleId)
        .collection('Floors')
        .onSnapshot((snapshot) => {
          if (snapshot) {
            const Floors = {}
            for (const doc of snapshot.docs) {
              Floors[doc.id] = doc.data()
              Floors[doc.id].floorId = doc.id
            }
            dispatch.pointOfSales.setFloors(Floors)
          }
        })
    },
    subscribeEmployees(pointOfSaleId) {
      return db
        .collection('PointOfSales')
        .doc(pointOfSaleId)
        .collection('Employees')
        .onSnapshot((snapshot) => {
          if (snapshot) {
            const Employees = {}
            for (const doc of snapshot.docs) {
              Employees[doc.id] = doc.data()
              Employees[doc.id].employeeId = doc.id
            }
            dispatch.pointOfSales.setEmployees(Employees)
          }
        })
    },
    subscribePassCodes(pointOfSaleId) {
      return db
        .collection('PointOfSales')
        .doc(pointOfSaleId)
        .collection('PassCodes')
        .onSnapshot((snapshot) => {
          if (snapshot) {
            const PassCodes = {}
            for (const doc of snapshot.docs) {
              PassCodes[doc.id] = doc.data()
              PassCodes[doc.id].passCode = doc.id
            }
            dispatch.pointOfSales.setPassCodes(PassCodes)
          }
        })
    },
  }),
}

export default pointOfSalesModel
