import React, {useState, useEffect} from 'react'
import {FView, FText, FButton, FTextField, ButtonOutlineView, ButtonFillView, IconButtonView} from 'components'
import {useDispatch} from 'react-redux'
import {Dialog, DialogTitle, DialogActions, DialogContent, Switch, FormControlLabel, Divider} from '@material-ui/core'
import {Checkbox, MenuItem} from '@material-ui/core'
import {Add, Close, Delete} from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {sortBy} from 'lodash'
import {moment, currencyRounding} from 'f-utils'

const ModifierEditDialog = ({
  locationId,
  restaurantId,
  modifierGroupId,
  open,
  handleClose,
  handleDelete,
  createNew,
  onCreateNew,
}) => {
  const dispatch = useDispatch()
  const [updating, setUpdating] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const classes = useStyle()
  const isUpSm = useMediaQueryUp('sm')

  const [newModifierGroupData, setNewModifierGroupData] = useState(null)

  const currentMoment = moment()
  const modifierGroupData = dispatch.restaurants.getModifierGroup({locationId, modifierGroupId})

  const [activeAfterIndex, setActiveAfterIndex] = useState({})
  let modifierActiveAfterDescriptions = {}
  Object.entries(modifierGroupData?.modifierItems ?? {}).forEach(([itemId, item]) => {
    const itemTime = item?.activeAfter?.toMillis()
    modifierActiveAfterDescriptions[itemId] =
      !itemTime || itemTime < currentMoment.valueOf()
        ? 'In Stock'
        : itemTime >= 1e14
        ? 'Out of Stock'
        : 'Out of Stock until ' + moment(itemTime).calendar()
  })

  const _handleDelete = () => {
    if (updating || deleting) {
      return
    }
    setDeleting(true)
    handleDelete()
  }

  useEffect(() => {
    setDeleting(false)
    if (!open) {
      setActiveAfterIndex({})
    }
  }, [open])

  useEffect(() => {
    if (createNew) {
      setNewModifierGroupData({
        isRequired: false,
        maxSelection: 1,
        modifierItems: [],
        title: '',
      })
    } else if (open) {
      setNewModifierGroupData(modifierGroupData)
    }
  }, [open, modifierGroupData, createNew])

  if (!newModifierGroupData) {
    return null
  }

  function handleSubmit() {
    if (updating || deleting) {
      return
    }
    if (!newModifierGroupData.title) {
      alert('Please fill out the title field.')
      return
    }
    setUpdating(true)
    if (createNew) {
      dispatch.restaurants
        .createModifierGroup({
          restaurantId,
          locationId,
          modifier: newModifierGroupData,
        })
        .then((doc) => {
          newModifierGroupData.id = doc.id
          onCreateNew(newModifierGroupData)
          handleClose && handleClose()
        })
        .catch((e) => {
          alert(e.message)
        })
        .finally(() => {
          setUpdating(false)
        })
    } else {
      dispatch.restaurants
        .updateModifierGroup({
          restaurantId,
          locationId,
          modifierGroupId,
          modifier: newModifierGroupData,
        })
        .then(() => {
          handleClose && handleClose()
        })
        .catch((e) => {
          alert(e.message)
        })
        .finally(() => {
          setUpdating(false)
        })
    }
  }

  return (
    <Dialog maxWidth={false} fullScreen={!isUpSm} open={open} onClose={handleClose}>
      <DialogTitle>
        <FView alignCenter row justifyBetween>
          <FView fill>
            <FText medium h5 alignCenter>
              Edit Modifier Group
            </FText>
          </FView>
          <FButton onClick={handleClose}>
            <Close />
          </FButton>
        </FView>
      </DialogTitle>
      <DialogContent>
        <FView fill>
          <FTextField
            margin="dense"
            value={newModifierGroupData.title}
            onChange={(e) =>
              setNewModifierGroupData({
                ...newModifierGroupData,
                title: e.target.value,
              })
            }
            autoFocus
            required
            label="Modifier Group Title"
          />
          <FView size={25} />
          <FView row>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={newModifierGroupData.isRequired}
                  onChange={(e) =>
                    setNewModifierGroupData({
                      ...newModifierGroupData,
                      isRequired: e.target.checked,
                    })
                  }
                />
              }
              label="Required"
            />
            <FView w={200}>
              <FTextField
                margin="dense"
                inputProps={{
                  min: 1,
                  max: 99,
                  className: classes.inputStyle,
                }}
                value={newModifierGroupData.maxSelection}
                onChange={(e) => {
                  setNewModifierGroupData({
                    ...newModifierGroupData,
                    maxSelection: Math.round(Number(e.target.value)),
                  })
                }}
                required
                label="Max number of selection"
                type="number"
              />
            </FView>
          </FView>
          <FView size={16} />
          <Divider />
          <FView size={16} />
          <FText h6 gutterBottom>
            Modifier Items:
          </FText>
          <FView row pv={8}>
            <FView w={70}>
              <FText body2 medium>
                Default:
              </FText>
            </FView>
            <FView fill>
              <FText body2 medium>
                Name:
              </FText>
            </FView>
            <FView w={100}>
              <FText body2 medium>
                Price:
              </FText>
            </FView>
            <FView w={50} />
          </FView>
        </FView>
        {sortBy(Object.entries(newModifierGroupData.modifierItems), ([itemId]) => itemId).map(([itemId, item]) => {
          return (
            <React.Fragment key={itemId}>
              <FView row>
                <FView w={70} center>
                  <Checkbox
                    color="primary"
                    checked={item.defaultValue}
                    onChange={(e) =>
                      setNewModifierGroupData({
                        ...newModifierGroupData,
                        modifierItems: {
                          ...newModifierGroupData.modifierItems,
                          [itemId]: {
                            ...item,
                            defaultValue: !item.defaultValue,
                          },
                        },
                      })
                    }
                  />
                </FView>
                <FView fill>
                  <FTextField
                    margin="dense"
                    value={item.name}
                    onChange={(e) =>
                      setNewModifierGroupData({
                        ...newModifierGroupData,
                        modifierItems: {
                          ...newModifierGroupData.modifierItems,
                          [itemId]: {
                            ...item,
                            name: e.target.value,
                          },
                        },
                      })
                    }
                  />
                </FView>
                <FView size={8} />
                <FView w={100}>
                  <FTextField
                    margin="dense"
                    value={item.price}
                    onChange={(e) => {
                      setNewModifierGroupData({
                        ...newModifierGroupData,
                        modifierItems: {
                          ...newModifierGroupData.modifierItems,
                          [itemId]: {
                            ...item,
                            price: currencyRounding(e.target.value),
                          },
                        },
                      })
                    }}
                    type="number"
                  />
                </FView>
                <FView w={50} center>
                  <FButton
                    onClick={() => {
                      if (updating || deleting) {
                        return
                      }
                      const {[itemId]: _, ...newModifierItems} = newModifierGroupData.modifierItems
                      setNewModifierGroupData({
                        ...newModifierGroupData,
                        modifierItems: newModifierItems,
                      })
                    }}>
                    <Delete color={updating || deleting ? 'disabled' : 'primary'} />
                  </FButton>
                </FView>
              </FView>
              <FView pl={70} pr={50}>
                <FTextField
                  margin="dense"
                  value={activeAfterIndex[itemId] ?? 0}
                  required
                  select
                  label="Modifier Status"
                  onChange={(event) => {
                    setActiveAfterIndex({...activeAfterIndex, [itemId]: event.target.value})
                    if (event.target.value > 0) {
                      setNewModifierGroupData({
                        ...newModifierGroupData,
                        modifierItems: {
                          ...newModifierGroupData.modifierItems,
                          [itemId]: {
                            ...item,
                            activeAfter: getActiveAfterTimestamp(event.target.value),
                          },
                        },
                      })
                    }
                  }}>
                  <MenuItem value={0}>{modifierActiveAfterDescriptions[itemId]}</MenuItem>
                  <MenuItem value={1}>Change to In Stock</MenuItem>
                  <MenuItem value={2}>Change to Out of Stock for 20 mins</MenuItem>
                  <MenuItem value={3}>Change to Out of Stock for 40 mins</MenuItem>
                  <MenuItem value={4}>Change to Out of Stock for 1 hour</MenuItem>
                  <MenuItem value={5}>Change to Out of Stock for 4 hours</MenuItem>
                  <MenuItem value={6}>Change to Out of Stock until tmr 3 AM</MenuItem>
                  <MenuItem value={7}>Change to Out of Stock indefinitely</MenuItem>
                </FTextField>
              </FView>
            </React.Fragment>
          )
        })}
        <FView alignStart mv={8}>
          <FButton
            disableRipple
            onClick={() => {
              if (updating || deleting) {
                return
              }
              setNewModifierGroupData({
                ...newModifierGroupData,
                modifierItems: {
                  ...newModifierGroupData.modifierItems,
                  [new Date().valueOf()]: {
                    defaultValue: false,
                    name: '',
                    price: 0,
                  },
                },
              })
            }}>
            <IconButtonView Icon={Add} label="Add Item" disabled={updating || deleting} />
          </FButton>
        </FView>
      </DialogContent>
      <Divider />
      <DialogActions>
        <FView fill center row>
          {!createNew && (
            <>
              <FButton onClick={_handleDelete}>
                <ButtonOutlineView row rounded w={isUpSm ? 250 : 130} selected={!updating && !deleting}>
                  <Delete color={updating || deleting ? 'disabled' : 'primary'} />
                  <FView size={8} />
                  <FText button primary grey500={updating || deleting}>
                    {deleting ? 'deleting...' : 'delete'}
                  </FText>
                </ButtonOutlineView>
              </FButton>
              <FView size={isUpSm ? 25 : 15} />
            </>
          )}
          <FButton onClick={handleSubmit}>
            <ButtonFillView rounded w={isUpSm ? 250 : 130} disabled={updating || deleting}>
              <FText button primaryContrast>
                {createNew ? (updating ? 'creating...' : 'create') : updating ? 'updating...' : 'update'}
              </FText>
            </ButtonFillView>
          </FButton>
        </FView>
      </DialogActions>
    </Dialog>
  )
}

const useStyle = makeStyles((theme) => ({
  inputStyle: {
    textAlign: 'center',
  },
}))
function getActiveAfterTimestamp(activeAfterIndex) {
  if (activeAfterIndex > 0) {
    const currentMoment = moment()

    switch (activeAfterIndex) {
      case 1:
        return currentMoment.subtract(5, 'minutes').toDate()

      case 2:
        return currentMoment.add(20, 'minutes').toDate()

      case 3:
        return currentMoment.add(40, 'minutes').toDate()

      case 4:
        return currentMoment.add(1, 'hours').toDate()

      case 5:
        return currentMoment.add(4, 'hours').toDate()

      case 6:
        const offUntilMoment = currentMoment.startOf('day').add(1, 'days').hour(3)
        return offUntilMoment.toDate()

      case 7:
      default:
        return moment(1e14).toDate()
    }
  }
}
export default ModifierEditDialog
