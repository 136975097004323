import React, {useState, useEffect} from 'react'
import {isEmpty} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {useTheme} from '@material-ui/core/styles'
import {Checkbox, CircularProgress, FormControlLabel, Divider} from '@material-ui/core'
import {Dialog, DialogContent, DialogTitle, Menu, MenuItem} from '@material-ui/core'
import {Search, Close, FilterList, Delete} from '@material-ui/icons'
import {colors} from 'styles'
import {FView, FNavBar, FButton, FText, ButtonFillView, FInput} from 'components'
import {moment} from 'f-utils'

const APPS = ['foodly-admin', 'foodly-mobile', 'foodly-web']
export default function Translations() {
  const theme = useTheme()
  const dispatch = useDispatch()
  // >>>>>>>>>>> Add Translation box >>>>>>>>>>>>>
  const [translationInput, setTranslationInput] = useState('')
  const [checkboxes, setCheckboxes] = useState({
    'foodly-admin': false,
    'foodly-mobile': false,
    'foodly-web': false,
  })
  const [isAdding, setIsAdding] = useState(false)
  const handleAdd = () => {
    if (isAdding) {
      return
    }
    if (translationInput === '') {
      alert('Please enter a word you would like to translate.')
      return
    }
    const hasOne = Object.values(checkboxes).some((v) => v)

    if (!hasOne) {
      alert('Please choose which app(s) you would like the word to be translated in.')
      return
    }
    setIsAdding(true)
    const translationData = {
      input: translationInput,
      target: checkboxes,
    }
    dispatch.translations
      .addTranslation(translationData)
      .catch((e) => {
        alert(e.message)
      })
      .finally(() => {
        setTranslationInput('')
        setIsAdding(false)
      })
  }
  // <<<<<<<<<<< End of add translation box <<<<<<<<<

  // >>>>>>>>>>> Start of translation lists >>>>>>>>>>
  const [searchInput, setSearchInput] = useState('')
  const [searchFilters, setSearchFilters] = useState({
    'foodly-admin': true,
    'foodly-mobile': true,
    'foodly-web': true,
  })
  const [openFilterList, setOpenFilterList] = useState(null)
  useEffect(() => {
    return dispatch.translations.subscribeTranslations()
  }, [dispatch.translations])
  const translations = useSelector(dispatch.translations.getTranslations)
  const filteredTranslationslist = Object.values(translations).filter((translation) => {
    return Object.entries(searchFilters ?? {}).some(
      ([filter, filterValue]) => filterValue && translation.target[filter],
    )
  })

  const searchFilteredTranslationsList = filteredTranslationslist.filter((translation) => {
    if (!searchInput) {
      return true
    }
    return Object.values(translation?.translated ?? {}).some((langValue) =>
      langValue.toLowerCase().includes(searchInput.toLowerCase()),
    )
  })
  // <<<<<<<<<< End of translation lists <<<<<<<<<<<

  // >>>>>>>>>>> Edit translation pop up >>>>>>>>>>>
  const [isEditSaving, setIsEditSaving] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isEditLocked, setIsEditLocked] = useState(true)
  const [isEditSavingInput, setIsEditSavingInput] = useState(false)
  const [editTranslationId, setEditTranslationId] = useState('')
  const editTranslationInfo = useSelector(() => dispatch.translations.getTranslation(editTranslationId))
  const [editInput, setEditInput] = useState('')
  const [editTranslated, setEditTranslated] = useState({})
  const [editTarget, setEditTarget] = useState({'foodly-admin': false, 'foodly-mobile': false, 'foodly-web': false})

  useEffect(() => {
    if (editTranslationInfo) {
      setEditInput(editTranslationInfo?.input)
      setEditTranslated(editTranslationInfo?.translated)
      setEditTarget(editTranslationInfo?.target)
    }
  }, [editTranslationInfo])
  // <<<<<<<<<<<< End of edit translation pop up elements <<<<<<<<<<<<<
  return (
    <FView fill bg={theme.palette.common.background}>
      <FNavBar shadow />
      <FView h={100} />
      <FView w="100%" maxWidth={550} bg={colors.white} p={15} mt={10} selfCenter>
        <FText h4 bold gutterBottom>
          Add Translation
        </FText>
        <FView row h={46}>
          <FView fill bc={colors.grey50} bw={1} rounded mr={10}>
            <FInput
              value={translationInput}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleAdd()
                }
              }}
              onChange={(e) => {
                if (isAdding) {
                  return
                }
                setTranslationInput(e.target.value)
              }}
              placeholder="Add a word to translate..."
            />
          </FView>
          <FView center>
            <FButton
              disabled={isAdding}
              onClick={(e) => {
                handleAdd()
              }}>
              <ButtonFillView w={100} rounded disabled={isAdding}>
                <FText bold white>
                  {isAdding ? 'Adding...' : 'Add'}
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
        <FView row flexWrap="wrap">
          {APPS.map((app) => {
            return (
              <FView mr={5} key={app}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{
                        color: colors.primary,
                      }}
                      checked={checkboxes[app]}
                      onChange={(e) => setCheckboxes({...checkboxes, [e.target.name]: e.target.checked})}
                      name={app}
                    />
                  }
                  label={app}
                />
              </FView>
            )
          })}
        </FView>
      </FView>
      <FView mt={15} w={'100%'} maxWidth={900} selfCenter>
        <FView row justifyBetween>
          <FView row w="50%" maxWidth={500} bc={colors.grey50} bg={colors.white} bw={1} rounded mr={10} mb={10}>
            <FView selfCenter ml={5}>
              <Search />
            </FView>
            <FInput
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search for a word..."
            />
          </FView>
          <FView row alignEnd selfCenter>
            <FView selfCenter mr={4}>
              <FText bold>Filters</FText>
            </FView>
            <FButton onClick={(e) => setOpenFilterList(e.currentTarget)}>
              <FilterList />
            </FButton>
            {/* Filter list menu */}
            <Menu anchorEl={openFilterList} open={!!openFilterList} onClose={() => setOpenFilterList(null)}>
              {APPS.map((app) => {
                return (
                  <MenuItem key={app}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{
                            color: colors.primary,
                          }}
                          checked={searchFilters[app]}
                          onChange={(e) => setSearchFilters({...searchFilters, [e.target.name]: e.target.checked})}
                          name={app}
                        />
                      }
                      label={app}
                    />
                  </MenuItem>
                )
              })}
            </Menu>
          </FView>
        </FView>
        {searchFilteredTranslationsList.map((translationData) => {
          if (!translationData) {
            return null
          }
          return (
            <FView key={translationData.id} bg={colors.white} row mb={10} alignCenter>
              <FView p={15} fill alignStart justifyBetween>
                <FText body1 bold>
                  Translation Input: {translationData.input}
                </FText>
                <FView h={100} minWidth={100} overflowY="auto">
                  <FView block>
                    {isEmpty(translationData.translated) ? (
                      <CircularProgress />
                    ) : (
                      Object.entries(translationData.translated).map(([lang, val]) => {
                        return <FText key={lang}>{`${lang}: ${val ? val : 'translating...'}`}</FText>
                      })
                    )}
                  </FView>
                </FView>
                <FView row flexWrap="wrap">
                  {APPS.map((app) => {
                    if (!translationData.target[app]) {
                      return null
                    }
                    return (
                      <FView mr={5} key={app} bg={colors.lightGrey25} rounded ph={8} pv={2}>
                        <FText>{app}</FText>
                      </FView>
                    )
                  })}
                </FView>
              </FView>
              <FView fill alignEnd justifyBetween mr={20}>
                <FButton onClick={() => dispatch.translations.deleteTranslation(translationData.id)}>
                  <FView row alignCenter p={4}>
                    <Delete color="primary" />
                    <FText primary body1 bold>
                      Delete
                    </FText>
                  </FView>
                </FButton>
                <FView>
                  <FButton
                    onClick={() => {
                      setEditTranslationId(translationData.id)
                      setIsEditLocked(true)
                      setIsEditOpen(true)
                    }}>
                    <ButtonFillView w={100} rounded>
                      <FText bold white>
                        Edit
                      </FText>
                    </ButtonFillView>
                  </FButton>
                </FView>
                <FView alignEnd mv={10}>
                  <FText>Created: {moment(translationData.createdAt?.toDate()).format('MMM D, YYYY h:mm A')}</FText>
                  <FText>Updated: {moment(translationData.updatedAt?.toDate()).format('MMM D, YYYY h:mm A')}</FText>
                </FView>
              </FView>
            </FView>
          )
        })}
      </FView>
      {/* Edit Translation dialog box */}
      <Dialog fullWidth open={isEditOpen} onClose={() => setIsEditOpen(false)}>
        <DialogTitle>
          <FView row alignCenter>
            <FView fill>
              <FText bold h5 alignCenter>
                Edit Translation
              </FText>
            </FView>
            <FButton onClick={() => setIsEditOpen(false)}>
              <Close />
            </FButton>
          </FView>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <FView row mb={25} alignCenter>
            <FView selfCenter>
              <FText bold>Input:</FText>
            </FView>
            <FView fill bc={colors.grey50} bw={1} rounded ml={7} mr={4} h={46}>
              <FInput
                disabled={isEditLocked}
                value={editInput}
                onChange={(e) => setEditInput(e.target.value)}
                placeholder="Edit word..."
              />
            </FView>
            <FView row>
              <FButton
                onClick={() => {
                  setIsEditLocked(!isEditLocked)
                }}>
                <ButtonFillView w={80} rounded>
                  <FText bold white>
                    {isEditLocked ? 'Unlock' : 'Lock'}
                  </FText>
                </ButtonFillView>
              </FButton>
              <FView mr={3} />
              <FButton
                disabled={isEditLocked || isEditSavingInput || editInput === editTranslationInfo.input}
                onClick={() => {
                  if (editInput === editTranslationInfo.input) {
                    return
                  }
                  if (editInput === '') {
                    alert('Please input a translation word')
                    return
                  }
                  setIsEditSavingInput(true)
                  dispatch.translations
                    .updateTranslationInput(editTranslationId, editInput)
                    .catch((e) => alert(e.message))
                    .finally(() => {
                      setIsEditSavingInput(false)
                    })
                }}>
                <ButtonFillView
                  w={85}
                  rounded
                  disabled={isEditLocked || isEditSavingInput || editInput === editTranslationInfo.input}>
                  <FText bold white>
                    Save Input
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
          </FView>
          <Divider />
          <FView p={15} h={190} overflowY="auto">
            <FView block>
              {isEmpty(editTranslated) ? (
                <FView fill center>
                  <CircularProgress />
                </FView>
              ) : (
                Object.entries(editTranslated).map(([lang, value]) => {
                  return (
                    <FView key={lang} row mb={4}>
                      <FView selfCenter>
                        <FText>{lang}:</FText>
                      </FView>
                      <FView fill bc={colors.grey50} bw={1} rounded ml={10}>
                        <FInput
                          disabled={!isEditLocked}
                          value={value ? value : 'translating...'}
                          onChange={(e) => {
                            setEditTranslated({...editTranslated, [lang]: e.target.value})
                          }}
                          placeholder={`Edit ${lang} word...`}
                        />
                      </FView>
                    </FView>
                  )
                })
              )}
            </FView>
          </FView>
          <FView row flexWrap="wrap" center>
            {APPS.map((app) => {
              return (
                <FView mr={5} key={app}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!isEditLocked}
                        style={{
                          color: isEditLocked ? colors.primary : colors.grey25,
                        }}
                        checked={editTarget[app]}
                        onChange={(e) => {
                          setEditTarget({
                            ...editTarget,
                            [app]: e.target.checked,
                          })
                        }}
                      />
                    }
                    label={app}
                  />
                </FView>
              )
            })}
          </FView>
          <FView row m={10}>
            <FView fill mh={10}>
              <FText>Created: {moment(editTranslationInfo?.createdAt?.toDate()).format('MMM D, YYYY h:mm A')}</FText>
              <FText>Updated: {moment(editTranslationInfo?.updatedAt?.toDate()).format('MMM D, YYYY h:mm A')}</FText>
            </FView>
            <FButton
              disabled={!isEditLocked || isEditSaving}
              onClick={() => {
                for (const [lang, val] of Object.entries(editTranslated)) {
                  if (!val) {
                    alert('Please enter a translation for ' + lang)
                    return
                  }
                }
                setIsEditSaving(true)
                dispatch.translations
                  .updateTranslation({
                    id: editTranslationId,
                    target: editTarget,
                    translated: editTranslated,
                  })
                  .catch((e) => alert(e.message))
                  .finally(() => {
                    setIsEditSaving(false)
                  })
              }}>
              <ButtonFillView w={145} disabled={!isEditLocked || isEditSaving} rounded>
                <FText bold white>
                  {isEditSaving ? 'Saving...' : 'Save Translations'}
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </DialogContent>
      </Dialog>
    </FView>
  )
}
