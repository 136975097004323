import React from 'react'
import {useDispatch} from 'react-redux'
import {css} from 'emotion'
import {Checkbox} from '@material-ui/core'
import {FView, FText, FButton} from 'components'
import {Edit, Delete} from '@material-ui/icons'
import {colors} from 'f-core/src/constants'
const RewardItem = ({
  allProducts,
  isEditLocked,
  locationId,
  restaurantId,
  rewardData,
  docId,
  setEditData,
  setIsCreateRewardOpen,
  setIsEdit,
  isOldReward, //TODO: remove after rewards are migrated
  setIsOldReward, //TODO: remove after rewards are migrated
}) => {
  const {
    appliedDefault = false,
    freeOnFirstOrder = false,
    imageUrl,
    qtyLimit = 100,
    minimumSubTotal = 0,
    points = 0,
    requiredOrderType,
    requiredPoints = 0,
    requiresLogin = false,
    rewardName,
    subtotalDiscountAmount,
    subtotalDiscountPercent,
    type = '',
    value,
    version = 1,
    visible = false,
    couponCode = '',
    qty,
    qtyLimitPerUser,
    // groupId = '',
    // activeUntil = null,
    isPublic = false,
    productIds = {},
    discountType,
    discountValue = null,
  } = rewardData

  const dispatch = useDispatch()
  const [rewardWith, redeemType, rewardCondition = 'Free'] = type.split('/')
  return (
    <FView bg="white" shadow10 rounded ph={24} pv={16} row justifyBetween>
      <FView fill>
        <FView row>
          {qty <= 0 && (
            <>
              <FText h6 bold color={qty <= 0 ? colors.ALERT : 'black'} gutterBottom>
                (Out of Stock)
              </FText>
              <FView w={8} />
            </>
          )}
          <FText h6 bold color={qty <= 0 ? colors.ALERT : 'black'} gutterBottom>
            {rewardName || '***No Reward Name'}
          </FText>
        </FView>
        {!!couponCode && !isPublic && (
          <FText bold gutterBottom>
            Coupon code: {couponCode}
          </FText>
        )}
        <FText body2 gutterBottom>
          Version: {version ?? 1}
        </FText>
        <FText body2 gutterBottom>
          Redeem Type: {redeemType}
        </FText>
        {redeemType === 'Product' ? (
          <FText body2 gutterBottom>
            {redeemType}: {allProducts[productIds]?.name || 'No Product Name'}
          </FText>
        ) : redeemType === 'Discount' ? (
          <FText body2 gutterBottom>
            {redeemType}: ${(subtotalDiscountAmount ?? value).toFixed(2)}
          </FText>
        ) : redeemType === 'ProductDiscount' ? (
          <FView>
            <FText body2 gutterBottom>
              Product:{' '}
              {Object.keys(productIds)
                .map((id) => allProducts[id]?.name || 'No Product Name')
                .join(', ')}
            </FText>
            <FText body2 gutterBottom>
              Discount amount: {'$' + discountValue || 'No Discount Amount'}
            </FText>
          </FView>
        ) : redeemType === 'ProductDiscountPercent' ? (
          <FView>
            <FText body2 gutterBottom>
              Product:{' '}
              {Object.keys(productIds)
                .map((id) => allProducts[id]?.name || 'No Product Name')
                .join(', ')}
            </FText>
            <FText body2 gutterBottom>
              Discount percent: {discountValue * 100}%
            </FText>
          </FView>
        ) : (
          redeemType === 'DiscountPercent' && (
            <FText body2 gutterBottom>
              {redeemType}: {(subtotalDiscountPercent ?? value) * 100}%
            </FText>
          )
        )}
        <FText body2 gutterBottom>
          Maximum per Order: {qtyLimit == null ? 'No Limit' : qtyLimit}
        </FText>
        <FText body2 gutterBottom>
          Required (Restaurant) Points: {requiredPoints || points}
        </FText>
        <FText body2 gutterBottom>
          Min Subtotal: ${(minimumSubTotal || 0).toFixed(2)}
        </FText>
        <FText body2 gutterBottom>
          Required Order Type: {(requiredOrderType === null && 'All') || rewardCondition}
        </FText>
        <FText body2 gutterBottom>
          QTY: {qty == null ? 'No Limit' : qty}
        </FText>
        <FText body2 gutterBottom>
          Limit Per User: {qtyLimitPerUser == null ? 'No Limit' : qtyLimitPerUser}
        </FText>
        {/* <FText body2 gutterBottom>
          Active Until: {activeUntil ? moment(activeUntil.toDate()).format('LLL') : 'Does not expire'}
        </FText>{' '} */}
        {/* <FText body2 gutterBottom>
          GroupId: {!!groupId ? groupId : 'No group Id'}
        </FText> */}
        <FText body2 gutterBottom>
          Options:
        </FText>
        <FView ml={15}>
          <OptionView label="Applied By Default" isChecked={appliedDefault} />
          <OptionView label="First Order" isChecked={freeOnFirstOrder || rewardWith === 'FreeFirstOrderOrPoints'} />
          <OptionView label="Requires Login" isChecked={requiresLogin} />
          <OptionView label="Visible" isChecked={visible} />
        </FView>
      </FView>
      <FView>
        <FView row alignCenter>
          <FView fill />
          <FView pr={15}>
            <FButton
              disabled={isEditLocked}
              onClick={() => {
                if (isOldReward) {
                  alert('Version 2 rewards can not longer be edited. Please delete and create a new reward')
                  return
                }
                const editData = {
                  rewardName: rewardName,
                  requiredPoints: points,
                  redeemType: redeemType,
                  value: value,
                  imageUrl: imageUrl,
                  orderType: requiredOrderType,
                  minimumSubTotal: minimumSubTotal,
                  qtyLimit: parseInt(qtyLimit),
                  checkboxes: {
                    'Applied By Default': appliedDefault,
                    'First Order': rewardWith === 'FreeFirstOrderOrPoints',
                    'Requires Login': requiresLogin,
                    Visible: visible,
                    qtyUnlimited: false,
                    qtyLimitUnlimited: false,
                    qtyLimitPerUserUnlimited: false,
                  },
                  docId: docId,
                  rewardId: rewardData.rewardId,
                  couponCode,
                  qty,
                  qtyLimitPerUser,
                  // groupId,
                  // activeUntil,
                  productIds,
                  discountType,
                  discountValue,
                }
                if (qty == null) {
                  editData.checkboxes['qtyUnlimited'] = true
                }
                if (qtyLimit == null) {
                  editData.checkboxes['qtyLimitUnlimited'] = true
                }
                if (qtyLimitPerUser == null) {
                  editData.checkboxes['qtyLimitPerUserUnlimited'] = true
                }
                if (subtotalDiscountAmount) {
                  editData.subtotalDiscountAmount = subtotalDiscountAmount
                } else if (subtotalDiscountPercent) {
                  editData.subtotalDiscountPercent = subtotalDiscountPercent
                }
                setEditData(editData)
                setIsEdit(true)
                setIsOldReward(isOldReward) //TODO: remove after rewards are migrated
                setIsCreateRewardOpen(true)
              }}>
              <FView row alignCenter>
                <Edit fontSize="small" color={isEditLocked ? 'disabled' : 'primary'} />
                <FView w={4} />
                <FText body1 primary={!isEditLocked} grey400={isEditLocked}>
                  Edit
                </FText>
              </FView>
            </FButton>
          </FView>
          <FView>
            <FButton
              disabled={isEditLocked}
              onClick={() => {
                if (isOldReward) {
                  dispatch.restaurants.deleteOldReward({restaurantId, locationId, docId})
                } else {
                  dispatch.restaurants.deleteReward({restaurantId, locationId, docId})
                }
              }}>
              <FView row alignCenter>
                <Delete fontSize="small" color={isEditLocked ? 'disabled' : 'primary'} />
                <FView w={4} />
                <FText body1 primary={!isEditLocked} grey400={isEditLocked}>
                  Delete
                </FText>
              </FView>
            </FButton>
          </FView>
        </FView>
        <FView>{imageUrl && <img src={imageUrl} alt="reward" className={classes.img} />}</FView>
      </FView>
    </FView>
  )
}

const OptionView = ({label, isChecked}) => {
  return (
    <FView row alignCenter maxWidth={200}>
      <FView fill>
        <FText body2>{label}</FText>
      </FView>
      <Checkbox disabled checked={isChecked} disableRipple color="primary" />
    </FView>
  )
}
const classes = {
  img: css({
    objectFit: 'contain',
    width: 170,
    height: 170,
  }),
}

export default RewardItem
