import React, {useState, useRef, useEffect} from 'react'
import {getCollection, FieldValue, getTransfersCreatedAtDesc} from 'f-core/src/config/firebase'
import {useDispatch} from 'react-redux'
import {useMediaQueryUp} from 'f-web/src/hooks'
import {useTheme, makeStyles} from '@material-ui/core/styles'
import {FView, FText, FButton, FTextField, ButtonFillView} from 'components'
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@material-ui/core'
import {IconButton} from '@material-ui/core'

import {Close, ArrowForward} from '@material-ui/icons'
import moment from 'moment'

let unsubTransferHistory = null

export default function TransferDialog({
  sourceAccountId,
  destinationAccountId,
  isTakeFunds,
  balance,
  open,
  handleClose,
  ownerEmail,
}) {
  const dispatch = useDispatch()
  const [creatingTransfer, setCreatingTransfer] = useState(false)
  const theme = useTheme()
  const classes = useStyle()
  const isUpSm = useMediaQueryUp('sm')

  const transferAmountInputRef = useRef(null)
  const descriptionInputRef = useRef(null)

  const [loading, setLoading] = useState(false)
  const [transferHistory, setTransferHistory] = useState([])
  const [sourceAccountName, setSourceAccountName] = useState(null)
  const [destinationAccountName, setDestinationAccountName] = useState(null)

  useEffect(() => {
    if (sourceAccountId) {
      getCollection('Accounts')
        .doc(sourceAccountId)
        .get()
        .then((snap) => {
          const accountData = snap?.data()
          setSourceAccountName(accountData?.name ?? accountData?.ownerEmail ?? 'Not Found')
        })
    } else {
      setSourceAccountName('')
    }
  }, [sourceAccountId])

  useEffect(() => {
    if (destinationAccountId) {
      getCollection('Accounts')
        .doc(destinationAccountId)
        .get()
        .then((snap) => {
          const accountData = snap?.data()
          setDestinationAccountName(accountData?.name ?? accountData?.ownerEmail ?? 'Not Found')
        })
    } else {
      setDestinationAccountName('')
    }
  }, [destinationAccountId])

  useEffect(() => {
    if (open) {
      unsubTransferHistory && unsubTransferHistory()
      setLoading(true)
      unsubTransferHistory = getTransfersCreatedAtDesc({limit: 25, sourceAccountId, destinationAccountId}).onSnapshot(
        (snap) => {
          setLoading(false)
          const transfers = []
          for (const doc of snap.docs) {
            const transfer = doc.data()
            transfer.id = doc.id
            transfers.push(transfer)
          }
          setTransferHistory(transfers)
        },
      )
    } else {
      setTransferHistory([])
    }
    return () => {
      unsubTransferHistory && unsubTransferHistory()
    }
  }, [open, sourceAccountId, destinationAccountId])

  return (
    <Dialog fullWidth fullScreen={!isUpSm} open={open} onClose={handleClose}>
      <DialogTitle>
        <FView alignCenter row justifyBetween>
          <FView fill>
            <FText medium h5>
              {isTakeFunds ? 'Take funds from' : 'Send funds to'} {destinationAccountName}
            </FText>
          </FView>
          <IconButton aria-label="close transfer dialog" onClick={handleClose}>
            <FText black noLineHeight>
              <Close />
            </FText>
          </IconButton>
        </FView>
      </DialogTitle>
      <DialogContent>
        <FView fill>
          <FText body1>Restaurant Balance: ${balance?.toFixed(2)}</FText>
          <FView size={16} />
          <FView row alignCenter>
            <FTextField value={sourceAccountName ?? ''} label="Source Account" margin="none" />
            <FView center p={10}>
              <ArrowForward />
            </FView>
            <FTextField value={destinationAccountName ?? ''} label="Destination Account" margin="none" />
          </FView>
          <FView row alignCenter>
            <FTextField
              inputRef={transferAmountInputRef}
              inputProps={{
                min: 0.01,
                step: 0.01,
                className: classes.inputStyle,
              }}
              required
              label="Amount to Transfer"
              type="number"
            />
            <FView w={90} />
            <FTextField inputRef={descriptionInputRef} required label="Description" />
          </FView>
          <FView size={16} />
          <Divider />
          <FView size={16} />
          <FText medium h5>
            Transfer History
          </FText>
          <FView h={200} overflowY="auto">
            <FView block>
              {loading && (
                <FView alignCenter p={15}>
                  <CircularProgress />
                </FView>
              )}
              {transferHistory.map((transfer) => {
                return (
                  <FView key={transfer.id} mv={5}>
                    <FView>
                      <FView row justifyBetween alignCenter mv={5}>
                        <FText body1>{moment(transfer.createdAt?.toDate()).format('MM/DD LT')}</FText>
                        <FText body1>{transfer.authorEmail}</FText>
                        <FText body1>${transfer.amount?.toFixed(2)}</FText>
                        <FText body1>{transfer.delivery?.state ?? '...'}</FText>
                        {transfer.delivery?.state === 'ERROR' && (
                          <FButton
                            onPress={() => {
                              getCollection('Transfers').doc(transfer.id).update({
                                'delivery.state': 'RETRY',
                              })
                            }}>
                            <ButtonFillView ph={10} rounded>
                              <FText primaryContrast>RETRY</FText>
                            </ButtonFillView>
                          </FButton>
                        )}
                      </FView>
                      <FText body1>Description: {transfer.description}</FText>
                      {transfer?.delivery?.error && (
                        <FText body1 error>
                          Error: {transfer.delivery.error}
                        </FText>
                      )}
                    </FView>
                    <Divider />
                  </FView>
                )
              })}
            </FView>
          </FView>
        </FView>
      </DialogContent>
      <Divider />
      <DialogActions>
        <FView fill center bg={theme.palette.common.white} pv={isUpSm ? 15 : 5} row>
          <FButton
            onPress={() => {
              if (creatingTransfer) {
                return
              }
              const description = descriptionInputRef.current.value
              if (!sourceAccountId || !destinationAccountId) {
                alert('Missing sourceAccountId or destinationAccountId')
                return
              }

              const amount = Number(transferAmountInputRef.current.value)
              if (!description || amount < 0 || isNaN(amount) || !amount) {
                alert('Missing or invalid field')
                return
              }
              const authorId = dispatch.portal.getUserId()
              const authorEmail = dispatch.portal.getUserEmail()
              if (!authorId || !authorEmail) {
                alert('Missing authorId or authorEmail')
                return
              }
              setCreatingTransfer(true)
              transferAmountInputRef.current.value = null
              getCollection('Transfers')
                .add({
                  amount,
                  authorEmail,
                  authorId,
                  createdAt: FieldValue.serverTimestamp(),
                  currency: 'CAD',
                  description,
                  destinationAccountId,
                  sourceAccountId,
                  type: 'BalanceTransfer',
                })
                .finally(() => {
                  setCreatingTransfer(false)
                })
            }}>
            <ButtonFillView rounded w={isUpSm ? 250 : 130} disabled={creatingTransfer}>
              <FText body1 bold primaryContrast>
                {isTakeFunds
                  ? creatingTransfer
                    ? 'Taking Funds...'
                    : 'Take Fund'
                  : creatingTransfer
                  ? 'Sending Funds...'
                  : 'Send Fund'}
              </FText>
            </ButtonFillView>
          </FButton>
        </FView>
      </DialogActions>
    </Dialog>
  )
}

const useStyle = makeStyles((theme) => ({
  inputStyle: {
    textAlign: 'center',
  },
}))
