import React from 'react'
import {FText, FView, FLink} from 'components'
import {moment, formatPhoneNumber} from 'f-utils'
import {useMediaMinMD} from 'f-web/src/hooks'
import {colors} from 'styles'
import {CalendarToday} from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles'
import OrderProductsDetails from './OrderProductsDetails'

export default function OrderItemView({orderData}) {
  const isPaid = orderData?.paymentStatus === 'paid' || orderData?.paymentStatus === 'paid-online'
  const isMinMD = useMediaMinMD()
  const classes = useStyles()
  return (
    <FView fill>
      <FView fill row={isMinMD}>
        <FView fill>
          <FText body1 bold>
            #{orderData.orderNumber}
          </FText>
          <FText body1>{orderData.restaurantName}</FText>
          <FView alignStart>
            <a href={'tel:' + orderData.restaurantPhoneNumber}>
              <FText body1 primary>
                {formatPhoneNumber(orderData.restaurantPhoneNumber)}
              </FText>
            </a>
          </FView>
          <FText span>
            {'Order Type: '}
            <FText bold inline>
              {orderData.orderType}
            </FText>
          </FText>

          <FView h={8}></FView>
          {orderData.orderType === 'Delivery' && (
            <FView>
              <FText span>{'Delivery Address: '}</FText>
              <FText bold inline>
                {orderData.deliveryAddress}
              </FText>
              {orderData.deliveryUnit && (
                <>
                  <FText span>{'Unit: '}</FText>
                  <FText bold inline>
                    {orderData.deliveryUnit}
                  </FText>
                </>
              )}
              {orderData.deliveryInstructions && (
                <>
                  <FText span>{'Instructions: '}</FText>
                  <FText bold inline>
                    {orderData.deliveryInstructions}
                  </FText>
                </>
              )}
            </FView>
          )}
          <FView size={15} />
          {orderData.orderTimeType === 'Scheduled' && (
            <FView row alignCenter>
              <CalendarToday className={classes.calendar} />
              <FView w={10} />
              <FText bold color={colors.warning}>
                SCHEDULED ORDER
              </FText>
            </FView>
          )}
          {(orderData.orderTimeType === 'Scheduled' || orderData.status === 'Preparing') && (
            <FView row>
              <FText>Prepare by: </FText>
              <FView w={4} />
              <FText bold>
                {orderData.orderTimeType === 'Scheduled'
                  ? moment(orderData.scheduledOrderTimestamp).format('ddd, MMM D, LT')
                  : moment(orderData.completionTime).calendar()}
              </FText>
            </FView>
          )}
          <FText span>
            {'Status: '}
            <FText bold inline>
              {orderData.status}
            </FText>
          </FText>
          {!!orderData.notes && (
            <FView>
              <FView size={15} />
              <FText>Special Request:</FText>
              <FText bold>{orderData.notes}</FText>
            </FView>
          )}
          <FView size={15} />
          <FText body2>{orderData.name}</FText>
          <FView alignStart>
            <a href={'tel:' + orderData.phoneNumber}>
              <FText body2 primary>
                {formatPhoneNumber(orderData.phoneNumber)}
              </FText>
            </a>
          </FView>
          {orderData.paymentMethod?.startsWith('online') && (
            <FText bold inline>
              {orderData.paymentStatus === 'free' ? 'FREE' : 'Paid Online'}
            </FText>
          )}
          {orderData.paymentMethod?.startsWith('inperson') && (
            <FText bold inline error={!isPaid}>
              Cash {isPaid ? 'Paid' : 'Unpaid'}
            </FText>
          )}
          <FView size={15} />
          <FLink href={`/users?userId=${orderData.userId}`}>
            <FText bold info>
              view customer profile
            </FText>
          </FLink>
        </FView>
      </FView>
      <OrderProductsDetails orderData={orderData} />
    </FView>
  )
}

const useStyles = makeStyles({
  calendar: {
    color: colors.warning,
  },
})
