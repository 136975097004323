import React from 'react'
import {NavLink as RouterNavLink} from 'react-router-dom'
import {Link} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'

export default function FNavLink(props) {
  const classes = useStyles()
  return (
    <Link
      component={RouterNavLink}
      underline="none"
      color={'inherit'}
      activeClassName={props.secondary ? classes.activeSecondaryLink : classes.activePrimaryLink}
      {...props}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  activePrimaryLink: {color: theme.palette.primary.main},
  activeSecondaryLink: {color: theme.palette.secondary.main},
}))
