import React from 'react'
import {FView, FText} from 'components'
import {css} from 'emotion'
import {colors} from 'styles'

const RestaurantItemView = ({restaurantIconUrl, restaurantName, address, isVisible}) => {
  return (
    <>
      <FView row ph={15} bg={colors.white} h={115} maxHeight="100vw" alignCenter>
        <img src={restaurantIconUrl} className={classes.imgStyle} alt={restaurantName} />
        <FView size={25} />
        <FView>
          <FText h6>{restaurantName}</FText>
          <FText subtitle1>{address}</FText>
          {!isVisible && (
            <FText body1 bold error>
              Hidden
            </FText>
          )}
        </FView>
      </FView>
      <FView size={8} />
    </>
  )
}

const classes = {
  imgStyle: css({
    objectFit: 'contain',
    width: 85,
    height: 85,
  }),
}

export default RestaurantItemView
